import { observer } from "mobx-react";
import { ROLE_LIST } from "constants/role-list";
import { FC, useEffect } from "react";

import dayjs from "dayjs";
import store from "./store";
import styled from "styled-components";
import topStore from "../store";
import GraphView from "./GraphView";
import ErrorIcon from "@mui/icons-material/Error";
import DimensionsList from "./DimensionsList";

type PrioritizeContentProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const PrioritizeContent: FC<PrioritizeContentProps> = observer((props) => {

  const sheduledCallData = () => {
    topStore.getSurveyResponseCount(props.localFilters);
    store.getQuestionList({
      survey_id: topStore.selectedSurvey.value,
      attributes: props.localFilters,
      questionId: store.selectedQuestion,
      viewType: topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
    });
    store.getDimensionList({
      survey_id: topStore.selectedSurvey.value,
      attributes: props.localFilters,
      questionId: store.selectedQuestion,
      viewType: topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
    });
    store.getChartData({
      survey_id: topStore.selectedSurvey.value,
      attributes: props.localFilters,
      questionId: store.selectedQuestion,
      dimensions: store.prioritizeList
        .map((el) => {
          if (store.selectedPrioritize.includes(el.id)) {
            return el.name;
          }
        })
        .filter((item) => item),
      viewType: topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
    });

    store.setData(
      `${dayjs().format("h")}:${Number(dayjs().format("m")) >= 30 ? "30" : "00"} ${dayjs().format(
        "A"
      )}`,
      "lastUpdated"
    );
  };

  useEffect(() => {
    if (topStore.activeTab === "overview" && topStore.isSurveyHaveResponseCount) {
      var currentTime = new Date();

      // Calculating the time until the next function call
      const min = currentTime.getMinutes();
      const diffMin = min > 30 ? 60 - min : 30 - min;
      const timeToNextCall = diffMin === 0 ? 60 * 30 * 1000 : diffMin * 60 * 1000;

      setTimeout(function () {
        // Running a function
        sheduledCallData();

        // Setting the interval for the next execution
        setInterval(sheduledCallData, 60 * 30 * 1000);
      }, timeToNextCall);
    }
  }, [topStore.activeTab, topStore.isSurveyHaveResponseCount]);

  return (
    <Content>
    {!topStore.isSurveyHaveResponseCount ? (
      <EmptyState>
        <ErrorIcon />
        <EmptyStateText>
          Results will be shown when there are {topStore.selectedSurvey?.threshold} or more
          responses
        </EmptyStateText>
      </EmptyState>
    ) : store.prioritizeList.length < 3 || !store.selectedQuestion ? (
      <CenteredEmptyState>
        <EmptyText>
          Prioritize is not available as less than 3 dimensions are available in This review.
        </EmptyText>
      </CenteredEmptyState>
    ) : (
      <>
        <GraphView />
        <DimensionsList />
      </>
    )}
    </Content>
  );
});

export default PrioritizeContent;

const Content = styled.div`
  width: 100%;
  display: flex;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  min-height: 312px;
`;

const EmptyState = styled.div`
  height: 40px;
  width: 100%;
  margin-top: 24px;
  background-color: var(--colorNeutralForeground4);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const CenteredEmptyState = styled.div`
  height: 100%;
  min-height: 312px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const EmptyStateText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;

const EmptyText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;

import { FC, useMemo, useEffect } from "react";

import styled from "styled-components";
import { observer } from "mobx-react";
import EmployeeImportManager from "features/Notification/import-manager/store";

type ReviewChangesProps = {
  fileName: string;
  confirmClicked: () => void;
  discardClicked: () => void;
  success: boolean;
  changelogs: any[];
};

const ReviewChanges: FC<ReviewChangesProps> = observer((props) => {
  const formName = "ReviewChanges";

  return (
    <ReviewChangesWrapper id={`${formName}_reviewChangesWrapper`}>
      <Content id={`${formName}_content`}>
        <SubHeader id={`${formName}_subHeader`}>Review import</SubHeader>
        <Row id={`${formName}_statRow`}>
          <StatisticContainer id={`${formName}_statisticContainerUpdated`}>
            <span id={`${formName}_updatedCount`}>
              {EmployeeImportManager.importReview?.countUpdate ?? "-"}
            </span>
            <p id={`${formName}_updatedCountUpdated`}>employees to be updated</p>
          </StatisticContainer>
          <StatisticContainer id={`${formName}_statisticContainerAdded`}>
            <span id={`${formName}_addedCount`}>
              {EmployeeImportManager.importReview?.countAdd ?? "-"}
            </span>
            <p id={`${formName}_addedCountEmployeesAdded`}>employees to be added</p>
          </StatisticContainer>
          <StatisticContainer id={`${formName}_statisticContainerRemoved`} $notLast={true}>
            <span id={`${formName}_removedCount`}>
              {EmployeeImportManager.importReview?.countDelete ?? "-"}
            </span>
            <p id={`${formName}_removedCountemployees`}>employees to be removed</p>
          </StatisticContainer>
        </Row>
      </Content>
    </ReviewChangesWrapper>
  );
});

const ReviewChangesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  background-color: var(--colorNeutralForeground8);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  color: var(--colorNeutralForeground1);
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
`;

const SubHeader = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
`;

const StatisticContainer = styled.div<{ $notLast?: boolean }>`
  width: 100%;
  height: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 1rem;
  background-color: var(--colorNeutralBackground1);
  margin-top: 20px;

  span,
  p {
    text-align: center;
  }

  span {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    color: var(--colorNeutralForeground2);
  }

  p {
    max-width: 120px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
`;

export default ReviewChanges;

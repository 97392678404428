import React from 'react';
import { observer } from "mobx-react";
import { FC, ReactElement, useEffect, useState } from "react";
import storeResponse from "./store";
import topStore from "../store";
import styled from "styled-components";
import PDFSubHeader from "../pdfExport/PDFSubHeader";
import PDFHeader from "../pdfExport/PDFHeader";
import ReactDOM from "react-dom";
import { QuestionElementsRenderer } from "./ScoredQuestionsTab/QuestionElementsRenderer";
import { OtherElementRenderer } from './OthersTab/OtherElementRenderer';
import { OpenEndedElementRenderer } from './OpenEndedTab/OpenEndedElementRenderer';
import responsesStore from './store';
type PDFResponsesContentProp = {
    localFilters: { id: number; name: string; segments: string[] }[];
}

interface Page {
  content: ReactElement;
  name: string;
}

const A4_HEIGHT_MM = 500;
const HEADER_HEIGHT_MM = 40; // Approximate height of header
const SUBHEADER_HEIGHT_MM = 20; // Approximate height of subheader
const CONTENT_PADDING_MM = 0; // Padding for content
const AVAILABLE_HEIGHT_MM = A4_HEIGHT_MM - CONTENT_PADDING_MM;

interface PageSection {
    title: string;
    content: ReactElement;
    height: number;
}

const PDFResponsesContent: FC<PDFResponsesContentProp> = observer((props): ReactElement => {
    const [pages, setPages] = useState<Page[]>([]);

    const measureComponent = async (component: React.ReactNode): Promise<number> => {
        return new Promise((resolve) => {
            const hiddenContainer = document.createElement('div');
            hiddenContainer.style.position = 'absolute';
            hiddenContainer.style.visibility = 'hidden';
            hiddenContainer.style.width = '300mm';
            document.body.appendChild(hiddenContainer);

            ReactDOM.render(component as ReactElement, hiddenContainer, () => {
                const heightPx = hiddenContainer.getBoundingClientRect().height;
                ReactDOM.unmountComponentAtNode(hiddenContainer);
                hiddenContainer.remove();
                // Convert pixels to millimeters
                resolve(heightPx / 3.779528);
            });
        });
    };

    const createPageContent = (sections: PageSection[], showHeader: boolean): ReactElement => (
        <A4Container>
            {showHeader && <PDFHeader />}
            <ContentContainer>
                {sections.map((section, index) => (
                    <div key={index}>
                        {section.title && <PDFSubHeader title={section.title} className={`${section.title.toLowerCase().replace(' ', '-')}-header`} />}
                        {section.content}
                    </div>
                ))}
            </ContentContainer>
        </A4Container>
    );

    const processContentIntoPages = async (
        contents: React.ReactNode[],
        sectionTitle: string,
        pageName: string,
        showHeaderCondition: boolean
    ): Promise<Page[]> => {
        const pages: Page[] = [];
        let currentPageSections: PageSection[] = [];
        let currentPageHeight = 0;
        let pageCounter = 1;
        let isFirstPage = true;

        for (let i = 0; i < contents.length; i++) {
            const item = contents[i];
            const contentHeight = await measureComponent(item);
            const headerHeight = isFirstPage ? (showHeaderCondition ? HEADER_HEIGHT_MM : 0) : 0;
            const subHeaderHeight = isFirstPage ? SUBHEADER_HEIGHT_MM : 0;
            const availableHeight = AVAILABLE_HEIGHT_MM - headerHeight - subHeaderHeight;

            // Check if content needs to be split
            if (contentHeight > availableHeight) {
                // If there's existing content on the current page, create a new page for it
                if (currentPageSections.length > 0) {
                    pages.push({
                        content: createPageContent(currentPageSections, showHeaderCondition && isFirstPage),
                        name: `${pageName}-${pageCounter}`
                    });
                    pageCounter++;
                    isFirstPage = false;
                }

                // Split the content if needed
                const tempContainer = document.createElement('div');
                ReactDOM.render(item as ReactElement, tempContainer);
                const children = Array.from(tempContainer.children);
                let currentHeight = 0;
                let currentElements: Element[] = [];

                children.forEach((child) => {
                    const childHeight = child.getBoundingClientRect().height / 3.779528;
                    if (currentHeight + childHeight > availableHeight && currentElements.length > 0) {
                        // Create a new page with current elements
                        const pageContent = <div>{currentElements.map(el => React.createElement('div', {
                            dangerouslySetInnerHTML: { __html: el.outerHTML }
                        }))}</div>;
                        pages.push({
                            content: createPageContent([{ title: '', content: pageContent as ReactElement, height: currentHeight }], false),
                            name: `${pageName}-${pageCounter}`
                        });
                        pageCounter++;
                        currentElements = [child];
                        currentHeight = childHeight;
                    } else {
                        currentElements.push(child);
                        currentHeight += childHeight;
                    }
                });

                // Add remaining elements if any
                if (currentElements.length > 0) {
                    const pageContent = <div>{currentElements.map(el => React.createElement('div', {
                        dangerouslySetInnerHTML: { __html: el.outerHTML }
                    }))}</div>;
                    currentPageSections = [{
                        title: '',
                        content: pageContent as ReactElement,
                        height: currentHeight
                    }];
                }

                ReactDOM.unmountComponentAtNode(tempContainer);
            } else {
                // Add content to current page if it fits
                if (currentPageHeight + contentHeight > availableHeight) {
                    pages.push({
                        content: createPageContent(currentPageSections, showHeaderCondition && isFirstPage),
                        name: `${pageName}-${pageCounter}`
                    });
                    currentPageSections = [];
                    currentPageHeight = 0;
                    pageCounter++;
                    isFirstPage = false;
                }

                currentPageSections.push({
                    title: i === 0 && isFirstPage ? sectionTitle : '',
                    content: item as ReactElement,
                    height: contentHeight
                });
                currentPageHeight += contentHeight;
            }
        }

        // Add remaining content
        if (currentPageSections.length > 0) {
            pages.push({
                content: createPageContent(currentPageSections, showHeaderCondition && isFirstPage),
                name: `${pageName}-${pageCounter}`
            });
        }

        return pages;
    };

    useEffect(() => {
        const processAllPages = async () => {
            let allPages: Page[] = [];
            const scoredResponses = storeResponse.getScoredResponses();
            const openEndedResponses = storeResponse.getOpenEndedResponses();
            const otherResponses = storeResponse.getOtherResponses();

            // Scored questions
            let scoredPages: Page[] = [];
            if (scoredResponses.length > 0) {
                const scoredRenderer: React.ReactNode[] = [];
                scoredResponses.map((dimension) => {
                    scoredRenderer.push(... new QuestionElementsRenderer({
                        dimension: dimension,
                        localFilters: props.localFilters,
                        isCommentsExpanded: true,
                        isResponseExpanded: true,
                    }).render());
                });

                scoredPages = await processContentIntoPages(
                    scoredRenderer,
                    "Scored questions",
                    "scored-responses",
                    true
                );
            }

            let openEndedPages: Page[] = [];
            if (openEndedResponses.length > 0) {
                const openEndedRenderer: React.ReactNode[] = [];
                openEndedResponses.forEach((dimension) => {
                    openEndedRenderer.push(<Text>{dimension.title || ''}</Text>);
                    openEndedRenderer.push(...new OpenEndedElementRenderer({
                        dataChart: dimension.data.graphData,
                        respondedCount: dimension.respondedCount - (typeof dimension.data?.Responses?.nilCount === 'number' ? dimension.data?.Responses?.nilCount : dimension.data?.Responses?.nilCount?.all || 0),
                        responses: dimension.data?.Responses?.data || [],
                        localFilters: props.localFilters,
                        nilCount: typeof dimension.data?.Responses?.nilCount === 'number' ? {
                            positive: 0,
                            neutral: 0,
                            negative: 0,
                            mixed: 0,
                            unavailable: 0,
                            total: dimension.data?.Responses?.nilCount,
                            all: dimension.data?.Responses?.nilCount,
                        } : (dimension.data?.Responses?.nilCount || {
                            positive: 0,
                            neutral: 0,
                            negative: 0,
                            mixed: 0,
                            unavailable: 0,
                            total: 0,
                            all: 0,
                        }),
                    }).render());
                });

                openEndedPages = await processContentIntoPages(
                    openEndedRenderer,
                    "Open ended questions",
                    "open-ended-responses",
                    scoredResponses.length === 0
                );
            }

            let otherPages: Page[] = [];
            if (otherResponses.length > 0) {
                const otherRenderer: React.ReactNode[] = [];
                otherResponses.map((dimension, index) => {
                    // Add missing properties to each question in questionsList
                    const enhancedDimension = {
                        ...dimension,
                        questionsList: dimension.questionsList.map(question => ({
                            ...question,
                            meaninglessCount: 0,
                            otherOptionsMeaninglessCount: 0
                        }))
                    };
                    
                    otherRenderer.push(...new OtherElementRenderer({
                        dimension: enhancedDimension,
                        id: `group-anchor-${dimension.dimension}`,
                        localFilters: props.localFilters,
                        isForceExpand: true,
                        className: `other-section-${dimension.dimension}`,
                        disableInteraction: false,
                        isLastGroup: index === otherResponses.length - 1,
                    }).render());
                });


                otherPages = await processContentIntoPages(
                        otherRenderer, 
                        "Other questions",
                        "other-responses", 
                        scoredResponses.length === 0 && openEndedResponses.length === 0);
            }

            allPages = [...allPages, ...scoredPages , ...openEndedPages, ...otherPages];
            setPages(allPages);
            topStore.setResponsesPagesName(allPages.map((page) => page.name));
        };

        processAllPages();
    }, [props.localFilters, responsesStore.dashboardResponsesRaw]);

    return (
        <>
            {pages.map((page, index) => (
                <div key={index} data-page-name={page.name} className={page.name}>
                    {page.content}
                </div>
            ))}
        </>
    );
});

export  default PDFResponsesContent;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 16px;
  white-space: nowrap;
  padding: 12px 0;
`;

const A4Container = styled(Container)`
    width: 100%;
    min-width: 300mm;
    min-height: 390mm;
    margin: 10mm auto;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    page-break-after: always;
    
    @media print {
        margin: 0;
        box-shadow: none;
    }
`;

const ContentContainer = styled.div`
    padding: 10mm;
`;
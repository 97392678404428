import { FC } from "react";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { TScoredQuestionsDimension } from "types/dashboard";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import { observer } from "mobx-react";

type TitleCardProps = {
  dimension: TScoredQuestionsDimension;
};

const TitleCard: FC<TitleCardProps> = observer((props) => {

    return (
        
      <TitleContainer>
      <TitleWrapper>
        <Title>{props.dimension?.dimension}</Title>
        {props.dimension.type === "enps" && (
          <CustomWidthTooltip
            title="The employee Net Promoter Score (eNPS) is a concept that based on the Net Promoter Score (NPS). 
          It allows companies to measure and get a quick easy snapshot of employee advocacy within their company."
            placement="top"
            arrow
          >
            <StyledInfoIcon />
          </CustomWidthTooltip>
        )}
      </TitleWrapper>
      {props.dimension.type !== "enps" && (
        <QuestionCounter>
          ({props.dimension?.questionsList.length}{" "}
          {props.dimension?.questionsList.length > 1 ? "questions" : "question"})
        </QuestionCounter>
      )}
    </TitleContainer>
    );
});

export default TitleCard;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorPaletteVioletBackground1);
`;

const QuestionCounter = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorPaletteVioletBackground1);
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
    marginLeft: "6px",
  },
});

const StyledInfoIcon = styled(InfoIcon)`
  color: var(--colorBrandForeground1);
  font-size: 20px !important;
  margin-left: 6px;
`;

import { API_URL, API_TEMPLATE_URL_GO } from "constants/config";
import http from "api/https";
import { createAuthService, Environment, Platform } from "@engagerocketco/auth-sdk";

const getExcelMilestoneRecepients = (survey_id: number) => {
  if (http.isAPIGO) {
    var url = API_TEMPLATE_URL_GO + `/sm/sm_project/GetRecipientsMilestoneExcel?survey_id=${survey_id}`;
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${createAuthService({
          platform: Platform.MAIN,
          environment: process.env.REACT_APP_ENVIRONMENT as Environment,
        }).getStoredTokens().accessToken}`,
      }
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    });
  }
  else {
    var url = API_URL + `/employees/GetRecepientsMilestoneExcel?survey_id=${survey_id}`;
    return fetch(url).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    });
  }

};

export default getExcelMilestoneRecepients;

import React, { FC } from "react";
import { Dialog, Divider, DialogActions } from "@mui/material";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";
import store from "./store";
import navStore from "../../store";
import BaseEmployeeAddEdit from "./base";
import AttributesForm from "./attributes";
import CustomButton from "components/button/Button";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MtmLookupRoles from "./mtmLookupRoles";
import LaunchIcon from "@mui/icons-material/Launch";
import AddEditRoleName from "features/EntityPermissions/Roles/AddEditRole/popupAddEditRoleName";
import { observer } from "mobx-react";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";


type EmployeeAddEditPopupProps = {
  open: boolean;
  handleHide: (state: boolean) => void;
  onSavedClicked: (id: number) => void;
  onBtnCancelClick: () => void;
  idEmployee: number;
  idEntity: number;
};

const EmployeeAddEditPopup: FC<EmployeeAddEditPopupProps> = observer((props) => {
  React.useEffect(() => {
    if (props.open) {
      const fetchData = async () => {
        await store.loadEmployee(props.idEmployee);
        store.loadAttributes(props.idEmployee, StoreLayout.currentEntityId);
        store.getRoles();
        store.loadStructures();
      };
      fetchData();
    } else {
      store.clearStore();
    }
  }, [props.open]);

  return (
    <StyledDialog
      open={props.open}
      onClose={() => {
        props.handleHide(false);
      }}
    >
      <StyledDialogContent dividers>
        <BaseEmployeeAddEdit />
        <StyledDivider />
        <AttributesForm />
        <StyledDivider />
        <AddEditRoleName
          openPanel={store.AddRolePanel}
          onBtnCancelClick={() => store.onAddRoleCancelClick()}
          onClose={() => store.onAddRoleCancelClick()}
          onSaved={() => store.onAddRoleSaveClick()}
          idRole={0}
          roleName={""}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <ButtonWrapper>
          <StyledNextButton
           id="EmployeesList_AddEditPopUp_SaveChangesBtn"
            variant="contained"
            disabled={!StorePermission.entityPermissions.editEmployee.hasPermission}
            onClick={async () => await store.saveEmployee(props)}
          >
            Save changes
          </StyledNextButton>
          <StyledCancelButton
          id="EmployeesList_AddEditPopUp_ContinueWithoutSavingBtn"
          variant="outlined" onClick={() => props.onBtnCancelClick()}>
            Continue without saving
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledDialogActions>
    </StyledDialog>
  );
});

export default EmployeeAddEditPopup;

const StyledDialogActions = styled(DialogActions)`
  box-shadow: -3px -6px 10px rgb(0 0 0 / 0.2);
  padding: 10px 30px !important;
`;

const StyledDialog = styled(Dialog)`
  left: auto !important;

  .MuiPaper-root {
    width: 100%;
    height: 100%;
    max-height: none;
    max-width: 700px;
    margin: 0px;
    border-radius: 0px;
  }
  .MuiDialog-container {
    justify-content: flex-start;
  }
  .MuiDialogContent-root {
    padding: 0px;
    border: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  padding: 60px 30px 30px 30px !important;
`;

const StyledDivider = styled(Divider)`
  color: var(--colorNeutralForeground4);
  margin: 30px 0px 24px 0px !important;
`;

const PermissionRoles = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LookupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 9px;
  color: var(--colorBrandForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const Header = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  padding: 5.5px 12px !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2) !important;
  border: 1px solid var(--colorNeutralForeground5) !important;
`;

const StyledNextButton = styled(CustomButton)`
  padding: 5.5px 46.5px !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

import http from "api/https";

export const getAllQuestionDimensions = (filterTemplate: boolean): Promise<any> => {
  let filter = ""
  if (filterTemplate) {
    filter += "?filter_template=true"
  }

  if(http.isAPIGO){
    return http.getGO("/template/question_dimension" + filter);
  } else {
    return http.get("/question_dimension/GetAll");
  }
};

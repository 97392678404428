import NotificationStore, { ImportReview } from "../store";
import { EmployeeImportUIState, FileUploaded } from "types/employee-import.type";
import { makeAutoObservable, reaction } from "mobx";
import { importJobApi } from "api/employeeImport/useImportJob";
import { importStatusApi } from "api/employeeImport/useImportStatus";
import { useAddEditImportStatus } from "api/employeeImport/useAddEditImportStatus";
import { useGeneratePresignURL } from "api/useGeneratePresignURL";
import { useUploadS3 } from "api/useUploadS3";
import { SELECTED_ACTION_REQUIRED } from "constants/import-emploee";
import MainStore from "MainStore";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { v4 as uuidv4 } from "uuid";
import useCancelImport from "api/employeeImport/useCancelImport";
import useConfirmImport from "api/employeeImport/useConfirmImport";
import { ImportStatusType } from "constants/import-status.type";
import { ImportStatusTypeKeys } from "types/import-status-type-key";
import { ImportStatus } from "types/import-status";
import { Metadata } from "types/sse-event";

class EmployeeImportManagerStore {
  importId = "";

  constructor() {
    reaction(
      () => NotificationStore.employeeImportEvent,
      (event) => {
        if (event) {
          this.latestType = event.type;
          switch (event.type) {
            case ImportStatusType.EXCEL_ONGOING.valueOf():
              this.getOngoing(event.metadata);
              break;
            case ImportStatusType.EXCEL_UPLOAD_SUCCESS.valueOf():
              this.getUploadSuccess(event.metadata);
              break;
            case ImportStatusType.EXCEL_UPLOAD_FAILED.valueOf():
              this.uploadFailed(event.metadata);
              break;
            case ImportStatusType.EXCEL_CANCELED.valueOf():
              this.getCancelled();
              break;
            case ImportStatusType.EXCEL_IMPORT_SUCCESS.valueOf():
              this.getImportSuccess(event.metadata);
              break;
            case ImportStatusType.EXCEL_IMPORT_FAILED.valueOf():
              this.getImportFailed(event.metadata);
              break;
            case ImportStatusType.PROGRESS_EXCEL_ONGOING.valueOf():
              this.getUploadProgress(event.metadata);
              break;
            case ImportStatusType.PROGRESS_EXCEL_IMPORT_ONGOING.valueOf():
              this.getImportProgress(event.metadata);
              break;
            default:
              break;
          }
        }
      },
    );
    if (StoreLayout.isEnableImportV2) {
      this.getOrGenerateImportID();
    }
    makeAutoObservable(this);
  }

  uploadExcelProgress: number | null = null;
  importExcelProgress: number | null = null;
  importSelectedFile: File = null;
  importSelectedMergeChoice: string = null;

  employeeImportUIState: EmployeeImportUIState = {
    uploadStatus: "empty",
    reviewStatus: "not started",
    importStatus: "not started",
    cancelStatus: null,
  };
  employeeImportUploadFile: FileUploaded = {
    fileId: null,
    filename: null,
    fileSize: null,
    progress: null,
    status: "empty",
  };
  importReview: ImportReview | null = null;
  latestType: ImportStatusTypeKeys | null = null;

  setInitialImportUIState = () => {
    this.employeeImportUIState = {
      uploadStatus: "empty",
      reviewStatus: "not started",
      importStatus: "not started",
      cancelStatus: null,
    };
  };

  findCurrentImport = (
    importStatus: ImportStatus[],
    statusType: ImportStatusTypeKeys,
    importId: string,
  ) => {
    return importStatus.find((x) => x.status === statusType.valueOf() && x.import_id === importId);
  };

  private generateImportID = () => {
    return uuidv4();
  };

  getOrGenerateImportID = async () => {
    try {
      const ongoingImport = await importStatusApi({
        entity_id: StoreLayout.currentEntityId,
        status: ImportStatusType.EXCEL_ONGOING,
      });

      if (ongoingImport && ongoingImport.data && ongoingImport.data.length > 0) {
        this.importId = ongoingImport.data[0].import_id;
        console.log("Ongoing import found", this.importId);
      } else {
        throw new Error("No ongoing import found");
      }
    } catch (error) {
      this.importId = this.generateImportID();

      console.error("Error getting ongoing import status", error);
    }
  };

  changeImportSelectedMergeChoice = (choice: string) => {
    this.importSelectedMergeChoice = choice;
  };

  regenerateImportID = () => {
    this.importId = this.generateImportID();
  };

  setUploadProgress(progress: number) {
    this.uploadExcelProgress = progress;
  }

  setImportProgress(progress: number) {
    this.importExcelProgress = progress;
  }

  setImportReview(review: ImportReview) {
    this.importReview = review;
  }

  async getOngoing(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        status: ImportStatusType.EXCEL_ONGOING,
      });

      if (importStatus.data.length > 0) {
        const isUploadSuccess = importStatus.data.find(
          (v) => v.status === ImportStatusType.EXCEL_UPLOAD_SUCCESS,
        );

        const isConfirmSuccess = this.findCurrentImport(
          importStatus.data,
          ImportStatusType.EXCEL_IMPORT_SUCCESS,
          meta.import_id,
        );

        if (isUploadSuccess) {
          const importJob = await importJobApi({
            entity_id: meta.entity_id,
            import_id: importStatus.data[0].import_id,
          });

          this.employeeImportUploadFile = {
            fileId: importJob.data.id.toString(),
            filename: importJob.data.raw_data_filename,
            fileSize: importJob.data.filezise,
            progress: 100,
            status: "success",
          };

          this.employeeImportUIState = {
            uploadStatus: "success",
            reviewStatus: "success",
            importStatus: "not started",
            cancelStatus: null,
          };
          this.importSelectedMergeChoice = importJob.data.replace
            ? SELECTED_ACTION_REQUIRED.replace_entire_list
            : SELECTED_ACTION_REQUIRED.remove_employees;
          this.uploadExcelProgress = 100;
          this.importReview = JSON.parse(importStatus.data[0].result);
        } else if (isConfirmSuccess) {
          this.employeeImportUIState = {
            uploadStatus: "success",
            reviewStatus: "success",
            importStatus: "success",
            cancelStatus: null,
          };
        }
      } else {
        this.uploadExcelProgress = 0;
        this.importExcelProgress = 0;
        this.importReview = null;
        this.setInitialImportUIState();
      }
    } catch (error) {
      console.error("Error getting ongoing import status", error);
    }
  }

  async getUploadSuccess(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
        status: ImportStatusType.EXCEL_UPLOAD_SUCCESS,
      });

      const importJob = await importJobApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
      });

      this.employeeImportUploadFile = {
        fileId: importJob.data.id.toString(),
        filename: importJob.data.raw_data_filename,
        fileSize: importJob.data.filezise,
        progress: 100,
        status: "success",
      };

      this.employeeImportUIState = {
        uploadStatus: "success",
        reviewStatus: "success",
        importStatus: "not started",
        cancelStatus: null,
      };
      this.importReview = JSON.parse(importStatus.data[0].result);
      this.uploadExcelProgress = 100;
    } catch (error) {
      console.error("Error getting upload success import status", error);
    }
  }

  async getCancelled() {
    try {
      this.setInitialImportUIState();
    } catch (error) {
      console.error("Error getting cancelled import status", error);
    }
  }

  async uploadFailed(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
      });

      this.uploadExcelProgress = null;
      this.employeeImportUIState = {
        uploadStatus: "error",
        reviewStatus: "not started",
        importStatus: "not started",
        errorUploadMsg: importStatus.data[0].validation_errors,
        cancelStatus: null,
      };
    } catch (error) {
      console.error("Error getting upload failed import status", error);
    }
  }

  async getImportSuccess(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
        status: ImportStatusType.EXCEL_IMPORT_SUCCESS,
      });

      this.importReview = importStatus.data[0].result;
      this.uploadExcelProgress = 100;
      this.employeeImportUIState = {
        uploadStatus: "success",
        reviewStatus: "success",
        importStatus: "success",
        cancelStatus: null,
      };
    } catch (error) {
      console.error("Error getting import success import status", error);
    }
  }

  async getImportFailed(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
      });

      this.importExcelProgress = null;
      this.employeeImportUIState = {
        uploadStatus: "success",
        reviewStatus: "success",
        importStatus: "error",
        errorImportMsg: importStatus.data[0].error,
        cancelStatus: null,
      };
    } catch (error) {
      console.error("Error getting import failed import status", error);
    }
  }

  async getUploadProgress(meta: Metadata) {
    try {
      this.uploadExcelProgress = meta.progress;
    } catch (error) {
      console.error("Error getting progress import status", error);
    }
  }

  async getImportProgress(meta: Metadata) {
    try {
      this.importExcelProgress = meta.progress;
    } catch (error) {
      console.error("Error getting progress import status", error);
    }
  }
  handleImportSelectedFile = (file: File) => {
    this.importSelectedFile = file;
  };

  handleUpload = async () => {
    const changeImportStatus = await this.addEditImportStatus(
      ImportStatusType.EXCEL_ONGOING,
      this.importId,
    );

    if (changeImportStatus?.status !== 201 && changeImportStatus?.status !== 200) {
      this.employeeImportUIState = {
        uploadStatus: "error",
        ...this.employeeImportUIState,
      };
      return;
    }

    this.employeeImportUIState = {
      uploadStatus: "loading",
      ...this.employeeImportUIState,
    };

    try {
      const response = await useGeneratePresignURL("employee-import", {
        filename: this.importSelectedFile.name,
        filesize: "999 KB",
        import_id: this.importId,
        entity_id: StoreLayout.currentEntityId,
        insert: true,
        replace: this.importSelectedMergeChoice === SELECTED_ACTION_REQUIRED.replace_entire_list,
      });

      const uploadURL = response.data.url;

      await useUploadS3({
        file: this.importSelectedFile,
        presignUrl: uploadURL,
      });
    } catch (err) {
      console.log(`Error uploading file: ${err}`);
      // notify server that upload failed with id from  *addEditImportStatus of "EXCEL_ONGOING"*
      // changeImportStatus.data is returning id from *addEditImportStatus of "EXCEL_ONGOING"*
      this.addEditImportStatus(
        ImportStatusType.EXCEL_UPLOAD_FAILED,
        this.importId,
        changeImportStatus.data,
      );
      this.employeeImportUIState = {
        uploadStatus: "error",
        errorUploadMsg: "Oops! Something went wrong. Try uploading your file again.",
        importStatus: "not started",
        reviewStatus: "not started",
      };
    }
  };

  clearFileUpload = () => {
    this.importSelectedFile = null;
  };

  setInitialUploadState = () => {
    this.employeeImportUploadFile = {
      fileId: null,
      filename: null,
      fileSize: null,
      progress: null,
      status: "empty",
    };
  };

  handleCancelImport = async (cb?: () => void) => {
    try {
      await this.getOrGenerateImportID();

      const cancel = await useCancelImport({
        entity_id: StoreLayout.currentEntityId,
        import_id: this.importId,
      });

      // cancel has result means has errors
      if (cancel) {
        return;
      }

      this.setInitialImportUIState();

      this.setInitialUploadState();
      this.clearFileUpload();
      this.regenerateImportID();

      if (cb) {
        cb.call(this);
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleConfirmReview = async () => {
    // console.log(`confirming review for import id: ${this.importId}`);

    const confirm = await useConfirmImport({
      entity_id: StoreLayout.currentEntityId,
      import_id: this.importId,
    });

    await useConfirmImport({
      entity_id: StoreLayout.currentEntityId,
      import_id: this.importId,
    });

    if (confirm) {
      // if confirm has result means has errors
      return;
    }

    this.employeeImportUIState = {
      uploadStatus: "success",
      reviewStatus: "success",
      importStatus: "success",
    };
    this.setInitialUploadState();
    this.employeeImportUploadFile = null;
    this.importSelectedFile = null;
  };

  addEditImportStatus = async (status: string, import_id: string, id?: number | undefined) => {
    try {
      const importType = "EMPLOYEE";

      const response = await useAddEditImportStatus({
        id,
        entity_id: StoreLayout.currentEntityId,
        import_type: importType,
        status: status,
        import_id: import_id,
      });

      if (response?.status === 201 || response?.status === 200) {
        return response;
      }

      throw response;
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    }
  };

  handleUploadError = (error: string) => {
    this.employeeImportUIState = {
      uploadStatus: "error",
      importStatus: "not started",
      reviewStatus: "not started",
      errorUploadMsg: error,
    };
    this.clearFileUpload();
    this.setInitialUploadState();
  };

  clearUploadError = () => {
    this.employeeImportUIState = {
      uploadStatus: "empty",
      reviewStatus: "not started",
      importStatus: "not started",
      errorUploadMsg: null,
    };
    this.setInitialUploadState();
  };

  handleFileDelete = () => {
    this.importSelectedFile = null;
    this.setInitialUploadState();
    this.setInitialImportUIState();
    this.handleCancelImport();
  };

  isInitial = () =>
    this.employeeImportUIState.uploadStatus === "empty" &&
    this.employeeImportUIState.reviewStatus === "not started" &&
    this.employeeImportUIState.importStatus === "not started";

  isUploading = () => this.employeeImportUIState.uploadStatus === "loading";

  isOnReview = () => this.employeeImportUIState.uploadStatus === "success";
  isNeedConfirm = () =>
    this.employeeImportUIState.uploadStatus === "success" &&
    this.employeeImportUIState.reviewStatus === "success" &&
    this.employeeImportUIState.importStatus === "not started";

  isImportInProgress = () =>
    this.employeeImportUIState.uploadStatus === "success" &&
    this.employeeImportUIState.reviewStatus === "success" &&
    this.employeeImportUIState.importStatus === "loading";

  isFinished = () =>
    this.employeeImportUIState.uploadStatus === "success" &&
    this.employeeImportUIState.reviewStatus === "success" &&
    this.employeeImportUIState.importStatus === "success";
}

const importStore = new EmployeeImportManagerStore();

export default importStore;

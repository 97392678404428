import { Checkbox, SxProps, Theme } from "@mui/material";

type CustomCheckboxProps = {
  id?: string;
  disabled?: boolean;
  size?: "small" | "medium";
  checked: boolean;
  onChange?: () => void;
  sx?: SxProps<Theme>;
};

const CustomSquareCheckbox = ({
  id = "Custom_Checkbox",
  disabled = false,
  size = "medium",
  checked,
  onChange = () => {},
  sx = {},
}: CustomCheckboxProps) => {
  return (
    <Checkbox
      id={id}
      size={size}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      disableRipple
      sx={{
        padding: "0px !important",

        "&.MuiCheckbox-root": {
          width: "18px",
          height: "18px",
          borderRadius: "0px",
          backgroundColor: "var(--colorNeutralBackground1)",
        },
        "& .MuiSvgIcon-root": {
          color: "var(--colorBrandForeground1)",
          borderRadius: "0px",
          width: "22px",
        },
        "& svg": {
          scale: "1.2",
        },

        ...(disabled && {
          "&.Mui-checked": {
            backgroundColor: "var(--colorNeutralForeground5)",
            border: "1px solid var(--colorNeutralForegroundLightGray) !important",
            borderRadius: "0px",
          },
          "& svg": {
            scale: "1.4",
          },
          "& .MuiSvgIcon-root": {
            fill: "var(--colorGrayForeground7)",
            fontSize: "16px",
          },
          "&:not(.Mui-checked) .MuiSvgIcon-root": {
            backgroundColor: "var(--colorGrayForeground7) !important",
            border: "1px solid var(--colorNeutralForegroundLightGray) !important",
            scale: "1.1",
          },
        }),
        ...sx,
      }}
    />
  );
};

export default CustomSquareCheckbox;

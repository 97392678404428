import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { reaction } from "mobx";

import dashboardStore from "../../store";
import store from "../store";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import LaunchIcon from "@mui/icons-material/Launch";
import DownloadIcon from "@mui/icons-material/Download";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import MainStore from "MainStore";
import { HOTSPOTS_EXPORT_XLSX_MESSAGE } from "constants/dashboard-hotspots";
type LoadingPopupProps = {};

const LoadingPopup: FC<LoadingPopupProps> = observer(() => {
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(MainStore.isOpenLoadingPopup);
  const [isManualClose, setIsManualClose] = useState(false);

  useEffect(() => {
    const disposer = reaction(
      () => MainStore.isOpenLoadingPopup,
      (isOpen) => {
        setIsOpen(isOpen);
      }
    );

    return () => {
      disposer();
    };
  }, []);

  useEffect(() => {
    let timer: number | null = null;

    if (isOpen) {
      const initialStartTime = Date.now();
      setProgress(0);

      timer = window.setInterval(() => {
        const elapsedTime = Date.now() - initialStartTime;
        const newProgress = Math.floor(Math.min((elapsedTime / 5000) * 100, 100));
        setProgress(newProgress);

        if (newProgress >= 100) {
          setProgress(100);
          MainStore.isOpenLoadingPopup = false;
        }
      }, 500);
    } else {
      if (timer) clearInterval(timer);
      if (!isManualClose) {
        setProgress(100);
      }
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isOpen, isManualClose]);

  const handleAutoDownload = () => {
    MainStore.setSnackbar(HOTSPOTS_EXPORT_XLSX_MESSAGE, "info");
    store.setData(true, "isDownloadOnQueue");
  }

  const openInNewTab = () => {
      const searchParams = new URLSearchParams({
          project_id: dashboardStore.selectedProject?.id?.toString(),
          survey_id: dashboardStore.selectedSurvey?.value?.toString(),
          target: 'hotspots'
      });
      
      const url = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
      window.open(url, '_blank');
  };

  return (
        <StyledDialog open={isOpen}>
            <StyledDialogContent>
                <CloseBtnWrapper>
                    <CloseButton onClick={() => {
                        MainStore.isOpenLoadingPopup = false;
                        setProgress(progress);
                        setIsManualClose(true);
                    }}>
                        <ClearIcon />
                    </CloseButton>
                </CloseBtnWrapper>
                <DialogTitle>We're preparing your Hotspots chart now...</DialogTitle>
                <LoadingWrapper>
                    <Text>Please give us some time as we work our magic on it.</Text>
                    <Percent>{`${progress}% completed`}</Percent>
                </LoadingWrapper>
                <BorderLinearProgress variant="determinate" value={progress} />
                <SubTitle>In the meantime, you can also:</SubTitle>
                <StyledButton $isFirst={true} disabled={store.isDownloadOnQueue} onClick={handleAutoDownload}>
                    <DownloadIcon />
                    Queue download of .xlsx file with Hotspots data
                </StyledButton>
                <StyledButton onClick={openInNewTab}>
                    View Hotspots chart in new tab
                    <LaunchIcon />
                </StyledButton>
                <Text>
                  This allows you to start a new analysis on the Hotspots page while your chart loads here.
                </Text>
            </StyledDialogContent>
        </StyledDialog>
    );
});

export default LoadingPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: none !important;
    width: 720px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 48px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;
  color: var(--colorBrandForeground1);

  svg {
    font-size: 24px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;

const Percent = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const BorderLinearProgress = styled(LinearProgress)`
  margin-top: 10px;
  height: 8px !important;
  background-color: var(--colorPaletteBlueBackground1) !important;
  border-radius: 100px;

  .MuiLinearProgress-bar {
    background-color: var(--colorBrandForeground1) !important;
  }
`;

const StyledButton = styled(Button) <{ $isFirst?: boolean }>`
  width: fit-content;
  background-color: var(--colorPaletteGrayBackground1) !important;
  border-radius: 10px !important;
  margin-bottom: 16px !important;
  padding: 16px !important;
  text-transform: none !important;

  svg {
    font-size: 18px;
    margin: ${(props) => (props.$isFirst ? "0px 8px 0px 0px" : "0px 0px 0px 8px")};
  }
`;

const SubTitle = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin: 72px 0px 16px 0px;
`;

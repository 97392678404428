type SurveysIconDarkProps = {
  width?: number;
  height?: number;
};

const SurveysIconDark = ({ width = 24, height = 24 }: SurveysIconDarkProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8H9V6.5H3V8ZM3 5H9V3.5H3V5ZM12 14.4125L8.23125 9.5H0V0.5H12V14.4125ZM0 15.5V11H7.5L10.95 15.5H0Z"
        fill="#3E4450"
      />
    </svg>
  );
};

export default SurveysIconDark;

import { forwardRef, useImperativeHandle, useCallback } from 'react';
import { Document, Page, StyleSheet, pdf, Image, View, Text } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import store from "../../Dashboard/store";
import MainStore from 'MainStore';

type PDFContentProps = {
  sections?: string[]
}

const PDFContent = forwardRef<any, PDFContentProps>((_props, ref) => {
  const captureElement = async (element: HTMLElement) => {
    // Pre-process images to prevent rendering issues
    const images = element.getElementsByTagName('img');
    await Promise.all(
      Array.from(images).map(
        img => img.complete || new Promise(resolve => img.onload = resolve)
      )
    );

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      width: element.offsetWidth,
      height: element.scrollHeight,
      logging: false,
      backgroundColor: '#ffffff',
      removeContainer: true,
      // Optimize rendering performance
      scrollX: 0,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight
    });

    const imageData = canvas.toDataURL('image/png');
    canvas.remove();
    return imageData;
  };

  const downloadPDF = useCallback(async () => {
    try {
      MainStore.changeLoader(true);

      const sections = Array.isArray(_props?.sections) 
        ? _props.sections 
        : [_props?.sections ?? '.dashboard-content'];

      // Create document structure first
      const pages: JSX.Element[] = [];
      
      const ITEMS_PER_PAGE = 7;
      let currentPage: JSX.Element[] = [];
      let currentPageItems = 0;

      for (const selector of sections) {
        const element = document.querySelector(selector) as HTMLElement;
        if (!element) continue;

        const imageData = await captureElement(element);
        
        if (currentPageItems >= ITEMS_PER_PAGE) {
          pages.push(
            <Page key={`page-${pages.length}`} size="A4" orientation="portrait" style={styles.page}>
              {currentPage}
            </Page>
          );
          currentPage = [];
          currentPageItems = 0;
        }

        currentPage.push(
          <Image 
            key={`content-${currentPageItems}`}
            src={imageData} 
            style={[styles.contentImage, currentPageItems > 0 ? styles.contentImageWithTopPadding : {}]} 
          />
        );
        currentPageItems++;
      }

      if (currentPage.length > 0) {
        pages.push(
          <Page key={`page-${pages.length}`} size="A4" orientation="portrait" style={styles.page}>
            {currentPage}
          </Page>
        );
      }

      const blob = await pdf(<Document>{pages}</Document>).toBlob();
      const date = new Date();
      const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }).replace(/ /g, '');
      
      saveAs(blob, `${store.selectedProject.name}_${store.selectedSurvey.label}_${formattedDate}.pdf`);
      
      MainStore.changeLoader(false);
    } catch (error) {
      console.error('Error in downloadPDF:', error);
      MainStore.changeLoader(false);
    }
  }, [_props.sections]);

  useImperativeHandle(ref, () => ({
    downloadPDF
  }));

  return null;
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    backgroundColor: '#ffffff',
  },
  filterImage: {
    width: '100%',
    objectFit: 'contain',
    alignSelf: 'center',
  },
  contentContainer: {
    marginBottom: 10,
  },
  contentImage: {
    width: '100%',
    objectFit: 'contain',
    alignSelf: 'center',
    backgroundColor: '#ffffff',
    marginBottom: 15,
  },
  contentImage1: {
    width: '100%',
    objectFit: 'contain',
    alignSelf: 'center',
    backgroundColor: '#ffffff',
  },
  contentImageWithTopPadding: {
    marginTop: 15,
  },
  contentContainerWithTopPadding: {
    marginTop: 15,
  },
});

export default PDFContent;
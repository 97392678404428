import { FC } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import styled from "styled-components";

type DownloadButtonProps = {
  onClick: () => void;
  id: string;
  disabled?: boolean;
};

const DownloadButton: FC<DownloadButtonProps> = ({ onClick, id, disabled }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <DownloadButtonWrap id={id} onClick={onClick} disabled={disabled}>
        <DownloadIcon />
        <p>Download</p>
      </DownloadButtonWrap>
    </div>
  );
};

const DownloadButtonWrap = styled(Button)`
  width: fit-content;
  padding: 5px !important;
  display: flex;
  border: 1px solid var(--colorBrandForeground1);
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  justify-content: flex-start !important;
  cursor: pointer;
  pointer-events: auto;

  svg {
    margin-right: 5px;
  }

  p {
    max-lines: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
  }
`;

export default DownloadButton;

const PreviewBlueIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.0013 3C4.66797 3 1.8213 5.07333 0.667969 8C1.8213 10.9267 4.66797 13 8.0013 13C11.3346 13 14.1813 10.9267 15.3346 8C14.1813 5.07333 11.3346 3 8.0013 3ZM8.0013 11.3337C6.1613 11.3337 4.66797 9.84032 4.66797 8.00033C4.66797 6.16033 6.1613 4.66699 8.0013 4.66699C9.8413 4.66699 11.3346 6.16033 11.3346 8.00033C11.3346 9.84032 9.8413 11.3337 8.0013 11.3337ZM8 6C6.89333 6 6 6.89333 6 8C6 9.10667 6.89333 10 8 10C9.10667 10 10 9.10667 10 8C10 6.89333 9.10667 6 8 6Z"
        fill="#1477F8"
      />
    </svg>
  );
};

export default PreviewBlueIcon;

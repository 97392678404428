const EditBlueIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.47119 10.6421V12.5288H5.35793L10.9226 6.96418L9.03582 5.07744L3.47119 10.6421ZM12.3816 5.5051C12.5779 5.30888 12.5779 4.99191 12.3816 4.79568L11.2043 3.61836C11.0081 3.42214 10.6911 3.42214 10.4949 3.61836L9.57417 4.53909L11.4609 6.42583L12.3816 5.5051Z"
        fill="#1477F8"
      />
    </svg>
  );
};

export default EditBlueIcon;

import { FC, ReactElement } from "react";
import store from "./store";
import storeEntityPermission from "../../store";
import { observer } from "mobx-react";
import Dialog from "components/modal/Modal";
import CustomButton from "components/button/Button";
import styled from "styled-components";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type InviteAgainFormProps = {
  id?: string;
  openPanel: boolean;
  onClose: () => void;
};

const InviteAgainForm: FC<InviteAgainFormProps> = observer((props): ReactElement => {
  return (
    <Dialog
      open={props.openPanel}
      hide={() => {
        props.onClose();
      }}
      aria-label="Invite users again"
    >
      <MainWrapper>
        <Header id={props.id && `${props.id}_Title`}>
          Are you sure you want to resend the email invitation?
        </Header>

        <Body id={props.id && `${props.id}_Body`}>
          Selected users will receive a new invitation email right away. The invite link will remain
          valid for 7 days.
        </Body>

        <ButtonsWrapper>
          <ButtonWrapper>
            <CustomButton
              id={props.id && `${props.id}_Yes_Btn`}
              onClick={() => {
                store.resendInvitation(StoreLayout.currentEntityId, () => {
                  storeEntityPermission.loadEntityUsers();
                  props.onClose();
                });
              }}
              variant="contained"
            >
              Yes, invite users again
            </CustomButton>
          </ButtonWrapper>

          <ButtonWrapper>
            <CancelButton
              id={props.id && `${props.id}_Cansel_Btn`}
              onClick={() => {
                props.onClose();
              }}
              variant="outlined"
            >
              Cancel
            </CancelButton>
          </ButtonWrapper>
        </ButtonsWrapper>
      </MainWrapper>
    </Dialog>
  );
});

const MainWrapper = styled.div`
  margin: 50px 50px 20px 50px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const Body = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 20px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  margin: 8px;
`;

const CancelButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  color: var(--colorNeutralForeground2);
`;

export default InviteAgainForm;

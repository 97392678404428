import { observer } from "mobx-react";
import { OPEN_ENDED_LOCAL_MENU } from "constants/dashboard-responses";
import { FC, useCallback, useEffect } from "react";

import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import LocalMenu from "./LocalMenu";
import QuestionInfo from "./QuestionInfo";
import QuestionsList from "./QuestionsList";
import EditTopicPopup from "./EditTopicPopup";
import ResponseByTopicsTab from "./ResponseByTopicsTab";
import ErrorPage from "components/errors/ErrorPage";
import EmptyLabel from "../EmptyLabel";
import { toJS } from "mobx";

type OpenEndedTabProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const OpenEndedTab: FC<OpenEndedTabProps> = observer((props) => {
  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "responses" &&
      topStore.responsesActiveLocalTab === "open_ended" &&
      store.activeOpenEndedLocalTab === "all_responses"
    ) {
      store.getDashboardResponsesOpenEndedResponses(
        topStore.selectedSurvey.value,
        props.localFilters
      );
    }
  }, [
    topStore.selectedSurvey,
    topStore.activeTab,
    topStore.responsesActiveLocalTab,
    props.localFilters,
    topStore.overviewBenchmark,
    store.activeOpenEndedQuestion,
    store.activeOpenEndedLocalTab,
    store.sentimentFilter,
    store.pagination.active_page,
    topStore.filterUnitList,
  ]);

  const renderActiveTab = useCallback(() => {
    if (store.activeOpenEndedLocalTab === OPEN_ENDED_LOCAL_MENU.ALL_RESPONSES)
      return <QuestionInfo localFilters={props.localFilters} />;
    else return <ResponseByTopicsTab localFilters={props.localFilters} />;
  }, [store.activeOpenEndedLocalTab, store.isOpenTopicResponseDetail]);

  if (topStore.isDashboardDataError) {
    return <ErrorPage />;
  }

  return (
    <>
      <ContentContainer>
        <QuestionsList localFilters={props.localFilters} />
      <ResponsesColumn>
        {store.activeOpenEndedQuestion?.respondedCount < topStore.selectedSurvey.threshold ? (
          <EmptyLabel />
        ) : (
          <>
            <QuestionName>{store.activeOpenEndedQuestion?.title}</QuestionName>
            <LocalMenu />
            {renderActiveTab()}
          </>
        )}
      </ResponsesColumn>
          <EditTopicPopup localFilters={props.localFilters} />
      </ContentContainer>
    </>
  );
});

export default OpenEndedTab;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--colorNeutralForegroundInverted2);
  background-color: var(--colorNeutralBackground1);
`;

const ResponsesColumn = styled.div`
  width: calc(100% - 299px);
  display: flex;
  flex-direction: column;
  padding: 55px 75px;
`;

const QuestionName = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorPaletteVioletBackground1);
  margin-bottom: 24px;
`;

import https from "api/https";
import { API_TEMPLATE_URL_GO } from "constants/config";
import { createUrl } from "helpers/url";

const useConfirmImport = async (query: { entity_id: number; import_id: string }) => {
  const url = createUrl({
    baseUrl: API_TEMPLATE_URL_GO,
    path: "/api/v1/employee/employees/EmployeeImportConfirm",
  });

  const response = await https.postGO(url, query);

  return response.data;
};

export default useConfirmImport;

import { FC } from "react";
import { TScoredQuestionsEnpsResponse } from "types/dashboard";

import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";

type EnpsResponseCountGraphProps = { question: TScoredQuestionsEnpsResponse };

const EnpsResponseCountGraph: FC<EnpsResponseCountGraphProps> = (props) => {
    return (
        <Container>
            <Row>
                <Col>
                    <StyledImg src="/assets/dashboard_overview_funny_smile.png" />
                    <Label>
                        {props.question.reponseList.promoters.percent}% Promoters (
                        {props.question.reponseList.promoters.countByCategories})
                    </Label>
                    {props.question.reponseList.promoters.list.length > 0 && <FakeStrokeLine $isPromoters />}
                </Col>
                {props.question && props.question.reponseList && props.question.reponseList.promoters && props.question.reponseList.promoters.list && props.question.reponseList.promoters.list.length > 0 && (
                    <GraphList>
                        {props.question.reponseList.promoters.list
                            .sort((a, b) => (Number(a.option) < Number(b.option) ? 1 : -1))
                            .map((el, index, array) => (
                                <GraphRow key={index}>
                                    <FakeStroke $isPromoters $isLast={Boolean(index === array.length - 1)} />
                                    <DataWrapper>
                                        <Count>{el.option}</Count>
                                        <BorderLinearProgress variant="determinate" value={el.graphPercent} />
                                        <Percent>{el.percent}%</Percent>
                                        <CountResponse>({el.countReponse})</CountResponse>
                                    </DataWrapper>
                                </GraphRow>
                            ))}
                    </GraphList>
                )}
            </Row>
            <Row>
                <Col>
                    <StyledImg src="/assets/dashboard_overview_neutral_smile.png" />
                    <Label>
                        {props.question.reponseList.passives.percent}% Passives (
                        {props.question.reponseList.passives.countByCategories})
                    </Label>
                    {props.question.reponseList.passives.list.length > 0 && <FakeStrokeLine $isPassives />}
                </Col>
                { props.question.reponseList.passives && props.question.reponseList.passives.list && props.question.reponseList.passives.list.length > 0 && (
                    <GraphList>
                        {props.question.reponseList.passives.list
                            .sort((a, b) => (Number(a.option) < Number(b.option) ? 1 : -1))
                            .map((el, index, array) => (
                                <GraphRow key={index}>
                                    <FakeStroke $isPassives $isLast={Boolean(index === array.length - 1)} />
                                    <DataWrapper>
                                        <Count>{el.option}</Count>
                                        <BorderLinearProgress variant="determinate" value={el.graphPercent} />
                                        <Percent>{el.percent}%</Percent>
                                        <CountResponse>({el.countReponse})</CountResponse>
                                    </DataWrapper>
                                </GraphRow>
                            ))}
                    </GraphList>
                )}
            </Row>
            <Row>
                <Col>
                    <StyledImg src="/assets/dashboard_overview_angry_smile.png" />
                    <Label>
                        {props.question.reponseList.detractors.percent}% Detractors (
                        {props.question.reponseList.detractors.countByCategories})
                    </Label>
                    {props.question.reponseList.detractors.list.length > 0 && (
                        <FakeStrokeLine $isDetractors />
                    )}
                </Col>
                { props.question.reponseList.detractors && props.question.reponseList.detractors.list && props.question.reponseList.detractors.list.length > 0 && (
                    <GraphList>
                        {props.question.reponseList.detractors.list
                            .sort((a, b) => (Number(a.option) < Number(b.option) ? 1 : -1))
                            .map((el, index, array) => (
                                <GraphRow key={index}>
                                    <FakeStroke $isDetractors $isLast={Boolean(index === array.length - 1)} />
                                    <DataWrapper>
                                        <Count>{el.option}</Count>
                                        <BorderLinearProgress variant="determinate" value={el.graphPercent} />
                                        <Percent>{el.percent}%</Percent>
                                        <CountResponse>({el.countReponse})</CountResponse>
                                    </DataWrapper>
                                </GraphRow>
                            ))}
                    </GraphList>
                )}
            </Row>
        </Container>
    );
};

export default EnpsResponseCountGraph;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  white-space: nowrap;
  margin-top: -25px;
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  margin: -25px 8px 0px 0px;
`;

const Col = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
`;

const GraphRow = styled.div`
  width: 100%;
  display: flex;
`;

const FakeStroke = styled.div<{
    $isPromoters?: boolean;
    $isPassives?: boolean;
    $isDetractors?: boolean;
    $isLast: boolean;
}>`
  width: 30px;
  height: 30px;
  border-left: ${(props) => (props.$isLast ? "0px" : "1px")};
  border-top: 1px;
  border-bottom: 0px;
  border-right: 0px;
  border-style: dashed;
  border-color: ${(props) =>
        props.$isPromoters
            ? "var(--colorPaletteGreenBackground1)"
            : props.$isPassives
                ? "var(--colorPaletteBlueBackground1)"
                : "var(--colorPaletteRedForeground1)"};
`;

const FakeStrokeLine = styled.div<{
    $isPromoters?: boolean;
    $isPassives?: boolean;
    $isDetractors?: boolean;
}>`
  width: 100%;
  min-width: 10px;
  height: 30px;
  margin-left: 15px;
  border-top: 1px;
  border-left: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-style: dashed;
  border-color: ${(props) =>
        props.$isPromoters
            ? "var(--colorPaletteGreenBackground1)"
            : props.$isPassives
                ? "var(--colorPaletteBlueBackground1)"
                : "var(--colorPaletteRedForeground1)"};
`;

const GraphList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DataWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: -25px 0px 0px 10px;
`;

const Count = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-right: 8px;
`;

const Percent = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.08px;
  color: var(--colorNeutralForeground3);
  margin-left: 8px;
`;

const CountResponse = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground10);
  margin-left: 4px;
`;

const BorderLinearProgress = styled(LinearProgress)`
  height: 8px !important;
  width: 100%;
  min-width: 120px;
  max-width: 200px;
  background-color: var(--colorNeutralBackground3) !important;
  border-radius: 10px;

  .MuiLinearProgress-bar {
    background-color: var(--colorPalettePurpleForeground1) !important;
    border-radius: 10px !important;
  }
`;

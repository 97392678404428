import { AccordionDetails } from "@mui/material";
import { Accordion } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AccordionSummary } from "@mui/material";
import styled from "styled-components";
import InfoIcon from "../../../../components/icons/InfoIcon";
import DownloadButton from "./DownloadButton";

type DownloadTemplateAccordionProps = {
  formName: string;
  isAccordionExpanded: boolean;
  setToggleAccordion: () => void;
  saveExcelEmployee: () => void;
};

const DownloadTemplateAccordion = ({
  formName,
  isAccordionExpanded,
  setToggleAccordion,
  saveExcelEmployee,
}: DownloadTemplateAccordionProps) => {
  return (
    <div>
      <TemplateWrapper expanded={isAccordionExpanded}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <RowWrapper>
            <TemplateWrapperHeader id={`${formName}_templateWrapperHeader`}>
              Getting started with our Excel (.xlsx) template
            </TemplateWrapperHeader>
            <DownloadButton id={`${formName}_downloadExcelTemplate`} onClick={saveExcelEmployee} />
            <ExpandAccordionButton
              expanded={isAccordionExpanded}
              onClick={() => setToggleAccordion()}
            >
              <KeyboardArrowDownIcon width={16} height={16} />
            </ExpandAccordionButton>
          </RowWrapper>
        </AccordionSummary>
        <AccordionDetails>
          <ColumnWrapper>
            <SummaryRow>
              <ImportImageWrapper id={`${formName}_importImageWrapper`}>
                <ImportImage id={`${formName}_importImage`} src={"/assets/img-import.png"} />
              </ImportImageWrapper>
              <ColumnWrapper>
                <TemplateWrapperTextSummary id={`${formName}_templateWrapperTextSummary`}>
                  Download our Excel template to get started! You will be guided with instructions
                  to fill in details for your employee list. Once you are done, save the file in
                  .xlsx format for upload below.
                </TemplateWrapperTextSummary>
              </ColumnWrapper>
            </SummaryRow>
            <InfoTooltip>
              <InfoIcon></InfoIcon>
              <InfoText>
                <p>How does it work?</p>
              </InfoText>
            </InfoTooltip>
            <ListTooltipUl>
              <ListTooltipLi>
                New employees in your file will be added to your employee list.
              </ListTooltipLi>
              <ListTooltipLi>
                Existing employee information will be updated in your employee list.
              </ListTooltipLi>
            </ListTooltipUl>
          </ColumnWrapper>
        </AccordionDetails>
      </TemplateWrapper>
    </div>
  );
};

const TemplateWrapper = styled(Accordion)`
  width: 100%;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  pointer-events: none;

  p {
    font-size: 12px;
    color: var(--colorBrandForeground1);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ExpandAccordionButton = styled.button<{ expanded: boolean }>`
  height: 22px;
  width: 22px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  border-radius: 100%;

  color: var(--colorBrandForeground1);
  background-color: var(--colorNeutralBackground1);
  display: flex;
  pointer-events: auto;
  transform: ${({ expanded }) => (expanded ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);

  svg {
    color: var(--colorBrandForeground1);
    width: 16px;
    height: 16px;
  }
`;

const ImportImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
`;

const ImportImage = styled.img`
  width: 64px;
  height: 64px;
`;

const TemplateWrapperHeader = styled.span`
  font-family: Roboto, sans-serif;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-weight: 500;
  color: var(--colorNeutralForeground1);
  margin-bottom: 5px;
`;

const TemplateWrapperTextSummary = styled.span`
  font-family: Roboto, sans-serif;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
  margin-bottom: 5px;
`;

const InfoTooltip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 16px;

  svg {
    width: 16px;
    height: 16px;
    color: var(--colorNeutralForeground1);
    margin-right: 4px;
  }
`;

const InfoText = styled.div`
  p {
    font-size: 19px;
    color: var(--colorNeutralForeground1);
    font-weight: 500;
  }
`;

const ListTooltipUl = styled.ul`
  align-self: flex-start;
  list-style-type: disc;
`;

const ListTooltipLi = styled.li`
  font-size: 12px;
  color: var(--colorNeutralForeground1);
`;

const SelectContainerTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-top: 2.5rem;
  margin-bottom: 8px;

  span {
    font-family: Roboto, sans-serif;
    color: var(--colorNeutralForeground2);
    font-weight: 400;
    font-size: 0.875em;
  }
`;

export default DownloadTemplateAccordion;

import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import store from "../store";
import TablePermission from "./TablePermission";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "components/button/Button";
import { Backdrop, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import StorePermission from "components/workspaces-sidebar/StorePermission";

const MainScreen = observer(() => {
  const [isLoading, setIsloading] = useState(false);

  const navigate = useNavigate();

  const breadcrumbs = [
    <CustomButton
      variant="text"
      onClick={() => navigate("/entityPermissions")}
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      Entity Permissions
    </CustomButton>,
    <CurrentPageLabel>Edit</CurrentPageLabel>,
  ];

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (
      StorePermission.entityPermissionsLoaded &&
      !StorePermission.entityPermissions.viewEntityPermissions.hasPermission
    ) {
      window.location.href = "/access-denied";
    }

    store.loadEntityEmployeePermissions();
  }, []);

  const handleSaveChanges = () => {
    setIsloading(true);

    store.updateEmployeesPermission(() => {
      setIsloading(false);

      navigate("/entityPermissions");
    });
  };

  const handleOnHasChanges = (value: boolean) => {
    setIsSaveDisabled(!value);
  };

  return (
    <>
      <MainPageWrapper>
        <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>

        <MainContentWrapper>
          <FlexJustifyBetweenBox>
            <Header id="EntityPermissions_EntityUsers_Title">
              Edit entity and employee list permissions
            </Header>
          </FlexJustifyBetweenBox>

          {!store.rolePermissionIsFetched ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <TablePermission onHasChanges={handleOnHasChanges} />
          )}
        </MainContentWrapper>
      </MainPageWrapper>
      <FooterWrapper>
        <ActionsWrapper>
          <CancelButton
            name="AlertButtonYes"
            color={"inherit"}
            variant="outlined"
            id={"AlertButtonYes"}
            onClick={() => navigate("/entityPermissions")}
          >
            Cancel
          </CancelButton>
          <CustomButton
            variant="contained"
            name="AlertButtonNo"
            id={"AlertButtonNo"}
            disabled={isSaveDisabled}
            onClick={() => handleSaveChanges()}
          >
            Save changes
          </CustomButton>
        </ActionsWrapper>
      </FooterWrapper>
    </>
  );
});

const FlexJustifyBetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 26px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContentWrapper = styled.div`
  padding-top: 40px;
`;

const MainPageWrapper = styled.div`
  width: 100%;
  padding: 36px 120px 120px 120px;
`;

const FooterWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 58px;
  height: 60px;
  display: flex;
  background-color: var(--colorNeutralBackground1);
  padding: 0 80px 10px 80px;
  justify-content: right;
  align-items: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
`;

const CurrentPageLabel = styled.label`
  font-size: 14px;
  padding: 8px;
`;

const CancelButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  color: var(--colorNeutralForeground2);
`;

export default MainScreen;

import { makeAutoObservable, reaction, runInAction } from "mobx";
import { getEntityInfo, getImportProgress } from "api/go/useGetEntityInfo";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { hasManagersWithoutPermission } from "api/go/useHasManagersWithoutPermission";
import MainStore from "MainStore";
import { connectHris } from "api/useConnectHris";
import { hrisLinkNotified } from "api/go/useHrisLinkClicked";
import { getEntitySettingByEntity } from "api/useGetEntitySettingByEntity";
import { TEntitySettings } from "types/workspace";
import { setOrganizationalStructure } from "api/go/useSetOrganizationalStructure";
import storeListEmployee from "./Employee/ListEmployee/store";
import { useGrantLaterClicked } from "api/go/useGrantLaterClicked";
import { Step } from "constants/layout-types";
import { createAuthService, Environment, Platform } from "@engagerocketco/auth-sdk";

class Store {
  openModal = false;
  curentEntityType: number = 0;
  curentEntityOrgType: string = "";
  importLock: boolean = null;
  isPoolingImport: boolean = false;
  poolingImport: any;
  curentEntityHris = null;
  hrisLinkClicked = false;
  hrisToggle = false;

  structure_code: number = 0;
  entityOrgStructure: string = "";
  saved_org_structure_code = "";
  saved_org_structure_name = "";
  openPanelGrantPermission = false;
  entitySetting: TEntitySettings = null;
  openGrantPermissionModal: boolean = false;

  setHrisToggle = (flag: boolean) => {
    this.hrisToggle = flag;
  };

  setOpenGrantPermissionModal = (flag: boolean) => {
    this.openGrantPermissionModal = flag;
  };

  changeOpenModal(val: boolean) {
    this.openModal = val;
  }

  changeopenPanelGrantPermission = (flag: boolean) => {
    this.openPanelGrantPermission = flag;
  };

  constructor() {
    makeAutoObservable(this);
    setInterval(this.autoUpdateEntity, 60000);

    reaction(
      () => storeListEmployee.countEmployee,
      (countEmployee) => {
        if (countEmployee > 0) {
          this.loadCheckManagersWithoutRoles();
        }
      },
    );
  }

  setEntitySetting(setting: TEntitySettings) {
    this.entitySetting = setting;
  }

  loadCheckManagersWithoutRoles = async () => {
    MainStore.changeLoader(true);

    const response = await hasManagersWithoutPermission(StoreLayout.currentEntityId);
    if (
      (response.status === 201 || response.status === 200) &&
      storeListEmployee.countEmployee > 0 &&
      response.data
    ) {
      runInAction(() => {
        this.setOpenGrantPermissionModal(true);
      });
    }
    MainStore.changeLoader(false);
  };

  // TODO: find better way to make sure this function is not called on all page
  autoUpdateEntity = async () => {
    if (
      !createAuthService({
        platform: Platform.MAIN,
        environment: process.env.REACT_APP_ENVIRONMENT as Environment,
      }).getStoredTokens().accessToken
    ) {
      return;
    }

    if (StoreLayout.currentEntityId != 0) {
      this.getCurrentEntity(StoreLayout.currentEntityId);
    }
  };

  autoGetImportProgress = async () => {
    if (StoreLayout.isEnableImportV2) {
      return;
    }
    if (StoreLayout.currentEntityId != 0) {
      getImportProgress(StoreLayout.currentEntityId).then((prg) => {
        StoreLayout.currentProgress = prg.data.progress;
        StoreLayout.showImportProgress = prg.data.progress < 100;
        if (
          prg.data.import_result &&
          prg.data.progress === 100 &&
          localStorage.getItem("fileGuid") != null
        ) {
          if (
            StoreLayout.currentStep === Step.ReviewChanges &&
            prg.data.import_result.code === "ConfirmImport"
          ) {
            StoreLayout.changeShowImportProgress(false);
            StoreLayout.changeImportDoneText(
              "Upload complete! Your confirmation is needed on changes to your employee list before it can be updated.",
            );
            StoreLayout.changeShowImportDone(true);
            StoreLayout.importDoneLink = "/employees-import";
            StoreLayout.changeReviewStep();
            StoreLayout.changeSuccessImport(true);
          }
          if (
            StoreLayout.currentStep === Step.ActionRequired &&
            prg.data.import_result.code === "ConfirmImport"
          ) {
            StoreLayout.isFirstImport = prg.data.import_result.is_first;
            if (prg.data.import_result.is_first) {
              StoreLayout.changeReviewStep();
            } else {
              StoreLayout.changeActionRequiredStep(prg.data.import_result);
            }
          }
          if (
            StoreLayout.currentStep === Step.ReviewChanges &&
            prg.data.import_result.code === "ReviewImport"
          ) {
            MainStore.changeLoader(false);
            StoreLayout.importDoneUpdated = prg.data.import_result.countUpdate;
            StoreLayout.importDoneDeleted = prg.data.import_result.countDelete;
            StoreLayout.importDoneAdded = prg.data.import_result.countAdd;
            StoreLayout.changeImportCurrentStep(Step.ReviewChanges);
          }
        }
        if (prg.data.import_error != null && prg.data.progress === 0) {
          MainStore.setSnackbar("Something went wrong", "error");
        }
      });
    }
  };

  async saveOrgStructure(onClose: () => void) {
    try {
      MainStore.changeLoader(true);
      const response = await setOrganizationalStructure(
        StoreLayout.currentEntityId,
        this.entityOrgStructure,
        StoreLayout.currentRegionId,
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        onClose();
        this.loadCheckManagersWithoutRoles();
        this.saved_org_structure_code = this.entityOrgStructure;
        this.saved_org_structure_name = this.entityOrgStructure;
        this.getCurrentEntity(StoreLayout.currentEntityId);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async hrisLinkNotified() {
    try {
      MainStore.changeLoader(true);
      const response = await hrisLinkNotified(StoreLayout.currentEntityId);
      if (response.status === 201 || response.status === 200) {
        if (response.data) {
          this.hrisLinkClicked = true;
        } else {
          MainStore.setSnackbar("Something went wrong", "error");
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }
  async connectHris() {
    try {
      MainStore.changeLoader(true);
      const response = await connectHris(StoreLayout.currentEntityId);
      if (response.status === 201 || response.status === 200) {
        if (response.data) {
          MainStore.setSnackbar("HRIS Sync is enabled successfully");
          this.hrisToggle = true;
          StoreLayout.lockEdit = true;
        } else {
          MainStore.setSnackbar("Something went wrong", "error");
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  setOrgStructure = (val: string) => {
    this.entityOrgStructure = val;
  };

  getEntitySettingByEntity = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getEntitySettingByEntity(StoreLayout.currentEntityId);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setEntitySetting(response.data);
      } else throw Error();
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  useGrantLaterClicked = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await useGrantLaterClicked(StoreLayout.currentEntityId);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
      } else throw Error();
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getCurrentEntity(idEntity: number) {
    getEntityInfo(idEntity).then((x) => {
      this.curentEntityHris = x.data.hris_merge_integration
        ? JSON.parse(x.data.hris_merge_integration)
        : null;
      if (this.curentEntityHris?.notified) {
        this.hrisLinkClicked = true;
      }
      if (this.curentEntityHris?.enabled) {
        this.hrisToggle = true;
        StoreLayout.lockEdit = true;
      }

      this.curentEntityType = x.data.id_orgstructure;
      this.curentEntityOrgType = x.data.id_orgstructureNavCode;
      this.importLock = x.data.import_lock;
      StoreLayout.showImportProgress = x.data.import_lock && StoreLayout.currentProgress != 100;
      if (x.data.import_lock && !this.isPoolingImport) {
        this.isPoolingImport = true;
        this.poolingImport = setInterval(this.autoGetImportProgress, 10000);
      } else if (!x.data.import_lock && this.isPoolingImport) {
        this.isPoolingImport = false;
        clearInterval(this.poolingImport);
      }
      if (x.data.id_orgstructure == null || x.data.id_orgstructure === 0) {
        this.openModal = true;
      } else {
        this.saved_org_structure_code = x.data.id_orgstructureNavCode;
        this.saved_org_structure_name = x.data.id_orgstructureNavName;
      }
    });
  }
}

const store = new Store();
export default store;

import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { TextField, Stack, Chip, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import ClearIcon from "@mui/icons-material/Clear";
import CustomModal from "components/modal/Modal";
import CustomButton from "components/button/Button";
import { SelectedItem, UserRole } from "types/permission";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropdownPopOver from "components/dropdownPopOver";

interface InviteFormModalProps {
  open: boolean;
  emails: string[];
  value: string;
  options: SelectedItem[];
  onClose: () => void;
  inviteHandler: () => void;
  onChange: (event: string) => void;
  submitEmailHandler: () => void;
  deleteEmail: (index: number) => void;
  submitRoleHandler: (roleIds: number[]) => void;
  deleteRole: (index: number) => void;
}

const InviteFormModal = ({
  open,
  emails,
  value,
  options,
  onClose,
  inviteHandler,
  onChange,
  submitEmailHandler,
  deleteEmail,
  submitRoleHandler,
  deleteRole,
}: InviteFormModalProps) => {
  const [isValidEmail, setIsValidEmail] = useState<string>("");
  const [isRoleSelected, setIsRoleSelected] = useState<string>("");
  const [selectedRoles, setSelectedRoles] = useState<UserRole[] | null>(null);

  const [currentEl, setCurrentEl] = useState<HTMLElement | null>(null);
  const [openRolePopup, setopenRolePopup] = useState(false);

  useEffect(() => {
    if (!open) {
      setIsValidEmail("");
      setSelectedRoles(null);
      setIsRoleSelected("");
    }
  }, [open]);

  useEffect(() => {
    if (emails.length < 1 && selectedRoles && selectedRoles.length > 0) {
      setIsValidEmail("Enter at least one email address to invite users");
    }

    if (emails.length > 0 && ((selectedRoles && selectedRoles.length < 1) || !selectedRoles)) {
      setIsRoleSelected("Select at least one user role");
    }

    if (selectedRoles && selectedRoles.length > 0) {
      setIsRoleSelected("");
    }

    if (emails.length > 0) {
      setIsValidEmail("");
    }
  }, [emails, selectedRoles]);

  const entryEmail = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const email = value;
    const emailReg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    const isValid = emailReg.test(email.toLowerCase());
    if (!isValid) {
      setIsValidEmail("Remove invalid email address");
      return;
    }

    if (emails.includes(email)) {
      setIsValidEmail("Remove any duplicate email address");
      return;
    }

    setIsValidEmail("");
    submitEmailHandler();
  };

  const handleSelectOptions = (selectedItems: SelectedItem[]) => {
    setSelectedRoles(selectedItems);

    submitRoleHandler(selectedItems.map((role) => role.id));

    if (emails.length < 1) {
      setIsValidEmail("Enter at least one email address to invite users");
      return;
    }
  };

  const deleteSelectedRole = (index: number) => {
    const updatedRoles = [...selectedRoles.slice(0, index), ...selectedRoles.slice(index + 1)];
    setSelectedRoles(updatedRoles);

    deleteRole(index);
  };

  const handleOnDeleteChip = (index: number) => {
    deleteEmail(index);
  };

  return (
    <CustomModal open={open} hide={onClose} width="600px" padding="0">
      <FormWrapper>
        <Box component="form" onSubmit={(event) => entryEmail(event)}>
          <Header data-testid="EntityPermissions_AddUsers_popup_title">
            Invite users to entity
          </Header>
          <SubHeader>
            Invite with email {emails?.length > 0 && `(${emails?.length} emails entered)`}
          </SubHeader>
          <AddedEmailBox>
            <TextField
              data-testid="EntityPermissions_AddUsers_popup_EmailInput"
              fullWidth
              type="email"
              placeholder="Enter one or more email addresses"
              variant="standard"
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(0, 20px) scale(1)",
                  transition: "none",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  transform: "translate(0, 20px) scale(1)",
                },
              }}
              error={isValidEmail !== ""}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
            {emails?.length > 0 && (
              <StyledStack direction="row" spacing={{ xs: 1, sm: 1 }} useFlexGap flexWrap="wrap">
                {emails?.map((value, index) => (
                  <Chip
                    data-testid="EntityPermissions_AddUsers_popup_EmailChip"
                    color="primary"
                    size={"medium"}
                    variant="outlined"
                    label={value}
                    deleteIcon={<ClearIcon />}
                    onDelete={() => handleOnDeleteChip(index)}
                    sx={{
                      fontSize: "14px",
                      weight: "500",
                      lineHeight: "20px",
                      backgroundColor: "white",
                    }}
                  />
                ))}
              </StyledStack>
            )}
          </AddedEmailBox>
        </Box>

        <LabelError>{isValidEmail}</LabelError>

        <RolesAssignWrapper>
          <SubHeader>Assign user role(s) from dropdown</SubHeader>
          <SelectionWrapper>
            <SelectionContainer
              onClick={(event) => {
                setopenRolePopup(true);
                setCurrentEl(event.currentTarget);
              }}
            >
              <p>Select user roles(s)</p>
              <KeyboardArrowDownIconStyle />
            </SelectionContainer>
            <DropdownPopOver
              anchorElement={currentEl}
              isOpen={openRolePopup}
              items={options}
              updateSelectedItems={selectedRoles}
              onSelect={(items: SelectedItem[]) => handleSelectOptions(items)}
              onClose={() => {
                setopenRolePopup(false);
                setCurrentEl(null);
              }}
              anchorPosition={{ vertical: "bottom", horizontal: "left" }}
              customStyle={{ width: `${currentEl?.clientWidth}px`, maxHeight: "288px" }}
            ></DropdownPopOver>
          </SelectionWrapper>
          <LabelError>{isRoleSelected}</LabelError>
        </RolesAssignWrapper>

        <StyledStack direction="row" spacing={{ xs: 1, sm: 1 }} useFlexGap flexWrap="wrap">
          {selectedRoles?.map((value, index) => (
            <Chip
              data-testid="EntityPermissions_Selected_Roles_Chip"
              color="primary"
              size={"medium"}
              variant="outlined"
              label={value.name}
              deleteIcon={<ClearIcon />}
              onDelete={() => deleteSelectedRole(index)}
              sx={{
                fontSize: "14px",
                weight: "500",
                lineHeight: "20px",
              }}
            />
          ))}
        </StyledStack>
      </FormWrapper>

      <StyledHelperText>
        <ErrorIcon style={{ color: "var(--colorPaletteVioletBackground3)" }} />
        <Typography data-testid="EntityPermissions_AddUsers_popup_warning">
          Invited users will receive an email immediately. The invitation will be valid for 7 days
          from today.
        </Typography>
      </StyledHelperText>
      <BtnWrp>
        <CustomButton
          data-testid="EntityPermissions_AddUsers_popup_AddBtn"
          onClick={inviteHandler}
          variant="contained"
          color="primary"
          disabled={isValidEmail !== "" || emails.length < 1 || isRoleSelected !== ""}
        >
          Invite users
        </CustomButton>
        <CancelButton
          data-testid="EntityPermissions_AddUsers_popup_CanselBtn"
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </CancelButton>
      </BtnWrp>
    </CustomModal>
  );
};

const FormWrapper = styled.div`
  padding: 60px 60px 40px;
`;

const RolesAssignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const StyledHelperText = styled.div`
  padding: 10px 60px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  background-color: var(--colorPaletteBlueBackground3);
`;

const BtnWrp = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px 60px 48px;
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0 0 24px;
`;

const SubHeader = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  margin: 0 0 8px;
`;

const Typography = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const AddedEmailBox = styled.div`
  height: 150px;
  overflow: auto;
  border: 1px solid var(--colorNeutralBackground3);
  background: var(--colorPaletteGrayBackground1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorNeutralForeground5);
  }
`;

const LabelError = styled.p`
  font-weight: 400px;
  size: 14px;
  line-height: 20px;
  color: var(--colorPaletteRedForeground2);
  padding-top: 4px;
`;

const StyledStack = styled(Stack)`
  margin-top: 10px;
`;

const CancelButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  color: var(--colorNeutralForeground2);
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  width: 20px !important;
  height: 20px !important;

  &:hover {
    background-color: transparent;
  }
`;

const SelectionWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SelectionContainer = styled.div`
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  color: var(--colorNeutralForeground5);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export default InviteFormModal;

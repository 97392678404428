import http from "api/https";
import { createUrl } from "../../helpers/url";
import { APIARY_DOMAIN } from "constants/apiary";

export const getSurveyPermissions = (
  entityId: number,
  searchValue: string,
  currentPage: number,
  pageSize: number,
): Promise<any> => {
  const query: {
    entity_id: string;
    search_value: string;
    current_page: string;
    page_size: string;
  } = {
    entity_id: entityId.toString(),
    search_value: searchValue,
    current_page: currentPage.toString(),
    page_size: pageSize.toString(),
  };

  const url = createUrl({
    baseUrl: APIARY_DOMAIN, //TODO: Remove when actual endpoint available in dev
    path: "/permission/entity_permissions/SurveyPermissions",
    query: query,
  });
  return http.getGO(url);
};

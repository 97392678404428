import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { ExpandLess, ExpandMore, Check } from '@mui/icons-material';
import styled from 'styled-components';
import { TDashboardUnitFilter } from 'types/dashboard';
import { Radio } from '@mui/material';

interface TreeSelectProps {
  items: TDashboardUnitFilter[];
  onSelect: (selectedIds: string[]) => void;
  searchTerm?: string;
  currentMatchIndex?: number;
  isSingleSelect?: boolean;
}

interface MatchInfo {
  id: string;
  label: string;
}

// Update the helper function at the component level
const getInitialSelectedItems = (items: TDashboardUnitFilter[]): Set<string> => {
  const selectedIds = new Set<string>();
  
  const processItem = (item: TDashboardUnitFilter) => {
    if (item.selected) {
      selectedIds.add(item.id);
      // When parent is selected, add all children recursively
      if (item.children) {
        const getAllChildrenIds = (children: TDashboardUnitFilter[]) => {
          children.forEach(child => {
            selectedIds.add(child.id);
            if (child.children) {
              getAllChildrenIds(child.children);
            }
          });
        };
        getAllChildrenIds(item.children);
      }
    } else if (item.children) {
      item.children.forEach(processItem);
    }
  };
  
  items.forEach(processItem);
  return selectedIds;
};

export const TreeSelect = forwardRef<{
  scrollToMatch: (itemId: string) => void;
  clearSelection: () => void;
  handleSelect: (id: string) => void;
}, TreeSelectProps>(({ items, onSelect, searchTerm, currentMatchIndex = 0, isSingleSelect }, ref) => {
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [matches, setMatches] = useState<MatchInfo[]>([]);
  const [currentMatchId, setCurrentMatchId] = useState<string>('');
  const itemRefs = useRef<Map<string, HTMLElement>>(new Map());

  // Add this useEffect right after state declarations
  useEffect(() => {
    const initialSelected = getInitialSelectedItems(items);
    setSelectedItems(initialSelected);
    onSelect(Array.from(initialSelected));
  }, []); // Empty dependency array means this runs once on mount

  const toggleExpand = (itemId: string, event: React.MouseEvent) => {
    // Prevent the event from bubbling up to the label
    event.preventDefault();
    event.stopPropagation();
    
    const newExpanded = new Set(expandedItems);
    if (newExpanded.has(itemId)) {
      // When collapsing, also remove all descendant items from expanded set
      const itemToCollapse = findItem(items, itemId);
      if (itemToCollapse) {
        const descendantIds = getAllDescendantIds(itemToCollapse);
        newExpanded.delete(itemId);
        descendantIds.forEach(id => newExpanded.delete(id));
      }
    } else {
      newExpanded.add(itemId);
    }
    setExpandedItems(newExpanded);
  };

  const handleSelect = (id: string) => {
    if (isSingleSelect) {
      // For single select, toggle the selection
      if (selectedItems.has(id)) {
        // If already selected, unselect it
        setSelectedItems(new Set());
        onSelect([]);
      } else {
        // If not selected, select it
        setSelectedItems(new Set([id]));
        onSelect([id]);
      }
      return;
    }

    // For multi-select, handle parent-child relationships
    const newSelected = new Set(selectedItems);
    const itemToToggle = findItem(items, id);

    if (!itemToToggle) return;

    // If item is currently selected, unselect it and all its descendants
    if (newSelected.has(id)) {
      newSelected.delete(id);
      
      // Remove all descendants from selection if they were individually selected
      const descendantIds = getAllDescendantIds(itemToToggle);
      descendantIds.forEach(descendantId => {
        newSelected.delete(descendantId);
      });

      // Update parent selection states
      const parentIds = getParentIds(items, id);
      parentIds.forEach(parentId => {
        newSelected.delete(parentId);
      });
    } else {
      // If item is not selected, select it
      newSelected.add(id);

      // Select all descendants
      if (itemToToggle.children && !isSingleSelect) {
        const descendantIds = getAllDescendantIds(itemToToggle);
        descendantIds.forEach(descendantId => {
          newSelected.add(descendantId);
        });
      }

      // Check and update all parent nodes
      const checkAndUpdateParents = (currentItems: TDashboardUnitFilter[], targetId: string) => {
        // Find the immediate parent of the target
        for (const item of currentItems) {
          if (item.children?.some(child => child.id === targetId)) {
            // Found the parent, now check if all its children are selected
            const allChildrenSelected = item.children.every(child => {
              // Check if the child itself is selected
              if (!newSelected.has(child.id)) return false;
              
              // If the child has children, make sure all descendants are selected
              if (child.children && child.children.length > 0) {
                const descendants = getAllDescendantIds(child);
                return descendants.every(descId => newSelected.has(descId));
              }
              
              return true;
            });
            
            if (allChildrenSelected) {
              // If all children are selected, select the parent
              newSelected.add(item.id);
              
              // Continue checking up the tree
              if (item.id !== targetId) { // Avoid infinite recursion
                checkAndUpdateParents(items, item.id);
              }
            }
            
            return;
          }
          
          // Continue searching in children
          if (item.children) {
            checkAndUpdateParents(item.children, targetId);
          }
        }
      };
      
      // Start checking parents
      checkAndUpdateParents(items, id);
    }

    setSelectedItems(newSelected);
    onSelect(Array.from(newSelected));
  };

  const handleSelectAll = () => {
    const allValues = getAllValues(items);
    const isEverythingSelected = allValues.length === selectedItems.size && 
      allValues.every(id => selectedItems.has(id));
    const topLevelIds = items.map(item => item.id);
    const allTopLevelSelected = topLevelIds.every(id => selectedItems.has(id));
  

    // If everything is selected, clear all selections
    if (isEverythingSelected || allTopLevelSelected) {
      setSelectedItems(new Set());
      onSelect([]);
    } else {
      // Select only top-level items
      const newSelected = new Set(allValues);
      setSelectedItems(newSelected);
      onSelect(Array.from(newSelected));
    }
  };

  const getAllValues = (items: TDashboardUnitFilter[]): string[] => {
    return items.reduce((acc: string[], item) => {
      // Include the parent ID as well
      acc.push(item.id);
      if (item.children && item.children.length > 0) {
        return [...acc, ...getAllValues(item.children)];
      }
      return acc;
    }, []);
  };

  const isPartiallySelected = (item: TDashboardUnitFilter): boolean => {
    if (!item.children) return false;
    
    // If the item itself is selected, it's not partially selected
    if (selectedItems.has(item.id)) {
      return false;
    }
    
    // Helper function to get all descendant IDs
    const getAllDescendantIds = (item: TDashboardUnitFilter): string[] => {
      let ids: string[] = [];
      if (item.children) {
        item.children.forEach(child => {
          ids.push(child.id);
          ids = [...ids, ...getAllDescendantIds(child)];
        });
      }
      return ids;
    };

    // Get all descendant IDs
    const descendantIds = getAllDescendantIds(item);
    
    // Check if any descendants are selected
    const hasSelectedDescendants = descendantIds.some(id => selectedItems.has(id));
    
    // Item is partially selected if any descendants are selected
    return hasSelectedDescendants;
  };

  const findItem = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const getAllDescendantIds = (item: TDashboardUnitFilter): string[] => {
    const ids: string[] = [];
    if (item.children) {
      for (const child of item.children) {
        ids.push(child.id);
        ids.push(...getAllDescendantIds(child));
      }
    }
    return ids;
  };

  // Function to expand parent nodes of a matched item
  const expandParents = (items: TDashboardUnitFilter[], targetId: string, parents: Set<string> = new Set()): boolean => {
    for (const item of items) {
      if (item.id === targetId) {
        return true;
      }
      if (item.children) {
        if (expandParents(item.children, targetId, parents)) {
          parents.add(item.id);
          return true;
        }
      }
    }
    return false;
  };

  // Add this function to get all parent IDs of an item
  const getParentIds = (items: TDashboardUnitFilter[], targetId: string, path: string[] = []): string[] => {
    for (const item of items) {
      if (item.id === targetId) {
        return path;
      }
      if (item.children) {
        const found = getParentIds(item.children, targetId, [...path, item.id]);
        if (found.length) return found;
      }
    }
    return [];
  };

  // Add this new function to find all matches
  const findAllMatches = (items: TDashboardUnitFilter[]): MatchInfo[] => {
    const matches: MatchInfo[] = [];
    
    const searchItems = (item: TDashboardUnitFilter) => {
      if (item.label.toLowerCase().includes(searchTerm?.toLowerCase() || '')) {
        matches.push({ id: item.id, label: item.label });
      }
      
      if (item.children) {
        item.children.forEach(searchItems);
      }
    };
    
    items.forEach(searchItems);
    return matches;
  };

  // Update the useEffect to use the new approach
  useEffect(() => {
    if (searchTerm) {
      const foundMatches = findAllMatches(items);
      setMatches(foundMatches);
      
      // Set initial match
      if (foundMatches.length > 0) {
        const matchIndex = Math.min(currentMatchIndex, foundMatches.length) - 1;
        setCurrentMatchId(foundMatches[matchIndex]?.id || '');
      } else {
        setCurrentMatchId('');
      }

      // Expand parents of all matches
      const nodesToExpand = new Set<string>();
      foundMatches.forEach(match => {
        const parentIds = getParentIds(items, match.id);
        parentIds.forEach(id => nodesToExpand.add(id));
      });
      setExpandedItems(nodesToExpand);
    } else {
      setMatches([]);
      setCurrentMatchId('');
      setExpandedItems(new Set());
    }
  }, [searchTerm, items]);

  // Add this helper function at the component level
  const findParentNode = (items: TDashboardUnitFilter[], targetId: string, parent?: TDashboardUnitFilter): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some(child => child.id === targetId)) {
        return item;
      }
      if (item.children) {
        const found = findParentNode(item.children, targetId, item);
        if (found) return found;
      }
    }
    return null;
  };

  // Update the renderHighlightedText function
  const renderHighlightedText = (text: string, itemId: string) => {
    if (!searchTerm) return text;
    
    const matchIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (matchIndex === -1) return text;

    const isCurrentMatch = itemId === currentMatchId;

    return (
      <span>
        {text.slice(0, matchIndex)}
        <HighlightedText 
          $isCurrent={isCurrentMatch}
          ref={el => {
            if (el) {
              itemRefs.current.set(itemId, el);
            }
          }}
        >
          {text.slice(matchIndex, matchIndex + searchTerm.length)}
        </HighlightedText>
        {text.slice(matchIndex + searchTerm.length)}
      </span>
    );
  };

  const renderItem = (item: TDashboardUnitFilter, level: number = 0, isLastItem: boolean = false) => {
    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedItems.has(item.id);
    const isSelected = selectedItems.has(item.id);
    const isPartial = isPartiallySelected(item);
    
    // Check if the item should appear selected (either directly or via parent)
    const appearsSelected = shouldAppearSelected(item);
    
    // Update this line to check for any selected ancestors
    const isDisabled = isSingleSelect && hasSelectedAncestor(item, items);
    
    return (
      <ItemContainer key={item.id} level={level}>
        <ItemWrapper isLastItem={isLastItem}>
          <ItemRow 
            isSelected={isSingleSelect ? appearsSelected : isSelected}
            hasMatch={searchTerm ? item.label.toLowerCase().includes(searchTerm.toLowerCase()) : false}
            role="treeitem"
            tabIndex={-1}
            $isDisabled={isDisabled}
            $isCurrentMatch={item.id === currentMatchId}
          >
            <CheckboxLabel>
              <CheckboxContainer>
                {isSingleSelect ? (
                  <div onClick={(e) => {
                    if (isDisabled) {
                      return;
                    }
                    e.stopPropagation();
                    handleSelect(item.id);
                  }}>
                    <Radio 
                      checked={appearsSelected}
                      disabled={isDisabled}
                      size="small"
                    />
                  </div>
                ) : (
                  <>
                    <HiddenCheckbox
                      type="checkbox"
                      checked={selectedItems.has(item.id)}
                      onChange={() => handleSelect(item.id)}
                    />
                    <StyledCheckbox 
                      isSelected={selectedItems.has(item.id)}
                      isPartial={isPartial}
                    >
                    {selectedItems.has(item.id) && 
                        <Check style={{ fontSize: 16 }} />
                      }
                    </StyledCheckbox>
                  </>
                )}
              </CheckboxContainer>
              {renderHighlightedText(item.label, item.id)}
              {hasChildren && (
                <ExpandButton
                  onClick={(e) => toggleExpand(item.id, e)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </ExpandButton>
              )}
            </CheckboxLabel>
          </ItemRow>
        </ItemWrapper>
        {hasChildren && isExpanded && (
          <ChildrenWrapper isLastItem={isLastItem}>
            <ChildrenContainer>
              {item.children!.map((child, index) => 
                renderItem(
                  child, 
                  level + 1, 
                  index === item.children!.length - 1
                )
              )}
            </ChildrenContainer>
          </ChildrenWrapper>
        )}
      </ItemContainer>
    );
  };

  // Update scrollToMatch to use itemId
  const scrollToMatch = (itemId: string) => {
    const element = itemRefs.current.get(itemId);
    if (element) {
      setCurrentMatchId(itemId);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  // Expose the methods through ref
  useImperativeHandle(ref, () => ({
    scrollToMatch,
    clearSelection,
    handleSelect
  }));

  // Add this new helper function
  const updateParentState = (items: TDashboardUnitFilter[], childId: string, selectedSet: Set<string>) => {
    const updateParent = (items: TDashboardUnitFilter[], targetId: string): void => {
      for (const item of items) {
        if (item.children?.some(child => child.id === targetId)) {
          // Found the parent, check its children's state
          const allChildrenSelected = item.children.every(child => selectedSet.has(child.id));
          const noChildrenSelected = item.children.every(child => !selectedSet.has(child.id));
          
          if (allChildrenSelected) {
            selectedSet.add(item.id);
          } else {
            selectedSet.delete(item.id);
          }
          
          // Continue updating up the tree
          updateParent(items, item.id);
          return;
        }
        if (item.children) {
          updateParent(item.children, targetId);
        }
      }
    };
    
    updateParent(items, childId);
  };

  // Remove the class-style clearSelection method and replace with this:
  const clearSelection = () => {
    setSelectedItems(new Set());
    onSelect([]);
  };

  // Add this new helper function near other helper functions
  const hasSelectedAncestor = (item: TDashboardUnitFilter, items: TDashboardUnitFilter[]): boolean => {
    const findAncestor = (currentItems: TDashboardUnitFilter[], targetId: string): boolean => {
        for (const currentItem of currentItems) {
            // Check if current item is a direct parent
            if (currentItem.children?.some(child => child.id === targetId)) {
                return selectedItems.has(currentItem.id) || findAncestor(items, currentItem.id);
            }
            // Continue searching in children
            if (currentItem.children) {
                const found = findAncestor(currentItem.children, targetId);
                if (found) return true;
            }
        }
        return false;
    };
    
    return findAncestor(items, item.id);
  };

  // Add this new function to check if an item should appear selected
  const shouldAppearSelected = (item: TDashboardUnitFilter): boolean => {
    // If the item is directly selected
    if (selectedItems.has(item.id)) {
      return true;
    }
    
    // If any parent is selected, the item should appear selected
    return hasSelectedAncestor(item, items);
  };

  return (
    <Container>
      {!isSingleSelect && (
        <CheckedButton isChecked={selectedItems.size > 0 && selectedItems.size === getAllValues(items).length} onClick={handleSelectAll}>
          <Check style={{ fontSize: 12 , color: selectedItems.size > 0 && selectedItems.size === getAllValues(items).length ? 'white' : 'var(--colorBrandForeground3)' }} />
          <span>Select All</span>
      </CheckedButton>
      )}

      <TreeSelectWrapper>
        <TreeSelectContainer>
          {items.map((item, index) => 
            renderItem(item, 0, index === items.length - 1)
          )}
        </TreeSelectContainer>
      </TreeSelectWrapper>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TreeSelectContainer = styled.div`
  padding-top: 8px;
  min-width: fit-content;
  overflow-x: auto;
  
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const ItemContainer = styled.div<{ level: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: ${props => props.level * 24}px;
  padding-bottom: 2px;

  ${props => props.level === 0 && `
    > ${ItemWrapper}:before,
    > ${ItemWrapper}:after,
    > ${ChildrenWrapper}:before {
      display: none;
    }
  `}
`;

const ItemWrapper = styled.div<{ isLastItem?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  
  &:before {
    content: '';
    position: absolute;
    left: -24px;
    top: 50%;
    width: 24px;
    height: 2px;
    background-color: var(--colorBrandForeground2);
  }

  &:after {
    content: '';
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${props => props.isLastItem ? '50%' : '110%'};
    background-color: var(--colorBrandForeground2);
  }
`;

const ItemRow = styled.div<{ 
  isSelected: boolean; 
  hasMatch: boolean;
  $isDisabled?: boolean;
  $isCurrentMatch?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);
  margin: 2px 0;
  width: fit-content;
  border: 1px solid ${props => {
    if (props.$isCurrentMatch) return '#6B4EFF';
    if (props.isSelected) return 'var(--colorBrandForeground3)';
    return 'transparent';
  }};
  min-height: 36px;
  transition: all 0.3s;
  outline: none;
  box-shadow: ${props => props.$isDisabled ? '0 0 0 1px white' : 'none'};

  &:hover {
    background-color: var(--colorNeutralBackground1);
  }

  &:focus {
    box-shadow: ${props => props.$isDisabled ? '0 0 0 1px white' : '0 0 0 2px #6B4EFF'};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ isSelected: boolean; isPartial: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: white;
  border: 2px solid ${props => 
    props.isSelected || props.isPartial 
      ? 'var(--colorBrandForeground3)' 
      : 'var(--colorBrandForeground1)'
  };
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;

  svg {
    color: white;
  }

  ${props => props.isPartial && !props.isSelected && `    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: var(--colorBrandForeground3);
      border-radius: 2px;
    }
  `}

  ${props => props.isSelected && `
    background: var(--colorBrandForeground3);
  `}

  &:hover {
    border-color: var(--colorBrandForeground3);
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-height: 24px;
  cursor: pointer;

  span {
    user-select: none;
  }
`;

const ChildrenContainer = styled.div`
  margin-left: 20px;
  width: 100%;
`;

const ExpandButton = styled.button`
  border: none;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: 20px;
    height: 20px;
    color: var(--colorBrandForeground3);
  }

  &:active {
    background-color: var(--colorNeutralBackground1);
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const ChildrenWrapper = styled.div<{ isLastItem?: boolean }>`
  position: relative;
  display: flex;
  
  &:before {
    content: '';
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${props => props.isLastItem ? '0' : 'calc(100% + 12px)'};
    background-color: var(--colorBrandForeground2);
  }
`;

const HighlightedText = styled.span<{ $isCurrent: boolean }>`
  color: #6B4EFF;
  font-weight: 600;
  background-color: ${props => props.$isCurrent ? '#F5F3FF' : 'transparent'};
  padding: ${props => props.$isCurrent ? '2px 4px' : '0'};
  border-radius: ${props => props.$isCurrent ? '4px' : '0'};
  scroll-margin: 100px;
`;

const TreeSelectWrapper = styled.div.attrs({ className: 'TreeSelectWrapper' })`
  flex: 1;
  overflow: auto;
  padding-bottom: 24px;
  
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const CheckedButton = styled.button<{ isChecked: boolean }>`
  border: ${props => props.isChecked ? 'none' : '1px solid var(--graySelectBoxBorder)'};
  background-color: ${props => props.isChecked ? 'var(--colorBrandForeground3)' : 'var(--colorNeutralBackground1)'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  padding: 6px 8px;
  border-radius: 4px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: ${props => props.isChecked ? 'white' : 'inherit'};

  svg {
    width: 20px;
    height: 20px;
    color: var(--colorBrandForeground3);
  }

  &:active {
    background-color: ${props => props.isChecked ? 'var(--colorBrandForeground3)' : 'var(--colorNeutralBackground1)'};
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: ${props => props.isChecked ? 'var(--colorBrandForeground3)' : 'var(--colorNeutralBackground2)'};
    opacity: ${props => props.isChecked ? 0.9 : 1};
  }
`;

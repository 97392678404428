import https from "api/https";
import { AxiosResponse } from "axios";
import { API_TEMPLATE_URL_GO } from "constants/config";
import { createUrl } from "helpers/url";

type ImportJob = {
  id: number;
  import_id: string;
  entity_id: number;
  preprocessed_data: {
    data: any;
    errors: any;
    attributes: any[];
  };
  raw_data_filename: string;
  filezise: string;
  insert: boolean;
  replace: boolean;
  status: string;
  created_at: string;
  updated_at: string;
};

export const importJobApi = async (query: {
  entity_id: string;
  import_id: string;
}): Promise<AxiosResponse<ImportJob>> => {
  const url = createUrl({
    baseUrl: `${API_TEMPLATE_URL_GO}`,
    path: "/api/v1/employee/employees/GetEmployeeImportJob",
    query,
  });

  const response = await https.get(url);

  if (response.status !== 200 && response.status !== 201) {
    throw new Error(response.statusText);
  }

  const importJob: ImportJob = response.data;

  return {
    data: importJob,
    ...response,
  };
};

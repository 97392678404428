import { FC, useMemo, useEffect } from "react";
import { IconXlsx } from "./IconXlsx";

import styled from "styled-components";
import Button from "components/button/Button";
import { observer } from "mobx-react";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import useDownloadChangeLog from "api/go/useDownloadChangeLog";
import MainStore from "MainStore";
import dayjs from "dayjs";
import { getTimeZone } from "utils/timeFormatter";
import { downloadFile } from "utils/downloadFile";
import { useNavigate } from "react-router-dom";
import { createUrl } from "helpers/url";
import PreviousImports from "./PreviousImports";

type ReviewChangesProps = {
  fileName: string;
  confirmClicked: () => void;
  discardClicked: () => void;
  success: boolean;
  changelogs: any[];
};

const ReviewChanges: FC<ReviewChangesProps> = observer((props) => {
  const navigate = useNavigate();
  const formName = "ReviewChanges";

  const formattedDates = useMemo(
    () =>
      props.changelogs.map(
        (x) =>
          dayjs(x.imported_at).format("DD MMM YYYY [at] h:mm A").toString() +
          ", UTC " +
          getTimeZone(),
      ),
    [props.changelogs],
  );

  return (
    <ReviewChangesWrapper id={`${formName}_reviewChangesWrapper`}>
      <Content id={`${formName}_content`}>
        {!props.success && (
          <Header id={`${formName}_headerReview`}>Review changes before importing</Header>
        )}
        {props.success && <Header id={`${formName}_headerSuccess`}>Successfully imported!</Header>}
        <FileWrapper id={`${formName}_fileWrapper`}>
          <IconXlsx id={`${formName}_iconXlsx`} />
          <p id={`${formName}_fileName`}>{props.fileName || "File not found"}</p>
        </FileWrapper>
        <SubHeader id={`${formName}_subHeader`}>Import summary</SubHeader>
        <Row id={`${formName}_statRow`}>
          <StatisticContainer id={`${formName}_statisticContainerUpdated`}>
            <span id={`${formName}_updatedCount`}>{StoreLayout.importDoneUpdated}</span>
            <p id={`${formName}_updatedCountUpdated`}>employees updated</p>
          </StatisticContainer>
          <StatisticContainer id={`${formName}_statisticContainerAdded`}>
            <span id={`${formName}_addedCount`}>{StoreLayout.importDoneAdded}</span>
            <p id={`${formName}_addedCountEmployeesAdded`}>employees added</p>
          </StatisticContainer>
          <StatisticContainer id={`${formName}_statisticContainerRemoved`} $notLast={true}>
            <span id={`${formName}_removedCount`}>{StoreLayout.importDoneDeleted}</span>
            <p id={`${formName}_removedCountemployees`}>employees removed</p>
          </StatisticContainer>
        </Row>
        {props.success && <PreviousImports />}
      </Content>
      {!props.success && (
        <ButtonWrapper id="buttonWrapper">
          <ConfirmButton
            id="confirmButton"
            variant="contained"
            onClick={() => props.confirmClicked()}
          >
            Confirm and import
          </ConfirmButton>
          <DiscardButton
            id="discardButton"
            onClick={() => props.discardClicked()}
            variant="outlined"
          >
            Discard changes
          </DiscardButton>
        </ButtonWrapper>
      )}
    </ReviewChangesWrapper>
  );
});

export default ReviewChanges;

const ReviewChangesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0 0 20px 0;
`;

const ContentItem = styled.div`
  padding-bottom: 40px;
`;

const HeaderText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: var(--colorNeutralForeground2);
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1);
  }

  svg {
    margin-right: 10px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 660px;
`;

const ConfirmButton = styled(Button)`
  width: 100%;
  height: 34px;
  max-width: 180px !important;
  margin-top: 40px !important;
`;

const DiscardButton = styled(Button)`
  width: 100%;
  height: 34px;
  max-width: 150px !important;
  margin: 40px 0 0 20px !important;
  color: var(--colorNeutralForeground2) !important;
  border: 1px solid var(--colorNeutralForeground2) !important;
`;

const SubHeader = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
`;

const StatisticContainer = styled.div<{ $notLast?: boolean }>`
  width: 100%;
  max-width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
  background-color: var(--colorNeutralBackground1);
  ${(props) => !props.$notLast && "margin-right: 30px"};
  height: 125px;
  margin-top: 20px;
  margin-bottom: 40px;

  span,
  p {
    text-align: center;
  }

  span {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    color: var(--colorNeutralForeground2);
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
  }
`;

const Row = styled.div`
  display: flex;
`;

const ChangeLogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;

  span,
  p {
    font-weight: 400;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: var(--colorNeutralForeground1);
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
    margin-left: 8px;
  }
`;

const ChangeLogFile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
    margin: 0 10px 0 8px;
  }
`;

const DownloadButton = styled(Button)`
  text-decoration: underline !important;
`;

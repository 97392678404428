import { StatusProgress } from "types/status-progress";

export const ImportStatusType = {
  EXCEL_ONGOING: "EXCEL_ONGOING" as StatusProgress,
  EXCEL_UPLOAD_SUCCESS: "EXCEL_UPLOAD_SUCCESS" as StatusProgress,
  EXCEL_UPLOAD_FAILED: "EXCEL_UPLOAD_FAILED" as StatusProgress,
  EXCEL_CANCELED: "EXCEL_CANCELED" as StatusProgress,
  EXCEL_IMPORT_ONGOING: "EXCEL_IMPORT_ONGOING" as StatusProgress,
  EXCEL_IMPORT_SUCCESS: "EXCEL_IMPORT_SUCCESS" as StatusProgress,
  EXCEL_IMPORT_FAILED: "EXCEL_IMPORT_FAILED" as StatusProgress,
  PROGRESS_EXCEL_ONGOING: "PROGRESS_EXCEL_ONGOING" as StatusProgress,
  PROGRESS_EXCEL_IMPORT_ONGOING: "PROGRESS_EXCEL_IMPORT_ONGOING" as StatusProgress,
} as const;

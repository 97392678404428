import http from "../https";
import { TDashboardParameters } from "./useGetDashboardData";

export enum ResponsesType {
  SCORED = "scored",
  OPEN_ENDED = "openEnded",
  OTHERS = "others"
}

type TRequestBody = {
  dashboardParameters: TDashboardParameters,
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
  responsesTypes?: ResponsesType[];
};

export const useGetDashboardResponses = (requestData: TRequestBody) =>
  http.dashboardAPIClient.post(`/dashboard/dashboard/responses`, requestData);

import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import CustomButton from "../button/Button";
import { TextField } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";

type CustomSimpleFormModalDialogProps = {
  title: string;
  fieldLabel: string;
  isDialogOpen: boolean;
  oldValue?: string;
  fieldMaxChar?: number;
  buttonsCaption?: {
    yesButton: string;
    cancelButton: string;
  };
  defaultPlaceholder?: string;
  receivedErrorMessage?: string;
  isShowConfirmField?: boolean;
  confirmFieldLabel?: string;
  confirmFieldPlaceholder?: string;
  onClose: () => void;
  onYes: (value: string) => void;
};

const CustomSimpleFormModalDialog = ({
  title,
  fieldLabel,
  isDialogOpen,
  oldValue = "",
  fieldMaxChar = 0,
  buttonsCaption = { yesButton: "", cancelButton: "" },
  defaultPlaceholder = "Enter user role name",
  receivedErrorMessage = "",
  isShowConfirmField = false,
  confirmFieldLabel = "",
  confirmFieldPlaceholder = "",
  onClose,
  onYes,
}: CustomSimpleFormModalDialogProps) => {
  const [textValue, setTextValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(receivedErrorMessage);
  const [confirmTextValue, setConfirmTextValue] = useState("");
  const [confirmErrorMessage, setConfirmErrorMessage] = useState("");

  const prevTextValue = useRef<string | null>(null);
  const prevConfirmTextValue = useRef<string | null>(null);

  useEffect(() => {
    if (isDialogOpen) {
      setTextValue("");
      setConfirmTextValue("");
      setErrorMessage("");
      setConfirmErrorMessage("");

      prevTextValue.current = null;
      prevConfirmTextValue.current = null;

      if (oldValue) {
        setTextValue(oldValue);
      }
    }
  }, [isDialogOpen]);

  useEffect(() => {
    setErrorMessage(receivedErrorMessage);
  }, [receivedErrorMessage]);

  useEffect(() => {
    if (prevTextValue.current && textValue === "") {
      setErrorMessage(`${fieldLabel} is required`);
    } else {
      setErrorMessage("");
    }

    prevTextValue.current = textValue;
  }, [textValue]);

  useEffect(() => {
    if (prevConfirmTextValue.current && confirmTextValue === "") {
      setConfirmErrorMessage(
        `Enter "${confirmFieldPlaceholder.toUpperCase()}" to confirm this action`,
      );
    } else {
      setConfirmErrorMessage("");
    }

    prevConfirmTextValue.current = confirmTextValue;
  }, [confirmTextValue]);

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter" || !textValue) return;
    if (oldValue && textValue === oldValue && !isShowConfirmField) return;

    if (isShowConfirmField && !confirmTextValue) {
      setConfirmErrorMessage(
        `Enter "${confirmFieldPlaceholder.toUpperCase()}" to confirm this action`,
      );
      return;
    }

    handleOnYes();
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  };

  const handleOnConfirmTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmTextValue(event.target.value);
  };

  const handleOnCancel = () => {
    onClose();
  };

  const handleOnYes = () => {
    if (isShowConfirmField && (!confirmTextValue || confirmTextValue !== confirmFieldPlaceholder)) {
      setConfirmErrorMessage(
        `Enter "${confirmFieldPlaceholder.toUpperCase()}" to confirm this action`,
      );
      return;
    }

    onYes(textValue);
  };

  const isYesButtonDisabled = () => {
    if (
      !textValue ||
      (oldValue && textValue === oldValue && !isShowConfirmField) ||
      (isShowConfirmField && !confirmTextValue)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Dialog
      onClose={() => onClose()}
      fullWidth={true}
      id={"SimpleCreateModal"}
      maxWidth={"sm"}
      open={isDialogOpen}
    >
      <CloseModal onClick={() => onClose()} />
      <ContentWrapper>
        <Header>
          <MainText>{title}</MainText>
        </Header>
        <FieldLabel>
          <p>{fieldLabel}</p>
        </FieldLabel>
        <TextField
          fullWidth
          type="text"
          placeholder={defaultPlaceholder}
          inputProps={fieldMaxChar > 0 ? { maxLength: fieldMaxChar } : {}}
          variant="standard"
          sx={{
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
            "& .MuiInputLabel-root": {
              transform: "translate(0, 20px) scale(1)",
              transition: "none",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              transform: "translate(0, 20px) scale(1)",
            },
            "& .MuiInputBase-root.Mui-disabled": {
              pointerEvents: "none",
            },
            "& .MuiInputBase-root.Mui-disabled:before": {
              borderBottom: "none !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "var(--colorNeutralForeground1) !important",
            },
          }}
          value={textValue}
          onChange={handleOnChange}
          onKeyUp={handleOnKeyUp}
          disabled={isShowConfirmField}
        />

        {errorMessage && <LabelErrorMessage>{errorMessage}</LabelErrorMessage>}

        {isShowConfirmField && (
          <ConfirmSectionWrapper>
            <FieldLabel>
              <p>{confirmFieldLabel}</p>
            </FieldLabel>
            <TextField
              fullWidth
              type="text"
              placeholder={confirmFieldPlaceholder}
              inputProps={fieldMaxChar > 0 ? { maxLength: fieldMaxChar } : {}}
              variant="standard"
              sx={{
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(0, 20px) scale(1)",
                  transition: "none",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  transform: "translate(0, 20px) scale(1)",
                },
              }}
              value={confirmTextValue}
              onChange={handleOnConfirmTextChange}
              onKeyUp={handleOnKeyUp}
            />
            {confirmErrorMessage && <LabelErrorMessage>{confirmErrorMessage}</LabelErrorMessage>}
          </ConfirmSectionWrapper>
        )}

        <ActionsWrapper>
          <CustomButton
            name="ButtonYes"
            color={"primary"}
            variant="contained"
            id={"ButtonYes"}
            disabled={isYesButtonDisabled()}
            onClick={handleOnYes}
          >
            {buttonsCaption?.yesButton || "Yes"}
          </CustomButton>
          <CancelButton variant="outlined" name="ButtonNo" id={"ButtonNo"} onClick={handleOnCancel}>
            {buttonsCaption?.cancelButton || "No"}
          </CancelButton>
        </ActionsWrapper>
      </ContentWrapper>
    </Dialog>
  );
};

const MainText = styled.h1`
  color: var(--colorNeutralForeground1);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
`;

const ContentWrapper = styled(MuiDialogContent)`
  padding: 64px !important;
`;

const Header = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const FieldLabel = styled.div`
  padding-bottom: 18px;

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--colorNeutralForeground2);
  }
`;

const CloseModal = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38px;
  right: 30px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  opacity: 0.6;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    width: 18px;
    height: 3px;
    background: var(--colorBrandForeground1);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const LabelErrorMessage = styled.p`
  color: var(--colorPaletteRedForeground2);
  padding-top: 8px;
  font-size: 14px;
`;

const ConfirmSectionWrapper = styled.div`
  margin-top: 24px;
`;

const CancelButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  color: var(--colorNeutralForeground2);
`;

export default CustomSimpleFormModalDialog;

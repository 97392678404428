import React, { FC } from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";

type SecondaryButtonProps = {
  text?: string;
  variant?: "text" | "outlined" | "contained";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "submit" | "reset" | "button";
  href?: string;
  disabled?: boolean;
  children?: any;
  size?: "small" | "medium";
  style?: any;
  color?: any;
  ref?: any;
  name?: string;
  sx?: any;
  id?: string;
  fullWidth?: boolean;
  endIcon?: any;
  disableRipple?: boolean;
};

const SecondaryCustomButton: FC<SecondaryButtonProps> = (props) => {
  const { text, ...rest } = props;

  return <StyledButton {...rest}>{props.children || props.text}</StyledButton>;
};

export default SecondaryCustomButton;

const StyledButton = styled(Button)`
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: ${(props) => (props.variant === "contained" ? 400 : 500)} !important;
  line-height: 20px !important;
  text-transform: none !important;
  box-shadow: none !important;
  border: 1px solid var(--colorBrandForeground1) !important;
  color: var(--colorBrandForeground1) !important;
  height: 34px;
  ${(props) =>
    props.variant === "contained" &&
    !props.disabled &&
    (props.color === "primary" || !props.color) &&
    "background: linear-gradient(180deg, var(--colorBrandForeground1) 58.33%, var(--colorBrandForeground3) 100%) !important;"}
`;

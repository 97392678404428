import MainStore from "MainStore";
import { getRecepientsMilestone } from "api/useGetEmployeesWithMilestones";
import getExcelMilestoneRecepients from "api/useGetExcelMilestoneRecepients";
import { getRecepientsMilestoneByProject } from "api/useGetRecepientsMilestoneByProject";
import { SetRecepientMilestoneSchedule } from "api/useSetRecepientMilestoneSchedule";
import { getSurveyCommunicationInformation } from "api/go/useGetSurveyCommunicationInformation";
import { getTodayRecepientMilestoneCount } from "api/useGetTodayRecepientMilestoneCount";
import { saveSurveyDates } from "api/go/useSaveSurveyDates";
import dayjs, { Dayjs } from "dayjs";
import { makeAutoObservable, runInAction } from "mobx";
import { SuveyRecepientData, Employee, Survey, Pagination } from "types/milestone";
import { getTimeZone, extractMinimumTime, extractAndMergeDateTime } from "utils/timeFormatter";
import { AllInfoCommunicationSurvey } from "types/surveys";

class Store {
  defaultManualTime = () => dayjs("09:00", "HH:mm");

  surveyId: number = 0;
  projectId: number = 0;
  AllData: SuveyRecepientData = null;
  Employees: Employee[] = [];
  Surveys: Survey[] = [];
  Pagination: Pagination = {
    active_page: 1,
    total_count: 0,
    count_current_page: 0,
    count_per_page: 10,
    total_pages: 0,
  };

  millstoneSelectedRecipients = [];
  searchNameEmail: string = "";
  resultSearchValue: string = "";

  openEditSchedule: boolean = false;

  date: Dayjs | null = null;
  manualDate: Dayjs | null = null;
  manualTime: Dayjs = this.defaultManualTime();
  minTime: Dayjs | null = null;

  activeScheduleType = "customDate";
  selected_survey_id: number = 0;
  selected_survey_name: string = "";
  selected_employee_id: number = 0;
  selected_employee_name: string = "";
  selectedEmployeeNoAttr: boolean = false;
  timezone: string = getTimeZone();

  timeStartMilestone: Dayjs = null;
  leaveDateEmpty: boolean = false;
  closeNow: boolean = false;
  datesChanged = false;
  isSurveyInfoCompleted = false;
  surveyStatus = null;
  todayRecipientMilestoneCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  doLoad = () => {
    this.getMilestoneData();
    this.loadMainInformation(this.surveyId);
  };

  setIdSurvey = (survey_id: number) => {
    this.surveyId = survey_id;
  };

  setIdProject = (projectId: number) => {
    this.projectId = projectId;
  };

  paginationSetActivePage = (page: number) => {
    this.Pagination.active_page = page;
    this.getMilestoneData();
  };

  escapeRegexSpecialChars(input: string) {
    if (input === null || input === undefined) return input;
    const specialChars = [".", "*", "+", "?", "^", "$", "(", ")", "[", "]", "{", "}", "\\", "|"];
    let escapedString = input;
    specialChars.forEach((char) => {
      const escapeChar = "\\" + char;
      const regex = new RegExp(char, "g");
      escapedString = escapedString.replace(regex, escapeChar);
    });

    return escapedString;
  }

  setMainInformation = (data: AllInfoCommunicationSurvey) => {
    this.timeStartMilestone = data.timestart_milestone && dayjs(data.timestart_milestone);
    this.changeValue("leaveDateEmpty", data.leave_date_empty);
    this.surveyStatus = data.survey_status;

    this.isSurveyInfoCompleted = true;
  };

  loadMainInformation = async (id: number) => {
    this.isSurveyInfoCompleted = false;

    try {
      MainStore.changeLoader(true);

      const response = await getSurveyCommunicationInformation(id);

      if ((response.status === 201 || response.status === 200) && response?.data) {
        await this.setMainInformation(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getMilestoneData = async () => {
    try {
      MainStore.changeLoader(true);
      if (this.surveyId && this.surveyId !== 0) {
        const response = await getRecepientsMilestone(
          this.surveyId,
          this.resultSearchValue,
          this.Pagination.count_per_page,
          this.Pagination.active_page,
        );
        if ((response.status === 200 || response.status === 201) && response?.data !== null) {
          this.AllData = response.data;
          this.Employees = response.data.employees;
          this.Surveys = response.data.surveys;
          this.Pagination = response.data.pagination;
        } else throw Error();
      } else {
        const response = await getRecepientsMilestoneByProject(
          this.projectId,
          this.escapeRegexSpecialChars(this.resultSearchValue),
          0,
          10,
        ); // get by projectId
        if ((response.status === 200 || response.status === 201) && response?.data !== null) {
          this.AllData = response.data;
          this.Employees = response.data.employees;
          this.Surveys = response.data.surveys;
        } else throw Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  editSurveySchedule = (
    id_survey: number,
    id_employee: number,
    survey_name: string,
    employee_name: string,
    activeScheduleType: string,
  ) => {
    this.onChangeOpenEditSchedule(true);

    const currEmployee = this.Employees.find((x) => x.id === id_employee);

    if (!currEmployee?.start_date_attribute_value) {
      this.selectedEmployeeNoAttr = true;
    }

    this.selected_survey_id = id_survey;
    this.selected_employee_id = id_employee;
    this.selected_survey_name = survey_name;
    this.selected_employee_name = employee_name;
    this.activeScheduleType = activeScheduleType;
  };

  onChangeOpenEditSchedule = (flag: boolean) => {
    if (flag === false) {
      this.date = null;
      this.activeScheduleType = "customDate";
      this.selected_employee_id = 0;
      this.selected_survey_id = 0;
      this.selected_employee_name = "";
      this.selected_survey_name = "";
      this.selectedEmployeeNoAttr = false;
    }

    this.openEditSchedule = flag;
  };

  setDate = (date: Dayjs) => {
    this.date = date;
  };

  changeSelected = (id: number) => {
    let new_selected = this.millstoneSelectedRecipients;
    const index = new_selected.indexOf(id, 0);

    if (index > -1) {
      new_selected.splice(index, 1);
    } else {
      new_selected = [...new_selected, id];
    }

    this.millstoneSelectedRecipients = new_selected;
  };

  changeValue = (name: string, value: any) => {
    if (name === "leaveDateEmpty" && value === true) {
      this.timeStartMilestone = null;
    }

    this[name] = value;
  };

  keyPress = (e: any) => {
    if (e.keyCode === 13) {
      this.onSearchClicked();
    }
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchNameEmail;
    this.searchNameEmail = "";
    this.paginationSetActivePage(1);
  };

  onMovePageClicked = (page: number) => {
    this.paginationSetActivePage(page);
  };

  exportRecepientsExcel = async () => {
    let currentSurvey = this.Surveys.find((x) => x.id === this.surveyId);
    let surveyName = currentSurvey?.name ?? "";
    try {
      MainStore.changeLoader(true);
      const data = await getExcelMilestoneRecepients(this.surveyId);
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "Milestone recipients_" + surveyName + ".xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  mergeDateAndTime = (datePart: Dayjs, timePart: Dayjs | null = null) => {
    this.manualDate = datePart.startOf("day");
    this.manualTime = timePart || this.manualTime;

    if (datePart && !timePart) {
      this.setupMinTime(this.manualDate);
    }

    if (this.manualDate && this.manualTime) {
      this.date = extractAndMergeDateTime(this.manualDate, this.manualTime);
    }
  };

  setupMinTime = (dateTarget: Dayjs | null) => {
    const roundedTime = extractMinimumTime(dateTarget);

    this.minTime = roundedTime;
    if (roundedTime) this.manualTime = roundedTime;
  };

  SaveSchedule = async () => {
    const scheduleDate =
      this.activeScheduleType === "unscheduled" ? null : this.date ? this.date.toISOString() : null;

    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const resp = await SetRecepientMilestoneSchedule(
        this.selected_survey_id,
        this.selected_employee_id,
        scheduleDate,
        this.activeScheduleType,
      );

      if (resp.status === 200 || resp.status === 201) {
        MainStore.setSnackbar(
          `Survey invite schedule has been updated for ${this.selected_employee_name}.`,
        );

        runInAction(() => {
          this.doLoad();
          this.onChangeOpenEditSchedule(false);
        });
      } else throw new Error();
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      runInAction(() => {
        MainStore.changeLoader(false);
      });
    }
  };

  changeDatesTab = (flag: boolean) => {
    this.datesChanged = flag;
  };

  SaveSurveyDates = async () => {
    let utcSurveyDateStart = null;
    let utcSurveyDateEnd = null;
    let utcTimestartMilestone = null;

    if (this.timeStartMilestone) {
      utcTimestartMilestone = this.timeStartMilestone.toISOString();
    }

    try {
      MainStore.changeLoader(true);
      const data = {
        survey_id: this.surveyId,
        strsurvey_date_start: utcSurveyDateStart,
        strsurvey_date_end: utcSurveyDateEnd,
        strtimestart_milestone: utcTimestartMilestone,
        leave_date_empty: this.leaveDateEmpty,
        close_now: this.closeNow,
      };

      await saveSurveyDates(data);
      this.loadMainInformation(this.surveyId);
      this.getMilestoneData();

      this.changeDatesTab(false);

      MainStore.setSnackbar("Survey dates saved successfully!");
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getTodayRecepientMilestoneCount = async (idSurvey: number | null = null) => {
    try {
      let id = idSurvey ?? this.surveyId;
      MainStore.changeLoader(true);

      if (!id) {
        throw new Error("Survey ID is not defined");
      }

      const response = await getTodayRecepientMilestoneCount(id);
      if ((response.status !== 200 && response.status !== 201) || !response?.data) {
        throw Error();
      }

      this.todayRecipientMilestoneCount = response.data.today_recipients_count;
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;

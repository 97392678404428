import { FC } from "react";
import styled from "styled-components";

type StatisticContainerProps = {
  id: string;
  text: string;
  subTitle: string;
};
const StatisticContainer: FC<StatisticContainerProps> = ({
  text,
  subTitle,
}: StatisticContainerProps) => {
  return (
    <StatisticContainerWrapper>
      <span>{text}</span>
      <p>{subTitle}</p>
    </StatisticContainerWrapper>
  );
};

const StatisticContainerWrapper = styled.div<{ $notLast?: boolean }>`
  width: 100%;
  /* max-width: 110px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;

  span {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    font-family: Roboto, sans-serif;
    color: var(--colorNeutralForeground1);
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    font-family: Roboto, sans-serif;
    color: var(--colorNeutralForeground1);
  }
`;

export default StatisticContainer;

import { observer } from "mobx-react";
import { FC, useEffect, useState, useRef } from "react";
import { Button, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import dayjs from "dayjs";
import store from "./store";
import styled from "styled-components";
import topStore from "../store";
import DownloadIcon from "@mui/icons-material/Download";
import AddNewTrendCard from "./AddNewTrendCard";
import TrendCardWrapper from "./TrendCardWrapper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IS_OLD_DASHBOARD_OR_ENABLED_FEATURE } from "constants/config";

type TrendScreenProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
  onPdfDownload: () => void;
};

const TrendScreen: FC<TrendScreenProps> = observer((props) => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = (el: HTMLElement) => {
    setIsSticky(Boolean(el.scrollTop >= 244));
  };

  useEffect(() => {
    const el = document.getElementById("main-scroll-content");

    el.addEventListener("scroll", () => handleScroll(el));

    return () => {
      window.removeEventListener("scroll", () => handleScroll(el));
    };
  });

  useEffect(() => {
    if (topStore.selectedProject && topStore.selectedProject.id) {
      store.getQuestions(topStore.selectedProject.id);
      topStore.getSurveyResponseCount(props.localFilters);
    }
  }, [topStore.selectedProject, topStore.role, topStore.secondaryRole, topStore.filterUnitList]);

  useEffect(() => {
    store.clearStore();
  }, [topStore.selectedProject]);

  return (
    <Container>
      <TopWrapper $isSticky={isSticky}>
        {IS_OLD_DASHBOARD_OR_ENABLED_FEATURE && (
          <>
            <TopContainer $isSticky={isSticky}>
              <TopContainerText>
                Viewing results for {topStore.calculateRespondentsCount} respondents
              </TopContainerText>
              <Divider>|</Divider>
              <CustomWidthTooltip
                title={`Local timezone: UTC ${dayjs().format("Z")}`}
                placement="top"
                arrow
              >
                <TopContainerText>
                  Last updated: Today, {topStore.lastUpdated} <FiberManualRecordIcon />
                </TopContainerText>
              </CustomWidthTooltip>
            </TopContainer>
            <Group $isSticky={isSticky}>
              <DownloadButton
                variant="outlined"
                disabled={store.cardList.length < 3 &&
                topStore.isDisplayTrends &&
                topStore.isNotOnlyOpenendQuestions}
                onClick={props.onPdfDownload}
                startIcon={<DownloadIcon />}
              >
                Download Results
              </DownloadButton>
            </Group>
          </>
        )}
      </TopWrapper>
      <TrendCardsList className="trend-content">
        {store.cardList.map((card, cardIndex) => (
          <TrendCardWrapper
            card={card}
            cardIndex={cardIndex}
            key={cardIndex}
            localFilters={props.localFilters}
          />
        ))}
        {store.cardList.length < 3 &&
          topStore.isDisplayTrends &&
          topStore.isNotOnlyOpenendQuestions && <AddNewTrendCard />}
      </TrendCardsList>
    </Container>
  );
});

export default TrendScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopWrapper = styled.div<{ $isSticky: boolean }>`
  height: 88px;
  width: ${(props) => (props.$isSticky ? "55%" : "100%")};
  ${(props) => props.$isSticky && "align-self:flex-end"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
  position: sticky;
  top: 150px;
  z-index: ${(props) => (props.$isSticky ? "11" : "1")};
`;

const TopContainer = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  align-items: center;
  display: ${(props) => (props.$isSticky ? "none" : "flex")};

  @media (max-width: 1439px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TopContainerText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  white-space: nowrap;

  svg {
    color: var(--colorPaletteGreenBackground2);
    font-size: 12px;
  }
`;

const Group = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Divider = styled.p`
  margin: 0px 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralBackground3);

  @media (max-width: 1439px) {
    display: none;
  }
`;

const DownloadButton = styled(Button) <{ $isSticky: boolean }>`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  margin-left: 10px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${props => !props.disabled ? 'var(--colorNeutralBackground1)' : 'transparent'} !important;

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;

const TrendCardsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});

import { FC, useMemo } from "react";
import styled from "styled-components";
import StatisticContainer from "./components/StatisticContainer";
import { useNavigate } from "react-router-dom";
import SuccessIcon from "components/icons/SuccessIcon";

type ImportSuccessViewProps = {
  employeeUpdated: number;
  employeeAdded: number;
  employeeRemoved: number;
  formName: string;
  changelogs?: any[];
};

const ImportSuccessView: FC<ImportSuccessViewProps> = ({
  formName,
  employeeUpdated,
  employeeAdded,
  employeeRemoved,
}: ImportSuccessViewProps) => {
  const nav = useNavigate();

  return (
    <ImportReviewSuccessBox>
      <Content id={`${formName}_content`}>
        <SuccessIcon height={40} width={40}></SuccessIcon>
        <Header id={`${formName}_headerReview`}>Employees imported successfully</Header>

        <SubHeader id={`${formName}_subHeader`}>
          Your employees have been imported successfully. View the list of employees on the
          Employees page.
        </SubHeader>

        <BorderSpacing />
        <SummarySection>
          <SummaryTitle>Summary</SummaryTitle>

          <Row id={`${formName}_statRow`}>
            <StatisticContainer
              id={`${formName}_statisticContainerUpdated`}
              text={`${employeeUpdated}`}
              subTitle={"employees updated"}
            ></StatisticContainer>
            <StatisticContainer
              id={`${formName}_statisticContainerAdded`}
              text={`${employeeAdded}`}
              subTitle={"employees added"}
            ></StatisticContainer>
            <StatisticContainer
              id={`${formName}_statisticContainerRemoved`}
              text={`${employeeRemoved}`}
              subTitle={"employees removed"}
            ></StatisticContainer>
          </Row>
          <ViewEmployeesBtn onClick={() => nav("/employees")}>
            <span>View employees</span>
          </ViewEmployeesBtn>
        </SummarySection>
      </Content>
    </ImportReviewSuccessBox>
  );
};

const ImportReviewSuccessBox = styled.div`
  background-color: var(--colorNeutralForeground8);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 8px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h6`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  margin: 8px 0 8px 0;
`;

const BorderSpacing = styled.div`
  width: 100%;
  height: 3 px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  margin-bottom: 32px;
  margin-top: 32px;
`;

const SubHeader = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const SummaryTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 16px;
`;

const SummarySection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ViewEmployeesBtn = styled.button`
  max-width: 200px;
  border: 1px solid var(--colorBrandForeground1);
  padding: 7px 20px 7px 20px;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--colorBrandForeground1);
  }
`;

export default ImportSuccessView;

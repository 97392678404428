import { createAuthService, Environment, Platform } from "@engagerocketco/auth-sdk";
import { API_NO_GATEWAY_URL } from 'constants/config';
import { EventSourcePolyfill } from 'event-source-polyfill';

export const useRealtimeNotification = (multiBannerFlag: boolean) => {
    const token = createAuthService({
        platform: Platform.MAIN,
        environment: process.env.REACT_APP_ENVIRONMENT as Environment,
    }).getStoredTokens().accessToken;

    let url = `${API_NO_GATEWAY_URL}/communication/events`;
    if (multiBannerFlag) {
        url = `${API_NO_GATEWAY_URL}/communication/eventsV2`
    }

    const newEventSource = new EventSourcePolyfill(
        url,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );

    return newEventSource;
}

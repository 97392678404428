import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import styled from "styled-components";

const ImpersonationPage: React.FC = () => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setHasError(true);
        }, 4000);

        const handleMessage = (event: MessageEvent) => {
            if (event.data.type !== 'ERImpersonation') {
                return;
            }

            localStorage.setItem('accessToken', event.data.accessToken);
            localStorage.setItem('refreshToken', event.data.refreshToken);
            window.location.href = '/select-workspace';
        };

        window.addEventListener('message', handleMessage);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <>
            <Snackbar
                id="Alert_Snackbar"
                open={hasError}
                autoHideDuration={3000}
                message="Something went wrong and we couldn’t process your request. Please try again. "
                key={"bottomleft"}
            >
                <StyledAlert
                    icon={<CancelIconWrapp />}
                    $severity="error"
                >
                    "Something went wrong and we couldn’t process your request. Please try again. "
                </StyledAlert>
            </Snackbar>
            {!hasError && (
                <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </>
    )
}

const StyledAlert = styled(Alert) <{ $severity?: "success" | "info" | "warning" | "error" }>`
  background-color: var(--colorNeutralForeground1, #3e4450) !important;
  color: var(--colorNeutralBackground1, #fff) !important;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const CancelIconWrapp = styled(CancelIcon)`
  color: var(--colorPaletteRedForeground2, #bd0202) !important;
`;

export default ImpersonationPage;

import CloseIcon from "@mui/icons-material/Close";
type DismissButtonProps = {
    onClick: () => void;
}
const DismissButton = ({ onClick }: DismissButtonProps) => {
    return (
        <CloseIcon sx={{
            color: "#707882",
            width: "16px",
            height: "16px",
            cursor: "pointer",
        }} onClick={onClick}></CloseIcon>
    )
}

export default DismissButton;
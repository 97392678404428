import { makeAutoObservable, runInAction } from "mobx";
import { getDashboardResponcesScoredCommentsAsync } from "api/dasboard/useGetDashboardResponcesScoredComments";
import { getDashboardResponcesScoredDimensionsAsync } from "api/dasboard/useGetDashboardResponcesScoredDimensions";
import { TScoredQuestionsDimension, TScoredQuestionsDimensionResponse } from "types/dashboard";

import topStore from "../../store";
import MainStore from "MainStore";
import { USE_DASHBOARD_API } from "constants/config";
import { ResponsesType, useGetDashboardResponses } from "api/dasboard/useGetDashboardResponses";
import { processFilters } from "utils/dashboard/processFilters";

class Store {
    scoredQuestionsList: TScoredQuestionsDimension[] = [];
    activeScoredQuestionDimension: TScoredQuestionsDimension = null;
    isOpenConditionalLogicPopup: boolean = false;
    activeScoredQuestion: TScoredQuestionsDimensionResponse = null;

    constructor() {
        makeAutoObservable(this);
    }

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    getDashboardResponsesScoredDimensions = async (
        workspace_id: number,
        entity_id: number,
        project_id: number,
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        let response;
        if (USE_DASHBOARD_API) {
            response = await useGetDashboardResponses({
                dashboardParameters: {
                    workspace_id,
                    entity_id,
                    project_id,
                    survey_id,
                    viewType: topStore.role,
                },
                attributes: processedFilters,    
                benchmarkType: topStore.overviewBenchmark,
                responsesTypes: [ResponsesType.SCORED]
            });
            response = response?.data?.scored;
        } else {
            response = await getDashboardResponcesScoredDimensionsAsync({
                survey_id,
                attributes: processedFilters,
                benchmarkType: topStore.overviewBenchmark,
            });
        }

        runInAction(() => {
            if (response) {
                this.scoredQuestionsList = response;
                this.activeScoredQuestionDimension = response && response.length > 0 ? response[0] : null;
            }
        });

        MainStore.changeLoader(false);
    };

    getDashboardResponsesScoredComments = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[],
        questionId: number,
        scoredType: number,
        pagination: { offset: number; limit: number }
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        const response = await getDashboardResponcesScoredCommentsAsync({
            survey_id,
            attributes: processedFilters,
            questionId,
            scoredType,
            benchmarkType: topStore.overviewBenchmark,
            pagination,
        });

        MainStore.changeLoader(false);

        return response;
    };
}

const store = new Store();

export default store;

import { FC } from "react";
import { observer } from "mobx-react";
import { bottomLabels, topLabels } from "./labelsTemplate";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import Highcharts from "highcharts";
import CustomSwitch from "components/switch/Switch";
import HighchartsReact from "highcharts-react-official";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type GraphViewProps = {};

const GraphView: FC<GraphViewProps> = observer(() => {
  const returnGraphLabels = (e) => {
    const duplicate = store.chartData?.points.filter((el) => el.x === e.x && el.y === e.y);
    if (duplicate && duplicate.length > 0) {
      return `<div style="padding: 8px; background-color: var(--colorShadowInverted2); border-radius: 4px;">
        <div style="font-weight: bold; color: var(--colorNeutralBackground1);">${duplicate[0].name}</div>
      </div>`;
    }
    return '';
  };

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "scatter",
      height: 687,
      backgroundColor: "transparent",
      zoomType: "xy",
    },
    title: {
      text: "",
    },

    subtitle: {
      text: topLabels,
      useHTML: true,
    },
    caption: {
      text: bottomLabels,
      useHTML: true,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "var(--colorShadowInverted2)",
      formatter: function() {
        return `<div style="padding: 8px; color: var(--colorNeutralBackground1);">
          <div style="font-weight: bold; margin-bottom: 5px;">${this.point.name}</div>
          <div>Impact score: ${this.x}</div>
          <div>Favorability: ${this.y}%</div>
        </div>`;
      },
      shared: false,
      style: {
        color: "var(--colorNeutralBackground1)",
      },
    },

    plotOptions: {
      scatter: {
        showInLegend: false,
        marker: {
          symbol: "circle",
          radius: 6,
          states: {
            hover: {
              enabled: true,
              lineColor: 'white',
              radiusPlus: 2,
              lineWidthPlus: 1
            }
          }
        },

        jitter: {
          x: 0.3,
          y: 0.3,
        },

        dataLabels: {
          enabled: store.isShowDimensionLabels,
          backgroundColor: "transparent",
          borderRadius: 0,
          padding: 0,
          distance: 15,
          formatter: function () {
            return returnGraphLabels(this);
          },
          useHTML: true,
          shadow: false,
          crop: false,
          overflow: 'allow',
          style: {
            textOutline: "none",
          },
        },
        states: {
          hover: {
            halo: {
              size: 12,
              opacity: 0.25
            }
          }
        }
      },
    },
    xAxis: {
      tickAmount: 3,
      arrow: true,
      gridLineColor: "#CDD3EC",
      lineColor: "#A9AFC6",
      lineWidth: 1,
      gridLineWidth: 1,
      visible: true,
      max: store.chartData?.topX || 100,
      min: store.chartData?.bottomX || 1,
      tickWidth: 0,
      showLastLabel: true,
      showFirstLabel: true,
      plotBands: [
        {
          from: 0,
          to: 1000000000000000,
          color: "#ffffff",
        },
      ],
      title: {
        text: "Impact score",
      },
      labels: {
        align: "center",
        y: 60,
      },
    },
    yAxis: {
      tickAmount: 3,
      arrow: true,
      lineColor: "#A9AFC6",
      visible: true,
      max: store.chartData?.topY || 100,
      lineWidth: 1,
      min: store.chartData?.bottomY || 1,
      gridLineWidth: 1,
      showLastLabel: true,
      showFirstLabel: true,
      title: {
        text: "Favorable score",
      },
      labels: {
        formatter: (e) =>
          `<p style="
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color:var(--colorNeutralForeground2);">
              ${e.value}%
              </p>`,
      },
      className: "axis-container",
      plotBands: [
        {
          from: 0,
          to: 1000000000000000,
          color: "#ffffff",
        },
      ],
    },
    series: [{
      type: 'scatter',
      data:
        store.selectedPrioritize.length > 2 && store.chartData?.points
          ? store.chartData?.points.map(point => ({
              x: point.x,
              y: point.y,
              name: point.name,
              color: point.color,
              marker: {
                radius: store.isShowDimensionLabels ? 8 : 6,
                lineWidth: store.isShowDimensionLabels ? 2 : 0,
                lineColor: 'white',
                fillColor: point.color,
                states: {
                  hover: {
                    lineWidthPlus: 1,
                    radiusPlus: 2
                  }
                }
              }
            }))
          : [],
    }],
  };

  return (
    <Container>
      <TitleWrapper>
        <Title>Question to calculate impact on:</Title>
        <CustomWidthTooltip
          title="Impact score indicates how much the results of the selected question will change if you take action on the specific dimension. It ranges from 1-100."
          placement="top"
          arrow
        >
          <StyledInfoIcon />
        </CustomWidthTooltip>
      </TitleWrapper>

      <StyledSelect
        id="question_selected"
        value={store.selectedQuestion}
        onChange={(e: SelectChangeEvent<string>) => {
          store.setData(e.target.value, "selectedQuestion");
          store.setData([], "selectedPrioritize");
        }}
        placeholder={store.questionsList[0]?.label}
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
        }}
      >
        {store.questionsList.map((question, index) => (
          <StyledMenuItem key={index} value={question.value}>
            {question.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>

      <SwitchGroup>
        <SwitchText>Display dimension labels</SwitchText>
        <CustomSwitch
          checked={store.isShowDimensionLabels}
          onChange={() => store.setData(!store.isShowDimensionLabels, "isShowDimensionLabels")}
          disabled={store.selectedPrioritize.length < 3}
        />
      </SwitchGroup>
      <GraphContainer>
        <StyledHighchartsReact highcharts={Highcharts} options={chartOptions} />
        {store.selectedPrioritize.length < 3 && (
          <EmptyGraphLabel>
            <ErrorIcon />
            <EmptyText>
              For an insightful analysis to show here, select 3 or more dimensions. This allows us
              to compare dimensions for you to prioritize your focus areas.
            </EmptyText>
          </EmptyGraphLabel>
        )}
      </GraphContainer>
    </Container>
  );
});

export default GraphView;

const Container = styled.div`
  width: 67%;
  padding: 32px 32px 32px 48px;
  border-right: 1px solid var(--colorPaletteBlueBackground1);
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  margin-right: 8px;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 237,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});

const StyledInfoIcon = styled(InfoIcon)`
  color: var(--colorBrandForeground1);
  font-size: 20px !important;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 100% !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-top: 8px;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const SwitchGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

const SwitchText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: var(--colorNeutralForeground1);
  margin-right: 9px;
  white-space: nowrap;
`;

const GraphContainer = styled.div`
  width: 100%;
  padding: 16px;
  background-color: var(--colorPalleteLightBlue);
  position: relative;
  margin-top: 16px;

  .highcharts-subtitle {
    width: 88% !important;
    left: 62px !important;
    right: 20px !important;
  }

  .highcharts-caption {
    width: 88% !important;
    left: 62px !important;
    right: 20px !important;
    margin-top: -80px !important;
  }
`;

const StyledHighchartsReact = styled(HighchartsReact)``;

const EmptyGraphLabel = styled.div`
  width: 50%;
  display: flex;
  background-color: var(--colorPalleteLightBlue);
  padding: 18px;
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% + 32px);
  transform: translate(-50%, -50%);

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const EmptyText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const StyledMenuItem = styled(MenuItem)`
  white-space: normal !important;
`;

import { makeAutoObservable, reaction, runInAction } from "mobx";
import { getDashboardResponcesOpenEndedTopicsAsync } from "api/dasboard/useGetDashboardResponcesOpenEndedTopics";
import { renameDashboardResponcesOpenEndedTopicAsync } from "api/dasboard/useRenameDashboardResponcesOpenEndedTopic";
import { getDashboardResponcesOpenEndedQuestionsAsync } from "api/dasboard/useGetDashboardResponcesOpenEndedQuestions";
import { getDashboardResponcesOpenEndedResponsesAsync } from "api/dasboard/useGetDashboardResponcesOpenEndedResponses";
import { editDashboardResponcesOpenEndedTopicListAsync } from "api/dasboard/useEditDashboardResponcesOpenEndedTopicList";
import { getDashboardResponcesOpenEndedResponsesByTopicAsync } from "api/dasboard/useGetDashboardResponcesOpenEndedResponsesByTopic";
import {
    DEFAULT_PAGINATION_DATA,
    OPEN_ENDED_LOCAL_MENU,
    SENTIMENT_FILTERS_LIST,
} from "constants/dashboard-responses";
import {
    TDefaultPagination,
    TOpenEndedGraphData,
    TOpenEndedQuestion,
    TOpenEndedQuestionInfo,
    TOpenEndedTopic,
} from "types/dashboard";

import topStore from "../../store";
import MainStore from "MainStore";
import { SENTIMENT_FILTERS_LIST_VALUES } from "constants/dashboard-responses";
import { USE_DASHBOARD_API } from "constants/config";
import { ResponsesType, useGetDashboardResponses } from "api/dasboard/useGetDashboardResponses";
import { processFilters } from "utils/dashboard/processFilters";
class Store {
    openEndedQuestionsList: TOpenEndedQuestion[] = [];
    activeOpenEndedQuestion: TOpenEndedQuestion = null;

    activeOpenEndedLocalTab: string = OPEN_ENDED_LOCAL_MENU.ALL_RESPONSES;
    activeOpenEndedQuestionInfo: TOpenEndedQuestionInfo = null;

    openEndedTopicsList: TOpenEndedTopic[] = [];
    openEndedTopicsCount: number = 0;
    isOpenTopicResponseDetail: boolean = false;
    isOpenEditTopicPopup: boolean = false;
    activeOpenEndedTopic: TOpenEndedTopic = null;

    sentimentFilter: string = SENTIMENT_FILTERS_LIST[0].value;

    isShowSentimentsOpenEndedCard: boolean = true;

    pagination: TDefaultPagination = DEFAULT_PAGINATION_DATA;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => [
                this.activeOpenEndedLocalTab,
                this.activeOpenEndedQuestion,
                this.sentimentFilter,
                topStore.activeTab,
                topStore.responsesActiveLocalTab,
                this.activeOpenEndedTopic,
            ],
            () => {
                this.pagination = DEFAULT_PAGINATION_DATA;
            }
        );

        reaction(
            () => [
                this.activeOpenEndedLocalTab,
                this.activeOpenEndedQuestion,
                topStore.activeTab,
                topStore.responsesActiveLocalTab,
                this.activeOpenEndedTopic,
            ],
            () => {
                this.sentimentFilter = SENTIMENT_FILTERS_LIST[0].value;
            }
        );

        reaction(
            () => [topStore.activeTab, topStore.responsesActiveLocalTab],
            () => {
                this.activeOpenEndedLocalTab = OPEN_ENDED_LOCAL_MENU.ALL_RESPONSES;
            }
        );
    }

 returnGraphMiddleLabel(dataChart?: TOpenEndedGraphData) {
        switch (this.sentimentFilter) {
            case SENTIMENT_FILTERS_LIST_VALUES.all:
                return dataChart ? dataChart.allCount : this.activeOpenEndedQuestionInfo?.graphData.allCount || 0;
            case SENTIMENT_FILTERS_LIST_VALUES.positive:
                return dataChart ? dataChart.positiveCount : this.activeOpenEndedQuestionInfo?.graphData.positiveCount || 0;
            case SENTIMENT_FILTERS_LIST_VALUES.negative:
                return dataChart ? dataChart.negativeCount : this.activeOpenEndedQuestionInfo?.graphData.negativeCount || 0;
            case SENTIMENT_FILTERS_LIST_VALUES.mixed:
                return dataChart ? dataChart.mixedCount : this.activeOpenEndedQuestionInfo?.graphData.mixedCount || 0;
            case SENTIMENT_FILTERS_LIST_VALUES.neutral:
                return dataChart ? dataChart.neutralCount : this.activeOpenEndedQuestionInfo?.graphData.neutralCount || 0;
            case SENTIMENT_FILTERS_LIST_VALUES.unavailable:
                return dataChart ? dataChart.unavailableCount : this.activeOpenEndedQuestionInfo?.graphData.unavailableCount || 0;
            default:
                return dataChart ? dataChart.allCount : this.activeOpenEndedQuestionInfo?.graphData.allCount || 0;
        }
    }
 

    changeQuestions = (question: TOpenEndedQuestion) => {
        this.activeOpenEndedQuestion = question;
        this.activeOpenEndedLocalTab = OPEN_ENDED_LOCAL_MENU.ALL_RESPONSES;
    };

    changeSentimentFilter = (value: string) => (this.sentimentFilter = value);

    changePagination = (filed: string, value: number) => {
        this.pagination = {
            ...this.pagination,
            [filed]: value,
        };
    };

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    getDashboardResponsesOpenEndedQuestions = async (
        workspace_id: number,
        entity_id: number,
        project_id: number,
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);

        let response; 

        if (USE_DASHBOARD_API) {
            response = await useGetDashboardResponses({
                dashboardParameters: {
                    workspace_id,
                    entity_id,
                    project_id,
                    survey_id,
                    viewType: topStore.role,
                },
                attributes: filters,
                benchmarkType: topStore.overviewBenchmark,
                responsesTypes: [ResponsesType.OPEN_ENDED]
            });
            response = response?.data?.openEnded;
        } else {
            response = await getDashboardResponcesOpenEndedQuestionsAsync({
                survey_id,
                attributes: filters,
                benchmarkType: topStore.overviewBenchmark,
            });
        }

        runInAction(() => {
            this.openEndedQuestionsList = response;
            this.activeOpenEndedQuestion = response && response.length > 0 ? response[0] : null;
        });

        MainStore.changeLoader(false);
    };

    getDashboardResponsesOpenEndedTopics = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        const response = await getDashboardResponcesOpenEndedTopicsAsync({
            survey_id,
            attributes: processedFilters,
            benchmarkType: topStore.overviewBenchmark,
            question_id: this.activeOpenEndedQuestion.id,
        });

        runInAction(() => {
            this.openEndedTopicsList = response.Topics.reverse();
            this.openEndedTopicsCount = response.countResponses;
        });

        MainStore.changeLoader(false);
    };

    getDashboardResponsesOpenEndedResponses = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        if (USE_DASHBOARD_API) {
            if (this.activeOpenEndedQuestion) {
                const questionResponses = this.activeOpenEndedQuestion.data || [];
                const responses = this.sentimentFilter === SENTIMENT_FILTERS_LIST_VALUES.all ?
                    questionResponses.Responses.data :
                    questionResponses.Responses.data.filter(r => r.type === this.sentimentFilter);
    
                const totalCount = responses.length;
                const totalPages = Math.ceil(totalCount / this.pagination.count_per_page);
                const startIndex = (this.pagination.active_page - 1) * this.pagination.count_per_page;
                const paginatedResponses = responses.slice(startIndex, startIndex + this.pagination.count_per_page);
                runInAction(() => {
                    this.activeOpenEndedQuestionInfo = {
                        responses: paginatedResponses,
                        graphData: questionResponses.graphData,
                        respondedCount: responses.length,
                    };
        
                    this.pagination = {
                        total_count: totalCount,
                        total_pages: totalPages,
                        active_page: this.pagination.active_page,
                        count_per_page: this.pagination.count_per_page,
                    };
                });
            }

        } else {
            const response = await getDashboardResponcesOpenEndedResponsesAsync({
                survey_id,
                attributes: processedFilters,
                benchmarkType: topStore.overviewBenchmark,
                question_id: this.activeOpenEndedQuestion?.id,
                sentiment: this.sentimentFilter,
                pagination: {
                    offset: this.pagination.active_page,
                    limit: this.pagination.count_per_page,
                },
            });
            runInAction(() => {
                this.activeOpenEndedQuestionInfo = {
                    responses: response.Responses.data,
                    graphData: response.graphData,
                    respondedCount: response.respondedCount,
                };
    
                this.pagination = {
                    total_count: response.Responses.total_count,
                    total_pages: response.Responses.total_pages,
                    active_page: response.Responses.active_page,
                    count_per_page: response.Responses.count_per_page,
                };
            });
        }


        MainStore.changeLoader(false);
    };

    getDashboardResponsesOpenEndedResponsesByTopic = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        const response = await getDashboardResponcesOpenEndedResponsesByTopicAsync({
            survey_id,
            attributes: processedFilters,
            benchmarkType: topStore.overviewBenchmark,
            question_id: this.activeOpenEndedQuestion.id,
            topic: this.activeOpenEndedTopic.Title,
            sentiment: this.sentimentFilter,
            pagination: {
                offset: this.pagination.active_page,
                limit: this.pagination.count_per_page,
            },
        });

        runInAction(() => {
            this.activeOpenEndedQuestionInfo = {
                responses: response.Responses.data,
                graphData: response.graphData,
                respondedCount: response.respondedCount,
            };

            this.pagination = {
                total_count: response.Responses.total_count,
                total_pages: response.Responses.total_pages,
                active_page: response.Responses.active_page,
                count_per_page: response.Responses.count_per_page,
            };
        });

        MainStore.changeLoader(false);
    };

    editTopicName = async (
        newName: string,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);

        await renameDashboardResponcesOpenEndedTopicAsync({
            survey_id: topStore.selectedSurvey.value,
            questionId: this.activeOpenEndedQuestion.id,
            oldName: this.activeOpenEndedTopic?.Title,
            newName,
            attributes: processedFilters,
        });

        runInAction(() => {
            this.isOpenEditTopicPopup = false;

            if (store.isOpenTopicResponseDetail) {
                this.activeOpenEndedTopic = { ...this.activeOpenEndedTopic, Title: newName };
            } else {
                this.getDashboardResponsesOpenEndedTopics(topStore.selectedSurvey.value, filters);
            }
        });

        MainStore.changeLoader(false);

        MainStore.setSnackbar(
            `${this.activeOpenEndedTopic.responsesCount} responses under ‘${this.activeOpenEndedTopic.Title}’ are now under ‘${newName}’.`,
            "success"
        );
    };

    editTopicList = async (
        responseId: string,
        topics: string[],
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        await editDashboardResponcesOpenEndedTopicListAsync({
            survey_id: topStore.selectedSurvey.value,
            questionId: this.activeOpenEndedQuestion.id,
            responseId,
            topics,
            attributes: processedFilters,
        });

        runInAction(() => {
            if (this.activeOpenEndedLocalTab === OPEN_ENDED_LOCAL_MENU.ALL_RESPONSES) {
                this.getDashboardResponsesOpenEndedResponses(topStore.selectedSurvey.value, filters);
            } else {
                this.getDashboardResponsesOpenEndedResponsesByTopic(topStore.selectedSurvey.value, filters);
            }
        });

        MainStore.changeLoader(false);
    };

    changeIsShowSentimentsOpenEndedCard = (value: boolean) => {
        runInAction(() => {
            this.isShowSentimentsOpenEndedCard = value;
        });
    }
}

const store = new Store();

export default store;

import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "styled-components";

type DatePickerValueProps = {
  id?: string;
  value: Dayjs;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  showCustomError?: boolean;
  changeValue: (value: Dayjs) => void;
  onError?: (value: boolean, message: string) => void;
};

const DatePickerValue = ({
  id,
  value,
  label,
  disabled,
  required = false,
  showCustomError = false,
  changeValue,
  onError
}: DatePickerValueProps) => {
  const [dateValue, setDateValue] = useState<Dayjs | null>(value);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (value !== dateValue) {
      setDateValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (!required) {
      setIsError(false);
      setErrorMessage("");

      onError(false, errorMessage);
    }
  }, [required]);

  useEffect(() => {
    if (!disabled) {
      onError(isError, errorMessage);
    }
  }, [isError, errorMessage, disabled]);

  useEffect(() => {
    if (disabled) {
      setDateValue(null);
      return;
    }

    setDateValue(value);
  }, [disabled]);

  const handleOnChange = (newValue: Dayjs | null) => {
    if (!(newValue instanceof dayjs || newValue === null)) return;

    const minDate = dayjs().startOf("day");
    const isBeforeMinDate = newValue?.isBefore(minDate, "day");
    const isMissingRequired = required && !newValue;
    const hasError = isBeforeMinDate || isMissingRequired;

    if (hasError) {
      setIsError(true);

      if (isMissingRequired) {
        setErrorMessage("Please ensure that you have entered a date.");
      } else if (isBeforeMinDate) {
        setErrorMessage("Selected date cannot be before today.");
      }
    } else {
      setIsError(false);
      setErrorMessage("");
    }

    changeValue(newValue);
  };

  const handleOnError = (error) => {
    if (error) {
      setIsError(true);
      setErrorMessage("Please ensure that you have entered a valid date.");
    }
  };

  return (
    <div id={id}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <WrappedDateField
          $error={showCustomError}
          format="DD/MM/YYYY"
          value={dateValue}
          label={!disabled ? (label ? label : "Select date") : ""}
          disabled={disabled}
          onChange={(newValue: Dayjs) => handleOnChange(newValue)}
          closeOnSelect={true}
          slotProps={{
            textField: {
              InputLabelProps: { shrink: true },
              inputProps: { placeholder: "DD/MM/YYYY" },
              error: isError || showCustomError,
            },
            openPickerIcon: {
              sx: {
                fontSize: 17,
                width: 15,
                height: 17,
              },
            },
          }}
          onError={(error) => handleOnError(error)}
          minDate={dayjs()}
        />
      </LocalizationProvider>
    </div>
  );
};

const WrappedDateField = styled(DatePicker)<{ $error: boolean }>`
  & .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
    background-color: var(--colorGrayForeground7);
    cursor: not-allowed;
  }

  input {
    padding: 6px 8px;
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;

    ${(props) =>
      props.$error
        ? "border-color: var(--colorPaletteRedForeground2)"
        : "border-color: var(--colorPaletteBlueBackground1)"};
  }

  label {
    position: absolute;
    top: -3px;
  }
`;

export default DatePickerValue;

import store from "../store";
import styled from "styled-components";
import EnpsCard from "./eNPSCard/EnpsCard";
import TrendCard from "./TrendCard/TrendCard";
import SegmentsCard from "./SegmentsCard/SegmentsCard";
import DimensionsCard from "./DimensionsCard/DimensionsCard";
import OveralScoreCard from "./OveralScoreCard/OveralScoreCard";
import ParticipationCard from "./ParticipationCard/ParticipationCard";
import OpenEndedQuestionsCard from "./OpenEndedQuestionsCard/OpenEndedQuestionsCard";
import { USE_DASHBOARD_API } from "constants/config";

interface OverViewElementRendererConfig {
    localFilters: { id: number; name: string; segments: string[] }[];
}

export class OverViewElementRenderer {
    private config: OverViewElementRendererConfig;

    constructor(config: OverViewElementRendererConfig) {
        this.config = config;
    }

    private renderOverviewCard() : React.ReactNode {
        return <Container>
        <TopBox>
          <OveralScoreCard />
          <EnpsCard />
        </TopBox>
        <MiddleTobBox>
          <DimensionsCard />
          <ParticipationCard />
        </MiddleTobBox>
        <MiddleBottomBox>
          <TrendCard isStretched={USE_DASHBOARD_API} />
          {!USE_DASHBOARD_API && <SegmentsCard />}
        </MiddleBottomBox>
      </Container>
    }

    private renderOpenEndedQuestionsCard() : React.ReactNode {
        return store.isOpenEndedShown && (
            <BottomBox>
              <OpenEndedQuestionsCard localFilters={this.config.localFilters} />
            </BottomBox>
        );
    }

    public render() : React.ReactNode[] {
        return [this.renderOverviewCard(), this.renderOpenEndedQuestionsCard()]
    }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 0;
  padding-bottom: 80px;
`;

const TopBox = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
`;

const MiddleTobBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 16px;
  margin-top: 16px;
`;

const MiddleBottomBox = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
  margin-top: 16px;
`;

const BottomBox = styled.div`
  width: 100%;
  margin-top: 16px;
`;
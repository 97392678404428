import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import styled from "styled-components";

type CustomTimeFieldProps = {
  value: Dayjs | null;
  changeValue: (value: Dayjs) => void;
  disabled?: boolean;
  minTime?: Dayjs | null;
  pairWithDate?: boolean;
  showCustomError?: boolean;
  isOnMilestone?: boolean;
  onError?: (value: boolean, message: string) => void;
};

const CustomTimeField = ({
  value,
  changeValue,
  disabled = false,
  minTime = null,
  pairWithDate = false,
  showCustomError = false,
  isOnMilestone = false,
  onError,
}: CustomTimeFieldProps) => {
  const [open, setOpen] = useState(false);
  const [minTimeValue, setMinTimeValue] = useState<Dayjs | null>(minTime);
  const [disabledValue, setDisabledValue] = useState<boolean>(disabled);
  const [timeValue, setTimeValue] = useState<Dayjs | null>(value);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (value !== timeValue) {
      setTimeValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (minTime !== minTimeValue) {
      setMinTimeValue(minTime);
    }
  }, [minTime]);

  useEffect(() => {
    setDisabledValue(disabled);
  }, [disabled]);

  useEffect(() => {
    onError(isError, errorMessage);
  }, [isError, errorMessage]);

  useEffect(() => {
    if (disabledValue) {
      setTimeValue(null);
    } else {
      setTimeValue(value);
      defaultTimeWODate();
    }
  }, [disabledValue]);

  const defaultTimeWODate = () => {
    if (pairWithDate || isOnMilestone) return;

    const roundedTime = dayjs()
      .minute(Math.floor(dayjs().minute() / 5) * 5)
      .second(0)
      .add(15, "minute");

    setMinTimeValue(roundedTime);
  };

  const handleOnError = (error) => {
    if (error) {
      setIsError(true);
      setErrorMessage("15 minutes from now or later.");
      return;
    }

    setIsError(false);
    setErrorMessage("");
  };

  return (
    <MainWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <WrappedTimeField
          $error={showCustomError}
          open={open}
          disabled={disabledValue}
          value={timeValue}
          label={!disabledValue ? "Select time" : ""}
          closeOnSelect={false}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(newValue: Dayjs) => {
            if (newValue instanceof dayjs || newValue === null) {
              changeValue(newValue);
              setTimeValue(newValue);
            }
          }}
          minTime={pairWithDate ? minTime : minTimeValue}
          slotProps={{
            textField: {
              InputLabelProps: { shrink: true },
              inputProps: { placeholder: "HH:MM AM" },
              onKeyDown: (event) => {
                event.preventDefault();
                setOpen(true);
              },
              error: showCustomError || isError,
            },
            openPickerIcon: { sx: { fontSize: 15 } },
          }}
          onError={(error) => handleOnError(error)}
        />
      </LocalizationProvider>
    </MainWrapper>
  );
};

const WrappedTimeField = styled(TimePicker)<{ $error: boolean }>`
  & .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
    background-color: var(--colorGrayForeground7);
    cursor: not-allowed;
  }

  input {
    padding: 6px 8px;
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;

    ${(props) =>
      props.$error
        ? "border-color: var(--colorPaletteRedForeground2)"
        : "border-color: var(--colorPaletteBlueBackground1)"};
  }

  label {
    position: absolute;
    top: -3px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export default CustomTimeField;

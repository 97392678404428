import { FC, useEffect, useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  Button,
  Tabs,
  Tab,
  Box
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import styled from "styled-components";
import PDFResponsesContent from '../responses/PDFResponsesContent';
import PDFOverviewContent from '../overview/PDFOverviewContent';
import responsesStore from '../responses/store';
import topStore from '../store';
import StoreLayout from 'components/workspaces-sidebar/StoreLayout';
import PDFPrioritizeContent from '../prioritize/PDFPrioritizeContent';
import prioritizeStore from '../prioritize/store';
import { ROLE_LIST } from 'constants/role-list';
import { observer } from 'mobx-react-lite';
import MainStore from 'MainStore';
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { SURVEY_STATUS_DASHBOARD } from 'constants/survey-status';
import dayjs from 'dayjs';

interface PdfDownloadDialogProps {
  localFilters: { id: number; name: string; segments: string[] }[];
  open: boolean;
  onClose: () => void;
  onDownload: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`tabpanel-${index}`}
      {...other}
      style={{
        minHeight: '100vh',
        scrollMarginTop: '64px'
      }}
    >
      <Box sx={{ mb: 4 }}>
        {children}
      </Box>
    </div>
  );
}

const PdfDownloadDialog: FC<PdfDownloadDialogProps> = observer((props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);

  useEffect(() => {
    if (props.open) {
      setSelectedTab(0);
    }
  }, [props.open]);
  
  useEffect(() => {
    if (props.open && !MainStore.loader) {
      setIsDownloadEnabled(false);
      const timer = setTimeout(() => {
        setIsDownloadEnabled(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [props.open, MainStore.loader]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    const element = document.getElementById(`tabpanel-${newValue}`);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  const getAvailableTabs = () => {
    const tabs = [0];
    let currentIndex = 1;
    
    if (responsesStore.dashboardResponsesRawisNotEmpty) {
      tabs.push(currentIndex);
      currentIndex++;
    }
    
    if (!prioritizeStore.isDownloadDisabled) {
      tabs.push(currentIndex);
    }
    
    return tabs;
  };

  useEffect(() => {
    if (props.open && StorePermission.features.dashboard_overview.hasFeature) {
      topStore.getSurveyResponseCount(props.localFilters);
      topStore.getSegmentsData(topStore.selectedSurvey.value, props.localFilters);
      topStore.getTrendsData(topStore.selectedSurvey.value, props.localFilters, true);
      topStore.getSurveyResponseCount(props.localFilters);
      topStore.getDashboardData(topStore.selectedSurvey.value, props.localFilters);
      if (topStore.selectedSurvey?.status === SURVEY_STATUS_DASHBOARD.Running) {
        topStore.setData(
          `${dayjs().format("h")}:${Number(dayjs().format("m")) >= 30 ? "30" : "00"} ${dayjs().format(
            "A"
          )}`,
          "lastUpdated"
        );
      }
    }
  }, [props.open, props.localFilters, topStore.selectedSurvey]);

  useEffect(() => {
    const dialogContent = document.querySelector('.MuiDialogContent-root');
    if (!dialogContent) return;

    const handleScroll = () => {
      const tabPanels = getAvailableTabs().map(index => 
        document.getElementById(`tabpanel-${index}`)
      ).filter(Boolean);

      const scrollPosition = dialogContent.scrollTop;
      const containerHeight = dialogContent.clientHeight;

      let maxVisibleArea = 0;
      let selectedIndex = 0;

      tabPanels.forEach((panel, index) => {
        if (!panel) return;

        const rect = panel.getBoundingClientRect();
        const dialogRect = dialogContent.getBoundingClientRect();

        const visibleTop = Math.max(rect.top, dialogRect.top);
        const visibleBottom = Math.min(rect.bottom, dialogRect.bottom);
        const visibleHeight = Math.max(0, visibleBottom - visibleTop);
        const visibleArea = visibleHeight * rect.width;

        if (visibleArea > maxVisibleArea) {
          maxVisibleArea = visibleArea;
          selectedIndex = index;
        }
      });

      setSelectedTab(selectedIndex);
    };

    dialogContent.addEventListener('scroll', handleScroll);
    return () => dialogContent.removeEventListener('scroll', handleScroll);
  }, [props.open, responsesStore.dashboardResponsesRawisNotEmpty, prioritizeStore.isDownloadDisabled]);

  useEffect(() => {
    if (props.open && responsesStore.dashboardResponsesRaw === null && StorePermission.features.dashboard_responses.hasFeature) {
      responsesStore.getDashboardResponses(
        StoreLayout.currentWorkspaceId,
        StoreLayout.currentEntityId,
        topStore.selectedProject.id,
        topStore.selectedSurvey.value,
        props.localFilters
      );
    }
  }, [props.open, topStore.selectedSurvey, props.localFilters, responsesStore.dashboardResponsesRaw]);

  
  useEffect(() => {
    if (props.open && StorePermission.features.dashboard_prioritize.hasFeature && topStore.isSurveyHaveResponseCount && topStore.activeTab !== 'prioritize') {
      prioritizeStore.getQuestionList({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    props.open,
    topStore.selectedSurvey,
    topStore.isSurveyHaveResponseCount,
    topStore.activeTab
  ]);

  useEffect(() => {
    if (props.open && prioritizeStore.selectedQuestion && StorePermission.features.dashboard_prioritize.hasFeature && topStore.isSurveyHaveResponseCount && topStore.activeTab !== 'prioritize') {
      prioritizeStore.getDimensionList({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        questionId: prioritizeStore.selectedQuestion,
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    props.open,
    prioritizeStore.selectedQuestion,
    topStore.isSurveyHaveResponseCount,
    topStore.activeTab
  ]);

  useEffect(() => {
    if (props.open && prioritizeStore.selectedQuestion && StorePermission.features.dashboard_prioritize.hasFeature && topStore.isSurveyHaveResponseCount && topStore.activeTab !== 'prioritize') {
      prioritizeStore.getChartData({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        questionId: prioritizeStore.selectedQuestion,
        dimensions: prioritizeStore.prioritizeList
          .map((el) => {
            if (prioritizeStore.selectedPrioritize.includes(el.id)) {
              return el.name;
            }
          })
          .filter((item) => item),
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    props.open,
    prioritizeStore.selectedQuestion,
    prioritizeStore.selectedPrioritize,
    topStore.isSurveyHaveResponseCount,
    topStore.activeTab
  ]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogHeader>
        <DialogHeaderLeft>
          <CloseButton
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </CloseButton>
          <DialogTitle>Preview Page</DialogTitle>
        </DialogHeaderLeft>
        <DownloadPDFButton
          variant="outlined"
          onClick={props.onDownload}
          startIcon={<DownloadIcon />}
          disabled={!isDownloadEnabled}
        >
          Download PDF
        </DownloadPDFButton>
      </DialogHeader>
      
      <TabsContainer>
        <Tabs 
          value={selectedTab} 
          onChange={handleTabChange}
          aria-label="download options tabs"
          sx={{ 
            '& .MuiTab-root': { 
              textTransform: 'none' 
            },
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper'
          }}
        >
          <Tab label="Overview" />
          {responsesStore.dashboardResponsesRawisNotEmpty && StorePermission.features.dashboard_responses.hasFeature && (
            <Tab label="Responses" />
          )}
          {!prioritizeStore.isDownloadDisabled && StorePermission.features.dashboard_prioritize.hasFeature && (
            <Tab label="Prioritize" />
          )}
        </Tabs>
      </TabsContainer>

      <ScrollableContent>
        <TabPanel value={selectedTab} index={0}>
          <Container>
            <PDFOverviewContent localFilters={props.localFilters}/>
          </Container>
        </TabPanel>

        {responsesStore.dashboardResponsesRawisNotEmpty && StorePermission.features.dashboard_responses.hasFeature && (
          <TabPanel value={selectedTab} index={1}>
            <Container>
              <PDFResponsesContent localFilters={props.localFilters}/>
            </Container>
          </TabPanel>
        )}

        {!prioritizeStore.isDownloadDisabled && StorePermission.features.dashboard_prioritize.hasFeature && (
          <TabPanel value={selectedTab} index={getAvailableTabs().slice(-1)[0]}>
            <Container>
              <PDFPrioritizeContent localFilters={props.localFilters}/>
            </Container>
          </TabPanel>
        )}
      </ScrollableContent>
    </Dialog>
  );
});

export default PdfDownloadDialog; 

const Container = styled.div`
  width: 1494px;
  min-height: 1942px;
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
`;

const DialogHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CloseButton = styled(IconButton)`
  padding: 4px !important;
  
  svg {
    font-size: 24px;
    color: var(--colorNeutralForeground1Hover);
  }
`;

const DownloadPDFButton = styled(Button)`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  background-color: ${props => !props.disabled ? 'var(--colorNeutralBackground1)' : 'transparent'} !important;

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;

const TabsContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
`;

const ScrollableContent = styled(DialogContent)`
  padding-top: 0 !important;
  padding-bottom: 24px;
  scroll-behavior: smooth;
  overflow-y: auto;
  height: calc(100vh - 200px);
`;
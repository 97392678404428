export const SURVEY_STATUS = {
  upcoming: "upcoming",
  unscheduled: "unscheduled",
  complete: "complete",
  running: "running",
  historical: "historical"
};

export const PROJECT_STATUS = {
  unscheduled: "Unscheduled",
  completed: "Completed",
  running: "Active",
};

export enum SURVEY_STATUS_DASHBOARD {
  None = "None",
  Running = "Running",
  Historical = "Historical",
  Complete = "Complete",
};

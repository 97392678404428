export enum Step {
  ActionRequired = "ActionRequired",
  ReviewChanges = "ReviewChanges",
  ProgressLoader = "ProgressLoader",
  Error = "Error",
  Success = "Success",
}

export enum StepV2 {
  Open = 0,
  Uploading = 1,
  Reviewing = 2,
  Confirming = 3,
  Finished = 4,
}

import http from "api/https";
import { createUrl } from "../../helpers/url";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";

export const getEntityEmployeePermissions = (
  entityId: number,
  regionId: number,
): Promise<any> => {
  const query: {
    entity_id: string,
    access_level: string,
    region_id: string,
  } = {
    entity_id: entityId.toString(),
    access_level: ACCESS_LEVEL_TYPE.entity,
    region_id: regionId.toString(),
  };

  const url = createUrl({
    path: "/permission/entity_permissions/Permissions",
    query: query,
  });
  return http.getGO(url);
};

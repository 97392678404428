import { FileUploaded } from "types/employee-import.type";
import { getFormattedSize } from "./getFilesizeFormatted";

export const parseFileUploaded = (params: {
  file?: File;
  fileUploaded?: FileUploaded;
  progress?: number;
}): FileUploaded[] => {
  const { file, fileUploaded, progress } = params;
  if (!file && !fileUploaded.filename) {
    return [];
  }
  let fileUploadedResult: FileUploaded;
  if (file) {
    const formattedSize = getFormattedSize(file!.size);

    fileUploadedResult = {
      fileId: "",
      filename: file.name,
      fileSize: formattedSize,
      progress: progress,
      status: "loading",
    };
  } else if (fileUploaded) {
    fileUploadedResult = {
      fileId: "",
      filename: fileUploaded.filename,
      fileSize: fileUploaded.fileSize,
      progress: fileUploaded.progress,
      status: fileUploaded.status,
    };
  }

  return [fileUploadedResult];
};

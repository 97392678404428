import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getEntityUsers = (
  entityId: number,
  userRolesFilter: string = "",
  currentPage: number,
  pageSize: number,
  sortOrder: string,
  sortField: string,
  searchValue: string,
): Promise<any> => {
  const query: {
    entity_id: string,
    user_roles_filter: string,
    current_page: string,
    page_size: string,
    sort_order: string,
    sort_field: string,
    search_value: string,
  } = {
    entity_id: entityId.toString(),
    user_roles_filter: userRolesFilter,
    current_page: currentPage.toString(),
    page_size: pageSize.toString(),
    sort_order: sortOrder,
    sort_field: sortField,
    search_value: searchValue
  };

  const url = createUrl({
    path: "/permission/entity_permissions/EntityUsers",
    query: query,
  });
  return http.getGO(url);
};

import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import store from "../store";
import TablePermission from "./TablePermission";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "components/button/Button";
import CustomSimpleFormModalDialog from "components/customSimpleFormModalDialog";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { RoleInEntity, SelectedItem } from "types/permission";

const createUserRolePanelButtons = {
  yesButton: "Create user role",
  cancelButton: "Cancel",
};

const updateUserRolePanelButtons = {
  yesButton: "Save changes",
  cancelButton: "Cancel",
};

const deleteUserRolePanelButtons = {
  yesButton: "Delete user role",
  cancelButton: "Cancel",
};

const MainScreen = observer(() => {
  const navigate = useNavigate();

  const [createUserRolePanel, SetCreateUserRolePanel] = useState<boolean>(false);
  const [modalSettingPanel, setModalSettingPanel] = useState<boolean>(false);
  const [roleSettingType, setRoleSettingType] = useState<SelectedItem | null>(null);

  const handleUserRolePanelClose = () => {
    SetCreateUserRolePanel(false);
  };

  const handleRoleSettingPanelClose = () => {
    setModalSettingPanel(false);
  };

  const handleUserRolePanelCreate = (value) => {
    store.createNewUserRole(value, () => {
      if (!store.errorOnCreateRole) {
        SetCreateUserRolePanel(false);
      }
    });
  };

  const commonHandleDisableButton = () => {
    if (!StorePermission.entityPermissions.editEntityPermissions.hasPermission) {
      return true;
    }

    return false;
  };

  const handleSettingModal = (type, oldRole) => {
    store.changeOldRole(oldRole);

    setRoleSettingType(type);
    setModalSettingPanel(true);
  };

  const handleRoleSettingPanelAction = (value) => {
    if (roleSettingType?.id === 1) {
      store.updateUserRole(value, () => {
        if (!store.errorOnUpdateRole) {
          setModalSettingPanel(false);
        }
      });
    } else {
      store.deleteUserRole(value, () => {
        if (!store.errorOnDeleteRole) {
          setModalSettingPanel(false);
        }
      });
    }
  };

  return (
    <>
      <FlexJustifyBetweenBox>
        <Header id="EntityPermissions_EntityUsers_Title">
          Entity and employee list permissions
        </Header>
        <MultiButtonsWrapper>
          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_Table_EditPermissionBtn`}
            style={{ margin: 5, backgroundColor: "white" }}
            variant="outlined"
            size="small"
            disabled={commonHandleDisableButton()}
            onClick={() => {
              navigate("/entityPermissions/edit");
            }}
          >
            Edit permissions
          </CustomButton>

          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_Table_NewUserRoleBtn`}
            style={{ margin: 5 }}
            variant="contained"
            size="small"
            disabled={commonHandleDisableButton()}
            onClick={() => {
              SetCreateUserRolePanel(true);
            }}
          >
            Create user role
          </CustomButton>
        </MultiButtonsWrapper>
      </FlexJustifyBetweenBox>

      {!store.rolePermissionIsFetched ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <TablePermission
          onClickRoleSetting={(type: SelectedItem, selectedRole: RoleInEntity) =>
            handleSettingModal(type, selectedRole)
          }
        />
      )}

      <CustomSimpleFormModalDialog
        title="Create entity user role"
        fieldLabel="User role name"
        isDialogOpen={createUserRolePanel}
        buttonsCaption={createUserRolePanelButtons}
        defaultPlaceholder="Enter user role name"
        fieldMaxChar={60}
        receivedErrorMessage={store.errorOnCreateRole}
        onClose={handleUserRolePanelClose}
        onYes={(value) => handleUserRolePanelCreate(value)}
      />

      <CustomSimpleFormModalDialog
        title={roleSettingType?.name}
        fieldLabel={roleSettingType?.id === 1 ? "User role name" : "Selected user role"}
        isDialogOpen={modalSettingPanel}
        oldValue={store.oldRole?.name}
        buttonsCaption={
          roleSettingType?.id === 1 ? updateUserRolePanelButtons : deleteUserRolePanelButtons
        }
        fieldMaxChar={60}
        receivedErrorMessage={
          roleSettingType?.id === 1 ? store.errorOnUpdateRole : store.errorOnDeleteRole
        }
        isShowConfirmField={roleSettingType?.id === 2}
        confirmFieldLabel={
          roleSettingType?.id === 2
            ? "To confirm the deletion of user role, please enter DELETE below"
            : ""
        }
        confirmFieldPlaceholder={roleSettingType?.id === 2 ? "DELETE" : ""}
        onClose={handleRoleSettingPanelClose}
        onYes={(value) => handleRoleSettingPanelAction(value)}
      />
    </>
  );
});

const FlexJustifyBetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 41px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MultiButtonsWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;

export default MainScreen;

import axios, { AxiosHeaders } from "axios";

type useUploadS3Props = {
  file: File;
  presignUrl: string;
  headers?: AxiosHeaders;
};

export const useUploadS3 = async ({ file, presignUrl, headers }: useUploadS3Props) => {
  return await axios.put(presignUrl, file, {
    headers: {
      "Content-Type": file.type,
      ...headers,
    },
  });
};

import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getManagersRecipients = (surveyID: number, entityID: number, page: number, pageSize: number) => {
  const url = createUrl({
    path: "/sm/sm_survey/GetManagersRecipients",
  });
  return http.postGO(url, {
    entity_id: entityID,
    survey_id: surveyID,
    page: page,
    limit: pageSize
  });
}

const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_13815_6559" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13815_6559)">
        <path
          d="M7.30775 20.4997C6.80908 20.4997 6.38308 20.3232 6.02975 19.97C5.67658 19.6167 5.5 19.1907 5.5 18.692V5.99973H4.5V4.49973H9V3.61523H15V4.49973H19.5V5.99973H18.5V18.692C18.5 19.1972 18.325 19.6247 17.975 19.9747C17.625 20.3247 17.1974 20.4997 16.6923 20.4997H7.30775ZM17 5.99973H7V18.692C7 18.7818 7.02883 18.8556 7.0865 18.9132C7.14417 18.9709 7.21792 18.9997 7.30775 18.9997H16.6923C16.7692 18.9997 16.8398 18.9677 16.9038 18.9035C16.9679 18.8395 17 18.769 17 18.692V5.99973ZM9.404 16.9997H10.9037V7.99973H9.404V16.9997ZM13.0962 16.9997H14.596V7.99973H13.0962V16.9997Z"
          fill="#707882"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;

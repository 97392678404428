import { observer } from "mobx-react";
import { FC, useState } from "react";
import { OPEN_ENDED_LOCAL_MENU, SENTIMENT_FILTERS_LIST } from "constants/dashboard-responses";
import { Button, MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import PieChart from "./PieChart";
import EditIcon from "@mui/icons-material/Edit";
import CustomSwitch from "components/switch/Switch";
import ResponseCard from "./ResponseCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { OpenEndedElementRenderer } from "./OpenEndedElementRenderer";

type QuestionInfoProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const QuestionInfo: FC<QuestionInfoProps> = observer((props) => {

  const returnBottomPaginationValue = () => {
    if (store.pagination.active_page === 1) return "1";
    return (store.pagination.active_page - 1) * 10;
  };

  const returnTopPaginationValue = () => {
    if (store.pagination.active_page === store.pagination.total_pages)
      return store.pagination.total_count;
    if (store.pagination.active_page === 1) return "10";
    return store.pagination.active_page * 10;
  };

  const returnTopicTitle = () => {
    if (store.activeOpenEndedTopic.withTopic) {
      return `${store.activeOpenEndedTopic.Title[0]?.toUpperCase()}${store.activeOpenEndedTopic.Title.slice(
        1
      )}`;
    }
    return `Responses with no topic`;
  };

  const renderer = new OpenEndedElementRenderer({
    dataChart: null,
    respondedCount: store.activeOpenEndedQuestionInfo?.respondedCount || 0,
    responses: store.activeOpenEndedQuestionInfo?.responses || [],
    localFilters: props.localFilters,
    nilCount: store.activeOpenEndedQuestion?.data?.Responses?.nilCount || {
      positive: 0,
      neutral: 0,
      negative: 0,
      mixed: 0,
      unavailable: 0,
      total: 0,
    },
  });
  
  return (
    <Container>
      {store.isOpenTopicResponseDetail &&
        store.activeOpenEndedLocalTab === OPEN_ENDED_LOCAL_MENU.RESPONSES_BY_TOPICS && (
          <TopicsHeader>
            <StyledButton $isBack onClick={() => store.setData(false, "isOpenTopicResponseDetail")}>
              <ArrowBackIcon /> Back to all topics
            </StyledButton>
            <Group>
              <TopicTitle>{returnTopicTitle()}</TopicTitle>
              {store.activeOpenEndedTopic.withTopic && (
                <StyledButton onClick={() => store.setData(true, "isOpenEditTopicPopup")}>
                  <StyledEditIcon />
                </StyledButton>
              )}
            </Group>
          </TopicsHeader>
        )}


      <FiltersContainer>
        <Group>
          <SwitchText>Sentiments</SwitchText>
          <CustomSwitch
            checked={store.isShowSentimentsOpenEndedCard}
            onChange={() => {
              store.changeIsShowSentimentsOpenEndedCard(!store.isShowSentimentsOpenEndedCard);
            }}
          />
        </Group>
        {store.isShowSentimentsOpenEndedCard && (
          <Group>
            <FilterLabel>Filter by sentiment:</FilterLabel>
            <StyledSelect
              id="question_selected"
              value={store.sentimentFilter}
              onChange={(e: SelectChangeEvent<string>) =>
                store.changeSentimentFilter(e.target.value)
              }
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
              }}
            >
              {SENTIMENT_FILTERS_LIST.map((filter) => (
                <StyledMenuItem key={filter.value} value={filter.value}>
                  <em>{filter.label}</em>
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Group>
        )}
      </FiltersContainer>

      {renderer.render()}
      <Paginator>
        <PaginationText>
          Viewing {returnBottomPaginationValue()} - {returnTopPaginationValue()} of{" "}
          {store.pagination.total_count}
        </PaginationText>
        <Pagination
          variant="outlined"
          shape="rounded"
          page={store.pagination.active_page}
          count={store.pagination.total_pages}
          onChange={(_, page) => store.changePagination("active_page", page)}
        />
      </Paginator>


    </Container>
  );
});

export default QuestionInfo;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: var(--colorNeutralForeground2);
  margin: 32px 0px 4px 0px;
`;

const Paginator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .MuiButtonBase-root {
    margin: 0;
    width: 36px;
    height: 36px;
    border: 1px solid var(--colorNeutralForeground4);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
    background-color: var(--colorNeutralBackground1);
    border-radius: 0;
  }

  .Mui-selected {
    background-color: var(--colorPaletteGrayBackground1) !important;
  }
`;

const PaginationText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.73px;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const SwitchText = styled.p`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  margin-right: 8px;
`;

const FilterLabel = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin-right: 8px;
  white-space: nowrap;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 140px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground1) !important;
    font-style: normal !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  white-space: normal !important;
  em {
    font-style: normal !important;
  }
`;

const TopicsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px 0px 8px 0px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const StyledEditIcon = styled(EditIcon)`
  font-size: 22px !important;
  color: var(--colorBrandForeground1);
`;

const TopicTitle = styled.p`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  color: var(--colorPaletteVioletBackground1);
  margin-right: 8px;
`;

const StyledButton = styled(Button) <{ $isBack?: boolean }>`
  text-transform: none !important;
  padding: 0px !important;
  min-width: 0px !important;
  ${(props) => props.$isBack && "margin-bottom: 16px !important"};

  svg {
    font-size: 16px;
    margin-right: 6px;
  }
`;

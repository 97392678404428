import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Checkbox, Dialog, FormControlLabel } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DialogContent from "@mui/material/DialogContent";

import store from "./store";
import storeSurveyList from "../../Survey/SurveyList/store";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { PROJECT_STATUS } from "constants/survey-status";
import { CheckDescIcon } from "./CheckDescIcon";
import CustomInput from "components/input/Input";
import CustomButton from "components/button/Button";
import SendOutSurveys from "./SendOutSurveys";
import SurveyFrequency from "./SurveyFrequency";
import SurveyMillstone from "./SurveyMillstone";
import TriggerAddRecipients from "./TriggerAddRecipients";
import ConfidentialityThreshold from "./ConfidentialityThreshold";
import { WarningIcon } from "./WarningIcon";

type EditProjectProps = {
  open: boolean;
  id_project: number;
  handleHide: (reload?: boolean) => void;
  onSaved?: () => void;
};

const EditProject: FC<EditProjectProps> = observer((props) => {
  useEffect(() => {
    if (props.open) store.loadAttributes();
  }, [props.open]);

  useEffect(() => {
    if (props.id_project !== 0 && props.id_project !== null && props.open === true)
      store.doload(props.id_project);
  }, [props.id_project, props.open]);

  useEffect(() => {
    if (props.open) store.millstoneDays.sort((a, b) => a.days - b.days);
  }, [props.open]);

  return (
    <StyledDialog open={props.open}>
      <CloseBtnWrapper>
        <CloseButton
          id="Survey_Project_EditPopup_ClosePopup"
          onClick={() => {
            props.handleHide();
            store.clearStore();
          }}
        >
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <DialogTitle id="Survey_Project_EditPopup_Title">Project settings</DialogTitle>
      {store.projectStatus && store.projectStatus !== PROJECT_STATUS.unscheduled && (
        <InfoRunningProject id="Survey_Project_EditPopup_Warning">
          <WarningIconWrapp />
          There are surveys running with these settings. Changing the settings now will only effect
          future surveys.
        </InfoRunningProject>
      )}
      <RenameProject id="Survey_Project_EditPopup_Rename_Title">Rename project</RenameProject>
      <StyledCustomInput
        id="Survey_Project_EditPopup_Rename_Input"
        variant="standard"
        placeholder="New project name"
        value={store.name}
        error={store.errorname !== ""}
        helperText={store.errorname}
        onChange={(e) => store.setData(e.target.value, "name")}
      />
      <StyledDialogContent>
        <CheckWrapper>
          <CheckBoxContainer>
            <StyledFormControlLabel
              control={
                <StyledCheckBox
                  id="Survey_Project_EditPopup_CheckBox_TestProject"
                  checked={store.isTestProject}
                  onChange={() => {
                    store.setData(!store.showInfoTestProject, "showInfoTestProject");
                    store.setData(!store.isTestProject, "isTestProject");
                  }}
                />
              }
              label={
                <span id="Survey_Project_EditPopup_CheckBox_TestProject_label">
                  This is a test project
                </span>
              }
            />
          </CheckBoxContainer>
          <CheckBoxDesc>
            <CheckDescIcon />
            <CheckBoxDescText id="Survey_Project_EditPopup_Description">
              Help us make better recommendations for your company. Test project data will not be
              considered for benchmarks and analyses to enhance the scientific rigor of insights
              generated.
            </CheckBoxDescText>
          </CheckBoxDesc>
          {store.showInfoTestProject && (
            <InfoChangeTestProject>
              <WarningIconWrapp id="Survey_Project_EditPopup_Warning_TestProject" />
              <span>
                You are changing this to a{" "}
                <TestProjectSpan>
                  {store.isTestProject ? "test project" : "non-test project"}
                </TestProjectSpan>
                . Projects that are marked as "test" will have their data excluded for benchmarks.
                We should ensure that only real (not pilot or test) projects are included for
                benchmarks so that the benchmarks are scientifically rigorous.
              </span>
            </InfoChangeTestProject>
          )}
        </CheckWrapper>
        {!store.isAutoTriggerDisabled && (
          <>
            <Header id="Survey_Project_EditPopup_Trigger_Recipients_Title">
              Set a trigger to add recipients to projects
            </Header>
            <TriggerAddRecipients />
          </>
        )}
        <Header id="Survey_Project_EditPopup_TypeSurveys_Title">
          How should we send out the surveys within this project
        </Header>
        <SendOutSurveys />
        {store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency && <SurveyFrequency />}
        {store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Milestones && <SurveyMillstone />}
        <Header id="Survey_Project_EditPopup_SendSurveys_Confidentiality_Title">
          Confidentiality threshold
        </Header>
        <ConfidentialityThreshold />
        <SmallText id="Survey_Project_EditPopup_SendSurveys_Confidentiality_Discription">
          Each segment will need at least{" "}
          <b>{store.minResponses > 0 ? store.minResponses : "1"} employees</b> in order to view
          results
        </SmallText>
        <ButtonWrapper>
          <CreateCustomButton
            id="Survey_Project_EditPopup_Save_Button"
            onClick={() => {
              store.saveProject(() => {
                props.handleHide(true);
                if (props.onSaved) {
                  props.onSaved();
                  storeSurveyList.getSurveys();
                }
                store.clearStore();
              });
            }}
            variant="contained"
            type="submit"
            disabled={
              (store.isErrorFrequensyDate &&
                store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency) ||
              store.sendOutSurveysType === "" ||
              store.errorname !== ""
            }
          >
            Save settings
          </CreateCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default EditProject;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 660px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0 30px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0;
  padding: 10px 30px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;
const WarningIconWrapp = styled(WarningIcon)`
  margin: 10px !important;
`;
const TestProjectSpan = styled.span`
  font-weight: 500;
`;

const InfoChangeTestProject = styled.div`
  margin: 10px 0 0 0;
  color: var(--ER-Theme-sys-light-on-secondary-container, #3e4450);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  background: var(--ER-Theme-sys-light-error-container, #ffcaca);
  display: flex;
  padding: 7px;
`;

const InfoRunningProject = styled.div`
  margin: 10px 30px;
  color: var(--ER-Theme-sys-light-on-secondary-container, #3e4450);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  background: var(--ER-Theme-sys-light-error-container, #ffcaca);
  display: flex;
  padding: 7px;
`;
const RenameProject = styled.div`
  padding: 0 30px;
  color: var(--ER-Theme-sys-light-on-secondary-container, #3e4450);
  /* ER Theme/title/medium */
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

const StyledCustomInput = styled(CustomInput)`
  width: calc(100% - 60px);
  margin: 0px 30px 10px 30px !important;
  height: 44px;

  &::placeholder {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--colorNeutralForeground5);
  }

  :before {
    border-bottom: 1px solid var(--colorPaletteBlueBackground1) !important;
  }
`;

const CheckWrapper = styled.div`
  margin: 44px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CheckBoxContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  padding: 12px 10px;
`;

const CheckBoxDesc = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 32px 1fr;
  margin-top: 10px;
`;

const CheckBoxDescText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const Header = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const SmallText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0 60px 0;
`;

const CreateCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 220px;
  height: 34px;
`;

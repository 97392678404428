import { Button } from "@mui/material";
import { FC, ReactNode } from "react";
import styled from "styled-components";
import CustomButton from "./Button";

type CancelButtonProps = {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: ReactNode;
  fullWidth?: boolean;
  style?: any;
  rest?: any;
};

const CancelButton: FC<CancelButtonProps> = ({
  disabled,
  onClick,
  children,
  fullWidth,
  style,
  rest,
}: CancelButtonProps) => (
  <CancelButtonWrap
    onClick={onClick}
    variant="outlined"
    fullWidth={fullWidth}
    $disabled={disabled}
    style={style}
    color="inherit"
    {...rest}
  >
    {children}
  </CancelButtonWrap>
);

const CancelButtonWrap = styled(CustomButton)<{ $disabled?: boolean }>`
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  border: 1px solid var(--colorNeutralForeground5) !important;
`;

export default CancelButton;

import FileUploadCard from "./FileUploadCard";
import UploadDropArea from "./UploadDropArea";
import styled from "styled-components";
import { FileUploaded, FileUploadStatus } from "types/employee-import.type";
import EmployeeImportManager from "features/Notification/import-manager/store";
import ErrorAlert from "./ErrorAlert";

type UploadSectionProps = {
  formName: string;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleFileChange: (files: FileList) => void;
  handleButtonClick: () => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  onFileDelete: (fileId: string) => void;
  disableFileDelete: boolean;
  files: FileUploaded[];
  status: FileUploadStatus;
};

const UploadSection = ({
  formName,
  handleDrop,
  handleFileChange,
  handleButtonClick,
  fileInputRef,
  onFileDelete,
  disableFileDelete,
  files,
  status,
}: UploadSectionProps) => {
  let disableUpload = false;
  if (files.length > 0) {
    disableUpload = true;
  }

  if (status === "error") {
    disableUpload = false;
  }

  return (
    <UploadSectionWrapper>
      <h3>Upload Excel file</h3>
      <span>File upload</span>

      <UploadDropArea
        formName={formName}
        handleDrop={handleDrop}
        handleFileChange={handleFileChange}
        handleButtonClick={handleButtonClick}
        disabled={
          disableUpload ||
          status === "loading" ||
          EmployeeImportManager.employeeImportUIState.importStatus === "loading"
        }
        fileInputRef={fileInputRef}
      />
      <Files>
        {files.map((file) => (
          <FileUploadCard
            status={status}
            item={file}
            onDelete={(fileId) =>
              EmployeeImportManager.employeeImportUIState.importStatus === "loading"
                ? () => {}
                : onFileDelete(fileId)
            }
          />
        ))}
      </Files>

      {EmployeeImportManager.employeeImportUIState.uploadStatus === "error" &&
        EmployeeImportManager.employeeImportUIState.errorUploadMsg && (
          <ErrorWrapper>
            <ErrorAlert
              errorMessage={EmployeeImportManager.employeeImportUIState.errorImportMsg}
              onDismiss={() => {
                EmployeeImportManager.clearUploadError();
              }}
            />
          </ErrorWrapper>
        )}
    </UploadSectionWrapper>
  );
};

const ErrorWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Files = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  margin-top: 1rem;
  row-gap: 10px;
`;

const UploadSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--colorNeutralForeground8);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  color: var(--colorNeutralForeground1);

  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 16px;
    color: var(--colorNeutralForeground1);
  }
`;

export default UploadSection;

import { makeAutoObservable, reaction, runInAction } from "mobx";
import topStore from "../store";
import { TGetDashboardResponse , TOthersDimension} from "types/dashboard";
import MainStore from "MainStore";
import { ResponsesType, useGetDashboardResponses } from "api/dasboard/useGetDashboardResponses";
import { processFilters } from "utils/dashboard/processFilters";

class Store {
    dashboardResponsesRaw : TGetDashboardResponse | null = null;
    dashboardResponsesRawisNotEmpty: boolean = false;

    openEndedQuestionGroupItem = 4;
    responsesPagesName: string[] = [];

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => [
                topStore.activeTab,
                topStore.selectedSurvey,
                topStore.isResponseScoredTabDisplay,
                topStore.isResponseOPenEndedTabDisplay,
                topStore.isResponseOthersTabDisplay,
            ],
            () => {
                if (topStore.isResponseScoredTabDisplay)
                    topStore.responsesActiveLocalTab = "scored_questions";
                else if (topStore.isResponseOPenEndedTabDisplay)
                    topStore.responsesActiveLocalTab = "open_ended";
                else topStore.responsesActiveLocalTab = "others";
            }
        );
    }

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    getDashboardResponses = async (
        workspace_id: number,
        entity_id: number,
        project_id: number,
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        this.dashboardResponsesRaw = null;
        this.dashboardResponsesRawisNotEmpty = false;
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        MainStore.changeLoader(true);
        let result;
        result  = await useGetDashboardResponses({
            dashboardParameters: {
                workspace_id,
                entity_id,
                project_id,
                survey_id,
                viewType: topStore.role,
            },
            attributes: processedFilters,
            benchmarkType: topStore.overviewBenchmark,
            responsesTypes: [ResponsesType.SCORED, ResponsesType.OPEN_ENDED, ResponsesType.OTHERS]
        });
        runInAction(() =>{
            this.dashboardResponsesRaw = result?.data;
            this.dashboardResponsesRawisNotEmpty = this.dashboardResponsesRaw?.scored?.length > 0 || this.dashboardResponsesRaw?.openEnded?.length > 0 || this.dashboardResponsesRaw?.others?.length > 0;
        })
        MainStore.changeLoader(false);
    }


    getOtherResponses()  {
    if(!this.dashboardResponsesRaw && !this.dashboardResponsesRaw?.others) return [];
    const formatedData = this.dashboardResponsesRaw?.others?.map((el) => {
        return {
            dimension: el.name,
            questionsList: el.questions?.map((question) => ({
                ...question,
                responseList: question.responses,
            })) ?? [],
        };
    }) ?? [];
    return formatedData.flat();
    }

   getScoredResponses() {
    if(!this.dashboardResponsesRaw || !this.dashboardResponsesRaw?.scored) return [];
    return this.dashboardResponsesRaw?.scored ?? [];
   }

   getOpenEndedResponses() {
    if(!this.dashboardResponsesRaw || !this.dashboardResponsesRaw?.openEnded) return [];
    return this.dashboardResponsesRaw?.openEnded ?? [];
   }
}

const store = new Store();

export default store;
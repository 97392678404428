import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { IconButton, Button } from "@mui/material";
import CustomButton from "components/button/Button";
import Pagination from "components/pagination/Pagination";
import CustomTable from "components/customTable";
import DropdownPopOver from "components/dropdownPopOver";
import { TColumn } from "types/recipients";
import store from "../store";
import storeInviteUsers from "./SubmissionUsersToRoles/store";
import styled from "styled-components";
import { EntityUser, EntityUsers, SelectedItem, UserRole } from "types/permission";
import InviteFormModal from "components/inviteUserModal/InviteFormModal";
import InviteAgainForm from "./SubmissionUsersToRoles/InviteAgainForm";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import RevokeUserPermissionModal from "components/revokeUserPermissionModal";
import ReassignRolesModal from "components/reassignRolesModal";
import { formatToLocalDateTime } from "utils/timeFormatter";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import CustomSquareCheckbox from "components/customSquareCheckbox";
import CustomSearchField from "components/customSearchField";
import {
  PersonRemove as PersonRemoveIcon,
  Close as CloseIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";

const EntityUserScreen = observer(() => {
  const [inviteUsersPanel, setInviteUsersPanel] = useState(false);
  const [revokeUserPanel, setRevokeUserPanel] = useState(false);
  const [reassignRolePanel, setReassignRolePanel] = useState(false);
  const [reinviteUsersPanel, setReinviteUsersPanel] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [currentEl, setCurrentEl] = useState<HTMLElement | null>(null);
  const [isReset, setIsReset] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<SelectedItem[] | null>(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [currentRecords, setCurrentRecords] = useState<EntityUsers>(null);

  useEffect(() => {
    if (store.invitedUsers?.users?.length === 0) {
      setIsReset(true);
      setSelectedFilters(null);
      setIsFilterApplied(false);
    }

    setCurrentRecords(store.invitedUsers);
  }, [store.invitedUsers]);

  const selectionChangeHandler = (isSelected?: boolean, row?: EntityUser) => {
    const newSelection = isSelected
      ? store.selectedData.filter((item: EntityUser) => item.id !== row.id)
      : [...store.selectedData, row];
    store.changeSelectedData(newSelection);
  };

  const handleSelectFilter = (selectedItems: SelectedItem[]) => {
    setSelectedFilters(selectedItems);
  };

  const handleApplyFilter = () => {
    if (selectedFilters && selectedFilters.length > 0) {
      store.setData(1, "currentPage");
    }

    const joinedRoleIds = selectedFilters?.map((item) => item.id).join(",");

    setIsFilterApplied(true);
    store.setData(joinedRoleIds, "filterByRoles");
    store.loadEntityUsers();
  };

  const handleClearAll = () => {
    setIsReset(true);
    setIsFilterApplied(false);
    setSelectedFilters(null);
  };

  const handleResendInvitation = () => {
    storeInviteUsers.changeEmails(store.selectedData.map((item: EntityUser) => item.email));
    storeInviteUsers.changeRoles(
      store.selectedData.flatMap((item: EntityUser) => item.user_roles.map((role) => role.id)),
    );

    setReinviteUsersPanel(true);
  };

  const handleCloseInviteAgain = () => {
    storeInviteUsers.changeEmails([]);
    storeInviteUsers.changeRoles([]);

    setReinviteUsersPanel(false);
  };

  const columns = [
    {
      width: 20,
      label: (
        <CustomSquareCheckbox
          id={`EntityPermissions_EntityUsers_Grid_Table_ChecboxAll`}
          disabled={
            currentRecords?.users.length === 0 ||
            !StorePermission.entityPermissions.editEntityPermissions.hasPermission
          }
          checked={store.selectedData.length === currentRecords?.users.length}
          onChange={() => {
            store.changeSelectedData(
              store.selectedData.length !== currentRecords?.users.length
                ? currentRecords?.users.map((item) => item)
                : [],
            );
          }}
        />
      ),
      render: (row: any, index, indexRow) => {
        const isSelected = store.selectedData.some((item: EntityUser) => item.id === row.id);

        return (
          <CustomSquareCheckbox
            id={`EntityPermissions_EntityUsers_Grid_Table_Checbox_${index}_${indexRow}_${row.id}`}
            checked={isSelected}
          />
        );
      },
    },
    {
      id: "full_name",
      width: 400,
      label: (
        <StyledContainerLabelColumn>
          <p>FULL NAME</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="EntityPermission_Table_Name_Sort_Asc"
              style={
                store.sortField === "name" && store.sortType === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("name", "asc")}
            />
            <PlugStyle />
            <KeyboardArrowDownIconStyle
              id="EntityPermission_Table_Name_Sort_Desc"
              style={
                store.sortField === "name" && store.sortType === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("name", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
    {
      id: "email",
      width: 400,
      label: (
        <StyledContainerLabelColumn>
          <p>EMAIL</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="EntityPermission_Table_Email_Sort_Asc"
              style={
                store.sortField === "email" && store.sortType === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("email", "asc")}
            />
            <PlugStyle />
            <KeyboardArrowDownIconStyle
              id="EntityPermission_Table_Email_Sort_Desc"
              style={
                store.sortField === "email" && store.sortType === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("email", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
    {
      id: "user_roles",
      width: 450,
      label: (
        <MainContainerColumnFilter>
          <StyledContainerLabelColumn>
            <p>USER ROLES</p>
            <FilterWrapper>
              <img
                src={"/assets/icons/column-filter-icon.svg"}
                width={24}
                height={24}
                alt="column filter"
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  setOpenFilter(true);
                  setCurrentEl(event.currentTarget);
                }}
              />
              {isFilterApplied && selectedFilters && selectedFilters.length > 0 && (
                <BadgeWrapper>
                  <label>{selectedFilters.length}</label>
                </BadgeWrapper>
              )}
            </FilterWrapper>
          </StyledContainerLabelColumn>
          <DropdownPopOver
            anchorElement={currentEl}
            isOpen={openFilter}
            items={store.roleData}
            resetFilter={isReset}
            updateSelectedItems={selectedFilters}
            onSelect={(items: SelectedItem[]) => handleSelectFilter(items)}
            onClose={() => {
              setOpenFilter(false);
              setCurrentEl(null);
            }}
            anchorPosition={{ vertical: "bottom", horizontal: "left" }}
            customStyle={{ maxHeight: "500px" }}
          >
            <PopoverFooterContainer>
              <CustomButton
                name="ApplyBtn"
                color="primary"
                variant="contained"
                onClick={handleApplyFilter}
              >
                Apply filter
              </CustomButton>
              <CustomButton
                name="ClearBtn"
                variant="text"
                onClick={handleClearAll}
                disabled={!selectedFilters || (selectedFilters && selectedFilters.length < 1)}
              >
                Clear all
              </CustomButton>
            </PopoverFooterContainer>
          </DropdownPopOver>
        </MainContainerColumnFilter>
      ),
      render: (
        row: any,
        index: number,
        rowIndex: number,
        onHandleToggle: (rowId: string) => void,
      ) => {
        const maxCharacterLimit = 60;

        const rolesString = row.user_roles.map((role) => role.name).join(", ");
        const isExceedingLimit = rolesString.length > maxCharacterLimit;
        const truncatedRoles = `${rolesString.slice(0, maxCharacterLimit)}...`;

        const isExpanded = row.isExpanded || false;

        const handleToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          row.isExpanded = !isExpanded;
          onHandleToggle(row.id);

          event.stopPropagation();
        };
        return (
          <RolesWrapper>
            <RoleLabel>{isExpanded || !isExceedingLimit ? rolesString : truncatedRoles}</RoleLabel>
            {isExceedingLimit && (
              <Button
                variant="text"
                disableRipple={true}
                sx={{
                  justifyContent: "flex-start",
                  textTransform: "none",
                  padding: "0px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={(e) => handleToggle(e)}
              >
                <label style={{ textAlign: "left", cursor: "pointer" }}>
                  {isExpanded ? "View less" : "View more"}
                </label>
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
            )}
          </RolesWrapper>
        );
      },
    },
    {
      id: "status",
      width: 350,
      label: (
        <StyledContainerLabelColumn>
          <p>STATUS</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="EntityPermission_Table_Status_Sort_Asc"
              style={
                store.sortField === "status" && store.sortType === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("status", "asc")}
            />
            <PlugStyle />
            <KeyboardArrowDownIconStyle
              id="EntityPermission_Table_Status_Sort_Desc"
              style={
                store.sortField === "status" && store.sortType === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("status", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      render: (param: any) => {
        return (
          <StatusWrapper $status={param.status.toLowerCase()}>
            <label>{param.status}</label>
          </StatusWrapper>
        );
      },
    },
    {
      id: "date_sent_invitation",
      width: 400,
      label: (
        <StyledContainerLabelColumn>
          <p>LAST INVITE DATE</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="EntityPermission_Table_Invite_Sort_Asc"
              style={
                store.sortField === "last_invited" && store.sortType === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("last_invited", "asc")}
            />
            <PlugStyle />
            <KeyboardArrowDownIconStyle
              id="EntityPermission_Table_Invite_Sort_Desc"
              style={
                store.sortField === "last_invited" && store.sortType === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("last_invited", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      render: (row: any) => {
        return (
          <InvitationSentDateContainer>
            <p>{formatToLocalDateTime(row.date_invite_sent)}</p>
          </InvitationSentDateContainer>
        );
      },
    },
  ] as TColumn[];

  const handleEditUserRolesClick = () => {
    setReassignRolePanel(true);

    storeInviteUsers.changeEmails(store.selectedData.map((item: EntityUser) => item.email));
  };

  const handleIsRowSelected = (row: any) => {
    return store.selectedData.some((item: EntityUser) => item.id === row.id);
  };

  const handleDisableReassignButton = () => {
    if (commonHandleDisableButton()) {
      return true;
    }

    const firstUserRoleIds = store.selectedData[0].user_roles.map((role) => role.id);

    const areRolesInconsistent = store.selectedData.some((entity: EntityUser) => {
      const currentUserRoleIds = entity.user_roles.map((role: UserRole) => role.id);

      return (
        currentUserRoleIds.length !== firstUserRoleIds.length ||
        !currentUserRoleIds.every((id) => firstUserRoleIds.includes(id))
      );
    });

    return areRolesInconsistent;
  };

  const commonHandleDisableButton = () => {
    if (!StorePermission.entityPermissions.editEntityPermissions.hasPermission) {
      return true;
    }

    if (store.selectedData.length === 0) {
      return true;
    }

    return false;
  };

  return (
    <>
      <FlexJustifyBetweenBox>
        <Header id="EntityPermissions_EntityUsers_Title">Entity users</Header>
        <CustomButton
          id={`EntityPermissions_EntityUsers_Grid_Table_AddUsersBtn`}
          disabled={!StorePermission.entityPermissions.editEntityPermissions.hasPermission}
          style={{ margin: 5 }}
          variant="contained"
          size="small"
          onClick={() => {
            setInviteUsersPanel(true);
          }}
        >
          Invite users
        </CustomButton>
      </FlexJustifyBetweenBox>

      <FlexJustifyBetweenBox>
        <FlexBox>
          <CustomStyleButton
            id={`EntityPermissions_EntityUsers_Grid_Table_ChangeRoleBtn`}
            disabled={handleDisableReassignButton()}
            variant="outlined"
            size="small"
            onClick={handleEditUserRolesClick}
          >
            Re-assign user roles
          </CustomStyleButton>

          <CustomStyleButton
            id={`EntityPermissions_EntityUsers_Grid_InviitAgainBtn`}
            disabled={commonHandleDisableButton()}
            variant="outlined"
            size="small"
            onClick={handleResendInvitation}
          >
            Resend Invitation
          </CustomStyleButton>

          <CustomStyleRevokeButton
            id={`EntityPermissions_EntityUsers_Grid_RevokeUserBtn`}
            disabled={commonHandleDisableButton()}
            variant="text"
            color="inherit"
            size="small"
            onClick={() => setRevokeUserPanel(true)}
          >
            <PersonRemoveIcon fontSize="small" />
            Revoke users
          </CustomStyleRevokeButton>
        </FlexBox>

        <CustomSearchField
          id="EntityPermissions_EntityUsers_SearchInput"
          placeholder="Search for name/email"
          searchedValue={store.searchedValue}
          onKeyDown={store.keyPress}
          onChange={(e) => store.changeSearchedValue(e.target.value)}
          onClearClick={() => store.onClearSearchClicked()}
        />
      </FlexJustifyBetweenBox>

      {store.resultSearchValue && (
        <SearchResultContainer>
          <SearchInformation>
            {store.invitedUsers.count} results found for {`"${store.resultSearchValue}"`}
          </SearchInformation>
          <SearchClearContainer
            onClick={() => {
              store.onClearSearchClicked();
            }}
          >
            <IconButton id="EntityPermissions_EntityUsers_SearchInformation_ClearBtn">
              <CloseIcon
                style={{ color: "var(--colorBrandForeground1)", width: "14px", height: "14px" }}
              />
            </IconButton>
            <label>Clear search</label>
          </SearchClearContainer>
        </SearchResultContainer>
      )}

      <TableContainer>
        <div style={{ width: "100%" }}>
          <CustomTable
            data={currentRecords ? currentRecords.users : []}
            columns={columns}
            onGetIsRowSelected={handleIsRowSelected}
            changeHandler={selectionChangeHandler}
            noDataTitle={{
              title: "No users yet.",
              description: "Get started by adding users.",
            }}
            isReadOnly={!StorePermission.entityPermissions.editEntityPermissions.hasPermission}
          />
        </div>
        <Pagination
          id={`EntityPermission_UsersGridInvite_InviteAgain`}
          activePage={store.currentPage}
          itemsCountPerPage={store.pageSize}
          onChange={(value) => {
            store.setData(value, "currentPage");
            store.loadEntityUsers();
          }}
          pageRangeDisplayed={5}
          totalItemsCount={currentRecords?.count}
          totalPages={currentRecords?.total_page}
          setCount={(value) => {
            store.setData(1, "currentPage");
            store.setData(Number(value), "pageSize");

            store.loadEntityUsers();
          }}
        />
      </TableContainer>

      <ReassignRolesModal
        id="EntityPermissions_ReassignRolesPopup"
        open={reassignRolePanel}
        emails={storeInviteUsers.emails}
        currentRoleNames={store.selectedData
          .map((item: EntityUser) =>
            item.user_roles.map((role: UserRole) => ({ id: role.id, name: role.name })),
          )
          .flat()}
        options={store.roleData}
        onClose={() => {
          setReassignRolePanel(false);
          storeInviteUsers.clearInviteUserStore();
        }}
        reassignHandler={() => {
          storeInviteUsers.reassignToRoles(StoreLayout.currentEntityId, () => {
            store.loadEntityUsers();
            setReassignRolePanel(false);
          });
        }}
        selectRoleHandler={(roleIds) => storeInviteUsers.changeRoles(roleIds)}
        deleteRole={(index) => storeInviteUsers.deleteRole(index)}
      />

      <InviteFormModal
        open={inviteUsersPanel}
        onClose={() => {
          setInviteUsersPanel(false);
          storeInviteUsers.clearInviteUserStore();
        }}
        inviteHandler={() => {
          storeInviteUsers.inviteUserSubmit(
            StoreLayout.currentEntityId,
            StoreLayout.currentEntityName,
            () => {
              store.onInvitedUsers();
              setInviteUsersPanel(false);
            },
          );
        }}
        emails={storeInviteUsers.emails}
        value={storeInviteUsers.emailValue}
        options={store.roleData}
        onChange={(value) => storeInviteUsers.changeEmailValue(value)}
        submitEmailHandler={() => storeInviteUsers.addEmail()}
        deleteEmail={(index) => storeInviteUsers.deleteEmail(index)}
        submitRoleHandler={(roleIds) => storeInviteUsers.changeRoles(roleIds)}
        deleteRole={(index) => storeInviteUsers.deleteRole(index)}
      />

      <InviteAgainForm
        id="EntityPermissions_InviteAgainPopUp"
        openPanel={reinviteUsersPanel}
        onClose={() => handleCloseInviteAgain()}
      />

      <RevokeUserPermissionModal
        id="EntityPermissions_RevokeUserPermissionPopUp"
        open={revokeUserPanel}
        emails={store.selectedData.map((item) => item.email)}
        onClose={() => setRevokeUserPanel(false)}
        submitRevokeHandler={() => {
          store.revokeUserAccess(() => setRevokeUserPanel(false));
        }}
      />
    </>
  );
});

const FlexJustifyBetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const FlexBox = styled.div`
  display: flex;
  column-gap: 16px;
`;

const TableContainer = styled.div`
  width: 100%;
  padding-top: 24px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const StatusWrapper = styled.div<{ $status: "active" | "pending" | "expired" }>`
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 4px;
  width: 69px;
  padding: 4px 12px 4px 12px;

  background-color: ${({ $status }) =>
    $status === "active"
      ? "var(--colorPaletteGreenBackground6)"
      : $status === "pending"
        ? "var(--colorPaletteRedForeground5)"
        : "var(--grayD9DDE7)"};

  border-color: ${({ $status }) =>
    $status === "active"
      ? "var(--colorPaletteGreenBackground6)"
      : $status === "pending"
        ? "var(--colorPaletteRedForeground5)"
        : "var(--grayD9DDE7)"};

  label {
    font-weight: 500px;
    color: var(--colorPaletteVioletBackground1);
    text-transform: capitalize;
  }
`;

const StyledContainerLabelColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FilterWrapper = styled.div`
  position: relative;
`;

const BadgeWrapper = styled.div`
  position: absolute;
  background-color: var(--colorBrandForeground1);
  border-radius: 9999px;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  padding-left: 8px;
  padding-right: 8px;
  top: -11px;
  right: -15px;

  label {
    font-weight: 500px;
    font-size: 12px;
    color: white;
  }
`;

const MainContainerColumnFilter = styled.div`
  position: relative;
`;

const BlockSortStyle = styled("span")`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const PlugStyle = styled("div")`
  width: 20px;
  height: 8px;
  background-color: transparent;
  position: absolute;
  top: 6px;
  left: -5px;
  z-index: 999;
`;

const PopoverFooterContainer = styled.div`
  display: flex;
  column-gap: 16px;
  padding: 14px;
`;

const RolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const RoleLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #000000de;
`;

const CustomStyleButton = styled(Button)`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  background-color: ${(props) =>
    !props.disabled ? "var(--colorNeutralBackground1)" : "transparent"} !important;

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;

const CustomStyleRevokeButton = styled(Button)`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  background-color: "transparent";
  display: "flex";
  column-gap: "4px";

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;

const SearchResultContainer = styled.div`
  background-color: var(--colorPalleteLightBlue);
  border-radius: 4px;
  padding: 8px 24px 8px 24px;
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const SearchInformation = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const SearchClearContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  label {
    color: var(--colorBrandForeground1);
    cursor: pointer;
  }
`;

const InvitationSentDateContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #000000de;
`;

export default EntityUserScreen;


export const buildTree = (data: any[], selectedUnit: any) => {
    // Create dictionary of units
    const unitDict: { [key: number]: any } = {};
    
    // First pass: Create all unit dictionary entries
    data.forEach(unit => {
      unitDict[unit.id] = {
        label: unit.name,
        id: unit.id,
        selected: selectedUnit?.segments.includes(`${unit.id}`),
        value: [String(unit.id)], // Initialize with own ID
        level: 0,
        rootName: null, // Will be set in second pass
        parent_id: unit.parent_id
      };
    });

    const getChildIds = (unitId: number): string[] => {
      const childIds = [];
      data.forEach(unit => {
        if (unit.parent_id === unitId) {
          childIds.push(String(unit.id));
          childIds.push(...getChildIds(unit.id));
        }
      });
      return childIds;
    };
    
    // Second pass: Determine root nodes and set initial levels
    data.forEach(unit => {
      if (!unit.parent_id || !unitDict[unit.parent_id]) {
        // This is a root node
        unitDict[unit.id].level = 0;
        unitDict[unit.id].rootName = unit.name;
      }
    });
    
    // Third pass: Propagate rootName down the tree
    let changed = true;
    while (changed) {
      changed = false;
      data.forEach(unit => {
        if (unit.parent_id && unitDict[unit.parent_id] && unitDict[unit.parent_id].rootName && !unitDict[unit.id].rootName) {
          unitDict[unit.id].rootName = unitDict[unit.parent_id].rootName;
          unitDict[unit.id].level = unitDict[unit.parent_id].level + 1;
          changed = true;
        }
      });
    }
    
    // Final pass: Build the tree structure
    const tree = [];
    data.forEach(unit => {
      // Ensure all units have a rootName (fallback to own name if still null)
      if (!unitDict[unit.id].rootName) {
        unitDict[unit.id].rootName = unit.name;
      }
      
      if (unit.parent_id && unitDict[unit.parent_id]) {
        // Add to parent's children
        if (!unitDict[unit.parent_id].children) {
          unitDict[unit.parent_id].children = [];
          // Add direct item as first child
          unitDict[unit.parent_id].children.push({
            label: `${unitDict[unit.parent_id].label} direct members`,
            id: `${unitDict[unit.parent_id].id}-direct`,
            selected: selectedUnit?.segments.includes(`${unitDict[unit.parent_id].id}-direct`),
            value: [unit.parent_id],
            level: unitDict[unit.parent_id].level + 1,
            rootName: unitDict[unit.parent_id].rootName,
          });
        }else{
          unitDict[unit.parent_id].value = [
            String(unit.parent_id), // Parent's own ID
            `${unit.parent_id}-direct`, // Direct members ID
            ...getChildIds(unit.parent_id)
          ];
        }
        
        // Add child to parent's children
        unitDict[unit.parent_id].children.push(unitDict[unit.id]);
        
        
      } else {
        // Root level nodes
        tree.push(unitDict[unit.id]);
      }
    });
    
    return tree;
  };
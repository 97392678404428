import { FC } from "react";
import styled from "styled-components";
import ProgressCard from "./components/ProgressCard";
import EmployeeImportManager from "features/Notification/import-manager/store";

const UpdatingEmployeesView: FC = () => {
  return (
    <UpdatingEmployeesWrapper>
      <Header>Updating employees list</Header>
      <ProgressCard
        title="You can continue using the platform while the file is being processed in the background."
        progress={EmployeeImportManager.importExcelProgress ?? 0}
      ></ProgressCard>
    </UpdatingEmployeesWrapper>
  );
};

const Header = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  self-align: start;
`;

const UpdatingEmployeesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  background-color: var(--colorNeutralForeground8);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  color: var(--colorNeutralForeground1);
`;

export default UpdatingEmployeesView;

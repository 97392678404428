import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getEntityUserRoleData = (
  entityId: number,
): Promise<any> => {
  const query: {
    entity_id: string,
  } = {
    entity_id: entityId.toString(),
  };

  const url = createUrl({
    path: "/permission/entity_permissions/UserRoles",
    query: query,
  });
  return http.getGO(url);
};

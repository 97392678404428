import http from "api/https";
import { createUrl } from "../../helpers/url";
import { APIARY_DOMAIN } from "constants/apiary";
import { DashboardPermission, SurveyManagementPermissions } from "types/permission";

export const updateOtherPermission = (
  projectId: number,
  updatedSurveyManagementPermissions: SurveyManagementPermissions,
  updatedDashboardPermssions: DashboardPermission[],
): Promise<any> => {
  const data: {
    survey_management_permissions: SurveyManagementPermissions;
    dashboard_permissions: DashboardPermission[];
  } = {
    survey_management_permissions: updatedSurveyManagementPermissions,
    dashboard_permissions: updatedDashboardPermssions,
  };

  const url = createUrl({
    baseUrl: APIARY_DOMAIN,
    path: `/permission/entity_permissions/UpdateOtherProjectPermissions/${projectId}`,
  });

  return http.putGO(url, data);
};

import { FC } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { getUnitSegmentsName } from "utils/dashboard/useGetSegmentName";

import store from "./store";
import styled from "styled-components";
import Button from "components/button/Button";
import FiltersPopup from "./FiltersPopup";
import { TDasbboardFilter } from "types/dashboard";

type FiltersProps = {
  surveyId: number;
  disableInteraction?: boolean;
};

const Filters: FC<FiltersProps> = observer((props) => {
  const getFilterDisplay = (filter: TDasbboardFilter) => {
    const attributeName = filter.unit_or_attr?.name || "";
    const segments = filter.segments.map(segment => 
      getUnitSegmentsName(filter, segment, store.filterAttributesList, store.isDashboardUnitFilterEnabled, store.filterUnitList)
    );
    
    const displaySegments = segments.slice(0, 3).join(", ");
    
    if (segments.length > 3) {
      const remainingCount = segments.length - 3;
      return `<strong>${attributeName}</strong>: ${displaySegments} +${remainingCount} more`;
    }
    
    return `<strong>${attributeName}</strong>: ${displaySegments}`;
  };

  return (
    <Container className="dashboard-filter">
      <Text>
        {store.filters.length > 0 && store.filters[0].unit_or_attr
          ? "Filter"
          : "Filter: None selected"}
      </Text>
      {store.filters.length > 0 && store.filters[0].unit_or_attr && (
        <SelectedFilters>
          {store.filters.map((filter, filterIndex) => {
            if (filter.segments.length > 0)
              return (
                <FilterSegment key={filterIndex}>
                  <span dangerouslySetInnerHTML={{ __html: getFilterDisplay(filter) }} />
                </FilterSegment>
              );
          })}
        </SelectedFilters>
      )}
      {!props.disableInteraction && (
        <SelectFilterButton onClick={() => store.setData(true, "isOpenFiltersPopup")}>
          {store.filters.length > 0 && store.filters[0].unit_or_attr
            ? "Edit filters"
            : "Select filters"}
        </SelectFilterButton>
      )}

      <FiltersPopup
        open={store.isOpenFiltersPopup}
        handleHide={() => store.setData(false, "isOpenFiltersPopup")}
        surveyId={props.surveyId}
        units={store.filterUnitList}
      />
    </Container>
  );
});

export default Filters;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  background-color: var(--colorNeutralBackground1);
  border-bottom: 1px solid var(--colorNeutralForeground4);
  padding: 17px 16px;
  z-index: 15;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-right: 16px;
  white-space: nowrap;
`;

const SelectFilterButton = styled(Button)`
  padding: 4px 10px !important;
  margin-left: 4px !important;
  white-space: nowrap !important;
`;

const SelectedFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
`;

const FilterSegment = styled.span`
  padding: 4px 12px;
  display: inline-block;
  margin: 2px 8px 2px 0px;
  background-color: #D8FAFF;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  color: var(--colorNeutralForeground1);
  border-radius: 4px;

  strong {
    font-weight: 500;
    color: #3E4450;
  }
`;

const Divider = styled.span`
  margin-right: 8px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

import { Dialog, Typography } from "@mui/material";
import CustomButton from "components/button/Button";
import styled from "styled-components";
import MuiDialogContent from "@mui/material/DialogContent";
import CloseIconRed from "components/icons/CloseIconRed";
import FileIcon from "components/icons/FileIcon";

type ErrorDialogProps = {
  errorMessage: string;
  open: boolean;
  onClose: () => void;
  onYes: () => void;
  buttonsCaption?: {
    yesButton: string;
    cancelButton: string;
  };
  title: string;
  fileName: string;
};
const ErrorDialog = ({
  title,
  errorMessage,
  onClose,
  open,
  onYes,
  buttonsCaption,
  fileName,
}: ErrorDialogProps) => {
  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      id={"ConfirmDialog"}
      maxWidth={"sm"}
      open={open}
    >
      <CloseModal onClick={() => onClose()} />
      <ContentWrapper>
        <CloseIconRed width="40px" height="40px"></CloseIconRed>
        <Header>
          <MainText>{title}</MainText>
        </Header>
        <Body>{errorMessage}</Body>
        <Row>
          <FileIcon></FileIcon>
          <Typography>{fileName}</Typography>
        </Row>

        <ActionsWrapper>
          <div>
            <CustomButton
              name="AlertButtonYes"
              color={"primary"}
              variant="contained"
              id={"AlertButtonYes"}
              onClick={() => onYes()}
            >
              {buttonsCaption?.yesButton || "Yes"}
            </CustomButton>
          </div>
          <div>
            <CustomButton
              variant="outlined"
              name="AlertButtonNo"
              id={"AlertButtonNo"}
              onClick={() => onClose()}
            >
              {buttonsCaption?.cancelButton || "No"}
            </CustomButton>
          </div>
        </ActionsWrapper>
      </ContentWrapper>
    </Dialog>
  );
};

const MainText = styled.h1`
  color: var(--colorNeutralForeground1);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
`;

const ContentWrapper = styled(MuiDialogContent)`
  padding: 64px !important;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const Body = styled.div``;

const Row = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  gap: 10px;
`;

const CloseModal = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38px;
  right: 30px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  opacity: 0.6;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    width: 18px;
    height: 3px;
    background: var(--colorBrandForeground1);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;
export default ErrorDialog;

import { makeAutoObservable } from "mobx";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import {
  CheckedEligibility,
  DashboardPermission,
  EntityUser,
  EntityUsers,
  PermissionInEntity,
  PermissionInProjectSurvey,
  PermissionRequest,
  RoleInEntity,
  RoleUser,
  SelectedItem,
  SurveyManagementPermissions,
  User,
} from "types/permission";
import MainStore from "MainStore";
import { getEntityUsers } from "api/entityPermission/useGetEntityUsers";
import { getEntityUserRoleData } from "api/entityPermission/useGetRoleData";
import { getFeatureFlagStatus } from "api/featureFlag/useGetFeatureFlag";
import { revokeUsersPermission } from "api/entityPermission/useRevokeUsers";
import { getEntityEmployeePermissions } from "api/entityPermission/useEntityEmployeePermission";
import { createUserRole } from "api/entityPermission/useCreateUserRole";
import { editEntityEmployeePermission } from "api/entityPermission/useEditEntityEmployeePermission";
import { updateUserRole } from "api/entityPermission/useUpdateUserRole";
import { deleteUserRole } from "api/entityPermission/useDeleteUserRole";
import { getSurveyPermissions } from "api/entityPermission/useGetSurveyPermissions";
import { getSurveyProject } from "api/useGetSurveyProject";
import { sm_project } from "types/projects";
import { getDashboardPermissions } from "api/entityPermission/useGetDashboardPermission";
import { updateOtherPermission } from "api/entityPermission/useUpdateOtherPermission";

//TODO: Remove comment below once all entity tabs work
//This store is used for Entity Permission Refinement
class Store {
  invitedUsers: EntityUsers;
  selectedData: EntityUser[] | [] = [];
  ExistingRoles: RoleUser[];
  currentUser: User = null;
  activeTab: string = "entityUsers";
  currentRoleName = "";

  currentUserId: number = 0;
  currentRoleId = 0; //TODO: need to be removed once re-assign user to role functionality works
  tabId: number = 0;

  addEditRoleNamePanel = false;
  changeRolePanel = false;
  deleteRolePanel = false;
  removeFromRolePanel = false;
  checkEmployeePanel = false;

  sortAttributeId: number = null;

  currentPage: number = 1;
  pageSize: number = 10;
  sortType: string = "asc";
  sortField: string = "";
  filterByRoles: string = "";
  searchedValue = "";
  resultSearchValue = "";

  roleData: SelectedItem[];
  featureFlag: any = null;

  //Entity and Employee List Permission
  rolesInEntity: RoleInEntity[] = [];
  permissionsInEntity: PermissionInEntity[] = [];
  rolePermissionIsFetched: boolean = false;
  permissionToUpdate: CheckedEligibility[] = [];
  errorOnCreateRole: string = "";
  errorOnUpdateRole: string = "";
  errorOnDeleteRole: string = "";
  oldRole: RoleInEntity | null = null;

  //Survey Permission
  project: sm_project = null;
  surveyPermissionIsFetched: boolean = false;
  surveySearchedValue = "";
  surveyResultSearchValue = "";
  surveyCurrentPage: number = 1;
  surveyPageSize: number = 10;
  surveyTotalPages: number = 1;
  surveyDataCount: number = 1;
  rolesInSurvey: RoleInEntity[] = [];
  permissionsInSurvey: PermissionInProjectSurvey[] = [];
  selectedProjectId: number | null = null;
  selectedRoleName: string | null = null;
  isOtherPermissionReadOnly: boolean = false;
  updatedDashboardPermissions: DashboardPermission[] = [];
  updatedSurveyManagementPermissions: SurveyManagementPermissions | null = null;
  dashboardPermissionsIsFetched: boolean = false;
  surveyManagementPermissions: SurveyManagementPermissions | null = null;
  dashboardPermissions: DashboardPermission[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  changeResultSearchValue = (value: string) => {
    this.resultSearchValue = value;
  };

  changeSurveyResultSearchValue = (value: string) => {
    this.surveyResultSearchValue = value;
  };

  changeSearchedValue = (value: string) => {
    this.searchedValue = value;
  };

  changeSurveySearchedValue = (value: string) => {
    this.surveySearchedValue = value;
  };

  changeTabId = (id: number) => {
    this.tabId = id;
  };

  changeCurrentUser = (user: User) => {
    this.currentUser = user;
  };

  changeSelectedData = (data: EntityUser[]) => {
    this.selectedData = data;
  };

  changeCurrentRoleName = (name: string) => {
    this.currentRoleName = name;
  };

  changeCurrentRoleId = (id: number) => {
    this.currentRoleId = id;
  };

  changeOldRole = (role: RoleInEntity) => {
    this.oldRole = role;
  };

  changeAddEditRoleNamePanel = (flag: boolean) => {
    this.addEditRoleNamePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
      this.currentRoleName = "";
    }
  };

  changeDeleteRolePanel = (flag: boolean) => {
    this.deleteRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  changeCheckEmployeePanel = (flag: boolean) => {
    this.checkEmployeePanel = flag;

    if (!flag) {
      this.currentUserId = 0;
    }
  };

  changeRemoveFromRolePanel = (flag: boolean) => {
    this.removeFromRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
      this.currentRoleName = "";
      this.selectedData = [];
    }
  };

  changeChangeRolePanel = (flag: boolean) => {
    this.changeRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  setData = (value: any, field: string) => {
    this[field] = value;
  };

  onSaved = () => {
    this.changeAddEditRoleNamePanel(false);
  };

  onRemovedFromRole = () => {
    this.changeRemoveFromRolePanel(false);
  };

  onRoleDeleted = () => {
    this.changeDeleteRolePanel(false);
    this.tabId = 0;
  };

  onInvitedUsers = () => {
    this.loadEntityUsers();
  };

  keyPress = (e: any) => {
    if (e.keyCode === 13) {
      this.currentPage = 1;
      this.resultSearchValue = this.searchedValue;

      this.loadEntityUsers();
    }
  };

  surveyKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      this.surveyCurrentPage = 1;
      this.surveyResultSearchValue = this.surveySearchedValue;

      this.loadSurveyPermission();
    }
  };

  onClearSearchClicked = () => {
    this.searchedValue = "";
    this.resultSearchValue = this.searchedValue;

    this.loadEntityUsers();
  };

  onClearSurveySearchClicked = () => {
    this.surveySearchedValue = "";
    this.surveyResultSearchValue = this.searchedValue;

    this.loadSurveyPermission();
  };

  clearStore() {
    this.roleData = [];
    this.selectedData = [];
  }

  changeSort = (field: string, type: string) => {
    this.sortField = field;
    this.sortType = type;

    this.loadEntityUsers();
  };

  appendToDashboardPermissionUpdate = (value: DashboardPermission) => {
    const existingPermission = this.updatedDashboardPermissions.find(
      (item) => item.id === value.id && item.code === value.code,
    );

    if (existingPermission) {
      existingPermission.allow_view_all_respondent_data = value.allow_view_all_respondent_data;
      existingPermission.allow_view_own_team_data = value.allow_view_own_team_data;
    } else {
      this.updatedDashboardPermissions.push(value);
    }
  };

  RemoveFromDashboardPermissionUpdate = (id: number, code: string) => {
    const isExist = this.updatedDashboardPermissions.some(
      (item) => item.id === id && item.code === code,
    );

    if (!isExist) {
      return;
    }

    this.updatedDashboardPermissions = this.updatedDashboardPermissions.filter(
      (permission) => !(permission.id === id && permission.code === code),
    );
  };

  appendToPermissionUpdate = (value: CheckedEligibility) => {
    const isExist = this.permissionToUpdate.some(
      (item) => item.roleId === value.roleId && item.featureCode === value.featureCode,
    );

    if (isExist) {
      return;
    }

    this.permissionToUpdate.push(value);
  };

  RemoveFromPermissionUpdate = (roleId: number, featureCode: string) => {
    const isExist = this.permissionToUpdate.some(
      (item) => item.roleId === roleId && item.featureCode === featureCode,
    );

    if (!isExist) {
      return;
    }

    this.permissionToUpdate = this.permissionToUpdate.filter(
      (eligibility) => !(eligibility.roleId === roleId && eligibility.featureCode === featureCode),
    );
  };

  updateSelectedData = () => {
    this.selectedData = this.selectedData.map((selectedUser) => {
      const updatedUser = this.invitedUsers.users.find((user) => user.id === selectedUser.id);
      return updatedUser || selectedUser;
    });
  };

  async loadEntityUsers() {
    try {
      const response = await getEntityUsers(
        StoreLayout.currentEntityId,
        this.filterByRoles,
        this.currentPage,
        this.pageSize,
        this.sortType,
        this.sortField,
        this.resultSearchValue,
      );

      this.invitedUsers = response.data;
      this.currentPage = this.invitedUsers.current_page;
      this.pageSize = this.invitedUsers.page_size;

      if (this.selectedData.length > 0) {
        this.updateSelectedData();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadEntityRoleData() {
    try {
      const response = await getEntityUserRoleData(StoreLayout.currentEntityId);

      this.roleData = response.data.user_roles;
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadFeatureFlagStatus() {
    try {
      const response = await getFeatureFlagStatus("entity_permission_v2");

      this.featureFlag = response?.data;
    } catch (err) {
      console.error(err);
    }
  }

  async revokeUserAccess(onRevoked: () => void) {
    const emails = this.selectedData.map((user) => user.email);

    try {
      const response = await revokeUsersPermission(emails, StoreLayout.currentEntityId);

      if ([201, 200].includes(response.status) && response?.data !== null) {
        this.selectedData = [];
        this.loadEntityUsers();

        if (emails.length > 1) {
          MainStore.setSnackbar(
            `Revoked access for ${response.data.count} users from ${StoreLayout.currentEntityName}`,
          );
        } else {
          MainStore.setSnackbar(
            `Revoked access for ${response.data.user_emails[0]} from ${StoreLayout.currentEntityName}`,
          );
        }

        onRevoked();
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadEntityEmployeePermissions(onLoaded?: () => void) {
    this.rolePermissionIsFetched = false;

    try {
      const response = await getEntityEmployeePermissions(StoreLayout.currentEntityId, 1);

      this.rolesInEntity = response.data.roles;
      this.permissionsInEntity = response.data.entity_permissions;
      this.rolePermissionIsFetched = true;

      if (onLoaded) {
        onLoaded();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async createNewUserRole(roleName: string, onCreated: () => void) {
    this.errorOnCreateRole = "";

    try {
      const response = await createUserRole(roleName, StoreLayout.currentEntityId);

      if ([201, 200].includes(response.status) && response?.data !== null) {
        MainStore.setSnackbar(`"${roleName}" user role created`);

        this.loadEntityEmployeePermissions();
      } else {
        throw new Error();
      }

      onCreated();
    } catch (err) {
      if (err.status === 422) {
        MainStore.changeSnackbar(false);
        this.errorOnCreateRole = err.response.data.error;

        return;
      }

      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async updateUserRole(newRoleName: string, onUpdated: () => void) {
    this.errorOnUpdateRole = "";

    try {
      const response = await updateUserRole(
        newRoleName,
        StoreLayout.currentEntityId,
        this.oldRole?.id,
      );

      if ([201, 200].includes(response.status) && response?.data !== null) {
        MainStore.setSnackbar(`Changes to "${newRoleName}" user role name saved`);

        this.loadEntityEmployeePermissions();
      } else {
        throw new Error();
      }

      onUpdated();
    } catch (err) {
      if (err.status === 422) {
        MainStore.changeSnackbar(false);
        this.errorOnUpdateRole = err.response.data.error;

        return;
      }

      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async deleteUserRole(roleName: string, onDeleted: () => void) {
    this.errorOnDeleteRole = "";

    try {
      const response = await deleteUserRole(this.oldRole?.id, StoreLayout.currentEntityId);

      if ([201, 200].includes(response.status) && response?.data !== null) {
        MainStore.setSnackbar(`"${roleName}" user role deleted.`);

        this.loadEntityEmployeePermissions();
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.status === 422) {
        MainStore.setSnackbar(err.response.data.error, "error");

        return;
      }

      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      onDeleted();
    }
  }

  async updateEmployeesPermission(onUpdated: () => void) {
    const permissionMap = new Map<string, PermissionRequest>();

    this.permissionToUpdate.forEach(({ featureCode, roleId, checked }) => {
      if (!permissionMap.has(featureCode)) {
        permissionMap.set(featureCode, { code: featureCode, eligibility: [] });
      }

      permissionMap.get(featureCode)!.eligibility.push({ role_id: roleId, checked });
    });

    const permissions: PermissionRequest[] = Array.from(permissionMap.values());

    try {
      const response = await editEntityEmployeePermission(StoreLayout.currentEntityId, permissions);

      if ([201, 200].includes(response.status) && response?.data !== null) {
        MainStore.setSnackbar("Changes to Entity and Employee permission saved");

        this.loadEntityEmployeePermissions();
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Failed to save changes. Please try again.", "error");
    }

    onUpdated();
  }

  async loadSurveyPermission(onLoaded?: () => void) {
    this.surveyPermissionIsFetched = false;

    try {
      const response = await getSurveyPermissions(
        StoreLayout.currentEntityId,
        this.surveySearchedValue,
        this.surveyCurrentPage,
        this.surveyPageSize,
      );

      this.rolesInSurvey = response.data.roles;
      this.permissionsInSurvey = response.data.survey_permissions;
      this.surveyPageSize = response.data.page_size;
      this.surveyCurrentPage = response.data.current_page;
      this.surveyDataCount = response.data.count;
      this.surveyTotalPages = response.data.total_pages;

      this.surveyPermissionIsFetched = true;

      if (onLoaded) {
        onLoaded();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadProjectSurvey() {
    if (this.selectedProjectId === 0) return;

    try {
      const response = await getSurveyProject(this.selectedProjectId);

      if ([201, 200].includes(response.status) && response.data) {
        this.project = response.data;
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    }
  }

  async loadDashboardPermissions() {
    this.dashboardPermissionsIsFetched = false;

    try {
      const response = await getDashboardPermissions(
        StoreLayout.currentEntityId,
        this.selectedProjectId,
      );

      if ([201, 200].includes(response.status) && response.data) {
        this.surveyManagementPermissions = response.data.survey_management_permissions;
        this.dashboardPermissions = response.data.dashboard_permissions;
      } else {
        throw new Error();
      }

      this.dashboardPermissionsIsFetched = true;
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async toUpdateOtherPermission(onUpdated: () => void) {
    try {
      const response = await updateOtherPermission(
        StoreLayout.currentEntityId,
        this.updatedSurveyManagementPermissions,
        this.updatedDashboardPermissions,
      );

      if ([201, 200].includes(response.status) && response?.data !== null) {
        MainStore.setSnackbar("Edits saved successfully.");

        this.loadSurveyPermission();
      } else {
        throw new Error();
      }

      onUpdated();
    } catch (err) {
      MainStore.setSnackbar("Failed to save changes. Please try again.", "error");
    }
  }
}

const store = new Store();
export default store;

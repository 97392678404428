export const ENTITY_PERMISSION_MENU = [
  {
    label: "Entity Users",
    value: "entityUsers",
  },
  {
    label: "Entity and employee permissions",
    value: "entityEmployeePermissions",
  },
  {
    label: "Survey permissions",
    value: "surveyPermissions",
  },
];

export const ENTITY_PERMISSION_MENU_VALUES = {
  entityUsers: "entityUsers",
  entityEmployeePermissions: "entityEmployeePermissions",
  surveyPermissions: "surveyPermissions",
};

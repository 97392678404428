import { CreatorBase } from "survey-creator-core"
import {
  ENPS_QUESTION_TYPE,
  REQUIRED_IF_ANSWER_PROPERTY_NAME,
  REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
  VISIBLE_IF_ANSWER_PROPERTY_NAME,
  VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
} from "./surveyJsConstants"

const surveyWhitelistedProperties = [
  "completedHtml",
  "description",
  "locale",
  "logo",
  "logoFit",
  "logoHeight",
  "logoWidth",
  "mode",
  "showTitle",
  "title",
  "widthMode",
]

const ratingWhitelistedProperties = [
  "description",
  "isRequired",
  "rateMax",
  "rateMin",
  "rateStep",
  "rateValues",
  "rateType",
  "showCommentArea",
  "title",
  "minRateDescription",
  "maxRateDescription",
  VISIBLE_IF_ANSWER_PROPERTY_NAME,
  VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
  REQUIRED_IF_ANSWER_PROPERTY_NAME,
  REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
]

const yesNoWhitelistedProperties = [
  "description",
  "isRequired",
  "showCommentArea",
  "title",
  VISIBLE_IF_ANSWER_PROPERTY_NAME,
  VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
  REQUIRED_IF_ANSWER_PROPERTY_NAME,
  REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
]

const radiogroupWhitelistedProperties = [
  "choices",
  "choicesFromQuestion",
  "description",
  "isRequired",
  "showCommentArea",
  "showNoneItem",
  "showOtherItem",
  "title",
  VISIBLE_IF_ANSWER_PROPERTY_NAME,
  VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
  REQUIRED_IF_ANSWER_PROPERTY_NAME,
  REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
]

const checkboxWhitelistedProperties = [
  "choices",
  "choicesFromQuestion",
  "description",
  "showCommentArea",
  "isRequired",
  "maxSelectedChoices",
  "minSelectedChoices",
  "otherText",
  "showNoneItem",
  "showOtherItem",
  "showSelectAllItem",
  "title",
  VISIBLE_IF_ANSWER_PROPERTY_NAME,
  VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
  REQUIRED_IF_ANSWER_PROPERTY_NAME,
  REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
]

const commentWhitelistedProperties = [
  "description",
  "isRequired",
  "title",
  VISIBLE_IF_ANSWER_PROPERTY_NAME,
  VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
  REQUIRED_IF_ANSWER_PROPERTY_NAME,
  REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
]

const enpsWhitelistedProperties = [
  "description",
  "isRequired",
  "showCommentArea",
  "title",
  VISIBLE_IF_ANSWER_PROPERTY_NAME,
  VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
  REQUIRED_IF_ANSWER_PROPERTY_NAME,
  REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
]

const typePropertiesMap: { [key: string]: string[] } = {
  survey: surveyWhitelistedProperties,
  rating: ratingWhitelistedProperties,
  boolean: yesNoWhitelistedProperties,
  radiogroup: radiogroupWhitelistedProperties,
  checkbox: checkboxWhitelistedProperties,
  comment: commentWhitelistedProperties,
  [ENPS_QUESTION_TYPE]: enpsWhitelistedProperties,
}

export default function displayWhitelistedProperties(_: CreatorBase, options: any): void {
  const properties = typePropertiesMap[options.obj.getType()]

  if (properties) {
    options.canShow = properties.indexOf(options.property.name) > -1
  }
}

export const SURVEY_PERMISSION = "survey";

export const MANAGEMENT_ALL_SURVEY = "allSurvey";
export const MANAGEMENT_HISTORICAL_SURVEY = "historicalSurvey";

export const FEATURE_VIEW_ALL_SURVEY = "viewAllSurveyProjects";
export const FEATURE_EDIT_ALL_SURVEY = "editAllSurveyProjects";
export const FEATURE_VIEW_SURVEY = "viewSurveys";
export const FEATURE_EDIT_SURVEY = "editSurveys";
export const FEATURE_OTHER_PERMISSIONS = "otherPermissions";

export const PERMISSION_ACTION_VIEW = "view";
export const PERMISSION_ACTION_EDIT = "edit";

export const DASHBOARD_PERMISSION_VIEW_ALL_RESPONDENTS = "View all respondent's data";
export const DASHBOARD_PERMISSION_VIEW_OWN_TEAM = "View own team's data";

export const DASHBOARD_PERMISSION_COLUMN_HEAD: Record<string, string> = {
  viewDashboardOverview: "Overview",
  viewDashboardResponses: "Responses",
  viewDashboardPrioritize: "Prioritize",
  viewDashboardHotspot: "Hotspots",
  viewDashboardTrendResult: "Trend Results",
};

import styled from "styled-components";
import DismissButton from "./DismissButton";
import CloseIconRed from "components/icons/CloseIconRed";

type ErrorAlertProps = {
  errorMessage: string;
  onDismiss: () => void;
};

const ErrorAlert = ({ errorMessage, onDismiss }: ErrorAlertProps) => {
  return (
    <ErrorAlertBorder>
      <ErrorAlertWrapper>
        <IconWrapper>
          <CloseIconRed />
        </IconWrapper>
        <ErrorAlertText>{errorMessage}</ErrorAlertText>
        <DismissButtonWrapper>
          <DismissButton onClick={onDismiss} />
        </DismissButtonWrapper>
      </ErrorAlertWrapper>
    </ErrorAlertBorder>
  );
};

const ErrorAlertWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 100%;
  padding: 16px;
`;

const ErrorAlertBorder = styled.div`
  border-radius: 6px;
  width: 100%;
  background-color: #ffebeb;
`;

const ErrorAlertText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-weight: 400;
  padding: 0;
  color: var(--colorPaletteRedForeground2);
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  padding-left: 16px;
  align-items: start;
  justify-content: start;
  align-self: center;
`;

const DismissButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding-left: 16px;
`;

export default ErrorAlert;

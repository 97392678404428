import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export function getTimeZone() {
  const offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
}

export function getLocalTime(time: string): string {
  if (time === null || time === "" || time === undefined) return null;
  const currentDate = new Date();
  const [hours, minutes] = time.split(":").map(Number);
  currentDate.setUTCHours(hours, minutes, 0, 0);
  let day_js = dayjs(currentDate);
  return day_js.format("HH:mm");
}

export function toUtcTime(time: string): string {
  if (time === null || time === "" || time === undefined || time === "Invalid Date") return null;
  if (time.split(":").length !== 2) return null;
  const [hours, minutes] = time.split(":").map(Number);
  const currentDate = new Date();
  currentDate.setHours(hours, minutes, 0, 0);
  const utcTimeString = currentDate.toISOString().substr(11, 5);
  return utcTimeString;
}

export function formatToLocalDate(dateString: string): string {
  if (!dateString) return "";

  const userTimeZone = getTimeZone(); 
  const localDate = dayjs(dateString).utcOffset(userTimeZone);

  const day = localDate.date();
  const month = localDate.format("MMM");
  const year = localDate.year();
  const weekday = localDate.format("ddd");

  return `${day} ${month} ${year}, ${weekday}`;
}

export function formatDateToLocalTime(dateString: string): string {
  if (!dateString) return "";

  const userTimeZone = getTimeZone();
  const localDateTime = dayjs(dateString).utcOffset(userTimeZone);
  const formattedTime = localDateTime.format("hh:mm A");

  return formattedTime;
}

export function formatToLocalDateTime(dateString: string): string {
  if (!dateString) return "";

  return `${formatToLocalDate(dateString)} at ${formatDateToLocalTime(dateString)}`;
}

export function convertToAmPm(time24: string): string {
  const time = dayjs(time24, "HH:mm");

  return time.format("hh:mm A");
};

export function extractMinimumTime(dateTarget: Dayjs | null): Dayjs | null {
  if (dateTarget && dateTarget.isSame(dayjs(), "day")) {
    const roundedTime = dayjs().add(15, "minute");

    return roundedTime;
  } 

  return null;
}

export function extractAndMergeDateTime(datePart: Dayjs, timePart: Dayjs): Dayjs {
  if (!datePart || !timePart) return;

  return datePart
    .startOf("day")
    .hour(timePart.hour())
    .minute(timePart.minute())
    .second(0);
}

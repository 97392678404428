import { QUESTION } from "constants/dashboard-hotspots";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { SURVEY_STATUS_DASHBOARD } from "constants/survey-status";

import store from "../store";
import styled from "styled-components";
import topStore from "../../store";
import FlagIcon from "@mui/icons-material/Flag";

type GraphBodyProps = {};

const GraphBody: FC<GraphBodyProps> = observer(() => {
    const returnColorCell = (type: string, value: number) => {
        let color = null;
        if (type === "enps") {
            if (value >= 60) color = "var(--colorPaletteGreenBackground6)";
            else if (value >= 20 && value < 60) color = "var(--colorPaletteGreenBackground7)";
            else if (value >= 0 && value < 20) color = "var(--colorPaletteRedForeground5)";
            else color = "var(--colorPaletteGrayBackground4)";
        } else {
            if (value >= 80) color = "var(--colorPaletteGreenBackground6)";
            else if (value >= 60 && value < 80) color = "var(--colorPaletteGreenBackground7)";
            else if (value >= 50 && value < 60) color = "var(--colorPaletteRedForeground5)";
            else color = "var(--colorPaletteGrayBackground4)";
        }
        return color;
    };

    const formatCellValue = (type: string, value: number) => {
        const noValue = "-";
        if (typeof value !== "number") {
            return noValue;
        }
        if (type === "enps") {
            return value;
        }
        if (value >= 0) {
            return `${value}%`;
        }
        return noValue;
    }

    useEffect(() => {
        const expandDimensionsList = store.graphData?.categories?.filter((el) => el.collapse);

        if (store.graphData && store.graphData.data) {
            const tempData = store.graphData.data.map((el) => {
                const newList = el.list.map((item) => {
                    if (
                        expandDimensionsList.some(
                            (dimension) => dimension.name === item.dimension && item.type === QUESTION
                        )
                    ) {
                        return {
                            ...item,
                            visibility: true,
                        };
                    } else
                        return {
                            ...item,
                            visibility: false,
                        };
                });

                return {
                    ...el,
                    list: newList,
                };
            });

            store.setData(
                {
                    ...store.graphData,
                    data: tempData,
                },
                "graphData"
            );
        }
    }, [store.graphData?.categories]);

    const isHistorical = topStore.selectedSurvey.status === SURVEY_STATUS_DASHBOARD.Historical;
    return (
        <Container>
            {store.graphData &&
                store.graphData?.data?.map((row, rowIndex) => {
                    if (row.type === "all_respondents") {
                        return (
                            <Row key={rowIndex}>
                                {row.list.map((col, colIndex) => {
                                    if (col.type === "participation") {
                                        return (
                                            <Col
                                                key={colIndex}
                                                style={{ backgroundColor: returnColorCell(col.type, col.value) }}
                                            >
                                                {!isHistorical && (
                                                    <ColRow>
                                                        {col.min}/{col.max}
                                                    </ColRow>
                                                )}
                                                {!isHistorical && <ColRow>({col.value}%)</ColRow>}
                                                {isHistorical && <p>{col.min}</p>}
                                            </Col>
                                        );
                                    } else if (col.type !== QUESTION || (col.type === QUESTION && col.visibility))
                                        return (
                                            <Col
                                                key={colIndex}
                                                style={{ backgroundColor: returnColorCell(col.type, col.value) }}
                                            >
                                                <Text>
                                                    {formatCellValue(col.type, col.value)}
                                                </Text>
                                                <Group>
                                                    {/* <StyledFlagIcon />
                                                    <Benchmark>
                                                        {col.benchmark}
                                                        {col.type !== "enps" && "%"}
                                                    </Benchmark> */}
                                                </Group>
                                            </Col>
                                        );
                                })}
                            </Row>
                        );
                    } else
                        return (
                            <Row key={rowIndex}>
                                {row.list.map((col, colIndex) => {
                                    if (col.type === "participation") {
                                        return (
                                            <Col
                                                key={colIndex}
                                                style={{ backgroundColor: returnColorCell(col.type, col.value) }}
                                            >
                                                {!isHistorical && (
                                                    <ColRow>
                                                        {col.min}/{col.max}
                                                    </ColRow>
                                                )}
                                                {!isHistorical && <ColRow>({col.value}%)</ColRow>}
                                                {isHistorical && <p>{col.min}</p>}
                                            </Col>
                                        );
                                    } else if (col.type !== QUESTION || (col.type === QUESTION && col.visibility)) {
                                        let isEmpty = col.value === null && col.min === null && col.max === null && col.benchmark === null;
                                        if (isEmpty){
                                            return (
                                                <Col
                                                    key={colIndex}
                                                    style={{ backgroundColor: "var(--colorEmptyHotspotCell)" }}
                                                >
                                                    <Text>
                                                        -
                                                    </Text>
                                                </Col>
                                            );
                                        }
                                        return (
                                            <Col
                                                key={colIndex}
                                                style={{ backgroundColor: returnColorCell(col.type, col.value) }}
                                            >
                                                <Text>
                                                    {formatCellValue(col.type, col.value)}
                                                </Text>
                                            </Col>
                                        );
                                    }
                                })}
                            </Row>
                        );
                })}
        </Container>
    );
});

export default GraphBody;

const Container = styled.div`
  width: 100%;
  background-color: var(--colorNeutralBackground1);
`;

const Row = styled.div`
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
`;

const Col = styled.span`
  width: 80px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;

const ColRow = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: var(--colorNeutralForeground1);
`;

const Benchmark = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorPaletteVioletBackground2);
  text-align: center;
  align-self: center;
`;

const StyledFlagIcon = styled(FlagIcon)`
  color: var(--colorPaletteVioletBackground3);
  width: 14px !important;
  margin-right: 4px;
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;

const Group = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

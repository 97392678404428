import { makeAutoObservable, runInAction } from "mobx";
import {
  attribute_with_segments,
  dictionary_language,
  newQuestions,
  question_dimension,
  sm_question,
  sm_survey,
} from "types/surveys";
import MainStore from "MainStore";
import { getSurvey } from "api/useGetSurvey";
import { getAllLanguagesForSurvey } from "api/go/useGetAllLanguagesForSurvey";
import { saveSurvey } from "api/useSaveSurvey";
import { addSurvey } from "api/useAddSurvey";
import { getSurveyQuestionDimensions } from "api/useGetSurveyQuestionDimensions";
import { saveSurveyQuestionDimensions } from "api/useSaveSurveyQuestionDimensions";
import { getAllQuestionDimensions } from "api/go/useGetAllQuestionDimensions";
import { getAttributesAndSegmentsByEntity } from "api/go/useGetAttributesAndSegmentsByEntity";
import { copySurveyTemplates } from "api/useCopySurveyTemplates";
import settingsStore from "./SurveySettings/store";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { QuestionType } from "components/surveyEditor/sharedTypes";
import { surveyLocalization } from "survey-core";
import { Question } from "types/templates";
import { checkUniqueSurveyName } from "api/useCheckUniqueSurveyName";
import SelectStore from "../SelectTemplate/storeSurvey";
import { SURVEY_STATUS } from "constants/survey-status";
import { createUrl } from "helpers/url";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

class Store {
  idSurvey: number = 0;
  idProject: number = 0;

  openSettings: boolean = false;
  openSelectTemplate: boolean = false;
  openSelectTemplateConfirm: boolean = false;
  Survey: sm_survey = null;
  SupportedLanguages: dictionary_language[] = [];
  SurveyQuestionDimensions: sm_question[] = [];
  QuestionDimensions: question_dimension[] = [];
  Attributes: attribute_with_segments[] = [];
  is_running_survey: boolean = false;
  openRunningSurveyEditPopup: boolean = false;

  survey_before: string = "";
  survey_text: string = "";
  survey_used_locales: string[] = [];
  questionJsons: Question[] = [];
  survey_title: string = "";
  survey_title_before: string = "";
  activePage: "edit" | "translation" | "dimension" | "preview" = "edit";

  questionsForAddBefore: newQuestions[] = [];
  commsTemplateId: number = null;
  questions_for_add: newQuestions[] = [];
  typeReplaceQuestions: "add" | "replace" = null;
  openDimensionErrorMessage: boolean = false;
  isTitleError: boolean = false;
  surveyChanged: boolean = false;
  translationChanged: boolean = false;
  leaveAndDiscardUrl: string = "";
  leaveAndDiscardOpenModal: boolean = false;
  leaveAndDiscardFunction: () => void;

  openUnsavedTranslation: boolean = false;
  proceedTranslation: string | undefined = undefined;

  checkSurvey: sm_survey = null;

  constructor() {
    makeAutoObservable(this);
  }

  changeOpenDimensionErrorMessage = (flag: boolean) => {
    this.openDimensionErrorMessage = flag;
  };
  changeOpenSettings = (flag: boolean) => {
    this.openSettings = flag;
  };
  changeOpenSelectTemplate = (flag: boolean) => {
    this.openSelectTemplate = flag;
  };
  changeOpenSelectTemplateConfirm = (flag: boolean) => {
    this.openSelectTemplateConfirm = flag;
  };
  openClickRunSurveyEditPopup = (flag: boolean) => {
    this.openRunningSurveyEditPopup = flag;
  };
  openUnsavedTranslationModal = (flag: boolean, path?: string) => {
    this.openUnsavedTranslation = flag;
    this.proceedTranslation = path;
  };

  backToTemplates = () => {
    this.changeOpenSelectTemplateConfirm(false);
    this.changeOpenSelectTemplate(true);
  };

  templateLibraryAdded = (questions: newQuestions[], commsTemplateId: number) => {
    if (!questions.length && !!commsTemplateId) {
      this.setCommsFromTemplate(commsTemplateId);
      this.commsTemplateId = null;
      this.questions_for_add = [];
      this.typeReplaceQuestions = null;
      return;
    }
    if (!!questions.length && !!this.questionJsons.length) {
      this.changeOpenSelectTemplateConfirm(true);
      this.commsTemplateId = commsTemplateId;
      this.questionsForAddBefore = questions;
    } else {
      this.commsTemplateId = commsTemplateId;
      this.questionsForAddBefore = questions;
      this.confirmReplaceQuestions("replace");
      SelectStore.clearStore();
    }
  };

  confirmReplaceQuestions = (typeReplaceQuestions: "add" | "replace") => {
    if (this.commsTemplateId) {
      this.setCommsFromTemplate(this.commsTemplateId);
    }
    this.changeOpenSelectTemplateConfirm(false);
    this.commsTemplateId = null;
    this.questions_for_add = this.questionsForAddBefore;
    this.typeReplaceQuestions = typeReplaceQuestions;
  };

  clear_questons_for_add = () => {
    this.questions_for_add = [];
  };

  loadAllData = (id: number) => {
    if (id === 0) {
      return;
    }
    this.loadMainInformation(id);
  };

  loadMainInformation = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await getSurvey(id);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.Survey = response.data;
        this.checkSurvey = response.data;
        this.idProject = response.data.project_id;
        this.survey_title = response.data.name;
        this.survey_title_before = response.data.name;
        const pattern = /(Day)/;
        if (
          !response.data.name ||
          ((response.data.name === "New Survey" || pattern.test(response.data.name)) &&
            response.data.questionJson.length === 0)
        ) {
          // if the status was running, a warning modal opened first
          if (!this.openRunningSurveyEditPopup) {
            this.openSelectTemplate = true;
          }
        }
        this.survey_used_locales = response.data.locales;
        this.questionJsons = response.data.questionJson;
        if (
          response.data.questionary_survey !== null &&
          response.data.questionary_survey !== "" &&
          response.data.questionary_survey !== "{}"
        ) {
          this.survey_before = response.data.questionary_survey;
          this.survey_text = response.data.questionary_survey;
        }
        settingsStore.idSurvey = id;
        if (response.data.status_idCode === SURVEY_STATUS.running) {
          this.is_running_survey = true;
        } else {
          this.is_running_survey = false;
        }
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setCommsFromTemplate = async (commsTemplateId: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await copySurveyTemplates(
        null,
        commsTemplateId,
        this.idProject,
        this.idSurvey
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        if (this.idSurvey === null || this.idSurvey === 0) {
          this.idSurvey = Number(response.data);
          this.loadMainInformation(this.idSurvey);
        }
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  async getAllSupportedLanguages() {
    try {
      MainStore.changeLoader(true);
      const response = await getAllLanguagesForSurvey();
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.SupportedLanguages = response.data;
        surveyLocalization.supportedLocales = store.SupportedLanguages.map((lang) => lang.iso);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async getSurveyQuestionDimensions() {
    try {
      MainStore.changeLoader(true);
      const response = await getSurveyQuestionDimensions(this.idSurvey);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.SurveyQuestionDimensions = response.data;
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async getAllQuestionDimensions() {
    try {
      MainStore.changeLoader(true);
      const response = await getAllQuestionDimensions(false);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.QuestionDimensions = response.data;
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async loadAttributesAndSegmentsByEntity() {
    try {
      MainStore.changeLoader(true);
      const response = await getAttributesAndSegmentsByEntity(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.Attributes = response.data;
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  isValidSurveyTitle = (): boolean => {
    return this.survey_title !== "" && this.survey_title !== null;
  };

  changeTab = async (tab: "edit" | "dimension" | "translation" | "preview") => {
    if (this.activePage !== tab && this.idSurvey !== 0) {
      if (this.surveyChanged || this.translationChanged) {
        this.openDiscardChangesModalFunction(() => {
          this.changeActivePage(tab);
        });
      } else {
        this.changeActivePage(tab);
        this.isTitleError = false;
        this.survey_title = this.survey_title_before;
        this.survey_text = this.survey_before;
      }
    }
  };

  checkValidTitle = async () => {
    if (this.survey_title === "" || this.survey_title === null) {
      this.isTitleError = true;
      MainStore.setSnackbar("Survey title is required", "error");
      return false;
    }
    const isUnique = await this.checkUniqueSurveyName();
    if (!isUnique) {
      this.isTitleError = true;
      MainStore.setSnackbar("The title must be unique", "error");
      return false;
    }
    this.isTitleError = false;
    return true;
  };

  checkUniqueSurveyName = async () => {
    try {
      const response = await checkUniqueSurveyName(
        this.survey_title,
        this.idSurvey,
        this.idProject
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        return response?.data;
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
    return false;
  };

  async saveDimensions(navigate: (path: string) => void) {
    if (store.checkDimensionValidation() === false) {
      this.changeOpenDimensionErrorMessage(true);
      return;
    }
    try {
      MainStore.changeLoader(true);
      const response = await saveSurveyQuestionDimensions(
        this.SurveyQuestionDimensions,
        this.idSurvey
      );
      if (response.status === 201 || response.status === 200) {
        navigate(
          createUrl({
            path:
              this.Survey?.project_type === SEND_OUT_SURVEY_TYPES.Milestones
                ? "/recipients-millstone-schedule"
                : "/recipients",
            query: { id: this.idSurvey.toString() },
          })
        );
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async saveSurvey(navigate: (path: string) => void) {
    const isValid = await this.checkValidTitle();
    if (!isValid) return;
    if (this.Survey === null) {
      this.createSurvey(navigate);
    } else {
      this.updateSurvey();
    }
  }

  async createSurvey(navigate: (path: string) => void) {
    try {
      let text = this.survey_text === "" || this.survey_text === null ? "{}" : this.survey_text;
      MainStore.changeLoader(true);
      const response = await addSurvey(
        this.idProject,
        text,
        this.survey_title,
        this.survey_used_locales,
        this.questionJsons
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        return runInAction(() => {
          this.surveyChanged = false;
          this.translationChanged = false;
          this.survey_before = text;
          this.idSurvey = response.data;
          store.loadAllData(Number(response.data));
          settingsStore.idSurvey = Number(response.data);
          const query: { id: string } = { id: response.data };
          const url = createUrl({
            path: "/survey/GetPreviousImports",
            query: query,
          });
          navigate(url);
          this.changeActivePage("translation");
        });
      }
      if (response.response.status === 422) {
        if (response.response?.data?.error === "question titles must be unique") {
          return MainStore.setSnackbar("Question titles must be unique", "error");
        }
      }
      throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async updateSurvey() {
    runInAction(() => {
      if (this.survey_text !== "" && this.survey_text !== null) {
        this.Survey.questionary_survey = this.survey_text;
      }
      this.Survey.name = this.survey_title;
      this.Survey.locales = this.survey_used_locales;
      this.Survey.questionJson = this.questionJsons;
    });
    try {
      MainStore.changeLoader(true);
      const response = await saveSurvey(this.Survey);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        return runInAction(() => {
          this.surveyChanged = false;
          this.translationChanged = false;
          this.survey_before = this.Survey.questionary_survey;
          if (this.activePage === "edit") {
            this.changeActivePage("translation");
          } else {
            this.changeActivePage("dimension");
          }
        });
      }
      if (response.response.status === 422) {
        if (response.response?.data?.error === "question titles must be unique") {
          return MainStore.setSnackbar("Question titles must be unique", "error");
        }
      }
      throw new Error();
    } catch (err) {
      MainStore.changeLoader(false);
    } finally {
      MainStore.changeLoader(false);
    }
  }

  checkDimensionValidation = () => {
    let new_ques_dimen = this.SurveyQuestionDimensions;
    new_ques_dimen.forEach((x) => {
      if (
        x.dimension_idNavName === null ||
        x.dimension_idNavName === undefined ||
        x.dimension_idNavName === ""
      ) {
        x.dimension_is_required = true;
      } else {
        x.dimension_is_required = false;
      }
    });
    this.SurveyQuestionDimensions = new_ques_dimen;
    let canSave = new_ques_dimen.filter((x) => x.dimension_is_required).length === 0;
    return canSave;
  };

  changeMainSurvey = (survey_text: string) => {
    this.survey_text = survey_text;
  };
  setSurveyChanged = (flag: boolean) => {
    this.surveyChanged = flag;
  };
  setTranslationChanged = (flag: boolean) => {
    this.translationChanged = flag;
  };

  openDiscardChangesModal = (url) => {
    this.leaveAndDiscardOpenModal = true;
    this.leaveAndDiscardUrl = url;
  };

  openDiscardChangesModalFunction = (func: () => void) => {
    this.leaveAndDiscardOpenModal = true;
    this.leaveAndDiscardFunction = func;
  };

  clearDiscardVariables = () => {
    this.leaveAndDiscardUrl = "";
    this.leaveAndDiscardOpenModal = false;
    this.leaveAndDiscardFunction = null;
  };

  clearStore = () => {
    this.idSurvey = 0;
    this.idProject = 0;
    this.openSettings = false;
    this.openSelectTemplate = false;
    this.openSelectTemplateConfirm = false;
    this.Survey = null;
    this.SupportedLanguages = [];
    this.SurveyQuestionDimensions = [];
    this.QuestionDimensions = [];
    this.Attributes = [];
    this.is_running_survey = false;
    this.openRunningSurveyEditPopup = false;
    this.survey_before = "";
    this.survey_text = "";
    this.survey_used_locales = [];
    this.questionJsons = [];
    this.survey_title = "";
    this.activePage = "edit";
    this.questionsForAddBefore = [];
    this.commsTemplateId = null;
    this.questions_for_add = [];
    this.typeReplaceQuestions = null;
    this.openDimensionErrorMessage = false;
    this.isTitleError = false;
    this.surveyChanged = false;
    this.translationChanged = false;
    this.leaveAndDiscardUrl = "";
    this.leaveAndDiscardOpenModal = false;
    this.leaveAndDiscardFunction = null;
    this.survey_title_before = "";
  };

  changeUsedLocales = (locales: string[]) => {
    this.survey_used_locales = locales;
  };

  changeQuestions = (questions: Question[]) => {
    this.questionJsons = questions;
  };

  changeTitleSurvey = (survey_title: string) => {
    this.survey_title = survey_title;
  };

  changeActivePage = async (page: "edit" | "dimension" | "translation" | "preview") => {
    this.activePage = page;
    this.surveyChanged = false;
    this.translationChanged = false;

    if (page === "dimension") {
      await this.getSurveyQuestionDimensions();
      // set enps dimension by default for enps question
      runInAction(() => {
        for (const question of this.SurveyQuestionDimensions) {
          if (question.type_question_code === QuestionType.enps) {
            question.dimension_idNavName  = QuestionType.enps;
          }
        }
      });
    }
  };

  changeDimension = (value: string, index: number) => {
    this.SurveyQuestionDimensions[index].dimension_idNavName = value ? value.trim() : null;
  };
  changeCalculateScore = (value: boolean, index: number) => {
    this.SurveyQuestionDimensions[index].isCalculateScore = value;
  };

  changeIdSurvey = (idSurvey: number) => {
    this.idSurvey = idSurvey;
  };

  changeIdProject = (idProject: number) => {
    this.idProject = idProject;
  };
}

const store = new Store();
export default store;

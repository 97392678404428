import { FC } from "react";
import { observer } from "mobx-react";
import { TOthersDimension } from "types/dashboard";

import styled from "styled-components";
import { OtherElementRenderer } from "./OtherElementRenderer";

type QuestionGroupProps = {
  dimension: TOthersDimension;
  id: string;
  localFilters: { id: number; name: string; segments: string[] }[];
  isForceExpand: boolean | null;
  className?: string;
  isLastGroup: boolean;
};

const QuestionGroup: FC<QuestionGroupProps> = observer((props) => {
  const renderer = new OtherElementRenderer({
    dimension: props.dimension,
    id: props.id,
    localFilters: props.localFilters,
    isForceExpand: props.isForceExpand,
    className: props.className,
    isLastGroup: props.isLastGroup
  });

  return (
    <Container id={props.id}>
      {renderer.render()}
    </Container>
  );
});

export default QuestionGroup;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 80px 32px 75px;
  scroll-margin-top: 320px;
`;
import { Button } from "@mui/material";
import ErrorDialog from "components/ErrorDialog/ErrorDialog";
import ReviewChanges from "features/EmployeeImport/v2/components/ReviewChanges";
import DownloadTemplateAccordion from "features/EmployeeImport/v2/components/DownloadTemplateAccordion";
import ErrorAlert from "features/EmployeeImport/v2/components/ErrorAlert";
import FileUploadCard from "features/EmployeeImport/v2/components/FileUploadCard";
import UploadSection from "features/EmployeeImport/v2/components/UploadSection";
import { DeleteIcon } from "features/Employees/AttributeList/components/DeleteIcon";
import { useState } from "react";
import styled from "styled-components";
import ProgressCard from "features/EmployeeImport/v2/components/ProgressCard";
import CustomConfirmationDialogWithChildren from "components/customConfirmationDialog/customConfirmationDialogWithChildren";
import DownloadButton from "features/EmployeeImport/v2/components/DownloadButton";
import CloseIconRed from "components/icons/CloseIconRed";
import FileIcon from "components/icons/FileIcon";
import ProgressIcon from "components/icons/ProgressIcon";
import SuccessIcon from "components/icons/SuccessIcon";
import UploadFileIcon from "components/icons/UploadFileIcon";
import DismissButton from "features/EmployeeImport/v2/components/DismissButton";
import BackButton from "features/EmployeeImport/v2/components/BackButton";

const Docs = () => {
  const [currentPage, setCurrentPage] = useState("Buttons");

  return (
    <>
      <AppMainWrapper>
        <Sidebar>
          <SidebarMenuButton
            type="button"
            title="Buttons"
            onClick={() => setCurrentPage("Buttons")}
          >
            Buttons
          </SidebarMenuButton>
          <SidebarMenuButton type="button" title="Icons" onClick={() => setCurrentPage("Icons")}>
            Icons
          </SidebarMenuButton>
          <SidebarMenuButton
            type="button"
            title="Components"
            onClick={() => setCurrentPage("Components")}
          >
            Components
          </SidebarMenuButton>
          <SidebarMenuButton type="button" title="Alerts" onClick={() => setCurrentPage("Alerts")}>
            Alerts
          </SidebarMenuButton>
          <SidebarMenuButton
            type="button"
            title="Dialogs"
            onClick={() => setCurrentPage("Dialogs")}
          >
            Dialogs
          </SidebarMenuButton>
        </Sidebar>
        <Column>
          {currentPage === "Buttons" && <Buttons />}
          {currentPage === "Icons" && <Icons />}
          {currentPage === "Components" && <Components />}
          {currentPage === "Alerts" && <Alerts />}
          {currentPage === "Dialogs" && <Dialogs />}
        </Column>
      </AppMainWrapper>
    </>
  );
};

const Icons = () => {
  return (
    <Column>
      <h2>Icons</h2>
      <Grids>
        <Section name="Delete Icon">
          <DeleteIcon />
        </Section>
        <Section name="UploadFileIcon">
          <UploadFileIcon width={20} height={20} />
        </Section>
        <Section name="SuccessIcon">
          <SuccessIcon />
        </Section>
        <Section name="CloseIconRed">
          <CloseIconRed height="20px" width="20px" />
        </Section>
        <Section name="ProgressIcon">
          <ProgressIcon />
        </Section>
        <Section name="FileIcon">
          <FileIcon />
        </Section>
      </Grids>
    </Column>
  );
};

const Buttons = () => {
  return (
    <Column>
      <h2>Buttons</h2>

      <Section name="DownloadButton">
        <DownloadButton onClick={() => {}} id="" disabled={false} />
      </Section>

      <Section name="DismissButton">
        <DismissButton onClick={() => {}}></DismissButton>
      </Section>

      <Section name="BackButton">
        <BackButton
          onClick={() => alert("Back button clicked")}
          text="Back to employee list"
        ></BackButton>
      </Section>
    </Column>
  );
};

const Components = () => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  return (
    <Column>
      <h2>Components</h2>
      <h3>Upload Section</h3>
      <Section name="FileUploadCard">
        <FileUploadCard
          status="success"
          item={{
            fileId: "1",
            filename: "test.csv",
            fileSize: "10000",
            progress: 100,
            status: "success",
          }}
          onDelete={() => {}}
        />
      </Section>
      <Section name="ReviewChanges">
        <ReviewChanges
          fileName="test.csv"
          confirmClicked={() => {}}
          discardClicked={() => {}}
          success={true}
          changelogs={[]}
        />
      </Section>

      <Section name="UploadSection Empty">
        <UploadSection
          formName="UploadSection"
          handleDrop={() => {}}
          handleFileChange={() => {}}
          handleButtonClick={() => {}}
          fileInputRef={null}
          files={[]}
          onFileDelete={() => {}}
          disableFileDelete={false}
          status="empty"
        />
      </Section>

      <Section name="UploadSection Success">
        <UploadSection
          formName="UploadSection"
          handleDrop={() => {}}
          handleFileChange={() => {}}
          handleButtonClick={() => {}}
          fileInputRef={null}
          files={[
            {
              fileId: "1",
              filename: "test.csv",
              fileSize: "10000",
              progress: 100,
              status: "success",
            },
          ]}
          onFileDelete={() => {}}
          disableFileDelete={false}
          status="success"
        />
      </Section>

      <Section name="UploadSection Loading">
        <UploadSection
          formName="UploadSection"
          handleDrop={() => {}}
          handleFileChange={() => {}}
          handleButtonClick={() => {}}
          fileInputRef={null}
          files={[
            {
              fileId: "1",
              filename: "test.csv",
              fileSize: "10000",
              progress: 50,
              status: "loading",
            },
          ]}
          onFileDelete={() => {}}
          disableFileDelete={false}
          status="loading"
        />
      </Section>

      <Section name="DownloadTemplateAccordion">
        <DownloadTemplateAccordion
          formName="DownloadTemplateAccordion"
          isAccordionExpanded={isAccordionExpanded}
          setToggleAccordion={() => setIsAccordionExpanded(!isAccordionExpanded)}
          saveExcelEmployee={() => {}}
        />
      </Section>

      <Section name="ProgressCard">
        <ProgressCard
          title="You can continue using the platform while the file is being processed in the background."
          progress={44}
        />
      </Section>
    </Column>
  );
};

const Alerts = () => {
  return (
    <Column>
      <h2>Alerts</h2>
      <Section name="ErrorAlert">
        <ErrorAlert
          errorMessage="Oops! Your Excel file contains errors. Review the list of errors on the right and try uploading your file again."
          onDismiss={() => {}}
        />
      </Section>
    </Column>
  );
};

const Dialogs = () => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isCustomConfirmationDialogOpen, setIsCustomConfirmationDialogOpen] = useState(false);

  return (
    <Column>
      <h2>Dialogs</h2>
      <Section name="ErrorDialog">
        <Button onClick={() => setIsErrorDialogOpen(!isErrorDialogOpen)}>Open Error Dialog</Button>
        <ErrorDialog
          title="File import unsuccessful"
          fileName="test.xlsx"
          open={isErrorDialogOpen}
          errorMessage="Oops! Your Excel file contains errors. Review the list of errors on the right and try uploading your file again."
          onClose={() => {
            setIsErrorDialogOpen(false);
          }}
          onYes={() => {}}
          buttonsCaption={{
            yesButton: "Review file",
            cancelButton: "Cancel",
          }}
        />
      </Section>

      <Section name="CustomConfirmationDialogWithChildren">
        <Button onClick={() => setIsCustomConfirmationDialogOpen(!isCustomConfirmationDialogOpen)}>
          Open Custom Confirmation Dialog
        </Button>
        <CustomConfirmationDialogWithChildren
          title="Are you sure you want to bulk import users?"
          isDialogOpen={isCustomConfirmationDialogOpen}
          onClose={() => {
            setIsCustomConfirmationDialogOpen(false);
          }}
          onYes={() => {}}
        >
          <p>The following actions will be performed:</p>
          <ul>
            <li>197 employees to be updated</li>
            <li>1 employee to be created</li>
            <li>1 employee to be deleted</li>
          </ul>
        </CustomConfirmationDialogWithChildren>
      </Section>
    </Column>
  );
};

const Section = ({ name, children }: { name: string; children: React.ReactNode }) => {
  return (
    <SectionContainer>
      <SectionTitle>{name}</SectionTitle>
      {children}
    </SectionContainer>
  );
};

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  return <SidebarContainer>{children}</SidebarContainer>;
};

const SidebarContainer = styled.aside`
  padding-top: 10rem;
  padding-left: 4rem;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100px;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
`;

const SidebarTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
`;

const SectionTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  gap: 10px;
`;

const Grids = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 5px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;
  padding: 1.2rem;
  border: 1px solid #a0a0a0;
  border-radius: 10px;
  background-color: #fff;
  max-width: 700px;
  width: 100%;
`;

const AppMainWrapper = styled.div`
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  top: 0;
  padding-bottom: 200px;

  /* display: grid; */
  /* grid-template-columns: 100px 1fr;
    gap: 10px; */
`;

const SidebarMenuButton = styled.button`
  font-size: 1rem;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  display: flex;
  width: 100%;
  height: 40px;
  border: none;
  background-color: none;
  color: blue;
  cursor: pointer;
`;

export default Docs;

import React from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { getSurveyCommunicationInformation } from "api/go/useGetSurveyCommunicationInformation";
import { getAllFooterLanguageMessages } from "api/survey/useGetAllFooterLanguageMessages";
import { getPreviewSurveyCommunication } from "api/go/useGetPreviewSurveyCommunication";
import { getEmailLanguageMessage, EmailType } from "api/survey/useGetEmailLanguageMessages";
import { getTemplReminderDays } from "api/go/useGetTemplReminderDays";
import { getTemplReminderRecipientGroup } from "api/survey/useGetTemplReminderRecipientGroup";
import { saveFooterSurveyCommunication } from "api/go/useSaveFooterSurveyCommunication";
import { getRolesByEntity } from "api/survey/useGetAllRolesByEntity";
import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { saveInviteSurveyCommunication } from "api/go/useSaveInviteSurveyCommunication";
import { saveReportSurveyCommunication } from "api/go/useSaveReportSurveyCommunication";
import { saveReminderSurveyCommunication } from "api/go/useSaveReminderSurveyCommunication";
import { saveSurveyDates } from "api/go/useSaveSurveyDates";
import { saveAccessesForSurvey } from "api/go/useSaveAccessesForSurvey";
import { removeReminderSurvey } from "api/go/useRemoveReminderSurvey";
import { addReminderForSurvey } from "api/go/useAddReminderForSurvey";
import {
  Access,
  AllInfoCommunicationSurvey,
  FooterSurveyLanguage,
  ReminderDays,
  ReminderGroups,
  ReminderSurvey,
  SurveyLanguage,
} from "types/surveys";
import { Role } from "types/permission";
import MainStore from "MainStore";
import { copySurveyTemplates } from "api/useCopySurveyTemplates";
import { checkCommsLanguageInconsistency } from "api/useCheckCommsLanguageInconsistency";
import {
  getLocalTime,
  getTimeZone,
  extractMinimumTime,
  toUtcTime,
  extractAndMergeDateTime,
} from "utils/timeFormatter";
import { useResendEmails } from "api/survey/useResendEmails";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import headerStore from "features/Survey/SurveyList/store";
import StoreSurveyList from "../SurveyList/store";
import { SURVEY_STATUS } from "constants/survey-status";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import storeRecipients from "../Recipients/store";
import storeSurvey from "features/Survey/SurveyCreation/store";
import { getAllEmployeesNoRecipients } from "api/go/useGetAllEmployeesNoRecipients";
import { REMINDER_RECIPIENT_GROUP_CODE } from "constants/recipient-reminder";
import { getManagersRecipients } from "api/go/useGetManagersRecipient";
import { GetOneByIdForEdit } from "api/useGetOneByIdForEdit";
import { TProjectForEdit } from "types/entityAttribute";

type CommonDateFields = {
  surveyDateStart: Dayjs | null;
  surveyDateEnd: Dayjs | null;
  timeSendReport: Dayjs | null;
};

type SelectedDate = CommonDateFields;
type SelectedTime = CommonDateFields;
type MinimumTime = CommonDateFields & {
  reminderScheduleTime: Dayjs | null;
};

class Store {
  idSurvey = 0;
  surveyAccessArray: Access[] = [];
  FooterData: FooterSurveyLanguage[] = [];
  InviteData: SurveyLanguage[] = [];
  ReminderData: ReminderSurvey[] = [];
  ReportData: SurveyLanguage[] = [];
  AllInfo: AllInfoCommunicationSurvey = null;
  ReminderGroups: ReminderGroups[] = [];
  ReminderDays: ReminderDays[] = [];
  Roles: Role[] = [];
  SelectedRolesIds: number[] = [];
  file = null;
  remove_file: boolean = false;
  openReminderIndex: null | number = null;
  reportDays_id = 0;
  is_send_report: boolean = false;
  prjNum: null | number = 0;
  openPreviewEmail: boolean = false;
  previewLastLanguageId: number = null;
  previewEmailText: string = "";
  timezone_message: string = getTimeZone();

  saveInviteEmailBtn: boolean = false;
  saveInviteEmailValue: boolean = false;
  saveFooterValue: boolean = false;
  saveAccessValue: boolean = false;
  comms_email_id: number = null;
  footer_id: number = null;

  changedFirstTab: boolean = false;
  changedSecondTab: boolean = false;
  changedReportTab: boolean = false;
  changedThirdTab: boolean = false;

  dates_changed: boolean = false;
  accessed_changed: boolean = false;
  survey_date_start: Dayjs = null;
  survey_date_end: Dayjs = null;
  dateEnd: Dayjs = null;
  surveyDateStart: Dayjs = null;
  surveyDateEnd: Dayjs = null;
  projectDateEnd: Dayjs = null;
  timestart_milestone: Dayjs = null;
  close_now: boolean = false;
  leaveDateEmpty: boolean = false;
  leaveEndDateSurveyEmpty: boolean = false;
  closeNow: boolean = false;
  timeSendReport: Dayjs = null;
  isSetMainInformationDone = false;
  isStartTimeNeedIncreased = false;
  isTimeSendNeedIncreased = false;

  selectedDate: SelectedDate = {
    surveyDateStart: null,
    surveyDateEnd: null,
    timeSendReport: null,
  };
  selectedTime: SelectedTime = {
    surveyDateStart: null,
    surveyDateEnd: null,
    timeSendReport: null,
  };
  minTime: MinimumTime = {
    surveyDateStart: null,
    surveyDateEnd: null,
    timeSendReport: null,
    reminderScheduleTime: null,
  };

  openCommTemplatesModal: boolean = false;
  openCommTemplateCheckModal: boolean = false;
  openCommLanguageInconsistency: boolean = false;
  openReminderModal: boolean = false;
  selectedCommtemplate: number = 0;

  curentSurveyId: number = 0;
  openExitModal: boolean = false;
  hasAccess: boolean = false;
  hasDates: boolean = false;

  isOpenResendEmailPoup: boolean = false;
  resendEmailsList = [];
  selectedResendEmail: number[] = [];

  expandedTab: string | boolean;

  valueTab: number = 0;

  checkStartDate: string | null = null;

  activePage: number = 1;
  countPerPage: number = 10;
  countTotal: number = 0;

  selectedReminderGroupCode: string = "";
  reminderDataSaved: boolean = false;
  frequencyRepeatEveryCount: number | null = null;
  frequencyRepeatEveryPeriod: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setReminderGroupCode = (code: string) => {
    this.selectedReminderGroupCode = code;
  };

  setCountPerPage = (num: number) => {
    this.countPerPage = num;
  };

  setActivePage = (num: number) => {
    this.activePage = num;
  };

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  loadAllData = (id: number) => {
    if (id === 0) {
      return alert(id);
    }
    storeSurvey.loadMainInformation(id);
    this.loadMainInformation(id).then(() => {
      this.loadProjectData();
    });
    this.loadFooterData(id);
    this.loadInviteData(id);
    this.loadReportData(id);
    this.loadReminderData(id);
    this.loadReminderDays();
    this.loadReminderGroups();
    this.loadRolesByEntity();

    storeRecipients.getAllRecipients(id);
    storeRecipients.getAllEmployees(id);
  };

  loadProjectData = async () => {
    GetOneByIdForEdit(this.prjNum).then((x: { data: TProjectForEdit }) => {
      if (x?.data !== undefined) {
        this.frequencyRepeatEveryCount = x.data.repeat_value;
        this.frequencyRepeatEveryPeriod = x.data.repeat_type;
      }
    });
  };

  loadMainInformation = async (id: number) => {
    this.isSetMainInformationDone = false;

    try {
      MainStore.changeLoader(true);
      const response = await getSurveyCommunicationInformation(id);
      if ((response.status === 201 || response.status === 200) && response?.data) {
        await this.setMainInformation(response.data);

        this.prjNum = response.data?.project_id;
        this.setAccesses(response.data.accesses);
        headerStore.setProjectId(response.data.project_id);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  // load invite data [test-commit]
  loadInviteData = async (id: number) => {
    try {
      MainStore.changeLoader(true);

      const response = await getEmailLanguageMessage(id, EmailType.Invite);
      const data = response?.data?.email_languages[0]?.reminder_message_languages;

      if ((response.status === 201 || response.status === 200) && data) {
        this.setInviteData(data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setCurentSurveyId = (id: number) => {
    this.curentSurveyId = id;
  };

  setOpenExitModal = (bool: boolean) => {
    this.openExitModal = bool;
  };

  setAllInfoName = (name: string) => {
    this.AllInfo.name = name;
  };

  loadReportData = async (id: number) => {
    try {
      MainStore.changeLoader(true);

      const response = await getEmailLanguageMessage(id, EmailType.Report);
      const data = response?.data?.email_languages[0]?.reminder_message_languages;

      if ((response.status === 201 || response.status === 200) && data) {
        this.setReportData(data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  loadReminderData = async (id: number) => {
    try {
      MainStore.changeLoader(true);

      const response = await getEmailLanguageMessage(id, EmailType.Reminder);
      const datas = response?.data?.email_languages;

      if ((response.status === 201 || response.status === 200) && datas) {
        this.setReminderData(datas);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  loadFooterData = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await getAllFooterLanguageMessages(id);
      if ((response.status === 201 || response.status === 200) && response?.data) {
        this.setFooterData(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  loadReminderDays = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getTemplReminderDays();
      if ((response.status === 201 || response.status === 200) && response?.data) {
        this.setReminderDays(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  loadReminderGroups = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getTemplReminderRecipientGroup(this.idSurvey);
      if ((response.status === 201 || response.status === 200) && response?.data) {
        this.setReminderGroups(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  loadRolesByEntity = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getRolesByEntity(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data) {
        this.setRoles(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setAllReceivedSchedule = (data: AllInfoCommunicationSurvey) => {
    this.AllInfo = data;

    this.checkStartDate = data.survey_date_start;
    this.surveyDateStart = data.survey_date_start && dayjs(data.survey_date_start);
    this.surveyDateEnd = data.survey_date_end && dayjs(data.survey_date_end);
    this.projectDateEnd = data.project_date_end && dayjs(data.project_date_end);
    this.timestart_milestone = data.timestart_milestone && dayjs(data.timestart_milestone);
    this.timeSendReport = data.time_send_report && dayjs(data.time_send_report);
  };

  setMainInformation = (data: AllInfoCommunicationSurvey) => {
    this.setAllReceivedSchedule(data);

    this.changeValue("leaveDateEmpty", data.leave_date_empty);

    this.SelectedRolesIds = data.report_roles.map((x) => x.id);
    this.isSetMainInformationDone = true;
  };

  setAccesses = (data: Access[]) => {
    this.surveyAccessArray = data;
    if (data.some((z) => z.valueChecked)) {
      this.changeValue("hasAccess", true);
    }
  };
  setFooterData = (data: FooterSurveyLanguage[]) => {
    this.FooterData = data;
  };
  setInviteData = (data: SurveyLanguage[]) => {
    this.InviteData = data;
  };
  setReminderData = (data: ReminderSurvey[]) => {
    data.forEach((x) => {
      if (x.strtime_send_reminder) {
        x.strtime_send_reminder = getLocalTime(x.strtime_send_reminder);
      }
    });
    this.ReminderData = data;
  };
  setReportData = (data: SurveyLanguage[]) => {
    this.ReportData = data;
  };
  setReminderDays = (data: ReminderDays[]) => {
    let index = data.findIndex((obj) => obj.code === "today");
    if (index !== -1) {
      let element = data.splice(index, 1)[0];
      data.unshift(element);
    }
    this.ReminderDays = data;
  };
  setReminderGroups = (data: ReminderGroups[]) => {
    this.ReminderGroups = data;
  };
  setRoles = (data: Role[]) => {
    this.Roles = data;
  };

  saveAccessesClicked = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await saveAccessesForSurvey(this.surveyAccessArray, this.idSurvey);
      if (response.status === 201 || response.status === 200) {
        this.accessed_changed = false;
        this.loadMainInformation(this.idSurvey);
        this.saveAccessValue = true;
        this.expandedTab = "panel2";
        MainStore.setSnackbar("Survey access saved successfully!");
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  checkChanged = (): boolean => {
    if (
      this.changedFirstTab ||
      this.changedReportTab ||
      this.changedSecondTab ||
      this.dates_changed ||
      this.accessed_changed ||
      this.changedThirdTab
    ) {
      return true;
    }
    return false;
  };

  checkDates = (): boolean => {
    if (this.leaveDateEmpty) return true;

    if (this.AllInfo?.project_type !== SEND_OUT_SURVEY_TYPES.Milestones) {
      if (!this.surveyDateStart || !this.surveyDateStart.isValid()) return false;

      if (
        this.surveyDateEnd &&
        (!this.surveyDateEnd.isValid() ||
          this.surveyDateEnd.isBefore(this.surveyDateStart.add(1, "minute")))
      ) {
        return false;
      }

      const currentDate = dayjs();
      if (
        this.surveyDateStart < currentDate.add(15, "minute") &&
        this.AllInfo?.survey_status !== SURVEY_STATUS.running &&
        this.AllInfo?.survey_status !== SURVEY_STATUS.complete
      ) {
        this.isStartTimeNeedIncreased = true;
        return false;
      }
    }
    return true;
  };

  SaveSurveyDates = async () => {
    let check = this.checkDates();

    if (check) {
      let utc_survey_date_start = null;
      let utc_survey_date_end = null;
      let utc_timestart_milestone = null;

      if (this.surveyDateStart) {
        utc_survey_date_start = this.surveyDateStart.toISOString();
      }

      if (this.surveyDateEnd) {
        utc_survey_date_end = this.surveyDateEnd.toISOString();
      }

      if (this.timestart_milestone) {
        utc_timestart_milestone = this.timestart_milestone.toISOString();
      }

      try {
        MainStore.changeLoader(true);
        const data = {
          survey_id: this.idSurvey,
          strsurvey_date_start: utc_survey_date_start,
          strsurvey_date_end: utc_survey_date_end,
          strtimestart_milestone: utc_timestart_milestone,
          leave_date_empty: this.leaveDateEmpty,
          close_now: this.closeNow,
        };
        await saveSurveyDates(data);

        this.loadMainInformation(this.idSurvey);
        this.changeDatesTab(false);
        this.expandedTab = "panel1";

        MainStore.setSnackbar("Survey dates saved successfully!");

        this.changeValue("hasDates", true);
        await this.loadReminderData(this.idSurvey);
      } catch {
        MainStore.setSnackbar("Something went wrong!", "error");
      } finally {
        MainStore.changeLoader(false);
      }
    }
  };

  saveFooterData = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await saveFooterSurveyCommunication(
        this.FooterData,
        this.idSurvey,
        this.file,
        this.remove_file
      );
      if (response.status === 201 || response.status === 200) {
        this.loadFooterData(this.idSurvey);
        this.loadMainInformation(this.idSurvey);
        this.changedFirstTab = false;
        this.remove_file = false;
        this.saveFooterValue = true;
        this.valueTab = 1;
        MainStore.setSnackbar("Email settings saved successfully!");
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  onCancelFirstTab = () => {
    this.loadFooterData(this.idSurvey);
    this.loadMainInformation(this.idSurvey);
    this.changedFirstTab = false;
    this.remove_file = false;
  };

  onCancelSecondTab = () => {
    this.loadInviteData(this.idSurvey);
    this.changedFirstTab = false;
  };

  onCancelReportTab = () => {
    this.loadReportData(this.idSurvey);
    this.loadMainInformation(this.idSurvey);
    this.changedReportTab = false;
  };

  saveInviteData = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await saveInviteSurveyCommunication(this.InviteData);
      if (response.status === 201 || response.status === 200) {
        this.loadInviteData(this.idSurvey);
        if (store.AllInfo.emails_completed === false) {
          store.AllInfo.emails_completed = true;
        }
        this.changedSecondTab = false;
        this.saveInviteEmailBtn = false;
        this.saveInviteEmailValue = true;
        this.valueTab = 2;
        MainStore.setSnackbar("Invite email saved successfully!");
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  checkReminderLookup = (): boolean => {
    let data = this.ReminderData[this.openReminderIndex];
    if (
      this.ReminderGroups.find((x) => x.id === data.reminder_recipients_group_id)
        ?.countRecipients !== 0
    ) {
      return true;
    }
    return false;
  };

  setupReminderDataSaved = () => {
    this.reminderDataSaved = false;
  };

  saveReminderData = async () => {
    let data = this.ReminderData[this.openReminderIndex];

    data.strtime_send_reminder = toUtcTime(data.strtime_send_reminder);
    data.iana_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      MainStore.changeLoader(true);
      if (storeRecipients.exportRecipientsList.length !== 0) {
        const response = await saveReminderSurveyCommunication(data);

        if (response.status === 201 || response.status === 200) {
          this.loadReminderData(this.idSurvey);

          this.changedThirdTab = false;
          this.reminderDataSaved = true;

          MainStore.setSnackbar("Reminder saved successfully!");
        } else {
          throw new Error();
        }
      } else {
        this.openReminderModal = true;
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  saveReportData = async () => {
    this.AllInfo.strtime_send_report = toUtcTime(this.AllInfo.strtime_send_report);
    let utc_time_send_report = null;
    
    if (this.timeSendReport) {
      const currentDate = dayjs();

      if (this.timeSendReport < currentDate.add(15, "minute")) {
        this.isTimeSendNeedIncreased = true;
        return false;
      }

      let test_time_send_report = this.timeSendReport.toDate();

      utc_time_send_report = test_time_send_report.toISOString();

      utc_time_send_report = !dayjs(utc_time_send_report).isValid() ? null : utc_time_send_report;
    }

    let roles = this.Roles.filter((x) => this.SelectedRolesIds.includes(x.id));
    try {
      MainStore.changeLoader(true);
      const response = await saveReportSurveyCommunication(
        this.ReportData,
        this.AllInfo.is_send_report,
        this.idSurvey,
        utc_time_send_report,
        this.AllInfo.reminder_days_id === null
          ? this.ReminderDays.length > 0
            ? this.ReminderDays[0].id
            : null
          : this.AllInfo.reminder_days_id,
        roles
      );
      if (response.status === 201 || response.status === 200) {
        this.loadReportData(this.idSurvey);
        if (store.AllInfo.report_completed === false) {
          store.AllInfo.report_completed = true;
        }
        this.changedReportTab = false;
        this.expandedTab = false;
        MainStore.setSnackbar("Report saved successfully!");
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeValue = (field: string, value: any) => {
    if (field === "leaveDateEmpty" && value === true) {
      this.surveyDateEnd = null;
      this.surveyDateStart = null;
      this.timestart_milestone = null;

      this.selectedDate["surveyDateStart"] = null;
      this.selectedDate["surveyDateEnd"] = null;
    }

    this[field] = value;

    if (field === "survey_date_end" && value) {
      dayjs.extend(isSameOrBefore);
      this.close_now = this.survey_date_end?.isSameOrBefore(dayjs());
    }
  };

  mergeDateAndTime = (
    datePart: Dayjs,
    timePart: Dayjs | null = null,
    field: keyof CommonDateFields = "surveyDateEnd"
  ) => {
    this.selectedDate[field] = datePart.startOf("day");
    this.selectedTime[field] = timePart || this.selectedTime[field];

    if (datePart && !timePart) {
      this.setupMinTime(this.selectedDate[field], field);
    }

    if (this.selectedDate[field] && this.selectedTime[field]) {
      this[field] = extractAndMergeDateTime(this.selectedDate[field], this.selectedTime[field]);
    }
  };

  setupMinTime = (dateTarget: Dayjs | null, field: keyof MinimumTime) => {
    const roundedTime = extractMinimumTime(dateTarget);

    this.minTime[field] = roundedTime;
  };

  clearStore = () => {
    this.idSurvey = 0;
    this.FooterData = [];
    this.InviteData = [];
    this.ReminderData = [];
    this.ReportData = [];
    this.AllInfo = null;
    this.ReminderGroups = [];
    this.ReminderDays = [];
    this.Roles = [];
    this.SelectedRolesIds = [];
    this.file = false;
    this.openReminderIndex = null;
    this.reportDays_id = 0;
    this.prjNum = 0;
    this.openPreviewEmail = false;
    this.previewLastLanguageId = null;
    this.previewEmailText = "";
    this.timezone_message = getTimeZone();
    this.saveInviteEmailBtn = false;
    this.saveInviteEmailValue = false;
    this.saveFooterValue = false;
    this.saveAccessValue = false;
    this.comms_email_id = null;
    this.footer_id = null;
    this.changedFirstTab = false;
    this.changedSecondTab = false;
    this.changedReportTab = false;
    this.dates_changed = false;
    this.accessed_changed = false;
    this.surveyDateStart = null;
    this.surveyDateEnd = null;
    this.timestart_milestone = null;
    this.leaveEndDateSurveyEmpty = false;
    this.leaveDateEmpty = false;
    this.timeSendReport = null;
    this.openCommTemplatesModal = false;
    this.openCommTemplateCheckModal = false;
    this.openCommLanguageInconsistency = false;
    this.selectedCommtemplate = 0;
    this.curentSurveyId = 0;
    this.openExitModal = false;
    this.hasAccess = false;
    this.hasDates = false;
    this.isOpenResendEmailPoup = false;
    this.resendEmailsList = [];
    this.expandedTab = false;
    this.closeNow = false;
    this.changedThirdTab = false;
    this.activePage = 1;
    this.countPerPage = 10;
    this.countTotal = 0;

    this.selectedDate = {
      surveyDateStart: null,
      surveyDateEnd: null,
      timeSendReport: null,
    };
    this.selectedTime = {
      surveyDateStart: null,
      surveyDateEnd: null,
      timeSendReport: null,
    };
    this.minTime = {
      surveyDateStart: null,
      surveyDateEnd: null,
      timeSendReport: null,
      reminderScheduleTime: null,
    };

    this.isSetMainInformationDone = false;
    this.isStartTimeNeedIncreased = false;
  };

  changeDatesTab = (flag: boolean) => {
    this.dates_changed = flag;
  };

  checkedChange = (id: number, value: any, access_code: string) => {
    const updatedSurveyAccessArray = this.surveyAccessArray.map((el) => {
      if (el.id === id) {
        return { ...el, valueChecked: value };
      }
      return el;
    });
    this.surveyAccessArray = updatedSurveyAccessArray;
    this.accessed_changed = true;

    let count_active = this.surveyAccessArray.filter((x) => x.valueChecked === true).length;
    if (this.AllInfo) {
      if (count_active === 0) {
        this.AllInfo.accesses_completed = false;
      } else {
        this.AllInfo.accesses_completed = true;
      }
    }
    if (this.surveyAccessArray.some((z) => z.valueChecked === true)) {
      this.accessed_changed = true;
    } else {
      this.accessed_changed = false;
    }
  };

  savingOnlyAfter = (navigate: (url: string) => void) => {
    const { project_type, leave_date_empty, timestart_milestone, survey_date_start } =
      this.AllInfo || {};

    if (project_type === SEND_OUT_SURVEY_TYPES.Milestones) {
      if (!leave_date_empty && !timestart_milestone) {
        MainStore.setSnackbar('Please select a date in the "Set survey dates" section', "error");
        return;
      }
    } else {
      if (!this.hasAccess && !leave_date_empty && !survey_date_start) {
        MainStore.setSnackbar(
          "Looks like you haven’t finished setting up the survey communications yet",
          "error"
        );
        return;
      }
      if (!leave_date_empty && !survey_date_start) {
        MainStore.setSnackbar('Please select a date in the "Set survey dates" section', "error");
        return;
      }
    }
    if (!this.hasAccess) {
      MainStore.setSnackbar(
        'Please select at least one option in the "Survey Access" section',
        "error"
      );
      return;
    }
    StoreSurveyList.getSurveyResult(this.AllInfo.id);
    navigate(`/projects?project_id=${this.prjNum}`);
  };

  changeFooterData = (value: string, index: number) => {
    this.changedFirstTab = true;
    this.FooterData[index].footer_email = value;
  };

  changeInviteData = (value: string, field: string, index: number) => {
    this.changedSecondTab = true;
    this.saveInviteEmailBtn = true;
    this.InviteData[index][field] = value;
  };
  changeReminderData = (value: string, field: string, index: number, indexLanguage: number) => {
    this.changedThirdTab = true;
    this.ReminderData[index].reminder_message_languages[indexLanguage][field] = value;
  };
  changeReportData = (value: string, field: string, index: number) => {
    this.changedReportTab = true;
    this.ReportData[index][field] = value;
  };
  changeReminderGroups = (value: number, index: number) => {
    this.changedThirdTab = true;
    this.ReminderData[index].reminder_recipients_group_id = Number(value);
  };
  changeReminderDays = (value: number, index: number) => {
    this.changedThirdTab = true;
    this.ReminderData[index].reminder_days_id = Number(value);
  };
  changeReminderTime = (value: any, index: number) => {
    this.changedThirdTab = true;
    let new_value = value === null ? null : value.format("HH:mm");
    this.ReminderData[index].strtime_send_reminder = new_value;
  };
  changeReportRoles = (value: number[]) => {
    this.changedReportTab = true;
    this.SelectedRolesIds = value;
  };
  resetChangedThirdTab = () => {
    this.changedThirdTab = false;
  };

  changeReportTime = (value: any) => {
    this.changedReportTab = true;
    let new_value = value.format("HH:mm");
    this.AllInfo.strtime_send_report = new_value;
  };
  changeReportDays = (value: number) => {
    this.changedReportTab = true;
    this.AllInfo.reminder_days_id = Number(value);
  };
  changeReportIsSendReport = (value: boolean) => {
    this.changedReportTab = true;
    this.AllInfo.is_send_report = value;
  };

  addNewReminder = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await addReminderForSurvey(this.idSurvey);
      if (response.status === 201 || response.status === 200) {
        this.loadReminderData(this.idSurvey).then((x) => {
          this.openReminderIndex = this.ReminderData.length - 1;
        });
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  DeleteReminderSubmit = (index: number) => {
    MainStore.openErrorConfirm(
      `Are you sure you want to delete "Reminder email ${index + 1}"?`,
      "",
      "Delete template",
      "Cancel",
      async () => {
        try {
          MainStore.changeLoader(true);
          const response = await removeReminderSurvey(this.ReminderData[index].id_comms_reminder);
          if (response.status === 201 || response.status === 200) {
            this.loadReminderData(this.idSurvey).then((x) => {
              this.loadReminderData(this.idSurvey);
              this.openReminderIndex = null;
            });
          } else {
            throw new Error();
          }
        } catch {
          MainStore.setSnackbar("Something went wrong!", "error");
        } finally {
          MainStore.changeLoader(false);
          MainStore.onCloseConfirm();
        }
      },
      () => {
        MainStore.onCloseConfirm();
      },
      null,
      null,
      "primary",
      "inherit"
    );
  };

  changeOpenReminderIndex = (index: number, flag: boolean) => {
    if (flag === true) {
      this.openReminderIndex = index;
    } else {
      this.openReminderIndex = null;
      this.loadReminderData(this.idSurvey);
    }
  };

  changeFile = (value: any) => {
    this.file = value;
    this.changedFirstTab = true;
  };

  setOPenTemplateCommModal = (x: boolean) => {
    this.openCommTemplatesModal = x;
  };

  setOpenTemplateCommCheckModal = (x: boolean) => {
    this.openCommTemplateCheckModal = x;
  };

  closeTemplateCommCheckModal = () => {
    this.openCommTemplateCheckModal = false;
    this.selectedCommtemplate = null;
  };

  changeOpenCommLanguageInconsistency = (x: boolean) => {
    this.openCommLanguageInconsistency = x;
  };

  changeReminderModal = (x: boolean) => {
    this.openReminderModal = x;
  };

  setCommsFromTemplate = async (commsTemplateId: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await copySurveyTemplates(null, commsTemplateId, 0, this.idSurvey);
      if (response.status === 201 || response.status === 200) {
        this.loadAllData(this.idSurvey);
        this.changeOpenCommLanguageInconsistency(false);
        this.selectedCommtemplate = null;
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  checkCommLanguageInconsistency = async () => {
    this.setOpenTemplateCommCheckModal(false);
    try {
      MainStore.changeLoader(true);
      const response = await checkCommsLanguageInconsistency(
        this.idSurvey,
        this.selectedCommtemplate
      );
      if (response.status === 201 || response.status === 200) {
        if (response?.data) {
          this.changeOpenCommLanguageInconsistency(true);
        } else {
          this.setCommsFromTemplate(this.selectedCommtemplate);
        }
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setSelectedCommsTemplateForComunication = (commsTemplate: number) => {
    this.selectedCommtemplate = commsTemplate;
    this.openCommTemplatesModal = false;
    this.openCommTemplateCheckModal = true;
  };

  changeOpenPreviewEmail = (flag: boolean) => {
    this.openPreviewEmail = flag;
    if (flag === false) {
    }
  };

  changeLastLanguage = (idLanguage: number) => {
    this.previewLastLanguageId = idLanguage;
  };

  loadPreviewMessage = async (idReminder: number, is_report: boolean) => {
    try {
      MainStore.changeLoader(true);
      const response = await getPreviewSurveyCommunication(
        this.idSurvey,
        this.previewLastLanguageId,
        idReminder,
        is_report
      );
      if ((response.status === 201 || response.status === 200) && response?.data) {
        this.previewEmailText = response.data;
        this.changeOpenPreviewEmail(true);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getAllResendEmail = async (surveyId: number, groupCode: string) => {
    try {
      MainStore.changeLoader(true);
      const entityID = Number(localStorage.getItem("idEntity"));
      let response;

      switch (groupCode) {
        case REMINDER_RECIPIENT_GROUP_CODE.MANAGER:
          response = await getManagersRecipients(
            surveyId,
            entityID,
            this.activePage,
            this.countPerPage
          );
          break;
        default:
          response = await getAllEmployeesNoRecipients(
            surveyId,
            "",
            [],
            this.activePage,
            this.countPerPage,
            "",
            true
          );
          break;
      }

      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        runInAction(() => {
          MainStore.changeLoader(false);
          this.resendEmailsList = response?.data?.recipients ?? [];
          this.countTotal = response?.data?.count ?? 0;
        });
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  resendEmail = async () => {
    try {
      MainStore.changeLoader(true);

      const data = this.ReminderData[this.openReminderIndex];
      const shouldResendToManagers = this.selectedReminderGroupCode === "managers";

      let emailsString = [];

      if (shouldResendToManagers) {
        emailsString = this.resendEmailsList
          .filter((item) => {
            return this.selectedResendEmail.find((id) => id === item.id);
          })
          .map((item) => item.email);
      }

      const response = await useResendEmails(
        this.selectedResendEmail,
        emailsString,
        data.id_comms_reminder,
        shouldResendToManagers
      );
      if (response.status === 200 || response.status === 201) {
        MainStore.setSnackbar("Your reminder email has been sent", "success");

        this.reminderDataSaved = false;
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);

      this.isOpenResendEmailPoup = false;
    }
  };

  handleTabChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    isExpanded ? (this.expandedTab = panel) : (this.expandedTab = false);
  };

  setMinDateTime = () => {
    if (this.surveyDateEnd) {
      return this.surveyDateEnd.add(1, "minute");
    }
    return dayjs().add(15, "minute");
  };

  handleTabSecondAccordionChange = (event: React.SyntheticEvent, newValue: number) => {
    this.valueTab = newValue;
  };
}

const store = new Store();
export default store;

import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CheckDescIcon } from "./CheckDescIcon";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { Checkbox, Dialog, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import CustomInput from "components/input/Input";
import CustomButton from "components/button/Button";
import DialogContent from "@mui/material/DialogContent";
import SendOutSurveys from "./SendOutSurveys";
import SurveyFrequency from "./SurveyFrequency";
import SurveyMillstone from "./SurveyMillstone";
import TriggerAddRecipients from "./TriggerAddRecipients";
import ConfidentialityThreshold from "./ConfidentialityThreshold";
import { createUrl } from "helpers/url";

type CreateProjectPopupProps = {
  open: boolean;
  handleHide: (reload?: boolean) => void;
};

const CreateProjectPopup: FC<CreateProjectPopupProps> = observer((props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.open) store.loadAttributes();
  }, [props.open]);

  useEffect(() => {
    if (props.open) store.millstoneDays.sort((a, b) => a.days - b.days);
  }, [store.millstoneDays, props.open]);

  return (
    <StyledDialog id="Survey_Project_CreatePopup" open={props.open}>
      <CloseBtnWrapper>
        <CloseButton
          id="Survey_Project_CreatePopup_ClosePopup"
          onClick={() => {
            props.handleHide();
            store.clearStore();
          }}
        >
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <DialogTitle id="Survey_Project_CreatePopup_Title">New project</DialogTitle>
      <StyledCustomInput
        id="Survey_Project_CreatePopup_Input_NewProjectName"
        variant="standard"
        placeholder="New project name"
        value={store.name}
        error={store.errorname !== ""}
        helperText={store.errorname}
        onChange={(e) => store.setData(e.target.value, "name")}
      />
      <StyledDialogContent>
        <CheckWrapper>
          <CheckBoxContainer>
            <StyledFormControlLabel
              control={
                <StyledCheckBox
                  id="Survey_Project_CreatePopup_CheckBox_TestProject"
                  checked={store.isTestProject}
                  onChange={() => store.setData(!store.isTestProject, "isTestProject")}
                />
              }
              label={
                <span id="Survey_Project_CreatePopup_CheckBox_TestProject_Label">
                  This is a test project
                </span>
              }
            />
          </CheckBoxContainer>
          <CheckBoxDesc>
            <CheckDescIcon />
            <CheckBoxDescText id="Survey_Project_CreatePopup_TestProject_Discription">
              Help us make better recommendations for your company. Test project data will not be
              considered for benchmarks and analyses to enhance the scientific rigor of insights
              generated.
            </CheckBoxDescText>
          </CheckBoxDesc>
        </CheckWrapper>
        {!store.isAutoTriggerDisabled && (
          <>
            <Header id="Survey_Project_CreatePopup_TriggerRecipients_Discription">
              Set a trigger to add recipients to projects
            </Header>
            <TriggerAddRecipients />
          </>
        )}
        <Header id="Survey_Project_CreatePopup_SendSurveys_Discription">Survey project type</Header>
        <br />
        <HeaderDescription id="Survey_Project_CreatePopup_SendSurveys_Warning">
          This setting cannot be changed once the project has been created.
        </HeaderDescription>
        <SendOutSurveys />
        {store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency && <SurveyFrequency />}
        {store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Milestones && <SurveyMillstone />}
        <Header id="Survey_Project_CreatePopup_SendSurveys_Confidentiality_Title">
          Confidentiality threshold
        </Header>
        <ConfidentialityThreshold />
        <SmallText id="Survey_Project_CreatePopup_SendSurveys_Confidentiality_Discription">
          Each segment will need at least{" "}
          <b>{store.minResponses > 0 ? store.minResponses : "1"} employees</b> in order to view
          results
        </SmallText>
        <ButtonWrapper>
          <CreateCustomButton
            id="Survey_Project_CreatePopup_Create_Button"
            onClick={() => {
              store.saveProject(() => {
                props.handleHide(true);

                if (store.newSurveyId !== 0) {
                  navigate(
                    createUrl({
                      path: "/survey",
                      query: {
                        id: store.newSurveyId.toString(),
                      },
                    })
                  );
                }
                store.clearStore();
              });
              localStorage.setItem("surveyAction", "create");
            }}
            variant="contained"
            type="submit"
            disabled={
              (store.isErrorFrequensyDate &&
                store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency) ||
              store.sendOutSurveysType === "" ||
              store.errorname !== ""
            }
          >
            Create
          </CreateCustomButton>
          <CancelCustomButton
            id="Survey_Project_CreatePopup_Cancel_Button"
            onClick={() => {
              props.handleHide();
              store.clearStore();
            }}
            variant="outlined"
            type="reset"
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default CreateProjectPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 660px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0 30px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0;
  padding: 10px 30px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const StyledCustomInput = styled(CustomInput)`
  width: calc(100% - 60px);
  margin: 24px 30px 10px 30px !important;
  height: 44px;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--colorNeutralForeground5);
  }

  :before {
    border-bottom: 1px solid var(--colorPaletteBlueBackground1) !important;
  }
`;

const CheckWrapper = styled.div`
  margin: 44px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CheckBoxContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  padding: 12px 10px;
`;

const CheckBoxDesc = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 32px 1fr;
  margin-top: 10px;
`;

const CheckBoxDescText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const Header = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const HeaderDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0 60px 0;
`;

const CreateCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 130px;
  height: 34px;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  color: var(--colorNeutralForeground2) !important;
  border-color: var(--colorNeutralForeground2) !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

import http from "api/https";
import { createUrl } from "helpers/url";
import { API_TEMPLATE_URL_GO } from "constants/config";

type AddEditImportStatusProps = {
  id?: number | undefined;
  entity_id: number;
  import_type: string;
  status: string;
  import_id: string;
};

export const useAddEditImportStatus = async (props: AddEditImportStatusProps) => {
  const url = createUrl({
    baseUrl: `${API_TEMPLATE_URL_GO}`,
    path: "/api/v1/employee/employees/AddEditImportStatus",
  });

  const response = await http.postGO(url, props);

  return response;
};

import { observer } from "mobx-react";
import styled from "styled-components";
import store from "../store";
import TablePermission from "./TablePermission";
import CircularProgress from "@mui/material/CircularProgress";
import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CustomSearchField from "components/customSearchField";

const MainScreen = observer(() => {
  return (
    <>
      <FlexJustifyBetweenBox>
        <Header id="SurveyPermissions_Title">Survey Permissions</Header>
        <CustomSearchField
          id="SurveyPermissions_SearchInput"
          placeholder="Search by survey project name"
          searchedValue={store.surveySearchedValue}
          onKeyDown={store.surveyKeyPress}
          onChange={(e) => store.changeSurveySearchedValue(e.target.value)}
          onClearClick={() => store.onClearSurveySearchClicked()}
        />
      </FlexJustifyBetweenBox>

      {store.surveyResultSearchValue && (
        <SearchResultContainer>
          <SearchInformation>
            {store.surveyDataCount} results found for {`"${store.surveyResultSearchValue}"`}
          </SearchInformation>
          <SearchClearContainer
            onClick={() => {
              store.onClearSurveySearchClicked();
            }}
          >
            <IconButton id="EntityPermissions_SurvePermission_SearchInformation_ClearBtn">
              <CloseIcon
                style={{ color: "var(--colorBrandForeground1)", width: "14px", height: "14px" }}
              />
            </IconButton>
            <label>Clear search</label>
          </SearchClearContainer>
        </SearchResultContainer>
      )}

      {!store.surveyPermissionIsFetched ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <TablePermission />
      )}
    </>
  );
});

const FlexJustifyBetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 41px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchResultContainer = styled.div`
  background-color: var(--colorPalleteLightBlue);
  border-radius: 4px;
  padding: 8px 24px 8px 24px;
  display: flex;
  column-gap: 16px;
  align-items: center;
  margin-bottom: 16px;
`;

const SearchInformation = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const SearchClearContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  label {
    color: var(--colorBrandForeground1);
    cursor: pointer;
  }
`;

export default MainScreen;

import { useEffect, useState } from "react";
import EventStore from "features/Notification/store";

const Notification = () => {
  const [eventSourceHasSet, setEventSourceHasSet] = useState(false);

  useEffect(() => {
    if (!EventStore.eventSource && eventSourceHasSet) {
      EventStore.initializeEventSource();
    }

    setEventSourceHasSet(true);
    return () => {
      EventStore.closeEventSource();
    };
  }, [eventSourceHasSet]);

  return <></>;
};

export default Notification;

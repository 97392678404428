import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { TOthersDimensionQuestion } from "types/dashboard";

import styled from "styled-components";
import topStore from "../../store";
import Accordion from "@mui/material/Accordion";
import MessageIcon from "@mui/icons-material/Message";
import PaginationList from "../PaginationList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Info } from "@mui/icons-material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

type QuestionOptionCardProps = {
    question: TOthersDimensionQuestion;
    isLastQuestion: boolean;
    localFilters: { id: number; name: string; segments: string[] }[];
    isExpand: boolean | null;
    className?: string; 
    disableInteraction?: boolean;
};

const QuestionOptionCard: FC<QuestionOptionCardProps> = observer((props) => {
    const [isOthersQuestionExpanded, setIsOthersQuestionExpanded] = useState<boolean>(props.isExpand ?? false);
  
    const [otherCommentsList, setOtherCommentsList] = useState<string[]>([]);

  
    useEffect(() => {
      const getData = async () => {
        if (isOthersQuestionExpanded && topStore.selectedSurvey && props.question.id) {
          setOtherCommentsList(props.question.otherOptionsReponses ?? []);
        }
      };
  
      getData();
    }, [
      isOthersQuestionExpanded,
      topStore.selectedSurvey,
      topStore.overviewBenchmark
    ]);
    
    const meaninglessCount = props.question.otherOptionsMeaninglessCount || 0;

    const renderminMaxValues = () => {
      const isTypeMinNumber = typeof props.question.min === "number";
      const isTypeMaxNumber = typeof props.question.max === "number";
  
      return `MCQ: Multiple selection allowed 
        ${(isTypeMinNumber || isTypeMaxNumber) && `(`}
        ${isTypeMinNumber && `Minimum: ${props.question.min}`}
        ${isTypeMinNumber && isTypeMaxNumber && `, `}
        ${isTypeMaxNumber && `Maximum: ${props.question.max}`}
        ${(isTypeMinNumber || isTypeMaxNumber) && `)`}
  `;
    };
  
    return (
      props.question.otherOptionsReponsesCount > 0 && (
        <Container $isLast={props.isLastQuestion}>
          <StyledAccordion
            expanded={isOthersQuestionExpanded}
            onChange={() => setIsOthersQuestionExpanded(!isOthersQuestionExpanded)}
            className= {props.className ? `${props.className}-other-comment` : null}
            disabled={props.disableInteraction}
          >
            <AccordionSummary aria-controls="others-question-accordion" id="others-question">
              <AccordionTitle>
                <AccordionTitleGroup>
                  <StyledMessageIcon />
                  <AccordionTitleText>
                    Responses for 'Others' option ({props.question.otherOptionsReponsesCount})
                  </AccordionTitleText>
                </AccordionTitleGroup>
                {!props.disableInteraction && (
                  <RightContainer>
                    {isOthersQuestionExpanded && meaninglessCount > 0 && (
                      <ResponsesNotShownContainer>
                        <NilCountText>{meaninglessCount} responses are not shown</NilCountText>
                        <TooltipWrapper>
                          <CustomWidthTooltip 
                            title="These responses are hidden because they are less meaningful, for example 'nil' or 'no comment'."
                            placement="top"
                            arrow
                          >
                            <StyledInfoIcon fontSize="small" />
                          </CustomWidthTooltip>
                        </TooltipWrapper>
                      </ResponsesNotShownContainer>
                    )}
                    <AccordionToogleButton>
                      {isOthersQuestionExpanded ? "Hide" : "Show"}
                      <AccordionToogleButtonLabel>
                      {isOthersQuestionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </AccordionToogleButtonLabel>
                    </AccordionToogleButton>
                  </RightContainer>
                )}
              </AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionContent>
                <PaginationList
                  list={otherCommentsList}
                  disableInteraction={props.disableInteraction}
                />
              </AccordionContent>
            </AccordionDetails>
          </StyledAccordion>
        </Container>
        )  
    );
  });


export default QuestionOptionCard;
  
  
   const Container = styled.div<{ $isLast: boolean}>`
    width: 100%;
    padding-bottom: 48px;
    ${(props) => !props.$isLast && "border-bottom: 1px solid var(--colorPaletteBlueBackground1)"};
    margin-top: 24px;
  `;

  const AccordionTitle = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 0px;
  `;
  
  const StyledMessageIcon = styled(MessageIcon)`
      color: var(--colorNeutralForeground5);
      font-size: 16px !important;
  `;
  
  const AccordionTitleText = styled.p`
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--colorNeutralForeground1);
      margin-left: 6px;
  `;
  
  const AccordionToogleButton = styled.button`
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--colorBrandForeground1);
      border: none;
      cursor: pointer;
      background-color: transparent;
      display: flex;
      align-items: center;
  `;
  
  const StyledAccordion = styled(Accordion)`
      margin-top: 8px;
      border-radius: 10px !important;
      border: 1px solid var(--colorPaletteBlueBackground1) !important;
      box-shadow: 0px 2px 8px 0px var(--colorNeutralBackground3) !important;
      background-color: var(--colorNeutralBackground1) !important;
  
      &::before {
          display: none !important;
      }
  
      .MuiTabs-root {
          min-height: 0 !important;
          height: 40px !important;
      }
  
      .Mui-disabled {
          opacity: 1 !important;
          background-color: var(--colorNeutralBackground1) !important;
      }
  
      .MuiTabScrollButton-root {
          border: 1px solid var(--colorPaletteBlueBackground1) !important;
      }
  `;
  
  const AccordionTitleGroup = styled.div`
      display: flex;
      align-items: center;
  `;
  
  const AccordionToogleButtonLabel = styled.span`
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: 0px 2px 5px 0px var(--colorNeutralBackground3);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
  `;
  
  const AccordionContent = styled.div`
      width: 100%;
      padding: 24px;
      background-color: var(--colorNeutralForeground8);
  `;

const ResponsesNotShownContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const NilCountText = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
`;

const TooltipWrapper = styled.span`
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
`;

const StyledInfoIcon = styled(Info)`
  width: 14px;
  height: 14px;
  color: var(--colorPaletteVioletBackground2);
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 200,
    padding: '8px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  }
});

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
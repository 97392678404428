import http from "api/https";
import { createUrl } from "../../helpers/url";

export const revokeUsersPermission = (
  emails: string[],
  entityId: number,
): Promise<any> => {
  const data: {
    emails: string[],
    entity_id: number,
  } = {
    emails: emails,
    entity_id: entityId,
  };

  const url = createUrl({
    path: "/permission/entity_permissions/RevokeUsers"
  });
  return http.postGO(url, data);
};

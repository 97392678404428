import { createAuthService, Environment, Platform } from "@engagerocketco/auth-sdk";
import { API_NO_GATEWAY_URL } from "constants/config";
import { ImportStatusType } from "constants/import-status.type";
import { EventSourcePolyfill } from "event-source-polyfill";
import { makeAutoObservable } from "mobx";
import { ImportStatusTypeKeys } from "types/import-status-type-key";
import { Metadata } from "types/sse-event";

export type ImportReview = {
  countAdd: number;
  countUpdate: number;
  countDelete: number;
};

export type EventSSE = {
  type: ImportStatusTypeKeys;
  metadata: Metadata;
};

class Store {
  eventSource?: EventSourcePolyfill | null = null;

  latestType: ImportStatusTypeKeys | null = null;

  employeeImportEvent: EventSSE | null = null;

  currentEntityId: number;

  constructor() {
    this.currentEntityId = Number(localStorage.getItem("idEntity"));

    makeAutoObservable(this);
  }

  setEventSource(eventSource: EventSourcePolyfill) {
    this.eventSource = eventSource;
  }

  closeEventSource() {
    if (this.eventSource) {
      this.eventSource.close();
    }
    this.eventSource = null;
  }

  onMessage = (event: MessageEvent) => {
    if (event.data === "ping") {
      return;
    }

    const data: EventSSE = JSON.parse(event.data);

    if (data.type && this.isEmployeeImportStatus(data.type)) {
      this.employeeImportEvent = data;
    }
  };

  onError = (event: Event) => {};
  onOpen = (event: Event) => {};

  initializeEventSource = (useV2 = true) => {
    const token = createAuthService({
      platform: Platform.MAIN,
      environment: process.env.REACT_APP_ENVIRONMENT as Environment,
    }).getStoredTokens().accessToken;

    const url = useV2
      ? `${API_NO_GATEWAY_URL}/communication/eventsV2?entity_id=${this.currentEntityId}`
      : `${API_NO_GATEWAY_URL}/communication/events?entity_id=${this.currentEntityId}`;
    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    eventSource.onmessage = this.onMessage;
    eventSource.onerror = this.onError;
    eventSource.onopen = this.onOpen;

    this.setEventSource(eventSource);
  };

  isEmployeeImportStatus = (status: string) => {
    return [
      ImportStatusType.EXCEL_ONGOING.valueOf(),
      ImportStatusType.EXCEL_UPLOAD_SUCCESS.valueOf(),
      ImportStatusType.EXCEL_UPLOAD_FAILED.valueOf(),
      ImportStatusType.EXCEL_CANCELED.valueOf(),
      ImportStatusType.EXCEL_IMPORT_ONGOING.valueOf(),
      ImportStatusType.EXCEL_IMPORT_SUCCESS.valueOf(),
      ImportStatusType.EXCEL_IMPORT_FAILED.valueOf(),
      ImportStatusType.PROGRESS_EXCEL_IMPORT_ONGOING.valueOf(),
      ImportStatusType.PROGRESS_EXCEL_ONGOING.valueOf(),
    ].includes(status);
  };
}

const store = new Store();
export default store;

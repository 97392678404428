import { CreatorBase } from "survey-creator-core";
import { SurveyCreator } from "survey-creator-react";
import { QuestionRatingModel } from "survey-core";
import {
  AddedFromButtonOptions,
  ElementCopiedOptions,
  OnModifiedOptions,
  PropertyChangedOptions,
} from "./types";
import { first, debounce } from "lodash";
import setSyncableRatingScaleProperties from "../setSyncableRatingScaleProperties";
import { SYNCABLE_RATING_LOCALIZABLE_PROPERTIES } from "./surveyJsConstants";

export enum QuestionType {
  comment = "comment",
  rating = "rating",
  checkbox = "checkbox",
  boolean = "boolean",
  radiogroup = "radiogroup",
  enps = "eNPS",
}
const syncableProperties = ["rateCount", "rateType"];

export function handleSyncRatingScaleProperties({
  creator,
}: {
  creator: SurveyCreator;
}): (sender: CreatorBase, options: OnModifiedOptions<QuestionRatingModel>) => void {
  const onModifiedCallback = (
    sender: CreatorBase,
    options: OnModifiedOptions<QuestionRatingModel>
  ) => {
    if (
      (options.type === "ADDED_FROM_PAGEBUTTON" || options.type === "ELEMENT_COPIED") &&
      options.question.getType() === QuestionType.rating
    ) {
      applyRatingScalePropertiesToNewQuestion({ creator, sender, options });
      return;
    }

    if (options.type === "PROPERTY_CHANGED" && options.target.getType() === QuestionType.rating) {
      syncRatingScaleProperties({ creator, sender, options });
      return;
    }
  };

  return debounce(onModifiedCallback, 1000);
}

function applyRatingScalePropertiesToNewQuestion({
  creator,
  sender,
  options,
}: {
  creator: SurveyCreator;
  sender: CreatorBase;
  options: AddedFromButtonOptions<QuestionRatingModel> | ElementCopiedOptions<QuestionRatingModel>;
}) {
  const surveyCreatorCopy = new SurveyCreator();
  surveyCreatorCopy.JSON = sender.survey.toJSON();

  const sampleRatingScaleQuestions = first(
    surveyCreatorCopy.survey
      .getAllQuestions()
      .filter(
        (question) =>
          question.getType() === QuestionType.rating && question.name !== options.question.name
      ) as QuestionRatingModel[]
  );

  if (!sampleRatingScaleQuestions) {
    return;
  }

  const targetQuestion = surveyCreatorCopy.survey.getQuestionByName(
    options.question.name
  ) as QuestionRatingModel;

  setSyncableRatingScaleProperties(sampleRatingScaleQuestions, targetQuestion);

  if (sampleRatingScaleQuestions.minRateDescription === "") {
    targetQuestion.minRateDescription = undefined;
  }

  creator.JSON = surveyCreatorCopy.survey.toJSON();
}

function syncRatingScaleProperties({
  creator,
  sender,
  options,
}: {
  creator: SurveyCreator;
  sender: CreatorBase;
  options: PropertyChangedOptions<QuestionRatingModel>;
}) {
  const isSyncableProperty = syncableProperties.some((property) => property === options.name);
  const isSyncableLocalizableProperty = SYNCABLE_RATING_LOCALIZABLE_PROPERTIES.some(
    (property) => property === options.name
  );

  if (!isSyncableProperty && !isSyncableLocalizableProperty) {
    return;
  }

  const surveyCreatorCopy = new SurveyCreator();
  surveyCreatorCopy.JSON = sender.survey.toJSON();

  const ratingScaleQuestions = surveyCreatorCopy.survey
    .getAllQuestions()
    .filter(
      (question) =>
        question.getType() === QuestionType.rating && question.name !== options.target.name
    ) as QuestionRatingModel[];

  const currentQuestion = surveyCreatorCopy.survey.getQuestionByName(
    options.target.name
  ) as QuestionRatingModel;

  ratingScaleQuestions.forEach((question) => {
    if (!currentQuestion.minRateDescription) {
      question.minRateDescription = undefined;
    }
    if (isSyncableProperty || isSyncableLocalizableProperty) {
      setSyncableRatingScaleProperties(options.target, question);
    }
  });

  setTimeout(() => {
    creator.JSON = surveyCreatorCopy.survey.toJSON();
    creator.selectElement(creator.survey.getQuestionByName(options.target.name));
  }, 0);
}

import { FC, useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import styled from "styled-components";
import CommunicationRolesLookup from "components/communicationRolesLookup";
import RichTextWithTabsAll from "../../RichTextWithTabsAll";
import CustomButton from "components/button/Button";
import CustomSwitch from "components/switch/Switch";
import PreviewIcon from "../../PreviewIcon";
import LetterIcon from "./LetterIcon";
import store from "./../../store";
import CustomDateField from "components/customDateField";
import CustomTimeField from "components/customTimeField";
import { formatToLocalDateTime } from "utils/timeFormatter";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

type FirstTabProps = {};

const FirstTab: FC<FirstTabProps> = observer(() => {
  const [datePart, setDatePart] = useState<Dayjs | null>(null);
  const [timePart, setTimePart] = useState<Dayjs | null>(null);

  const [dateError, setDateError] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);
  const [dateErrorMessage, setDateErrorMessage] = useState("");
  const [timeErrorMessage, setTimeErrorMessage] = useState("");

  const messages = useMemo(
    () => ({
      [SEND_OUT_SURVEY_TYPES.OneOff]:
        "We recommend sending this email after collecting responses from survey recipients.",
      [SEND_OUT_SURVEY_TYPES.Frequency]:
        "We recommend sending this email after collecting all responses from your survey recipients.",
      [SEND_OUT_SURVEY_TYPES.Milestones]:
        "We recommend sending this email after collecting all responses from your survey recipients.",
    }),
    []
  );

  const initiateTimeSendReport = () => {
    const date = store.timeSendReport?.startOf("day") || dayjs().startOf("day");

    store.setupMinTime(date, "timeSendReport");

    const time = store.timeSendReport ? dayjs(store.timeSendReport.format("HH:mm"), "HH:mm") : null;

    setDatePart(date);
    setTimePart(time);
  };

  useEffect(() => {
    if (store.AllInfo?.is_send_report) {
      initiateTimeSendReport();
    } else {
      store.changeValue("timeSendReport", null);
    }
  }, [store.AllInfo?.is_send_report]);

  useEffect(() => {
    if (store.selectedTime["timeSendReport"] !== timePart) {
      setTimePart(store.selectedTime["timeSendReport"]);

      if (store.isTimeSendNeedIncreased) {
        store.setData("isTimeSendNeedIncreased", false);
      }
    }
  }, [store.selectedTime["timeSendReport"]]);

  useEffect(() => {
    if (store.selectedDate["timeSendReport"] !== datePart) {
      setDatePart(store.selectedDate["timeSendReport"]);
    }
  }, [store.selectedDate["timeSendReport"]]);

  useEffect(() => {
    if (store.isTimeSendNeedIncreased) {
      handleTimeError(true, "start email must be 15 minutes from now or later.");
      return;
    }

    handleTimeError(false, "");
  }, [store.isTimeSendNeedIncreased]);

  const handleDateError = (isError: boolean, message: string) => {
    setDateError(isError);
    setDateErrorMessage(message);
  };

  const handleTimeError = (isError: boolean, message: string) => {
    setTimeError(isError);
    setTimeErrorMessage(`You can only schedule this email ${message}`);
  };

  const handleSwitchSendReport = () => {
    store.changeReportIsSendReport(store.AllInfo ? !store.AllInfo.is_send_report : false);

    if (!store.AllInfo.is_send_report) {
      store.changeValue("time_send_report", null);

      setDatePart(null);
      setTimePart(null);
      setDateError(false);
      setTimeError(false);
    }
  };

  return (
    <>
      <Wrapped>
        <Header id="Survey_EditCreate_Communication_SendReport_Title">
          Inform Report owners of their dashboard access
        </Header>
        <HeaderDescription>
          The dashboard allows Report owners to see real-time data for survey results.
        </HeaderDescription>

        <TopContentWrapper>
          <SwitchDiv>
            <SwitchLabel id="Survey_EditCreate_Communication_SendReport_Label">
              Schedule to send email
            </SwitchLabel>
            <CustomSwitch
              checked={store.AllInfo ? store.AllInfo.is_send_report : false}
              onChange={handleSwitchSendReport}
            />
          </SwitchDiv>

          {store.AllInfo?.is_send_report && (
            <>
              <SendEmailQuestion>WHEN SHOULD WE SEND THE EMAIL?</SendEmailQuestion>
              <SendEmailInfoWrapper>
                <img src={"/assets/icons/notice-gray.svg"} width={16} height={16} alt="notice" />
                <SendEmailInfoContainer>
                  <SendEmailInfoParagraph>
                    {messages[store.AllInfo?.project_type]}
                  </SendEmailInfoParagraph>

                  {store.AllInfo?.project_type !== SEND_OUT_SURVEY_TYPES.Milestones && (
                    <div>
                      <SendEmailInfoParagraph>
                        Survey start:{" "}
                        {store.AllInfo?.survey_date_start
                          ? formatToLocalDateTime(store.AllInfo?.survey_date_start)
                          : "Not scheduled"}
                      </SendEmailInfoParagraph>
                      <SendEmailInfoParagraph>
                        Survey end:{" "}
                        {store.AllInfo?.survey_date_end
                          ? formatToLocalDateTime(store.AllInfo?.survey_date_end)
                          : "Not scheduled"}
                      </SendEmailInfoParagraph>
                    </div>
                  )}
                </SendEmailInfoContainer>
              </SendEmailInfoWrapper>

              <ScheduleWrapper>
                <SendEmailScheduleWrapper>
                  <CustomDateField
                    id="Survey_EditCreate_Communication_SendReport_SendAfter_TimeZone_Container"
                    value={datePart}
                    changeValue={(value: Dayjs) => {
                      store.mergeDateAndTime(value, null, "timeSendReport");
                      store.changeValue("changedReportTab", true);
                    }}
                    onError={handleDateError}
                  />
                  <CustomTimeField
                    value={timePart}
                    changeValue={(value: Dayjs) => {
                      store.mergeDateAndTime(datePart, value, "timeSendReport");
                      store.changeValue("changedReportTab", true);
                    }}
                    minTime={store.minTime["timeSendReport"]}
                    pairWithDate={true}
                    onError={handleTimeError}
                  />
                </SendEmailScheduleWrapper>

                {dateError && <LabelErrorMessage>{dateErrorMessage}</LabelErrorMessage>}
                {timeError && <LabelErrorMessage>{timeErrorMessage}</LabelErrorMessage>}
              </ScheduleWrapper>
            </>
          )}

          {store.AllInfo && <CommunicationRolesLookup />}
        </TopContentWrapper>

        {store.AllInfo && (
          <>
            <SubHeader id="Survey_EditCreate_Communication_SendReport_EmailSubject_Title">
              EMAIL SUBJECT
            </SubHeader>

            <RichTextWithTabsAll
              editorId="Survey_EditCreate_Communication_SendReport_EmailSubject"
              onlyText
              data={store.ReportData}
              width={100}
              changeValue={store.changeReportData}
              field="subject_email"
            />

            <SubHeader id="Survey_EditCreate_Communication_SendReport_EmailContent_Title">
              EMAIL CONTENT
            </SubHeader>

            <RichTextWithTabsAll
              editorId="Survey_EditCreate_Communication_SendReport_EmailContent"
              data={store.ReportData}
              width={200}
              changeValue={store.changeReportData}
              field="body_email"
            />
          </>
        )}

        <BottomWrapper>
          <ButtonWrapper>
            <ButtonWr>
              <StyledNextButton
                id="Survey_EditCreate_Communication_SendReport_Save_Button"
                variant="contained"
                disabled={!store.changedReportTab || dateError || timeError}
                onClick={() => store.saveReportData()}
              >
                Save changes
              </StyledNextButton>
            </ButtonWr>
            <ButtonWr>
              <StyledCancelButton
                id="Survey_EditCreate_Communication_SendReport_Cancel_Button"
                variant="outlined"
                disabled={!store.changedReportTab}
                onClick={() => store.onCancelReportTab()}
              >
                Cancel
              </StyledCancelButton>
            </ButtonWr>
          </ButtonWrapper>

          <ButtonWrapper>
            <LinkPreview
              id="Survey_EditCreate_Communication_SendReport_PreviewEmail_Button"
              onClick={() => store.loadPreviewMessage(null, true)}
            >
              <PreviewIcon />
              <LinkPreviewText id="Survey_EditCreate_Communication_SendReport_PreviewEmail_Button_Text">
                Preview email
              </LinkPreviewText>
            </LinkPreview>

            {store.timeSendReport && store.timeSendReport.toDate() < dayjs().toDate() && (
              <ResendCustomButton
                id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Button"
                onClick={() => store.setData("isOpenResendEmailPoup", true)}
                variant="outlined"
              >
                <LetterIcon /> Resend email
              </ResendCustomButton>
            )}
          </ButtonWrapper>
        </BottomWrapper>
      </Wrapped>
    </>
  );
});

const ResendCustomButton = styled(CustomButton)`
  height: 36px;
  width: 180px;
  max-width: 190px !important;
  margin-left: 25px !important;

  :first-child {
    margin-right: 11px;
  }
`;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const HeaderDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const BottomWrapper = styled.div`
  width: 100%;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkPreviewText = styled.span`
  margin-left: 3px;
`;

const LinkPreview = styled.a`
  width: 108px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TopContentWrapper = styled.div`
  width: 488px;
  padding-bottom: 64px;
`;

const SwitchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 30px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  padding: 15px 16px 17px 16px;
  justify-content: space-between;
`;

const ButtonWr = styled.div`
  margin-right: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 75px;
  margin-left: 160px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const SubHeader = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
  margin-top: 30px;
  color: var(--colorNeutralForeground2);
`;

const SwitchLabel = styled.p`
  margin-left: 6px;
`;

const SendEmailQuestion = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
`;
const SendEmailInfoWrapper = styled.div`
  padding: 16px;
  background-color: var(--colorPalleteLightBlue);
  display: flex;
  column-gap: 6px;
  border-radius: 4px;
  margin-top: 20px;

  p {
    color: var(--colorNeutralForeground1);
  }
`;

const SendEmailScheduleWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

const ScheduleWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
`;

const SendEmailInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const SendEmailInfoParagraph = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

const LabelErrorMessage = styled.p`
  color: var(--colorPaletteRedForeground2);
  padding-top: 8px;
  font-size: 14px;
`;

export default FirstTab;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AlertBanner, { Notification } from "./banner";
import { useRealtimeNotification } from "api/useRealtimeNotification";
import { dismissNotification } from "api/useDismissNotification";
import { observer } from "mobx-react";
import store from "./workspaces-sidebar/StoreLayout";

interface SSEEvent {
  type: string;
  metadata: Notification;
}

interface SSEEventV2 {
  type: string;
  metadata: Notification[];
}

const BannerWrapper = observer(() => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const [allNotifications, setAllNotifications] = useState<Notification[] | null>(null);
  const [notificationIndex, setNotificationIndex] = useState(0);
  const [totalNotification, setTotalNotification] = useState(0);
  const [createEventSource, setCreateEventSource] = useState<any | null>(null);
  const location = useLocation();

  useEffect(() => {
    store.loadFeatureFlagMultiBannerStatus();
  }, []);

  useEffect(() => {
    const isMultiBannerEnabled =
      store.featureFlagMultiBanner.enabled && store.featureFlagMultiBanner.multi_banner_enabled;
    if (isMultiBannerEnabled) {
      setCreateEventSource(useRealtimeNotification(isMultiBannerEnabled));
    } else {
      setCreateEventSource(useRealtimeNotification(false));
    }
  }, [store.featureFlagMultiBanner]);

  useEffect(() => {
    if (!createEventSource) {
      return;
    }

    let eventSource = createEventSource;

    eventSource.onmessage = (event) => {
      try {
        if (
          store.featureFlagMultiBanner?.enabled &&
          store.featureFlagMultiBanner?.multi_banner_enabled
        ) {
          const data: SSEEventV2 = JSON.parse(event.data);

          if (data.type === "NEW_NOTIFICATION" || data.type === "DISMISS_NOTIFICATION") {
            setAllNotifications(data.metadata);

            if (data.metadata) {
              setNotification(data.metadata[notificationIndex]);
              setTotalNotification(data.metadata.length);
            } else {
              setNotification(null);
              setNotificationIndex(0);
              setTotalNotification(0);
            }
          }
        } else {
          const data: SSEEvent = JSON.parse(event.data);

          if (data.type === "NEW_NOTIFICATION" || data.type === "DISMISS_NOTIFICATION") {
            setNotification(data.metadata);
          }
        }
      } catch (error) {
        console.error("Error parsing SSE event:", error);
      }
    };

    eventSource.onerror = (error) => {
      console.error("SSE connection error:", error);
      eventSource?.close();

      const timeoutId = setTimeout(() => {
        eventSource = createEventSource;
      }, 5000);

      return () => clearTimeout(timeoutId);
    };

    return () => {
      eventSource?.close();
    };
  }, [createEventSource]);

  const handleDismiss = async (id: string) => {
    try {
      dismissNotification(id);

      if (
        !(
          store.featureFlagMultiBanner?.enabled &&
          store.featureFlagMultiBanner?.multi_banner_enabled
        )
      ) {
        setNotification(null);
        return;
      }

      let currentNotification = allNotifications;
      currentNotification.splice(notificationIndex, 1);

      if (currentNotification.length === 0) {
        setNotification(null);
        setAllNotifications(null);
        setNotificationIndex(0);
        setTotalNotification(0);
        return;
      }

      setAllNotifications(currentNotification);
      setTotalNotification(currentNotification.length);
      setNotification(currentNotification[0]);
      setNotificationIndex(0);
    } catch (error) {
      console.error("Error dismissing notification:", error);
    }
  };

  const handleUpdateIndex = async (action: string) => {
    try {
      if (action === "Increase" && notificationIndex < totalNotification - 1) {
        const index = notificationIndex + 1;

        setNotificationIndex(index);
        setNotification(allNotifications[index]);
      } else if (action === "Decrease" && notificationIndex > 0) {
        const index = notificationIndex - 1;

        setNotificationIndex(index);
        setNotification(allNotifications[index]);
      }
    } catch (error) {
      console.error("Error updating index notification:", error);
    }
  };

  const shouldShowBanner = !location.pathname.includes("import-historical-survey");

  return (
    <div className="flex flex-col min-h-screen">
      {notification && !notification.dismissed_at && shouldShowBanner && (
        <AlertBanner
          data={notification}
          dataIndex={notificationIndex}
          totalData={totalNotification}
          onDismiss={handleDismiss}
          updateIndex={handleUpdateIndex}
        />
      )}
    </div>
  );
});

export default BannerWrapper;

import { observer } from "mobx-react";
import { Transition } from "./TransitionPopup";
import { THotspotsQuestion } from "types/dashboard";
import { FC, useEffect, useState } from "react";

import store from "../store";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ErrorIcon from "@mui/icons-material/Error";
import ClearIcon from "@mui/icons-material/Clear";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import QuestionsList from "./QuestionsList";
import SelectColumnsType from "./SelectColumnsType";

type SetUpColumnsPopupProps = {};

const SetUpColumnsPopup: FC<SetUpColumnsPopupProps> = observer(() => {
  const [questionsList, setQuestionsList] = useState<THotspotsQuestion[]>([]);
  const [isOveralScore, setIsOveralScore] = useState<boolean>(false);
  const [isLocalENPSColumnVisible, setIsLocalENPSColumnVisible] = useState<boolean>(store.isShowENPSColumn);

  const changeIsOveralScore = (value: boolean) => setIsOveralScore(value);
  const changeIsShowENPSColumn = (value: boolean) => {
    setIsLocalENPSColumnVisible(value);
  };

  useEffect(() => {
    setIsOveralScore(store.isOveralScore);
    setIsLocalENPSColumnVisible(store.isShowENPSColumn);
    setQuestionsList(store.questionsList);
  }, [store.questionsList, store.isOveralScore, store.isShowENPSColumn]);

  useEffect(() => {
    if (!store.isOpenColumnsSetupPopup) {
      setIsOveralScore(store.isOveralScore);
      setIsLocalENPSColumnVisible(store.isShowENPSColumn);
      setQuestionsList(store.questionsList);
    }
  }, [store.isOpenColumnsSetupPopup, store.isOveralScore, store.isShowENPSColumn]);

  const viesScoresOnChart = () => {
    store.setData(isOveralScore, "isOveralScore");
    store.setData(isLocalENPSColumnVisible && store.isActiveEnps, "isShowENPSColumn");
    store.setData(questionsList, "questionsList");
    store.setData(false, "isOpenColumnsSetupPopup");
  };

  const chekAllQuestions = (sectionId: number, value: boolean) => {
    const newQuestionList = questionsList.map((el) => {
      if (el.dimensionId === sectionId) {
        const newList = el.list.map((question) => ({
          ...question,
          checked: value,
        }));
        return {
          ...el,
          list: newList,
        };
      } else return el;
    });
    setQuestionsList(newQuestionList);
  };

  const changeCheckQuestionsList = (value: boolean) => {
    const newQuestionList = questionsList.map((el) => {
      const newList = el.list.map((question) => ({
        ...question,
        checked: value,
      }));
      return {
        ...el,
        list: newList,
      };
    });
    setQuestionsList(newQuestionList);
  };

  const changeColSetupQuestion = (sectionId: number, questionId: number, value: boolean) => {
    const newQuestionList = questionsList.map((el) => {
      if (el.dimensionId === sectionId) {
        const newList = el.list.map((question) => {
          if (question.questionId === questionId) {
            const newQuestion = {
              ...question,
              checked: value,
            };
            return newQuestion;
          } else return question;
        });
        return {
          ...el,
          list: newList,
        };
      } else return el;
    });
    setQuestionsList(newQuestionList);
  };

  return (
    <StyledDialog open={store.isOpenColumnsSetupPopup} TransitionComponent={Transition}>
      <StyledDialogContent>
        <CloseBtnWrapper>
          <CloseButton onClick={() => store.setData(false, "isOpenColumnsSetupPopup")}>
            <ClearIcon />
          </CloseButton>
        </CloseBtnWrapper>
        <DialogTitle>Columns setup</DialogTitle>
        <SubTitle>Set up my columns as...</SubTitle>
        <SelectColumnsType />
        <QuestionsList
          isShowENPSColumn={isLocalENPSColumnVisible}
          questionsList={questionsList}
          isOveralScore={isOveralScore}
          changeIsOveralScore={changeIsOveralScore}
          changeIsShowENPSColumn={changeIsShowENPSColumn}
          chekAllQuestions={chekAllQuestions}
          changeColSetupQuestion={changeColSetupQuestion}
          changeCheckQuestionsList={changeCheckQuestionsList}
        />
      </StyledDialogContent>
      <DialogActions>
        <ViewScoresButton
          variant="contained"
          onClick={viesScoresOnChart}
          disabled={
            !isLocalENPSColumnVisible &&
            !isOveralScore &&
            !questionsList.some((el) => el.list.find((item) => item.checked))
          }
        >
          View scores on chart
        </ViewScoresButton>
        <BottomDescriptionContainer>
          <ErrorIcon />
          <BottomDescriptionText>
            Loading scores on the chart may experience delays when dealing with numerous data
            points. However, you can conveniently download the .xlsx file while waiting for the
            chart to load.
          </BottomDescriptionText>
        </BottomDescriptionContainer>
      </DialogActions>
    </StyledDialog>
  );
});

export default SetUpColumnsPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 586px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 48px !important;
  }

  .MuiPaper-root {
    height: 100% !important;
    max-height: none !important;
    margin: 0px !important;
    border-radius: 0px !important;
  }

  .MuiDialog-container {
    justify-content: flex-end !important;
  }

  .MuiDialogActions-root {
    padding: 24px 48px !important;
    flex-direction: column;
    box-shadow: 0px -2px 6px 0px var(--colorShadowInverted1);
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;
  color: var(--colorBrandForeground1);

  svg {
    font-size: 24px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const SubTitle = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-top: 16px;
`;

const ViewScoresButton = styled(Button)`
  width: 100%;
  text-transform: none !important;
`;

const BottomDescriptionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr;
  margin-top: 16px;
  column-gap: 4px;

  svg {
    font-size: 20px;
    color: var(--colorPaletteVioletBackground3);
  }
`;

const BottomDescriptionText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
`;

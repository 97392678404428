import { linearProgressClasses, LinearProgress, Typography } from "@mui/material";

import { Box } from "@mui/material";
import ProgressIcon from "components/icons/ProgressIcon";
import styled from "styled-components";

type ProgressCardProps = {
  title: string;
  progress: number;
};

const ProgressCard: React.FC<ProgressCardProps> = ({ title, progress }: ProgressCardProps) => {
  return (
    <FileBox display="flex" alignItems="start" padding={2} borderRadius={2}>
      <IconWrapper>
        <ProgressIcon />
      </IconWrapper>
      <ProgressCardTitle>
        <p>{title}</p>
      </ProgressCardTitle>
      <ProgressWrapper display="flex" alignItems="center">
        <BorderLinearProgress variant="determinate" value={progress} />
        <Typography variant="body2" style={{ marginLeft: 16 }}>
          <p>{`${progress}%`}</p>
        </Typography>
      </ProgressWrapper>
    </FileBox>
  );
};

const ProgressCardTitle = styled.div`
  p {
    color: #707882;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  margin-left: 16px;
`;

const IconWrapper = styled.div`
  margin-right: 16px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const FileBox = styled(Box)`
  width: 100%;
  border: 1px solid #cdd3ec;
  border-radius: 8px;
`;

const ProgressWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "130px",

  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E0E0E0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#7562FC",
  },
}));

export default ProgressCard;

import { FC, useState } from "react";
import { observer } from "mobx-react";
import { MultipleSelect } from "components/multiselect/MultiSelect";
import {
  Button,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';

import store from "../store";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { decodeUnicode } from "utils/decodeUnicode";
import UnitSelectionPopup from "../../UnitSelectionPopup";
import { TDashboardUnitFilter } from "types/dashboard";
import { getUnitName } from "utils/dashboard/useGetSegmentName";
import topStore  from "features/Dashboard/store";

type SettingsListProps = {
  localSelectedSegments: { attribute: string; mode: string; value: string | string[] }[];
  handleModeSelect: (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => void;
  handleAttributeSelect: (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => void;
  changeSegments: (changedIndex: number, field: string, value: any) => void;
  clearLastAttribute: (changedIndex: number) => void;
  removeFilter: (index: number) => void;
  clearSubsequentFilters: (fromIndex: number) => void;
};

const SettingsList: FC<SettingsListProps> = observer((props) => {
  const [openSelects, setOpenSelects] = useState<{ [key: number]: boolean }>({});
  const [showUnitPopup, setShowUnitPopup] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);

  const handleSelectOpen = (index: number) => {
    setOpenSelects((prev) => ({ ...prev, [index]: true }));
  };

  const handleSelectClose = (index: number) => {
    setOpenSelects((prev) => ({ ...prev, [index]: false }));
    // Only clear search if all selects are closed
    const areAllSelectsClosed = Object.values({ ...openSelects, [index]: false }).every(
      (isOpen) => !isOpen
    );
    if (areAllSelectsClosed) {
      store.setData("", "segmentSearchValue");
    }
  };

  const handleRemoveFilter = (index: number) => {
    
    props.removeFilter(index);
    props.clearSubsequentFilters(index);
    
    // Force UI update
    setOpenSelects(prev => ({...prev}));
    store.setData("", "segmentSearchValue");
  };

  const getSegmentsList = (filter: any, rowIndex: number) => {
    if (filter.attribute === "Unit") {
      const previousRow = props.localSelectedSegments[rowIndex - 1];
      const previousUnitId = previousRow?.attribute === "Unit" 
        ? (typeof previousRow.value === 'string' 
            ? previousRow.value 
            : Array.isArray(previousRow.value) && previousRow.value.length === 1 
              ? previousRow.value[0] 
              : null)
        : null;

      // Get all units in the hierarchy
      const allUnits = store.attributeList
        .find((el) => String(el.label) === "Unit")
        ?.list || [];

      if (rowIndex === 0) {
        return allUnits.map((item) => ({
          value: item.id.toString(),
          label: `${item?.name} (Level ${item.level})`,
        }));
      }

      // Find the previous unit
      const previousUnit = allUnits.find(item => item.id.toString() === previousUnitId);
      if (!previousUnit) return [];

      // Filter units that are descendants of the previous unit
      return allUnits
        .filter(item => {
          if (!item?.name) return false;
          
          // Check if this unit is a descendant of the selected unit
          let currentUnit = item;
          while (currentUnit) {
            if (currentUnit.parent_id === previousUnit.id) {
              return true;
            }
            currentUnit = allUnits.find(u => u.id === currentUnit.parent_id);
          }
          return false;
        })
        .map((item) => ({
          value: item.id.toString(),
          label: `${item?.name} (Level ${item.level})`,
        }));
    }

    return (
      store.attributeList
      .find((el) => String(el.label) === String(filter.attribute))
        ?.list.filter((item) => typeof item.value !== "undefined")
      .map((item) => item.value.toString()) || []
    );
  };

  const renderValue = (row: { attribute: string; mode: string; value: string | string[] }, rowIndex: number) => {
    if (row.attribute === "Unit") {
      const previousRow = props.localSelectedSegments[rowIndex - 1];
      const previousUnitId = previousRow?.attribute === "Unit"
        ? (typeof previousRow.value === 'string'
            ? previousRow.value
            : Array.isArray(previousRow.value) && previousRow.value.length === 1
              ? previousRow.value[0]
            : null)
        : null;

      const unitItems =
        store.attributeList
          .find((el) => String(el.label) === "Unit")
          ?.list.filter((item) => {
            if (!item?.name) return false;

            if (rowIndex === 0) {
              if (store.segmentSearchValue) {
                return item.name.toLowerCase().includes(store.segmentSearchValue.toLowerCase());
              }
              return true;
            }

            // Check if this unit is a descendant of the selected unit
            let currentUnit = item;
            while (currentUnit) {
              if (currentUnit.parent_id === Number(previousUnitId)) {
                if (store.segmentSearchValue) {
                  return item.name.toLowerCase().includes(store.segmentSearchValue.toLowerCase());
                }
                return true;
              }
              currentUnit = store.attributeList
                .find((el) => String(el.label) === "Unit")
                ?.list.find(u => u.id === currentUnit.parent_id);
            }
            return false;
          })
          .map((item, index) => (
            <MenuItem key={item.id.toString() + index} value={item.id.toString()}>
              {`${item?.name} (Level ${item.level})`}
            </MenuItem>
          )) || [];

      return unitItems;
    }

    const items =
      store.attributeList
        .find((el) => String(el.label) === String(row.attribute))
        ?.list.filter((item) => {
          if (!item?.value) return false;
          // Only filter if there's an active search and any select is open
          if (store.segmentSearchValue && Object.values(openSelects).some((isOpen) => isOpen)) {
            return item.value.toLowerCase().includes(store.segmentSearchValue.toLowerCase());
          }
          return true;
        })
        .map((item, index) => (
          <MenuItem key={item.value + index} value={item.value}>
            {item.value}
          </MenuItem>
        )) || [];

    return items;
  };

  const handleValueChange = (rowIndex: number, value: string | string[]) => {
    props.changeSegments(rowIndex, "value", value);
    
    const currentRow = props.localSelectedSegments[rowIndex];
    if (currentRow.attribute === "Unit" && rowIndex < props.localSelectedSegments.length - 1) {
      props.clearSubsequentFilters(rowIndex + 1);
      setOpenSelects({});
    }
  };

  const handleModeSelect = (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => {
    if (row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled) {
      setActiveRowIndex(rowIndex);
      setShowUnitPopup(true);
    }
    props.handleModeSelect(e, row, rowIndex);
  };

  const handleUnitSelect = (selectedUnits: (string | number)[]) => {
    if (activeRowIndex !== null) {
      // Normalize all unit IDs to strings
      const normalizedSelectedUnits = selectedUnits.map(unit => unit.toString());

      const updateUnitSelection = (unitList: TDashboardUnitFilter[]) => {
        unitList.forEach(unit => {
          unit.selected = normalizedSelectedUnits.includes(unit.id.toString());
          if (unit.children?.length) {
            updateUnitSelection(unit.children);
          }
        });
      };

      updateUnitSelection(store.filterUnitList);

      // Update the value with the normalized unit IDs
      handleValueChange(
        activeRowIndex, 
        normalizedSelectedUnits.length === 1 ? normalizedSelectedUnits[0] : normalizedSelectedUnits
      );
    }
    setShowUnitPopup(false);
    setActiveRowIndex(null);
  };

  const getLabelById = (id: string, items: TDashboardUnitFilter[]): string => {
    // Recursive function to search through the unit hierarchy
    const findUnit = (units: TDashboardUnitFilter[]): TDashboardUnitFilter | undefined => {
      for (const unit of units) {
        if (unit.id.toString() === id.toString()) {
          return unit;
        }
        if (unit.children) {
          const found = findUnit(unit.children);
          if (found) return found;
        }
      }
      return undefined;
    };

    const unit = findUnit(items);
    return getUnitName(unit);
  };

  return (
    <>
      <Container>
        {props.localSelectedSegments.map((row, rowIndex, arraySegments) => (
          <FilterContainer
            $isLast={Boolean(rowIndex + 1 === props.localSelectedSegments.length)}
            key={rowIndex}
          >
            <FilterRow>
              <FilterRowContent>
                <LabelsRow>
                  {arraySegments.map((rowValue, index) => {
                    // Only render if this is the current row or previous rows
                    if (index > rowIndex) return null;

                    if (Array.isArray(rowValue.value) && rowValue.value.length > 0) {
                      return (
                        <LabelGroup key={index}>
                          {rowValue.value.slice(0, 10).map((el, indexLabel) => (
                            <Label key={indexLabel}>
                              <span dangerouslySetInnerHTML={{ __html:  rowValue.attribute === "Unit"
                                ? getLabelById(el, store.filterUnitList)
                                : typeof el === 'string' ? decodeUnicode(el) : el}} />
                            </Label>
                          ))}
                          {rowValue.value.length > 10 && (
                            <CounterLabels>+ {rowValue.value.length - 10} more</CounterLabels>
                          )}
                          {index === rowIndex && (
                            <ClearButon
                              variant="text"
                              onClick={() => props.clearLastAttribute(rowIndex)}
                            >
                              Clear all
                            </ClearButon>
                          )}
                        </LabelGroup>
                      );
                    }
                    
                    // Handle single value selection
                    if (rowValue.value) {
                      return (
                        <LabelGroup key={index}>
                          <Label>
                            <span dangerouslySetInnerHTML={{ __html: rowValue.attribute === "Unit"
                              ? getLabelById(rowValue.value.toString(), store.filterUnitList)
                              : decodeUnicode(rowValue.value.toString())}} />
                          </Label>
                          {index !== arraySegments.length - 1 && <ArrowForwardIosIcon />}
                        </LabelGroup>
                      );
                    }

                    // Show "Select segment" only for the current empty row
                    if (index === rowIndex && !rowValue.value) {
                      return (
                        <LabelGroup key={index}>
                          <SelectLabel>Select segment</SelectLabel>
                        </LabelGroup>
                      );
                    }

                    return null;
                  })}
                </LabelsRow>
                {rowIndex > 0 && (
                  <RemoveFilterButton
                    onClick={() => {
                      handleRemoveFilter(rowIndex);
                    }}
                    aria-label="remove filter"

                  >
                    <CloseIcon />
                  </RemoveFilterButton>
                )}
              </FilterRowContent>
            </FilterRow>
            <FilterRow>
              <StyledSelectAttribute
                id="unit_or_attribute"
                value={row.attribute}
                onChange={(e: SelectChangeEvent<string>) => {
                  props.handleAttributeSelect(e, row, rowIndex);
                }}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{ PaperProps: { sx: { maxHeight: 220 } } }}
                renderValue={
                  row.attribute
                    ? () => row.attribute
                    : () => <Placeholder>Select attribute</Placeholder>
                }
              >
                {store.attributeList
                  .filter((el) => {
                    if (el.label === "Unit") return true;
                    return !props.localSelectedSegments.find((item) => item.attribute === el.label);
                  })
                  .map((attribute, index) => (
                    <MenuItem key={attribute.value + index} value={attribute.label}>
                      {attribute.label}
                    </MenuItem>
                  ))}
              </StyledSelectAttribute>
              <StyledSelectModeValue
                id="mode"
                value={row.mode}
                onChange={(e: SelectChangeEvent<string>) => handleModeSelect(e, row, rowIndex)}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                inputProps={{ "aria-label": "Without label" }}
                key={`mode-select-${rowIndex}-${row.mode}`}
                onClick={() => {
                  if (row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled) {
                    setActiveRowIndex(rowIndex);
                    setShowUnitPopup(true);
                  }
                }}
                renderValue={
                  row.mode !== ""
                    ? undefined
                    : () => <Placeholder>Select mode of analysis</Placeholder>
                }
              >
                <MenuItem value={"multiple"}>Analyze multiple segments</MenuItem>
                <MenuItem value={"one"}>Select 1 segment to analyze deeper</MenuItem>
              </StyledSelectModeValue>
            </FilterRow>
            {row.mode && row.attribute && !(row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled) && (
              <FilterSecondaryRow>
                {row.mode === "one" ? (
                  <StyledSelectModeValue
                    id="value"
                    value={row.value || ""}
                    open={openSelects[rowIndex]}
                    onOpen={() => handleSelectOpen(rowIndex)}
                    onClose={() => handleSelectClose(rowIndex)}
                    onChange={(e: SelectChangeEvent<string>) => {
                      handleValueChange(rowIndex, e.target.value);
                    }}
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 220 } },
                      autoFocus: false,
                      disableAutoFocusItem: true,
                    }}
                    renderValue={
                      row.value !== ""
                        ? (value) => {
                            if (row.attribute === "Unit") {
                              const item = store.attributeList
                                .find((el) => String(el.label) === "Unit")
                                ?.list.find((item) => item.id.toString() === value);
                              return item ? `${item.name} (Level ${item.level})` : "";
                            }
                            return value as string;
                          }
                        : () => <Placeholder>Select segments</Placeholder>
                    }
                  >
                    <StyledListSubheader>
                      <StyledSearchInput
                        placeholder="Search for segment"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={store.segmentSearchValue || ""}
                        onChange={(e) => {
                          store.setData(e.target.value, "segmentSearchValue");
                        }}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        autoComplete="off"
                      />
                    </StyledListSubheader>
                    {renderValue(row, rowIndex)}
                  </StyledSelectModeValue>
                ) : (
                  <MultipleSelectWrapper>
                      <MultipleSelect
                        options={getSegmentsList(row, rowIndex)}
                        selectedList={row.value && typeof row.value === "object" ? row.value : []}
                        onChange={(changedIndex, field, value) => {
                          const processedValue = Array.isArray(value) 
                            ? value.map(v => typeof v === 'object' ? v.value : v)
                            : typeof value === 'object' ? value.value : value;
                          handleValueChange(changedIndex, processedValue);
                        }}
                        name="value"
                        index={rowIndex}
                      />
                    </MultipleSelectWrapper>
                )}
              </FilterSecondaryRow>
            )}
          </FilterContainer>
        ))}
      </Container>

      {showUnitPopup && topStore.isDashboardUnitFilterEnabled && (
        <UnitSelectionPopup
          open={showUnitPopup}
          onClose={() => setShowUnitPopup(false)}
          onSelect={handleUnitSelect}
          isSingleSelect={props.localSelectedSegments[activeRowIndex!]?.mode === "one"}
          units={store.filterUnitList}
        />
      )}
    </>
  );
});

export default SettingsList;

const Container = styled.div`
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--colorPaletteGrayBackground1);
  margin-top: 24px;
  border-radius: 10px;
`;

const FilterContainer = styled.div<{ $isLast?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  border-bottom: ${(props) =>
    props.$isLast ? "none" : "1px solid var(--colorPaletteBlueBackground1)"};
`;

const FilterRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const FilterSecondaryRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

const StyledSelectAttribute = styled(Select)`
  height: 39px;
  width: 170px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 8px;

  div {
    padding: 8px !important;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const StyledSelectModeValue = styled(Select)`
  height: 39px;
  width: calc(100% - 178px) !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  div {
    padding: 8px !important;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const LabelsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

const Label = styled.span`
  padding: 2px 8px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: var(--colorNeutralForeground1);
  background-color: var(--colorPaletteGreenBackground4);
  margin: 0px 4px 4px 0px;
`;

const MultipleSelectWrapper = styled.div`
  width: calc(100% - 178px);

  svg {
    font-size: 16px;
  }
`;

const Placeholder = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground9);
`;

const ClearButon = styled(Button)`
  text-transform: none !important;
  padding: 0px !important;
`;

const LabelGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    font-size: 12px;
    margin-bottom: 4px;
    color: var(--colorNeutralForeground2);
  }
`;

const SelectLabel = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
`;

const CounterLabels = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorNeutralForeground1);
  margin-bottom: 4px;
`;

const StyledListSubheader = styled(ListSubheader)`
  border-bottom: 1px solid var(--colorNeutralForeground4);
  padding: 8px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSearchInput = styled(TextField)`
  && {
    width: calc(100% - 32px);
    margin: 8px 16px;

    .MuiInputBase-root {
      background-color: white;
      z-index: 1;
      position: relative;
      width: 100%;
    }

    .MuiInputBase-input {
      color: rgba(0, 0, 0, 0.87);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
      padding: 8px 11px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
`;

const FilterRowContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const RemoveFilterButton = styled(IconButton)`
  && {
    padding: 4px;
    margin-left: 8px;
    color: var(--colorNeutralForeground3);
    
    &:hover {
      color: var(--colorNeutralForeground1);
      background-color: var(--colorNeutralBackground3);
    }

    svg {
      font-size: 18px;
    }
  }
`;

import { TDasbboardFilter, TDashboardGlobalFilter, TDashboardUnitFilter } from "types/dashboard";
import topStore  from "features/Dashboard/store";

export const getUnitSegmentsName = (
    filter: TDasbboardFilter,
    segment: string,
    fullList: TDashboardGlobalFilter[],
    isWithLevel?: boolean,
    units?: TDashboardUnitFilter[],
    isUnit?: boolean,
) => {
    if ((filter.unit_or_attr?.name === "Unit" || isUnit) && !isWithLevel) {
        const fullFilter = fullList.find((el) => el?.name === "Unit");
        if (fullFilter) {
            const findedsegment = fullFilter.values.find((el) => el.id.toString() === segment);
            {
                if (findedsegment) {
                    return findedsegment?.name || "";
                }
                return null;
            }
        } 
        return null;
    }else if (isWithLevel && (filter.unit_or_attr?.name === "Unit" || isUnit)) {
        const findInTree = (items?: TDashboardUnitFilter[]): TDashboardUnitFilter | undefined => {
            if (!items) return undefined;
            
            for (const item of items) {
                if (item.id.toString() === segment.toString()) return item;
                if (item.children) {
                    const found = findInTree(item.children);
                    if (found) return found;
                }
            }
            return undefined;
        };

        const truncateName = (name: string, maxLength: number = 15) => {
            return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
        };

        const foundItem = findInTree(units);
        if (foundItem) {
            return getUnitName(foundItem);
        }
        return null;
    }

    return segment;
};

export const getUnitSegmentName = (segment: string, fullList: TDashboardGlobalFilter[]) => {
    const fullFilter = fullList.find((el) => el.name === "Unit");
    if (fullFilter) {
        const findedsegment = fullFilter.values.find((el) => el.id.toString() === segment);
        {
            if (findedsegment) {
                return findedsegment?.name || "";
            }
            return null;
        }
    }
    return null;
};


export const getUnitName = (item: TDashboardUnitFilter, isTruncated: boolean = true) => {
    if (!item) {
        return '';
    }

    if (!topStore.isDashboardUnitFilterEnabled) {
        return item.label;
    }

    const truncateName = (name: string, isLastLevel: boolean = false, maxLength: number = 15) => {
        if (!name) return '';
        const truncated = name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
        const finalName = isTruncated ? truncated : name;
        return isLastLevel ? `<strong>${finalName}</strong>` : finalName;
    };
    
    if (!item.label || typeof item.level !== 'number') {
        return `<strong>${truncateName(item.label || '', true)}</strong>`;
    }
    
    const rootName = item.rootName || item.label;
    
    if (item.level === 0) {
        return `<strong>${truncateName(item.label)}</strong>`;
    }else if (item.level === 1) {
        return rootName ? `${rootName} > <strong>${truncateName(item.label, true)}</strong>` : `<strong>${truncateName(item.label, true)}</strong>`;
    }else {
        return `${truncateName(rootName)} > ${item.level - 1} ${item.level > 3 ? 'levels' : 'level'} > <strong>${truncateName(item.label, true)}</strong>`;
    }
    
};

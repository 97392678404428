import { LogOutIcon } from "./LogOutIcon";
import { ReactElement, useState } from "react";

import Menu from "@mui/material/Menu";
import styled from "styled-components";
import Button from "../button/Button";
import MenuItem from "@mui/material/MenuItem";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { useAuth } from "@engagerocketco/auth-sdk";

const SimpleHeader = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const auth = useAuth();

  const openMenu = Boolean(anchorEl);
  const handleCloseEntityMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <img alt="EngageRocket logo" height={50} width={176.41} src={"/assets/logo_500.png"} />

      <LogOutButton onClick={() => StoreLayout.logout().then(auth.logout)} variant="text">
        <LogOutIcon />
        Sign out
      </LogOutButton>

      <StyledMenu
        id="logout-menu"
        anchorEl={anchorEl}
        onClose={handleCloseEntityMenu}
        open={openMenu}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={() => StoreLayout.logout().then(auth.logout)}>y.lang@consultancy.co</MenuItem>
        <MenuItem onClick={() => StoreLayout.logout().then(auth.logout)}>yves.lang@widgets.co</MenuItem>
      </StyledMenu>
    </Container>
  );
};

export default SimpleHeader;

const Container = styled.div`
  width: 100%;
  background: var(--colorNeutralBackground1);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 48px;
  position: relative;

  .MuiSnackbar-root {
    position: absolute;
    top: 90px;
    width: 100%;
    left: 0px;
    right: 0px;
    z-index: 0;
  }
`;

const LogOutButton = styled(Button)`
  color: var(--colorNeutralForeground1) !important;

  svg {
    margin-right: 8px;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0px 1px 2px 0px var(--colorShadowInverted1);
    border: 1px solid var(--colorNeutralForeground4);
    border-radius: 0px;
  }
  .MuiButtonBase-root {
    &:hover {
      background-color: var(--colorPalleteLightBlue);
    }
  }
`;

import {  Dialog } from "@mui/material";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Divider } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect, useRef } from "react";
import { TreeSelect } from "components/TreeSelect/TreeSelect";
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { Box } from "@mui/material";
import { TDashboardUnitFilter } from "types/dashboard";
import Chip from '@mui/material/Chip';
import ChipsUnitWidget from "components/ChipsUnitWidget/ChipsUnitWidget";

type UnitSelectionPopupProps = {
  open: boolean;
  onClose: () => void;
  onSelect: (units: string[]) => void;
  units: TDashboardUnitFilter[];
  isSingleSelect: boolean;
};

const UnitSelectionPopup = ({ open, onClose, onSelect, units, isSingleSelect }: UnitSelectionPopupProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const treeSelectRef = useRef<any>(null);
  const [matches, setMatches] = useState<{ id: string; label: string }[]>([]);
  const [currentMatchId, setCurrentMatchId] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [initialSelection, setInitialSelection] = useState<string[]>([]);
  const [isChipsExpanded, setIsChipsExpanded] = useState(false);
  const chipsContainerRef = useRef<HTMLDivElement>(null);
  const [showExpandButton, setShowExpandButton] = useState(false);

  // Function to find all matches and store them
  const findMatches = (items: any[]): { id: string; label: string }[] => {
    const matches: { id: string; label: string }[] = [];
    
    const search = (item: any) => {
      if (item.label.toLowerCase().includes(searchTerm.toLowerCase())) {
        matches.push({ id: item.id, label: item.label });
      }
      if (item.children) {
        item.children.forEach(search);
      }
    };
    
    items.forEach(search);
    return matches;
  };

  // Update matches when search term changes
  useEffect(() => {
    if (searchTerm) {
      const foundMatches = findMatches(units);
      setMatches(foundMatches);
      if (foundMatches.length > 0) {
        setCurrentMatchId(foundMatches[0].id);
      } else {
        setCurrentMatchId('');
      }
    } else {
      setMatches([]);
      setCurrentMatchId('');
    }
  }, [searchTerm, units]);

  const handleNext = () => {
    if (matches.length === 0) return;
    
    const currentIndex = matches.findIndex(match => match.id === currentMatchId);
    const nextIndex = currentIndex < matches.length - 1 ? currentIndex + 1 : 0;
    const nextMatchId = matches[nextIndex].id;
    
    setCurrentMatchId(nextMatchId);
    if (treeSelectRef.current?.scrollToMatch) {
      treeSelectRef.current.scrollToMatch(nextMatchId);
    }
  };

  const handlePrev = () => {
    if (matches.length === 0) return;
    
    const currentIndex = matches.findIndex(match => match.id === currentMatchId);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : matches.length - 1;
    const prevMatchId = matches[prevIndex].id;
    
    setCurrentMatchId(prevMatchId);
    if (treeSelectRef.current?.scrollToMatch) {
      treeSelectRef.current.scrollToMatch(prevMatchId);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setCurrentMatchIndex(0);
  };

  // Update isDisabled based on comparison with initial selection
  useEffect(() => {
    const areArraysEqual = (a: string[], b: string[]) => 
      a.length === b.length && 
      [...a].sort().join(',') === [...b].sort().join(',');
    
    setIsDisabled(areArraysEqual(selectedItems, initialSelection));
  }, [selectedItems, initialSelection]);

  // Add this useEffect to initialize selectedItems
  useEffect(() => {
    const getSelectedUnitIds = (items: TDashboardUnitFilter[]): string[] => {
      let selectedIds: string[] = [];
      
      const areAllChildrenSelected = (item: TDashboardUnitFilter): boolean => {
        if (!item.children) return item.selected;
        return item.selected && item.children.every(child => areAllChildrenSelected(child));
      };

      items.forEach(item => {
        // If this item has children and all children are selected
        if (item.children && areAllChildrenSelected(item)) {
          // Only add the parent ID
          selectedIds.push(item.id);
        } 
        // If this item has no children or not all children are selected
        else {
          if (item.selected) {
            selectedIds.push(item.id);
          }
          if (item.children) {
            selectedIds = [...selectedIds, ...getSelectedUnitIds(item.children)];
          }
        }
      });
      
      return selectedIds;
    };

    const initialSelectedItems = getSelectedUnitIds(units);
    setInitialSelection(initialSelectedItems);
    setSelectedItems(initialSelectedItems);
  }, [units]);

  const handleTreeSelect = (items: string[]) => {
    const processSelection = (allItems: TDashboardUnitFilter[]): string[] => {
      let processedIds: string[] = [];
      
      const areAllChildrenSelected = (item: TDashboardUnitFilter): boolean => {
        if (!item.children) return items.includes(item.id);
        return items.includes(item.id) && item.children.every(child => areAllChildrenSelected(child));
      };

      allItems.forEach(item => {
        if (item.children && areAllChildrenSelected(item)) {
          // If all children are selected, only include the parent
          processedIds.push(item.id);
        } else {
          if (items.includes(item.id)) {
            processedIds.push(item.id);
          }
          if (item.children) {
            processedIds = [...processedIds, ...processSelection(item.children)];
          }
        }
      });

      return processedIds;
    };

    const processedItems = processSelection(units);
    setSelectedItems(processedItems);
  };

  const handleSelect = () => {
    // Create a new array with updated selected flags
    const updatedUnits = units.map(unit => {
      const updateUnitFlags = (item: TDashboardUnitFilter): TDashboardUnitFilter => {
        const newItem = {
          ...item,
          selected: selectedItems.includes(item.id)
        };
        
        if (item.children) {
          newItem.children = item.children.map(updateUnitFlags);
        }
        
        return newItem;
      };
      
      return updateUnitFlags(unit);
    });

    onSelect(selectedItems);
    onClose();
  };

  const handleRemoveItem = (idToRemove: string) => {
    // Update parent component state
    setSelectedItems(selectedItems.filter(id => id !== idToRemove));
    
    // Update TreeSelect state through ref
    if (treeSelectRef.current?.handleSelect) {
      treeSelectRef.current.handleSelect(idToRemove);
    }
  };

  // Add this function to handle clearing all selections
  const handleClearAll = () => {
    setSelectedItems([]);
    // Update the tree selection state by calling the onSelect callback
    if (treeSelectRef.current) {
      treeSelectRef.current.clearSelection();
    }
  };

  const toggleChipsContainer = () => {
    setIsChipsExpanded(!isChipsExpanded);
  };

  // Add useEffect to check content height
  useEffect(() => {
    const container = chipsContainerRef.current;
    if (container) {
      // Compare scroll height with client height
      setShowExpandButton(container.scrollHeight > container.clientHeight);
    }
  }, [selectedItems]); // Re-check when selected items change

  const findSelectedUnits = (units: TDashboardUnitFilter[], selectedIds: string[]): TDashboardUnitFilter[] => {
    let result: TDashboardUnitFilter[] = [];
    
    const searchUnit = (unit: TDashboardUnitFilter) => {
      if (selectedIds.includes(unit.id)) {
        result.push(unit);
      }
      if (unit.children) {
        unit.children.forEach(searchUnit);
      }
    };
    
    units.forEach(searchUnit);
    return result;
  };

  return (
    <StyledDialog 
      open={open}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'transparent' }
        }
      }}
    >
        <HeaderContainer>
          <div style={{
            display: 'flex', 
            flexDirection: 'column', 
            padding: '24px 0 0 0', 
            backgroundColor: 'var(--colorNeutralBackground1)'
          }}>
            <CloseBtnWrapper>
              <CloseButton onClick={onClose}>
                <ArrowBackIcon />
              </CloseButton>
              <TextTitle style={{ marginRight: '4px' }}>Back to filters </TextTitle>
              <TextSubTitle>(Your changes will be discarded)</TextSubTitle>
            </CloseBtnWrapper>
            
            <ChipsUnitWidget 
              selectedItems={findSelectedUnits(units, selectedItems)}
              onRemoveItem={handleRemoveItem}
              onClearAll={handleClearAll}
            />

            <SearchContainer>
                <StyledDialogTitle>Select units</StyledDialogTitle>
                <SearchWrapper>
                    <SearchInputWrapper>
                        <SearchInput 
                          type="text" 
                          placeholder="Search for unit"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                    </SearchInputWrapper>
                </SearchWrapper>
            </SearchContainer>

          </div>

        </HeaderContainer>

        <ContentContainer>
        

        <Divider style={{margin: '0 24px'}}/>

        {searchTerm  && (
                  <SearchNavigation >
                    <div style={{ width: '75%' }}>
                    <MatchCount>
                      {matches.length} results found for "{searchTerm}"
                    </MatchCount>
                    <NavClearButton onClick={handleClearSearch}>
                      <CloseIconWrapper>
                        <CloseIcon  color="primary"/>
                        <CloseText>Clear Search</CloseText>
                      </CloseIconWrapper>
                    </NavClearButton>
                    </div>


                    <NavButtons style={{ width: '25%' }}>
                      <NavButton onClick={handlePrev}>
                        <ArrowUpward />
                      </NavButton>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                        <TextCounter>
                          {matches.length > 0 ? 
                            `${matches.findIndex(match => match.id === currentMatchId) + 1} of ${matches.length}` : 
                            '0 of 0'}
                        </TextCounter>
                      </Box>
                      <NavButton onClick={handleNext}>
                        <ArrowDownward />
                      </NavButton>
                    </NavButtons>
                  </SearchNavigation>
                )}

        <TreeSelectWrapper>
          <TreeSelect 
            ref={treeSelectRef}
            items={units}
            onSelect={(items) => {
              if (isSingleSelect) {
                setSelectedItems(items.slice(-1));
              } else {
                handleTreeSelect(items);
              }
            }}
            searchTerm={searchTerm}
            currentMatchIndex={currentMatchIndex}
            isSingleSelect={isSingleSelect}
          />
        </TreeSelectWrapper>

        </ContentContainer>

        <FooterContainer>
        <ButtonWrapper>
            <SelectButton 
              onClick={handleSelect}
              disabled={isDisabled}
            >
              Select units
            </SelectButton>
          </ButtonWrapper>
        </FooterContainer>
    </StyledDialog>
  );
};

export default UnitSelectionPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 586px !important;
    overflow: hidden !important;
    min-height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 12px 24px;
  background-color: var(--colorNeutralBackground4);
  z-index: 1100;
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 300px;
  flex: 0 0 auto;
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  padding-right: 40px;
  border: 1px solid var(--colorNeutralStroke1);
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  &::placeholder {
    color: var(--colorNeutralForeground2);
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--colorNeutralForeground2);
  display: flex;
  align-items: center;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 12px 20px 0 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: var(--colorBrandForeground3);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: 20px;
    height: 20px;
    color: white;
  }

  &:hover {
    background-color: var(--colorBrandForeground3);
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: var(--colorBrandForeground3);
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 24px 12px 24px;
`;

const SelectButton = styled(CustomButton)`
  width: 100%;
  height: 36px;
  padding-left: 24px;
  padding-right: 24px;
  color: ${props => props.disabled ? 'var(--colorTextAllButton)' : 'var(--colorNeutralBackground1)'} !important;
  background-color: ${props => props.disabled ? 'var(--colorPaletteGrayBackground1)' : 'var(--colorBrandForeground1)'} !important;
  text-decoration: none !important;

  &:hover {
    background-color: ${props => props.disabled ? 
      'var(--colorPaletteGrayBackground1)' : 
      'var(--colorBrandForeground1)'} !important;
    text-decoration: none !important;
  }
`; 

const TextTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-left: 12px;
  color: var(--colorNeutralForeground3);
`;

const TextCounter = styled(TextTitle)`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
  padding-left: 0;
  padding-right: 0;
`;

const TextSubTitle = styled(TextTitle)`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 0;
  color: var(--colorNeutralForeground5);
`;

const CloseText = styled(TextTitle)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 0;
  color: var(--colorBrandForeground1);
  padding-left: 4px;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: var(--colorNeutralBackground4);
  margin-bottom: 0;
  z-index: 1200;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--colorNeutralStroke1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(80vh - 180px);
  background-color: var(--colorNeutralBackground4);
  margin-top: 0;
  z-index: 1000;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--colorNeutralBackground1);
  margin-top: auto;
  
  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
  }
`;

const StyledDialogTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-right: 12px;
  color: var(--colorNeutralForeground3);
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SearchNavigation = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  background-color: var(--colorSearchResultBackground);
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px 24px;
  position: sticky;
  top: 0;
  z-index: 1150;
`;

const MatchCount = styled.span`
  font-size: 12px;
  color: var(--colorNeutralForeground2);
`;

const NavButtons = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
`;

const NavButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 4px;
  color: var(--colorBrandForeground3);
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  display: flex;
  border: 1px solid var(--colorBrandForeground3);

  &:active {
    background-color: var(--colorNeutralBackground4);
  }

  svg {
    font-size: 20px;
  }
`;

const NavClearButton = styled.div`
  background-color: var(--colorSearchResultBackground);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TreeSelectWrapper = styled.div`
  flex: 1;
  padding: 24px 24px;
  padding-bottom: 20px;
  position: relative;
  z-index: 1000;
`;
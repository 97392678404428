import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg``;

export const IconWord = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
    <StyledSvg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.58065 0V3.6129H0V12.8H2.58065V16H16V0H2.58065ZM2.57812 6.19531H3.94587L4.64264 7.22757L5.33942 6.19531H6.70716L5.33942 8.25983L6.70716 10.3243H5.33942L4.64264 9.29209L3.94587 10.3243H2.57812L3.94587 8.25983L2.57812 6.19531ZM3.19922 15.2008H15.1992V0.800781H3.19922V3.57001H9.59922V4.6777H13.6719V5.7854H9.59922V6.89309H13.6719V8.00078H9.59922V9.10847H13.6719V10.2162H9.59922V12.7392H3.19922V15.2008Z"
            fill="url(#paint0_linear_909_72300)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_909_72300"
                x1="8"
                y1="0"
                x2="8"
                y2="16"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.583333" stopColor="#1477F8" />
                <stop offset="1" stopColor="#005DEA" />
            </linearGradient>
        </defs>
    </StyledSvg>
);

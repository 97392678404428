import { FC } from "react";


type FileIconNewProps = {
    color?: string;
    width?: number;
    height?: number;
}

const UploadFileIcon: FC<FileIconNewProps> = ({ color = '#959BA2', width = 64, height = 64 }: FileIconNewProps) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64 64" fill="none">
                <mask id="mask0_13815_1938" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
                    <rect width="64" height="64" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_13815_1938)">
                    <path d="M30 49.0247H34V37.1993L38.9333 42.1327L41.7433 39.2813L32 29.538L22.2567 39.2813L25.108 42.0914L30 37.1993V49.0247ZM16.8207 57.3327C15.4736 57.3327 14.3333 56.866 13.4 55.9327C12.4667 54.9994 12 53.8591 12 52.512V11.4867C12 10.1396 12.4667 8.99935 13.4 8.06602C14.3333 7.13268 15.4736 6.66602 16.8207 6.66602H38L52 20.666V52.512C52 53.8591 51.5333 54.9994 50.6 55.9327C49.6667 56.866 48.5264 57.3327 47.1793 57.3327H16.8207ZM36 22.666V10.666H16.8207C16.6153 10.666 16.4273 10.7516 16.2567 10.9227C16.0856 11.0933 16 11.2813 16 11.4867V52.512C16 52.7174 16.0856 52.9054 16.2567 53.076C16.4273 53.2471 16.6153 53.3327 16.8207 53.3327H47.1793C47.3847 53.3327 47.5727 53.2471 47.7433 53.076C47.9144 52.9054 48 52.7174 48 52.512V22.666H36Z" fill={color} />
                </g>
            </svg>

        </>
    )
}

export default UploadFileIcon;
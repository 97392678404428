import React from 'react';
import styled from 'styled-components';
import erErrorImg from 'assets/images/er-error-img.svg';
interface ErrorPageProps {
  message?: string;
  supportEmail?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  message = "Oops, something went wrong while loading the dashboard.",
  supportEmail = "support@engagerocket.co"
}) => {
  return (
    <Container>
      <ContentWrapper>
        {/* Error Icon */}
        <IconWrapper>
          <div className="relative inline-block">
            <div className="w-16 h-16 bg-gray-200 rounded-lg rotate-3 relative">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-12 h-12 bg-gray-300 rounded-lg -rotate-3"></div>
              </div>
            </div>
            <div className="absolute -top-2 -right-2 w-8 h-8 bg-red-500 rounded-full flex items-center justify-center">
              <ImageWrap 
                src={erErrorImg}
                alt="Error icon"
                className="w-5 h-5 text-white"
              />
            </div>
          </div>
        </IconWrapper>

        {/* Error Message */}
        <MessageText>
          {message}
        </MessageText>

        {/* Support Text */}
        <SupportText>
          You can try refreshing this page. If the error persists, drop us a note at{' '}
          <StyledLink href={`mailto:${supportEmail}`}>
            {supportEmail}
          </StyledLink>
          , and we'll help you sort it out!
        </SupportText>
      </ContentWrapper>
    </Container>
  );
};

export default ErrorPage; 

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  margin-top: 24px;
  padding: 48px 0;
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 16px;
`

const IconWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const MessageText = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
`

const SupportText = styled.p`
  color: #4B5563;
  text-align: center;
  width: 100%;
`

const StyledLink = styled.a`
  color: #3B82F6;
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    color: #2563EB;
  }
`

const ImageWrap = styled.img`
  max-width: 100px;
  max-height: 100px;
`
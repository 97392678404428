import { ButtonBase } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";

type BackButtonProps = {
  onClick?: () => void;
  text?: string;
};

const BackButton: FC<BackButtonProps> = ({ onClick, text }: BackButtonProps) => {
  return (
    <BackButtonWrapper onClick={onClick} disableRipple={true}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12.5" cy="12" r="11" fill="#1477F8" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.5 11.25H9.3725L13.565 7.0575L12.5 6L6.5 12L12.5 18L13.5575 16.9425L9.3725 12.75H18.5V11.25Z"
          fill="white"
        />
      </svg>
      {text && <BackButtonText>{text}</BackButtonText>}
    </BackButtonWrapper>
  );
};

const BackButtonWrapper = styled(ButtonBase)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

const BackButtonText = styled.span`
  font-size: 14px;
  color: #3e4450; /* Color from Figma */
  margin-left: 8px;
`;

export default BackButton;

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import styled from "styled-components";
import PreviousImports from "../PreviousImports";
import LeftNavigationBar from "features/Employees/LeftNavigationBar";
import EmployeeNavStore from "../../Employees/store";
import ImportSuccessView from "./ImportSuccessView";
import UploadStepView from "./UploadStepView";
import ReviewStepView from "./ReviewStepView";
import CustomButton from "components/button/Button";
import CancelButton from "components/button/CancelButton";
import BackButton from "./components/BackButton";
import CustomConfirmationDialogWithChildren from "components/customConfirmationDialog/customConfirmationDialogWithChildren";
import CustomConfirmationDialog from "components/customConfirmationDialog";
import UpdatingEmployeesView from "./UpdatingEmployeesView";
import ErrorAlert from "./components/ErrorAlert";
import EmployeeImportManager from "features/Notification/import-manager/store";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import StorePermission from "components/workspaces-sidebar/StorePermission";

type EmployeeImportScreenProps = {};

const EmployeeImportScreenV2: FC<EmployeeImportScreenProps> = observer(() => {
  const formName = "EmployeeImportScreen";
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const changeTab = (tab: string) => setActiveTab(tab);

  useEffect(() => {
    const isHavingEmployeePermission =
      StorePermission.entityPermissions.editEmployee.hasPermission ||
      StorePermission.entityPermissions.viewEmployee.hasPermission;

    if (!isHavingEmployeePermission) {
      window.location.href = "/dashboard";
    }
  }, []);

  useEffect(() => {
    if (EmployeeImportManager.importId.length < 1) {
      EmployeeImportManager.getOrGenerateImportID();
    }
  }, [EmployeeImportManager.importId]);

  useEffect(() => {
    EmployeeNavStore.getEntitySettingByEntity();
  }, []);

  useEffect(() => {
    EmployeeNavStore.getCurrentEntity(StoreLayout.currentEntityId);
  }, []);

  return (
    <>
      <PageWrapper id={`${formName}_pageWrapper`}>
        <LeftNavigationBar changeTab={changeTab} activeTab={activeTab} />
        <ContentLeft id={`${formName}_contentLeft`}>
          <EmployeeImportView formName={formName} />
        </ContentLeft>
        <ContentRight id={`${formName}_contentRight`}>
          <PreviousImports />
        </ContentRight>
      </PageWrapper>
    </>
  );
});

type EmployeeImportViewProps = {
  formName: string;
};

const EmployeeImportView: FC<EmployeeImportViewProps> = observer(
  ({ formName }: EmployeeImportViewProps) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

    const navigate = useNavigate();

    if (EmployeeImportManager.isFinished()) {
      return (
        <ColumnWrapper>
          <ImportSuccessView
            formName={formName}
            employeeUpdated={EmployeeImportManager.importReview?.countUpdate ?? 0}
            employeeAdded={EmployeeImportManager.importReview?.countAdd ?? 0}
            employeeRemoved={EmployeeImportManager.importReview?.countDelete ?? 0}
          />
        </ColumnWrapper>
      );
    }

    const confirmImport = () => {
      EmployeeImportManager.handleConfirmReview();
    };

    const cancelImport = () => {
      EmployeeImportManager.handleCancelImport(() => navigate("/employees"));
    };

    return (
      <>
        {/* Confirm Import Dialog */}
        <CustomConfirmationDialogWithChildren
          title="Are you sure you want to bulk import users?"
          isDialogOpen={isConfirmationDialogOpen}
          onClose={() => {
            setIsConfirmationDialogOpen(false);
          }}
          onYes={confirmImport}
        >
          <p>The following actions will be performed:</p>
          <ul>
            <li>
              {EmployeeImportManager.importReview?.countUpdate ?? "0"} employees to be updated
            </li>
            <li>{EmployeeImportManager.importReview?.countAdd ?? "0"} employee to be created</li>
            <li>{EmployeeImportManager.importReview?.countDelete ?? "0"} employee to be deleted</li>
          </ul>
        </CustomConfirmationDialogWithChildren>

        {/* Cancel Dialog */}
        <CustomConfirmationDialog
          title="Are you sure you want to cancel?"
          bodyMessage="The employee list import is not complete. Canceling will delete the uploaded Excel file and reset the process."
          isDialogOpen={isCancelDialogOpen}
          onClose={() => {
            setIsCancelDialogOpen(false);
          }}
          onYes={cancelImport}
          buttonsCaption={{
            yesButton: "Yes, cancel",
            cancelButton: "Stay on page",
          }}
        ></CustomConfirmationDialog>

        <BackButton
          onClick={() => navigate("/employees")}
          text="Back to employee list"
        ></BackButton>

        <Header id={`${formName}_header`}>Bulk import employees</Header>

        {/*  Step 1 */}
        <UploadStepView />

        {/* Step 2 */}
        {EmployeeImportManager.isOnReview() && <ReviewStepView />}

        {/* Step 3 */}
        {EmployeeImportManager.isImportInProgress() && <UpdatingEmployeesView />}

        {/* Error Alert on Upload (FE Validation) */}
        {EmployeeImportManager.employeeImportUIState.importStatus === "error" &&
          EmployeeImportManager.employeeImportUIState.errorImportMsg && (
            <ErrorWrapper>
              <ErrorAlert
                errorMessage={EmployeeImportManager.employeeImportUIState.errorImportMsg}
                onDismiss={() => {
                  EmployeeImportManager.clearUploadError();
                }}
              />
            </ErrorWrapper>
          )}

        {/* Confirm/Cancel */}
        <Row>
          <BtnMaxWidth>
            <CustomButton
              name="proceedButton"
              color="primary"
              variant="contained"
              onClick={() => {
                setIsConfirmationDialogOpen(true);
              }}
              disabled={!EmployeeImportManager.isNeedConfirm()}
              fullWidth={true}
              style={{
                marginTop: "1rem",
              }}
            >
              Confirm import
            </CustomButton>
          </BtnMaxWidth>
        </Row>
        <div style={{ width: "150px" }}>
          <CancelButton
            onClick={() => {
              setIsCancelDialogOpen(true);
            }}
            disabled={
              EmployeeImportManager.employeeImportUIState.uploadStatus === "loading" ||
              EmployeeImportManager.employeeImportUIState.importStatus === "loading"
            }
            fullWidth={true}
            style={{
              marginTop: "1rem",
            }}
          >
            Cancel
          </CancelButton>
        </div>
      </>
    );
  },
);

const PageWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 202px 1.2fr 1fr;
  column-gap: 55px;
  padding-bottom: 120px;
  padding-top: 55px;
`;

const ContentLeft = styled.div`
  width: 100%;
  max-width: 680px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  justify-self: center;
`;

const ErrorWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
const Header = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const BtnMaxWidth = styled.div`
  width: 150px;
`;

export default EmployeeImportScreenV2;

import { FC } from "react";
import EntityPermissionsEditScreen from "../../features/EntityPermissions/EntityEmployeeEditPermission/MainScreen";

type PermissionsEditPageProps = {};

const PermissionsPage: FC<PermissionsEditPageProps> = () => {
  return <EntityPermissionsEditScreen />;
};

export default PermissionsPage;

import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { AFTER_HEADER_MENU, HEADER_MENU_PATH } from "../../constants/menu";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import StorePermission from "components/workspaces-sidebar/StorePermission";

type HeaderMenuProps = {};

const HeaderMenu: FC<HeaderMenuProps> = observer(() => {
  const location = useLocation();

  if (!StorePermission.hasAnyEntityPermission) return <HeaderMenuWrapper />;

  const shouldHideMenuItem = (path: string) => {
    if (StoreLayout.currentEntityId === 0) {
      const entityRestrictedPaths = [
        HEADER_MENU_PATH.entityPermissions,
        HEADER_MENU_PATH.employees,
        HEADER_MENU_PATH.dashboard,
        HEADER_MENU_PATH.projects,
        HEADER_MENU_PATH.templates,
      ];
      if (entityRestrictedPaths.includes(path)) return true;
    } else if ([HEADER_MENU_PATH.entities, HEADER_MENU_PATH.workspacePermissons].includes(path)) {
      return true;
    }
    return false;
  };

  const getMenuItemState = (path: string) => {
    let templateDisabledFromFeatureFlag =
      StorePermission.disabledFeatureCodes.includes("all_templates");
    if (StorePermission.excludedWorkspaceIds.includes(StoreLayout.currentWorkspaceId)) {
      templateDisabledFromFeatureFlag = false;
    }
    const menuPermissions: Record<
      string,
      { hidden: boolean; disabled: boolean; customPath?: string }
    > = {
      [HEADER_MENU_PATH.dashboard]: {
        hidden: !StorePermission.entityPermissions.viewDashboard.hasPermission,
        disabled: false,
      },
      [HEADER_MENU_PATH.projects]: {
        hidden: !StorePermission.features.surveys.hasFeature,
        disabled: !StorePermission.entityPermissions.viewSurveySettings.hasPermission,
      },
      [HEADER_MENU_PATH.templates]: {
        hidden:
          !StorePermission.features.all_templates.hasFeature || templateDisabledFromFeatureFlag,
        disabled:
          !StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
          templateDisabledFromFeatureFlag,
      },
      [HEADER_MENU_PATH.employees]: {
        hidden: !StorePermission.entityPermissions.viewEmployee.hasPermission,
        disabled: false,
      },
      [HEADER_MENU_PATH.entityPermissions]: {
        hidden:
          !StorePermission.entityPermissions.viewEntityPermissions.hasPermission &&
          !StorePermission.entityPermissions.addAndRemoveEntityUsers.hasPermission &&
          !StorePermission.entityPermissions.editEntityPermissions.hasPermission,
        disabled: false,
        customPath: !StorePermission.entityPermissions.addAndRemoveEntityUsers.hasPermission
          ? "/entityPermissions?nav=role"
          : undefined,
      },
      [HEADER_MENU_PATH.workspacePermissons]: {
        hidden:
          !StorePermission.workspacePermissions.viewWorkspacePermissions.hasPermission &&
          !StorePermission.workspacePermissions.addAndRemoveWorkspaceUsers.hasPermission,
        disabled: false,
        customPath: !StorePermission.workspacePermissions.addAndRemoveWorkspaceUsers.hasPermission
          ? "/workspacePermissions?nav=role"
          : undefined,
      },
    };

    return menuPermissions[path] || { hidden: false, disabled: false };
  };

  return (
    <HeaderMenuWrapper>
      {AFTER_HEADER_MENU.map((menuItem) => {
        if (shouldHideMenuItem(menuItem.path)) return null;

        const { hidden, disabled, customPath } = getMenuItemState(menuItem.path);
        if (hidden) return null;

        return (
          <ItemMenu
            key={menuItem.name}
            $disabled={disabled}
            $active={location.pathname.includes(menuItem.path)}
          >
            <Link id={`MenuItem${menuItem.path}`} to={customPath || menuItem.path}>
              {menuItem.name}
            </Link>
          </ItemMenu>
        );
      })}
    </HeaderMenuWrapper>
  );
});

export default HeaderMenu;

const HeaderMenuWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorNeutralBackground1);
  padding: 0 80px;
`;

const ItemMenu = styled.li<{ $active?: boolean; $disabled?: boolean }>`
  list-style-type: none;
  height: 56px;

  a {
    cursor: pointer;
    display: flex;
    align-items: center;

    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    pointer-events: ${(props) => props.$disabled && `none`};
    color: ${(props) =>
      props.$disabled
        ? "var(--colorNeutralForeground5)"
        : props.$active
          ? "var(--colorBrandForeground1)"
          : "var(--colorPaletteVioletBackground1)"};
    width: fit-content;
    border-bottom: ${(props) => props.$active && `4px solid var(--colorBrandForeground1)`};
    height: 100%;
    padding: ${(props) => (props.$active ? "4px" : "0px")} 16px 0;

    &:hover {
      text-decoration: none !important;
    }
  }
`;

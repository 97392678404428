type SuccessIconProps = {
  width?: number;
  height?: number;
};
const SuccessIcon = ({ width = 16, height = 16 }: SuccessIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill="#0CD196" />
      <path
        d="M12.2938 5.17363C12.2343 5.11861 12.1635 5.07494 12.0854 5.04514C12.0074 5.01534 11.9237 5 11.8392 5C11.7546 5 11.6709 5.01534 11.5929 5.04514C11.5148 5.07494 11.444 5.11861 11.3845 5.17363L6.61354 9.5526L4.60911 7.70944C4.5473 7.65471 4.47433 7.61167 4.39438 7.58279C4.31442 7.55391 4.22904 7.53974 4.14311 7.54111C4.05718 7.54247 3.97239 7.55933 3.89357 7.59073C3.81475 7.62213 3.74345 7.66746 3.68374 7.72411C3.62403 7.78077 3.57708 7.84765 3.54557 7.92094C3.51406 7.99423 3.49861 8.07249 3.5001 8.15126C3.50158 8.23002 3.51998 8.30774 3.55424 8.37999C3.5885 8.45223 3.63794 8.51759 3.69975 8.57232L6.15886 10.8264C6.2184 10.8814 6.28923 10.9251 6.36726 10.9549C6.4453 10.9847 6.529 11 6.61354 11C6.69808 11 6.78179 10.9847 6.85983 10.9549C6.93786 10.9251 7.00869 10.8814 7.06822 10.8264L12.2938 6.03651C12.3588 5.98154 12.4107 5.91483 12.4462 5.84058C12.4817 5.76632 12.5 5.68614 12.5 5.60507C12.5 5.52401 12.4817 5.44382 12.4462 5.36957C12.4107 5.29531 12.3588 5.2286 12.2938 5.17363Z"
        fill="white"
      />
    </svg>
  );
};

export default SuccessIcon;

import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import store from "../store";
import TablePermission from "./TablePermission";
import CustomButton from "components/button/Button";
import { Backdrop, Breadcrumbs, CircularProgress } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { SurveysIconDark, RoleUserIcon } from "components/icons";
import CustomConfirmationDialog from "components/customConfirmationDialog";

const dialogButtonsCaption = {
  yesButton: "Yes, leave page",
  cancelButton: "Stay on page",
};

const MainScreen = observer(() => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isHasChanges, setIsHasChanges] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const breadcrumbs = [
    <CustomButton
      variant="text"
      onClick={() => navigate("/entityPermissions")}
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      Survey permissions
    </CustomButton>,
    <CurrentPageLabel>
      {store.isOtherPermissionReadOnly
        ? "View permissions"
        : store.selectedProjectId === 0
          ? "Edit other permissions"
          : "Edit permissions"}
    </CurrentPageLabel>,
  ];

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (
      StorePermission.entityPermissionsLoaded &&
      !StorePermission.entityPermissions.viewEntityPermissions.hasPermission
    ) {
      window.location.href = "/access-denied";
    }

    store.loadProjectSurvey();
    store.loadDashboardPermissions();
  }, []);

  const handleSaveChanges = () => {
    setIsloading(true);

    store.toUpdateOtherPermission(() => {
      setIsloading(false);

      navigate("/entityPermissions");
    });
  };

  const handleOnHasChanges = (value: boolean) => {
    setIsSaveDisabled(!value);
    setIsHasChanges(value);
  };

  const handleCancelChanges = () => {
    if (!isHasChanges) navigate("/entityPermissions");

    setIsDialogOpen(true);
  };

  return (
    <>
      <MainPageWrapper>
        <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>

        <MainContentWrapper>
          <ColFlexBox>
            <FlexGapBox>
              <SurveysIconDark width={22} height={22} />
              <Header id="EntityPermissions_EntityUsers_Title">
                {store.selectedProjectId === 0 ? "All survey projects" : store.project?.name}
              </Header>
            </FlexGapBox>
            <FlexGapBox>
              <RoleUserIcon />
              <SubHeader id="EntityPermissions_EntityUsers_Title">
                {store.selectedRoleName}
              </SubHeader>
            </FlexGapBox>
          </ColFlexBox>

          {!store.dashboardPermissionsIsFetched ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <TablePermission onHasChanges={handleOnHasChanges} />
          )}
        </MainContentWrapper>
      </MainPageWrapper>
      <FooterWrapper>
        <ActionsWrapper>
          <CancelButton
            name="AlertButtonYes"
            color={"inherit"}
            variant="outlined"
            id={"AlertButtonYes"}
            onClick={() => handleCancelChanges()}
          >
            Cancel
          </CancelButton>
          <CustomButton
            variant="contained"
            name="AlertButtonNo"
            id={"AlertButtonNo"}
            disabled={isSaveDisabled}
            onClick={() => handleSaveChanges()}
          >
            Save changes
          </CustomButton>
        </ActionsWrapper>
      </FooterWrapper>
      <CustomConfirmationDialog
        title="Are you sure you want to leave this page?"
        bodyMessage="You have unsaved changes. Leaving this page will discard any edits made."
        buttonsCaption={dialogButtonsCaption}
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onYes={() => navigate("/entityPermissions")}
      />
    </>
  );
});

const FlexGapBox = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const ColFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 24px;
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const SubHeader = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground2);
  margin: 0px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContentWrapper = styled.div`
  padding-top: 32px;
  padding-left: 12px;
`;

const MainPageWrapper = styled.div`
  width: 100%;
  padding: 36px 120px 120px 120px;
`;

const FooterWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 58px;
  height: 60px;
  display: flex;
  background-color: var(--colorNeutralBackground1);
  padding: 0 80px 10px 80px;
  justify-content: right;
  align-items: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
`;

const CurrentPageLabel = styled.label`
  font-size: 14px;
  padding: 8px;
`;

const CancelButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  color: var(--colorNeutralForeground2);
`;

export default MainScreen;

import http from '../https'


export const checkEntityNameUnique = async (name: string, workspaceId: number, entityId: number): Promise<any> => {

  if(http.isAPIGO){
    return http.postGO(`/entity/checkname`,
    {
      name: name,
      workspace_id: workspaceId,
      entity_id: entityId
    },
    {}
  );
  }else{
    return http.post(`/entity/checkname`,
    {
      name: name,
      workspace_id: workspaceId,
      entity_id: entityId
    },
    {}
  );
  }
};

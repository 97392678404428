import { observer } from "mobx-react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import CustomButton from "../button/Button";

type CustomConfirmationDialogWithChildrenProps = {
  title: string;
  children: React.ReactNode;
  isDialogOpen: boolean;
  buttonsCaption?: {
    yesButton: string;
    cancelButton: string;
  };
  onClose: () => void;
  onYes: () => void;
};

const CustomConfirmationDialogWithChildren: React.FC<CustomConfirmationDialogWithChildrenProps> = (
  props: CustomConfirmationDialogWithChildrenProps
) => {
  return (
    <Dialog
      onClose={() => props.onClose()}
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      id={"ConfirmDialog"}
      maxWidth={"sm"}
      open={props.isDialogOpen}
    >
      <CloseModal onClick={() => props.onClose()} />
      <ContentWrapper>
        <Header>
          <MainText>{props.title}</MainText>
        </Header>
        <Body>{props.children}</Body>

        <ActionsWrapper>
          <ButtonWrapper>
            <CustomButton
              name="AlertButtonYes"
              color={"primary"}
              variant="contained"
              id={"AlertButtonYes"}
              onClick={() => props.onYes()}
            >
              {props.buttonsCaption?.yesButton || "Yes"}
            </CustomButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <CustomButton
              variant="outlined"
              name="AlertButtonNo"
              id={"AlertButtonNo"}
              onClick={() => props.onClose()}
            >
              {props.buttonsCaption?.cancelButton || "No"}
            </CustomButton>
          </ButtonWrapper>
        </ActionsWrapper>
      </ContentWrapper>
    </Dialog>
  );
};

const MainText = styled.h1`
  color: var(--colorNeutralForeground1);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
`;

const ButtonWrapper = styled.div``;

const ContentWrapper = styled(MuiDialogContent)`
  padding: 64px !important;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const Body = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: "#3E4450";
`;

const CloseModal = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38px;
  right: 30px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  opacity: 0.6;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    width: 18px;
    height: 3px;
    background: var(--colorBrandForeground1);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export default CustomConfirmationDialogWithChildren;

import http from "api/https";
import { createUrl } from "../../helpers/url";
import { APIARY_DOMAIN } from "constants/apiary";

export const getDashboardPermissions = (entityId: number, projectId: number): Promise<any> => {
  const query: {
    entity_id: string;
  } = {
    entity_id: entityId.toString(),
  };

  const url = createUrl({
    baseUrl: APIARY_DOMAIN,
    path: `/permission/entity_permissions/UpdateOtherProjectPermissions/${projectId}`,
    query: query,
  });

  return http.getGO(url);
};

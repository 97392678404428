import React from 'react';
import { TScoredQuestionsDimension } from "types/dashboard";
import TitleCard from './TitleCard';
import QuestionCard from './QuestionCard';
import EnpsCard from './EnpsCard';
import CommentCard from './CommnetCard';

interface QuestionElementsRendererConfig {
  dimension: TScoredQuestionsDimension;
  localFilters: { id: number; name: string; segments: string[] }[];
  isCommentsExpanded?: boolean;
  isResponseExpanded?: boolean;
}

export class QuestionElementsRenderer {
  private config: QuestionElementsRendererConfig;

  constructor(config: QuestionElementsRendererConfig) {
    this.config = config;
  }

  public render(): React.ReactNode[] {
    const elements: React.ReactNode[] = [];
    
    // Add Title
    elements.push(<TitleCard dimension={this.config.dimension}/>);

    // Add Dimension Score Card (if not ENPS)
    if (this.config.dimension.type !== "enps") {
      var question  = {
        id: 0,
        title: `${this.config.dimension.dimension} score`,
        commentsCount: null,
        respondedCount: null,
        benchmarkDiff: this.config.dimension.benchmarkDiff,
        benchmark: this.config.dimension.allRespondentsPercentDiff,
        allRespondentsPercent: this.config.dimension.allRespondentsPercent,
        allRespondentsPercentDiff: this.config.dimension.allRespondentsPercentDiff,
        favourablePercent: this.config.dimension.favourablePercent,
        unFavourablePercent: this.config.dimension.unFavourablePercent,
        neutralPercent: this.config.dimension.neutralPercent,
        conditionalLogic: null,
        positiveCommentsCount: this.config.dimension.positiveCommentsCount,
        negativeCommentsCount: this.config.dimension.negativeCommentsCount,
        overall: false,
        passiveCommentsCount: this.config.dimension.passiveCommentsCount,
    } 
      elements.push(
        <QuestionCard
          question={question}
        key={`${this.config.dimension.dimension}-score`}
        isLastQuestion={Boolean(this.config.dimension?.questionsList.length < 1)}
        localFilters={this.config.localFilters}
      />)
      elements.push(
         <CommentCard 
            question={question} 
            isLastQuestion={false} 
            localFilters={this.config.localFilters} 
            isCommentsExpanded={this.config.isCommentsExpanded} />)
    }

    {this.config.dimension?.questionsList.forEach((question, index) => {
      if (this.config.dimension.type !== "enps") {
        elements.push(
          <QuestionCard
            question={question}
            key={question.id}
            isLastQuestion={Boolean(index === this.config.dimension?.questionsList.length - 1)}
            localFilters={this.config.localFilters}
            isCommentsExpanded={this.config.isCommentsExpanded}
            isQuestion
          />
        );
        elements.push(
          <CommentCard 
            question={question} 
            isLastQuestion={false} 
            localFilters={this.config.localFilters} 
            isCommentsExpanded={this.config.isCommentsExpanded} />)
      } else {
        elements.push(
          <EnpsCard
            question={question}
            key={question.id}
            isLastQuestion={Boolean(index === this.config.dimension?.questionsList.length - 1)}
            localFilters={this.config.localFilters}
            isCommentsExpanded={this.config.isCommentsExpanded}
            isResponseExpanded={this.config.isResponseExpanded}
          />
        )
      };
    })}

    return elements;
  }
}
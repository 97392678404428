import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";
import {
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "components/button/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MillstoneSheduleTable from "./MillstoneSheduleTable";
import SchedulePopup from "./SchedulePopup";
import storeSurvey from "features/Survey/SurveyList/store";
import storeRecipients from "../Recipients/store";
import store from "./store";
import CustomTimeField from "./../../../components/customTimeField";
import dayjs from "dayjs";

type MillstoneRecipientSheduleScreenProps = {};

const MillstoneRecipientSheduleScreen: FC<MillstoneRecipientSheduleScreenProps> = observer(() => {
  const [errorTimeField, setErrorTimeField] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [timeDefault, setTimeDefault] = useState<dayjs.Dayjs | null>(store.timeStartMilestone);

  const navigate = useNavigate();
  const query = useQuery();
  const surveyId = query.get("id") || "0";
  const idSurvey = Number(surveyId);
  const projectId = query.get("project_id") || "0";
  const idProject = Number(projectId);

  if (isNaN(idSurvey) || idSurvey === 0) {
    // if idSurvey not created
    if (isNaN(idProject) || idProject === 0) {
      window.location.href = "/projects";
    }
  }

  useEffect(() => {
    if (store.timeStartMilestone) {
      setTimeDefault(store.timeStartMilestone);
    }
  }, [store.timeStartMilestone]);

  useEffect(() => {
    if (idSurvey) {
      store.setIdSurvey(idSurvey);
    } else {
      store.setIdProject(idProject);
    }
    store.doLoad();
  }, []);

  useEffect(() => {
    if (store.isSurveyInfoCompleted && !store.timeStartMilestone && !store.leaveDateEmpty) {
      store.changeValue("timeStartMilestone", timeDefault);
      store.changeDatesTab(true);
    }
  }, [store.isSurveyInfoCompleted, store.timeStartMilestone, store.leaveDateEmpty]);

  useEffect(() => {
    storeRecipients.getSurveyInformation(idSurvey);
  }, [idSurvey]);

  useEffect(() => {
    if (storeSurvey.project_id && !storeSurvey.project) {
      storeSurvey.getProject();
    }
  }, [storeSurvey.project_id]);

  useEffect(() => {
    store.getTodayRecepientMilestoneCount(store.surveyId);
  }, [store.surveyId]);

  const handleTimeFieldError = (isError: boolean, message: string) => {
    if (store.leaveDateEmpty) return;

    setErrorTimeField(isError);
    setErrorMessage(`You can only schedule this survey ${message}`);
  };

  return (
    <Container>
      <SchedulePopup
        open={store.openEditSchedule}
        hide={(flag) => {
          store.onChangeOpenEditSchedule(false);
        }}
      />
      <Content>
        {localStorage.getItem("surveyAction") === "edit" && (
          <Box>
            <BackButton onClick={() => navigate("/projects?project_id=" + storeSurvey.project_id)}>
              {"< Back to project"}
            </BackButton>
          </Box>
        )}
        {store.todayRecipientMilestoneCount > 0 && (
          <Banner>
            <BannerWrapper>
              <img src={"/assets/icons/notice.svg"} width={19} height={19} alt="banner" />
              <InformationWrapper>
                <p>
                  <b>{store.todayRecipientMilestoneCount} recipients</b> have reached their
                  milestones today.
                </p>
                <p>
                  To ensure that their survey is auto-triggered today, we recommend setting the
                  invite time at least 1 hour from now and launching the survey in step 3 across all
                  milestones.
                </p>
              </InformationWrapper>
            </BannerWrapper>
          </Banner>
        )}
        <HeaderWrapper>
          <Header>{storeSurvey.project?.name} (all milestones)</Header>
          <InviteScheduleWrapper>
            <InviteScheduleLabel>What time should survey invites be sent?</InviteScheduleLabel>
            <ScheduleContentWrapper>
              <InviteTimeScheduleWrapper>
                <CustomTimeField
                  value={timeDefault}
                  disabled={store.leaveDateEmpty}
                  changeValue={(value: any) => {
                    store.changeValue("timeStartMilestone", value);
                    store.changeDatesTab(true);
                  }}
                  isOnMilestone={true}
                  onError={handleTimeFieldError}
                />
                <StyledSavedButton
                  id="Survey_EditCreate_Communication_SetSurveyDates_Save_Button"
                  disabled={
                    !store.datesChanged || (!timeDefault && !store.leaveDateEmpty) || errorTimeField
                  }
                  variant="contained"
                  onClick={() => {
                    store.SaveSurveyDates();
                  }}
                >
                  Save changes
                </StyledSavedButton>
              </InviteTimeScheduleWrapper>

              {!store.leaveDateEmpty && errorTimeField && (
                <LabelErrorMessage>{errorMessage}</LabelErrorMessage>
              )}

              <FormControlLabel
                label={
                  <LabelCheckbox id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox_Label">
                    Leave send time unscheduled
                  </LabelCheckbox>
                }
                control={
                  <Checkbox
                    id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox"
                    onChange={(e) => {
                      store.changeValue("leaveDateEmpty", e.target.checked);
                      store.changeDatesTab(true);
                    }}
                    size="small"
                    checked={store.leaveDateEmpty}
                  />
                }
              />
            </ScheduleContentWrapper>
            <LineBar></LineBar>
            <BottomInformationLabel>
              If invites are scheduled manually for recipients, this will not apply.
            </BottomInformationLabel>
          </InviteScheduleWrapper>
        </HeaderWrapper>

        {store.resultSearchValue !== "" && (
          <ResultSearch>
            <SearchText data-testid={`MilstoneRecepient_Search_Value`}>
              "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" can be found in the
              following employees
            </SearchText>

            <RemoveSearch>
              <CustomButton
                data-testid={`MilstoneRecepient_Search_ValueRemove`}
                onClick={() => {
                  store.changeValue("resultSearchValue", "");
                  store.getMilestoneData();
                }}
              >
                Remove search
              </CustomButton>
            </RemoveSearch>
          </ResultSearch>
        )}

        <AfterHeaderWrapper>
          <Box>
            <Label data-testid={`MilstoneRecepient_Subtitle`}>Milestone Recipient schedule</Label>
            <ManageEntitiesutton
              data-testid={`MilstoneRecepient_ManageEmployeesBtn`}
              onClick={() => window.open("/employees", "_blank")}
            >
              Manage employees <OpenInNewIcon />
            </ManageEntitiesutton>
          </Box>
        </AfterHeaderWrapper>
        <AfterHeaderWrapper>
          <Box>
            <StyledSearchInput
              data-testid={`MilstoneRecepient_Serch_Container`}
              size="small"
              placeholder="Search for name/ email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-testid={`MilstoneRecepient_Serch_Btn`}
                      onClick={() => store.onSearchClicked()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={store.searchNameEmail}
              onKeyDown={store.keyPress}
              onChange={(e) => store.setData("searchNameEmail", e.target.value)}
            />
          </Box>
          <Box>
            <ExportButton
              data-testid={`MilstoneRecepient_Export_Btn`}
              onClick={() => store.exportRecepientsExcel()}
              variant="outlined"
            >
              Export all recipients [.xlsx]
            </ExportButton>
            <AddButton
              data-testid={`MilstoneRecepient_EditBtn`}
              variant="outlined"
              onClick={() => {
                navigate("/recipients?id=" + store.surveyId);
              }}
            >
              Edit recipients
            </AddButton>
          </Box>
        </AfterHeaderWrapper>
        <MillstoneSheduleTable />
      </Content>
    </Container>
  );
});

export default MillstoneRecipientSheduleScreen;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResultSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 24px;
  height: 50px;
`;

const SearchText = styled.div`
  margin-left: 16px;
`;
const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1380px;
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const BackButton = styled(CustomButton)`
  text-decoration: underline !important;
  color: var(--colorNeutralForeground2) !important;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  padding-bottom: 25px;
  padding-top: 18px;
`;

const InviteScheduleWrapper = styled.div`
  padding: 16px 24px 16px 24px;
  background-color: var(--colorNeutralBackground1);
  width: fit-content;
  margin-top: 8px;
  border-radius: 4px;
`;

const InviteScheduleLabel = styled.label`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const InviteTimeScheduleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  padding-right: 56px;
  align-items: center;
`;
const ScheduleContentWrapper = styled.div`
  padding-top: 20px;
`;

const Header = styled.h1`
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: var(--colorNeutralForeground1);
  margin: 0;
  padding: 5px 0px 8px 0px;
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 246px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  margin: 0px !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 8px 11px !important;
    border-radius: 4px !important;
    font-family: Roboto;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const AfterHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
`;

const ExportButton = styled(CustomButton)`
  height: 34px !important;
  border: 1px solid var(--colorNeutralForeground6) !important;
  margin-right: 20px !important;
`;

const AddButton = styled(CustomButton)`
  height: 34px !important;
`;

const ManageEntitiesutton = styled(CustomButton)`
  padding: 0px !important;
  margin: 0px 0px -2px 12px !important;
  white-space: nowrap;

  svg {
    margin-left: 4px;
    font-size: 18px;
  }

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  text-transform: uppercase;
`;

const LabelCheckbox = styled.span`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const StyledSavedButton = styled(CustomButton)`
  width: 100%;
  margin-right: 0px !important;
`;

const LineBar = styled.hr`
  border-top: 1px solid var(--colorPaletteBlueBackground1);
  margin-top: 24px !important;
  margin-bottom: 8px !important;
`;

const BottomInformationLabel = styled.label`
  font-size: 12px;
  font-weight: 400px;
  size: 12px;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
`;

const Banner = styled.div`
  padding: 16px 48px 16px 48px;
  background-color: #fffbf3;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
`;
const BannerWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--colorNeutralForeground1);
  }
`;

const LabelErrorMessage = styled.p`
  color: var(--colorPaletteRedForeground2);
  padding-top: 8px;
  font-size: 14px;
`;

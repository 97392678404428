import { FC } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { ExcelIcon } from "./ExcelIcon";
import store from "./store";
import styled from "styled-components";
import { Link } from "react-router-dom";


const ExportImportLinkBox: FC = observer(() => {

  return (
    <Box>
      <BoxExcelStyle>
        <LabelIconWrapper>
          <ExcelIcon id="EmployeesList_Employee_Panel_Employee" style={{ margin: 0 }} />
          <TextStyle
            style={{ color: "var(--colorNeutralForeground1)" }}
            id="EmployeesList_Employee_Panel_Employee_EmployeFormatText"
          >
            Employees (xlsx):
          </TextStyle>
        </LabelIconWrapper>
        <StyledLinkImport
          id="EmployeesList_Employee_Panel_Employee_ImportLink"
          to={"/employees-import"}
        >
          Import
        </StyledLinkImport>
        <TextStyle>|</TextStyle>
        <StyledLink
          id="EmployeesList_Employee_Panel_Employee_ExportLink"
          to={"/employees"}
          onClick={() => store.exportExcel()}
        >
          Export
        </StyledLink>
      </BoxExcelStyle>

    </Box>
  );
});


const TextStyle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const StyledLink = styled(Link)`
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: var(--colorBrandForeground1) !important;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLinkImport = styled(Link) `
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: ${(props) =>"var(--colorBrandForeground1)"} !important;
  pointer-events: auto!important;

  &:hover {
    text-decoration: underline;
  }
`;

const BoxExcelStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
  
`;

const LabelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;



export default ExportImportLinkBox;

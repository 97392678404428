import { FC } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import styled from "styled-components";
import store from "./store";
import AddIcon from "@mui/icons-material/Add";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { observer } from "mobx-react";
import LaunchIcon from "@mui/icons-material/Launch";
import SelectAttribute from "./SelectAttribute";
import CustomButton from "components/button/Button";
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import storeList from "features/Employees/store";
import ManagerSearch from "./managerSearch";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";
import TreeLookup from "./TreeLookup";
import employeeListStore from "./../ListEmployee/store";

type EmployeeAddEditProps = {};

const EmployeeAddEdit: FC<EmployeeAddEditProps> = observer((props) => {
  return (
    <>
      <Box id="EmployeeAttributesContent">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Header id="EmployeeList_AddEditPopUp_Attributes">Attributes</Header>
            <Link
            id="EmployeeList_AddEditPopUp_ManageAttributes"
            href={"/employees?nav=attributeList"} target="_blank">
              Manage attributes <LaunchIcon sx={{ fontSize: 20 }} />
            </Link>
          </Grid>
        </Grid>
      </Box>

      <StyledBoxContainer id="EmployeeList_AddEditPopUp_ManageAttributes_Content">
        {storeList.curentEntityOrgType === ORG_STRUCTURE_CODES.unit_based && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="EmployeeList_AddEditPopUp_ManageAttributes_Content_Panel1bhContent"
            >
              <StyledTypography>
                {"Unit "} 
                {store.SelectedStructure && (
                  <Chip
                    onDelete={() => {
                      store.idStructure = null;
                      store.SelectedStructure = null;
                    }}
                    size="small"
                    label={
                      store.SelectedStructure?.name +
                      " (Level " +
                      store.SelectedStructure?.level +
                      ")"
                    }
                  />
                )}
              </StyledTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{ m: 1 }}
                display={"block"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <TreeLookup
                  id="idStructure"
                  value={store.idStructure}
                  onChange={(e) => {
                    store.handleChange(e.target.name, e.target.value);
                    store.SelectedStructure = store.Structures.find((x) => x.id === e.target.value);
                  }}
                  data={store.Structures}
                  name="idStructure"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {storeList.curentEntityOrgType === ORG_STRUCTURE_CODES.manager_based && 
         employeeListStore.Employees.length !== 0 &&
         !store.editCheckEmployee && (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="EmployeeList_AddEditPopUp_ManageAttributes_Content_Panel1bHheader"
              >
                <StyledTypography>
                  {"Manager's email "}
                  {store.selectedManager && (
                    <Chip
                      icon={<EmailIcon />}
                      onDelete={() => {
                        store.selectedManager = null;
                      }}
                      size="small"
                      label={store.selectedManager?.email}
                    />
                  )}
                </StyledTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{ m: 1 }}
                  display={"block"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <LabelSearchText id="EmployeeList_AddEditPopUp_ManageEmail_Info">
                    Managers need to exist on the list before they can be added
                  </LabelSearchText>
                  <br />
                  <ManagerSearch />
                  <br />
                </Box>
              </AccordionDetails>
            </Accordion>
          </>
        )}

        {store.Attributes.map((attribute, index) => {
          return (
            <Accordion 
            id={`EmployeeList_AddEditPopUp_panel1bhHeader_${attribute.name}`}
            key={attribute.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id={`EmployeeList_AddEditPopUp_panel1bhHeader_${attribute.name}_button`}
                 
              >
                <StyledTypography >
                  {attribute.name}{" "}
                  {attribute.value && <Chip id={`EmployeeList_AddEditPopUp_Attribute_${attribute.name}_Sigment`} label={attribute.value} size="small" />}
                </StyledTypography>
              </AccordionSummary>
              {attribute.name === "Tenure" && (
                <>
                  <AccordionDetails>
                    <Box
                      id={`EmployeeList_AddEditPopUp_Attribute_${attribute.name}_Info`}
                      sx={{ m: 1 }}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      this attribute is calculated automatically
                    </Box>
                  </AccordionDetails>
                </>
              )}
              {attribute.name !== "Tenure" && (
                <AccordionDetails>
                  <Box
                    sx={{ m: 1 }}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <SelectAttribute
                      Id={`EmployeeList_AddEditPopUp_Atribute__${attribute.name}Select`}
                      value={attribute.value}
                      onChange={(e) => {
                        store.changeAttribute(index, e.target.value);
                      }}
                      values={attribute.values}
                    />
                    <IconButton
                    id={`EmployeeList_AddEditPopUp_Attribute_${attribute.name}SelectAddBtn`}
                      title="Add segment"
                      onClick={() => {
                        store.handleChange("openAddSegment", true);
                        store.handleChange("idAttributeOnAddSegment", attribute.id);
                        store.handleChange(
                          "newSegmentValue", 
                          !attribute.is_date_attribute ? "" : dayjs()
                        );
                      }}
                    >
                      <AddIcon fontSize="large" />
                    </IconButton>
                  </Box>

                  {store.openAddSegment && store.idAttributeOnAddSegment === attribute.id && (
                    <Box sx={{ m: 1 }}>
                      {attribute.is_date_attribute ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
                          <StyledDateField
                            id={`EmployeeList_AddEditPopUp_Attribute_${attribute.name}_Date`}
                            format="DD.MM.YYYY"
                            value={store.newSegmentValue}
                            onChange={(newValue: Dayjs) =>
                              store.handleChange("newSegmentValue", newValue)
                            }
                          />
                        </LocalizationProvider>
                      ) : (
                        <TextField
                        id={`EmployeeList_AddEditPopUp_Attribute_${attribute.name}_Input`}
                          placeholder={attribute.name}
                          value={store.newSegmentValue}
                          fullWidth
                          name="newSegmentValue"
                          onChange={(e) => {
                            store.handleChange("newSegmentValue", e.target.value);
                          }}
                          size="small"
                        />
                      )}
                      <Box sx={{ m: 1 }} display={"flex"} alignItems={"center"}>
                        <CustomButton
                          id={`EmployeeList_AddEditPopUp_Attribute_${attribute.name}_DateAddBtn`}
                          variant="contained"
                          size="small"
                          sx={{ mr: 2 }}
                          onClick={() => {
                            store.saveSegment(index);
                            store.handleChange("openAddSegment", false);
                            store.handleChange("idAttributeOnAddSegment", 0);

                            let value =
                              typeof store.newSegmentValue === "string"
                                ? store.newSegmentValue
                                : dayjs(store.newSegmentValue).format("DD.MM.YYYY").toString();

                            store.changeAttribute(index, value);
                            store.handleChange("newSegmentValue", "");
                          }}
                        >
                          Add
                        </CustomButton>

                        <CustomButton
                          id={`EmployeeList_AddEditPopUp_Attribute_${attribute.name}_DateCanselBtn`}
                          variant="contained"
                          color="inherit"
                          size="small"
                          sx={{ mr: 2 }}
                          onClick={() => {
                            store.handleChange("openAddSegment", false);
                            store.handleChange("idAttributeOnAddSegment", 0);
                            store.handleChange("newSegmentValue", "");
                          }}
                        >
                          Cancel
                        </CustomButton>
                      </Box>
                    </Box>
                  )}
                </AccordionDetails>
              )}
            </Accordion>
          );
        })}
      </StyledBoxContainer>
    </>
  );
});

export default EmployeeAddEdit;

const Header = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const StyledBoxContainer = styled(Box)`
  margin-top: 20px;
  .MuiChip-root {
    background-color: var(--colorPaletteBlueBackground1) !important;
    margin-left: 10px !important;
    text-transform: none !important;
    font-weight: 400 !important;
  }
`;

const StyledTypography = styled(Typography)`
  color: var(--colorNeutralForeground2) !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 16px;
  text-transform: uppercase;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 9px;
  color: var(--colorBrandForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledDateField = styled(DateField)`
  width: 100%;
  input {
    padding: 8.5px 14px !important;
  }
`;

const LabelSearchText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

import { IconButton, InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import { Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";

type CustomSearchFieldProps = {
  id?: string;
  searchedValue: string;
  placeholder?: string;
  disabled?: boolean;
  onClearClick?: () => void;
  onKeyDown?: (e: any) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  sx?: SxProps<Theme>;
};

const CustomSearchField = ({
  id = "Custom_Search_Field",
  searchedValue,
  placeholder = "",
  disabled = false,
  onClearClick = () => {},
  onKeyDown = () => {},
  onChange = () => {},
  sx = {},
}: CustomSearchFieldProps) => {
  return (
    <TextField
      id={id}
      disabled={disabled}
      sx={{
        minWidth: 440,
        "& .MuiInputBase-input": {
          padding: "8px",
        },
        "& .MuiInputBase-root": {
          background: "white",
        },
        ...sx,
      }}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchedValue === "" ? (
              <IconButton id="Custom_Search_Field_SearchBtn">
                <SearchIcon />
              </IconButton>
            ) : (
              <IconButton id="Custom_Search_Field_CloseBtn" onClick={onClearClick}>
                <CloseIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      value={searchedValue}
      onKeyDown={(e) => onKeyDown(e)}
      onChange={(e) => onChange(e)}
    />
  );
};

export default CustomSearchField;

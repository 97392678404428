import { FC, ReactElement } from "react";
import styled from "styled-components";
import logoImage from "../../../assets/images/er-logo-square.png";
import Filters from "../Filters";
import store from "../store";

interface PDFHeaderProps {
    name?: string;
}

const PDFHeader: FC<PDFHeaderProps> = ({ name = "Responses" }): ReactElement => {
    return (
        <HeaderContainer>
            <MainHeader>
                <LogoContainer>
                    <Logo src={logoImage} alt="EngageRocket Logo" />
                </LogoContainer>
                <HeaderTitle>Dashboard: {name}</HeaderTitle>
            </MainHeader>
            <SubHeader>
                <SubHeaderContent>
                    <SubHeaderText>{store.selectedProject.name} : {store.selectedSurvey.label}</SubHeaderText>
                    <SubHeaderText>Viewing as: {store.role}</SubHeaderText>
                </SubHeaderContent>
            </SubHeader>
            <Filters surveyId={store.selectedSurvey?.value} disableInteraction={true} />
        </HeaderContainer>
    );
};

export default PDFHeader;

const HeaderContainer = styled.div`
    width: 100%;
    background-color: #ffffff;
`;

const MainHeader = styled.div`
    background-color: #1e004d;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
`;

const LogoContainer = styled.div`
    width: 32px;
    height: 32px;
`;

const HeaderTitle = styled.h1`
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
`;

const SubHeader = styled.div`
    background-color: #ffffff;
    padding: 12px 24px;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const SubHeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const SubHeaderText = styled.span`
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.9;
`;

const Logo = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`; 
import https from "api/https";
import { AxiosResponse } from "axios";
import { API_TEMPLATE_URL_GO } from "constants/config";
import { ImportType } from "types/import-type";
import { createUrl } from "helpers/url";
import { StatusProgress } from "types/status-progress";
import { ImportStatus } from "types/import-status";

type ImportStatusProps = { entity_id: number; status?: StatusProgress; import_id?: string };

export const importStatusApi = async ({
  entity_id,
  status,
  import_id,
}: ImportStatusProps): Promise<AxiosResponse<ImportStatus[]>> => {
  const queryParams: { entity_id: string; status?: string; import_id?: string; type?: ImportType } =
    {
      entity_id: String(entity_id),
      status,
      type: "EMPLOYEE",
    };

  if (import_id) {
    queryParams.import_id = import_id;
  }

  const url = createUrl({
    baseUrl: API_TEMPLATE_URL_GO,
    path: "/api/v1/employee/employees/ImportStatus",
    query: queryParams,
  });

  const response = await https.get(url);
  if (response.status !== 200 && response.status !== 201) {
    throw new Error(response.statusText);
  }

  const importStatus: ImportStatus[] = response.data;

  return {
    data: importStatus,
    ...response,
  };
};

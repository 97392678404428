import http from "api/https";
import { createUrl } from "../helpers/url";

export const getRecepientStatusForRunningMilestone = (survey_id: number): Promise<any> => {
  const query: { survey_id: string } = {
    survey_id: survey_id.toString(),
  };
  const url = createUrl({
    path: "/sm/sm_survey_recepients/GetRunningMilestoneRecepientsCount",
    query: query,
  });
  return http.getGO(url);
};

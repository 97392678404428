import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import store from "./../../store";
import CustomButton from "components/button/Button";
import ReminderBlock from "./ReminderBlock";
import ResendEmailPopup from "./ResendEmailPopup";
import CustomModal from "components/modal/Modal";
import ReminderPopup from "./ReminderPopup";
import { formatDateToLocalTime, formatToLocalDateTime } from "utils/timeFormatter";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

type ThirdTabProps = {};

const ThirdTab: FC<ThirdTabProps> = observer(() => {
  const scheduledReminder = store.ReminderData.filter(
    ({ strtime_send_reminder, time_send_reminder }) => strtime_send_reminder && time_send_reminder
  ).length;

  return (
    <>
      <Wrapped>
        <Header id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Title">
          Reminder emails{" "}
          <StyledCountScheduled>({scheduledReminder} scheduled)</StyledCountScheduled>
        </Header>

        <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_SubTitle">
          {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Frequency
            ? "Reminder emails will be sent to those who have not completed the survey yet. Your next surveys will be automatically repeated with the same email schedule and content."
            : "Reminder emails will be sent to those who have not completed the survey yet."}
        </SubHeader>

        <LaunchSurvey id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_LaunchSurvey">
          <span id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_LaunchSurveyTitle">
            Invite email to
          </span>{" "}
          <LaunchSecondText id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_LaunchSurvey_Text">
            All recipients
          </LaunchSecondText>
          {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones ? (
            <LaunchSurveySchedule>
              {store.AllInfo?.timestart_milestone
                ? `${store.AllInfo.name} at ${formatDateToLocalTime(
                    store.AllInfo?.timestart_milestone
                  )}`
                : "Not scheduled"}{" "}
              <span>(Same as survey start time)</span>
            </LaunchSurveySchedule>
          ) : (
            <LaunchSurveySchedule>
              {store.AllInfo?.survey_date_start
                ? formatToLocalDateTime(store.AllInfo?.survey_date_start)
                : "Not scheduled"}{" "}
              <span>(Same as survey start time)</span>
            </LaunchSurveySchedule>
          )}
        </LaunchSurvey>
        <LaunchSurveyAfter />

        {store.ReminderData.map((reminder, i) => {
          return <ReminderBlock reminder={reminder} index={i} key={i} />;
        })}

        <CustomButtonStyle
          variant="outlined"
          id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_AddReminderEmail_Button"
          onClick={() => store.addNewReminder()}
        >
          <StyledPlus>+</StyledPlus>
          Add reminder email
        </CustomButtonStyle>

        <ResendEmailPopup />
      </Wrapped>
      <CustomModal
        open={store.openReminderModal}
        width="522px"
        minHeightContent={300}
        hide={(x) => store.changeReminderModal(x)}
      >
        <ReminderPopup closePopup={() => store.changeReminderModal(false)} />
      </CustomModal>
    </>
  );
});

export default ThirdTab;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const Header = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const StyledCountScheduled = styled.span`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
`;

const LaunchSurvey = styled.div`
  padding: 12px;
  background-color: var(--colorNeutralForeground4);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--colorNeutralForeground1);
`;

const LaunchSurveySchedule = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--colorNeutralForeground1);
  padding-top: 6px;

  span {
    font-weight: 400;
  }
`;

const LaunchSurveyAfter = styled.div`
  height: 15px;
  border-left: 3px dashed var(--colorPaletteBlueBackground2);
  margin-left: 20px;
`;

const LaunchSecondText = styled.span`
  font-weight: 500;
  color: var(--colorPaletteVioletBackground3);
`;

const SubHeader = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 8px;
  margin-bottom: 32px;
`;

const CustomButtonStyle = styled(CustomButton)`
  padding: 5px 40px !important;
  background-color: var(--colorPaletteGrayBackground1) !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;

  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const StyledPlus = styled.span`
  background-color: var(--colorNeutralBackground1);
  border-radius: 50%;
  font-size: large;
  padding: 0 5px;
`;

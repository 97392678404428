import { FC } from "react";
import { observer } from "mobx-react";
import { TScoredQuestionsDimension } from "types/dashboard";
import styled from "styled-components";
import {QuestionElementsRenderer} from "./QuestionElementsRenderer";

type QuestionGroupProps = {
  dimension: TScoredQuestionsDimension;
  id: string;
  localFilters: { id: number; name: string; segments: string[] }[];
};

const QuestionGroup: FC<QuestionGroupProps> = observer((props) => {

  const renderer = new QuestionElementsRenderer({
    dimension: props.dimension,
    localFilters: props.localFilters,
  });

  return (
    <Container id={props.id}>
      {renderer.render()}
    </Container>
  );
});

export default QuestionGroup;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 80px 32px 75px;
  scroll-margin-top: 320px;
`;
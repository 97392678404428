import { TOthersDimension } from "types/dashboard";
import styled from "styled-components";
import QuestionCard from "./QuestionCard";
import QuestionOptionCard from "./QuestionOptionCard";

interface OtherElementRendererConfig {
  dimension: TOthersDimension;
  id: string;
  localFilters: { id: number; name: string; segments: string[] }[];
  isForceExpand: boolean | null;
  className?: string;
  disableInteraction?: boolean;
  isLastGroup: boolean;
}

export class OtherElementRenderer {
    private config: OtherElementRendererConfig;

    constructor(config: OtherElementRendererConfig) {
        this.config = config;
    }

    private renderTitle(): React.ReactNode {
        const { dimension } = this.config;
        return (
            <TitleContainer className={this.config.className ? `${this.config.className}-title` : null}>
                <Title>{dimension?.dimension}</Title>
                <QuestionCounter>
                    ({dimension?.questionsList.length}{" "}
                    {dimension?.questionsList.length > 1 ? "questions" : "question"})
                </QuestionCounter>
            </TitleContainer>
        );
    }

    
    private renderQuestionCards(): React.ReactNode[] {
        const { dimension } = this.config;
        const questionCards: React.ReactNode[] = [];
        if (!dimension?.questionsList || dimension?.questionsList.length === 0) return [];
       dimension.questionsList.forEach((question, index) => {
            questionCards.push(
                <QuestionCard
                  question={question}
                  key={question.id}
                  isExpand  = {this.config.isForceExpand}
                  isLastQuestion={Boolean(index === this.config.dimension?.questionsList.length - 1)}
                  isAnyOtherOptions={question.otherOptionsReponses?.length > 0}
                  localFilters={this.config.localFilters}
                  className={this.config.className ? `${this.config.className}-${index}` : null}
                  disableInteraction={this.config.disableInteraction}
                />
            )
            if (question.otherOptionsReponsesCount > 0) {
                questionCards.push(
                    <QuestionOptionCard
                      question={question}
                      key={question.id}
                      isExpand  = {this.config.isForceExpand}
                      isLastQuestion={Boolean(index === this.config.dimension?.questionsList.length - 1)}
                      localFilters={this.config.localFilters}
                      className={this.config.className ? `${this.config.className}-${index}` : null}
                      disableInteraction={this.config.disableInteraction}
                    />
                )
            }
        })
        if (!this.config.isLastGroup) {
            questionCards.push(<Spacing/>);
        }
        return questionCards;
    }
    

    public render(): React.ReactNode[] {
        return [this.renderTitle(), ...this.renderQuestionCards()];
    }
}


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 80px 32px 75px;
  scroll-margin-top: 320px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorPaletteVioletBackground1);
`;

const QuestionCounter = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorPaletteVioletBackground1);
`;

const Spacing = styled.div`
  display: block;
  width: 100%;
  height: 32px;
  flex-shrink: 0;
`;
import { FC } from "react";
import { observer } from "mobx-react";

import store from "./store";
import styled from "styled-components";
import QuestionGroup from "./QuestionGroup";

type QuestionsListProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const QuestionsList: FC<QuestionsListProps> = observer((props) => {
  return (
    <Container>
      {store.othersList && store.othersList.length > 0 && store.othersList.map((dimension, index) => (
        <QuestionGroup
          key={dimension.dimension}
          dimension={dimension}
          isForceExpand={null}
          id={`group-anchor-${dimension.dimension}`}
          localFilters={props.localFilters}
          isLastGroup={index === store.othersList.length - 1}
        />
      ))}
    </Container>
  );
});

export default QuestionsList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

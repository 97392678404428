export const ENTITY_PERMISSIONS = {
  addAndRemoveEntityUsers: { code: "addAndRemoveEntityUsers", hasPermission: false },
  editEntityPermissions: { code: "editEntityPermissions", hasPermission: false },
  viewEntityPermissions: { code: "viewEntityPermissions", hasPermission: false },
  editEmployee: { code: "editEmployee", hasPermission: false },
  viewEmployee: { code: "viewEmployee", hasPermission: false },
  createAndEditSurvey: { code: "createAndEditSurvey", hasPermission: false },
  viewSurveySettings: { code: "viewSurveySettings", hasPermission: false },
  viewDashboard: { code: "viewDashboard", hasPermission: false },
};

export const WORKSPACE_PERMISSIONS = {
  addAndRemoveWorkspaceUsers: { code: "addAndRemoveWorkspaceUsers", hasPermission: false },
  editWorkspacePermissions: { code: "editWorkspacePermissions", hasPermission: false },
  viewWorkspacePermissions: { code: "viewWorkspacePermissions", hasPermission: false },
};

export const PROJECT_PERMISSIONS = {
  createAndEditProject: { code: "createAndEditProject", hasPermission: false },
  viewSurveySettingsProject: { code: "viewSurveySettingsProject", hasPermission: false },
  viewAllRespondentsData: { code: "viewAllRespondentsData", hasPermission: false },
  viewOwnTeamData: { code: "viewOwnTeamData", hasPermission: false },
};

import http from "api/https";

export const useResendEmails = (employee_ids: any, emails: string[], reminder_id: number, isManagerReminder = false): Promise<any> =>{
  return http.postGO("/sm/sm_comms_reminder/SendReminder", {
    employee_ids,
    reminder_id,
    is_manager_reminder: isManagerReminder,
    emails,
  });
}

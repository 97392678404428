import { FC } from "react";
import { sm_question } from "types/templates";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Box, Checkbox, Divider, Tooltip } from '@mui/material'

import store from './storeSurvey'
import CustomButton from "components/button/Button";
import { newQuestions } from "types/surveys";

export type questionTypeProps = {
  item: sm_question[]
  onQuestionsAdded: (questions: newQuestions[], commTemplateId: number) => void
}
const QuestionCard: FC<{ item: sm_question, index: number; }> = observer((props) => {

  return <Item key={props.item.id} id={"SelectTemplateQuestionLibraryQuestion_" + props.index}>
    <CardBlock>

      <LeftSideCard>

        <TextSecondary id={"SelectTemplateQuestionLibraryQuestionType_" + props.index}>
          {props.item.type_question_idNavName}
        </TextSecondary>
        <TextPrymary id={"SelectTemplateQuestionLibraryQuestionTitle_" + props.index}>
          {props.item.title}
        </TextPrymary>
      </LeftSideCard>
      <CheckboxWrapp>
        {store.addedQuestionNames.includes(props.item.id) ?
          <Tooltip id={"SelectTemplateQuestionLibraryCheckboxToolTip_" + props.index} title="Question already added">
            <div>
              <Checkbox
                name="isCheked"
                disabled={true}
                id={"SelectTemplateQuestionLibraryCheckbox_" + props.index}
                onChange={(e) => store.setCheckQuestion(props.item.id)} size="small"
                checked={true}
              />
            </div>
          </Tooltip> :
          <Checkbox
            name="isCheked"
            disabled={store.addedQuestionNames.includes(props.item.id)}
            id={"SelectTemplateQuestionLibraryCheckbox_" + props.index}
            onChange={(e) => store.setCheckQuestion(props.item.id)} size="small"
            checked={store.selectedQuestionsId.includes(props.item) || store.addedQuestionNames.includes(props.item.id)}
          />}
      </CheckboxWrapp>
    </CardBlock>
  </Item>
});

const QuestionTable: FC<questionTypeProps> = observer((props) => {

  return <QuestionContent id={"SelectTemplateQuestionLibrary"}>

    <HeaderContent>
      <HeaderTitle id={"SelectTemplateQuestionLibraryHeaderLabel"}>Get started by selecting questions from a template that best fits your objectives</HeaderTitle>
      <HeaderActions>
        <HeaderDescription id={"SelectTemplateQuestionLibraryHeaderDescription"}><span id={"SelectTemplateQuestionLibraryHeaderQuestionsCount"}>{store.selectedQuestionsId.length}</span> questions</HeaderDescription>
        <CustomButton
          variant="contained"
          id={"SelectTemplateQuestionLibraryAddCheckedQuestionButton"}
          disabled={store.selectedQuestionsId.length === 0}
          onClick={() => {            
            let add_quest = store.selectedQuestionsId.map((x): newQuestions => {
              return {
                id: x.id,
                name: x.name,
                title: x.title,
                dimension: x.dimension_idNavName,
                dimension_id: x.dimension_id,
                type_id: x.type_question_id,
                type: x.type_question_code,
                questionJson: x.questionJson
              }
            })

            props.onQuestionsAdded(add_quest, store.selectedCommunication)
          }}
        >
          Add checked questions
        </CustomButton>
      </HeaderActions>

    </HeaderContent>
      <Divider />
      <ContainerMain>

    <SelectButtons>

      <Box display="flex-end" justifyContent="space-between">
        <CustomButton
          disabled={store.selectedQuestionsId.length === 0}
          id={"SelectTemplateQuestionLibraryDeselectAllButton"}
          onClick={() => {
            store.deselectAllQuestions()
          }}
        >
          Deselect all
        </CustomButton>
        <CustomButton
          disabled={store.selectedQuestionsId.length + store.addedQuestionNames.filter(x => store.questionsData.map(x => x.id).includes(x)).length === store.questionsData.length}
          id={"SelectTemplateQuestionLibrarySelectAllButton"}
          onClick={() => {
            store.selectAllQuesions()
          }}
        >
          Select all
        </CustomButton>
      </Box>
    </SelectButtons>

    {props.item.map((x, i) => (
      <QuestionCard index={i} key={x.id} item={x} />
    ))}
    </ContainerMain>
  </QuestionContent>
});


const CardBlock = styled.div`
  border-radius: 4px;
  border: 1px solid var(--ER-Theme-sys-light-outline, #F1F3F8);
  background: var(--ER-Theme-white, #FFF);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 7px 0;
`

const Item = styled.div`
  margin-bottom: 20px; 
`

const LeftSideCard = styled.div`

`

const CheckboxWrapp = styled.div`
  min-width: 40px;
  min-height: 40px;
`

const TextSecondary = styled.p`
  color: var(--ER-Theme-sys-light-on-tertiary-fixed-variant, #959BA2);
  /* ER Theme/label/small */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

const QuestionContent = styled.div`
`;

const HeaderContent = styled.div`
   padding: 20px 30px 10px;
`;

const ContainerMain = styled.div`
  padding: 20px 30px;
  overflow-y: scroll;
  height: 555px;

  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorGrayForeground6);
  }
`;

const SelectButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`

const HeaderTitle = styled.div`
  color: var(--colorNeutralForeground2, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const HeaderDescription = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 10px 0;
`;

const TextPrymary = styled.p`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  /* ER Theme/body/large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;


export default QuestionTable;

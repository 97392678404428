import http from "api/https";
import { createUrl } from "../../helpers/url";

export const updateUserRole = (
  newName: string,
  entityId: number,
  currentRoleId: number,
): Promise<any> => {
  const data: {
    name: string;
    entity_id: number;
  } = {
    name: newName,
    entity_id: entityId,
  };

  const url = createUrl({
    path: "/permission/entity_permissions/Role/" + currentRoleId,
  });
  return http.putGO(url, data);
};

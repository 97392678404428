import { observer } from "mobx-react";
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { EMPTY_STRING_FILTERS } from "constants/dashboard-menu";
import { useLocation, useSearchParams } from "react-router-dom";
import { FC, useCallback, useEffect, useState, useRef } from "react";

import Menu from "./Menu";
import store from "./store";
import styled from "styled-components";
import Header from "./Header";
import Filters from "./Filters";
import TrendScreen from "./trend/TrendScreen";
import OverviewScreen from "./overview/OverviewScreen";
import HotspotsScreen from "./hotspots/HotspotsScreen";
import ResponsesScreen from "./responses/ResponsesScreen";
import PrioritizeScreen from "./prioritize/PrioritizeScreen";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import NoSurveysAvailable from "./NoSurveysAvailable";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import PdfDownloadDialog from './pdfExport/PdfDownloadDialog';
import responsesStore from './responses/store';
import PDFContent from "./pdfExport/PDFContent";

type DashboardScreenProps = {};

const DashboardScreen: FC<DashboardScreenProps> = observer(() => {
  const [isSticky, setIsSticky] = useState(false);
  const [localFilters, setLocalFilters] = useState<
    { id: number; name: string; segments: string[] }[]
  >([]);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [_, setQueryParams] = useSearchParams();
  const pdfContentRef = useRef<any>(null);

  let { search } = useLocation();

  // Add ref to track scroll handler setup
  const scrollHandlerRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const tab = queryParams.get("target");

    if (tab === 'hotspots' && StorePermission.features.dashboard_hotspot.hasFeature) {
      store.setData("hotspots", "activeTab");
    } else if (StorePermission.features.dashboard_overview.hasFeature) {
      store.setData("overview", "activeTab");
    } else if (StorePermission.features.dashboard_responses.hasFeature) {
      store.setData("responses", "activeTab");
    } else if (StorePermission.features.dashboard_hotspot.hasFeature) {
      store.setData("hotspots", "activeTab");
    } else if (StorePermission.features.dashboard_trends.hasFeature) {
      store.setData("trend", "activeTab");
      store.setData(null, "selectedSurvey");
    } else if (StorePermission.features.dashboard_prioritize.hasFeature) {
      store.setData("prioritize", "activeTab");
    }
  }, [StorePermission.featuresLoaded, search]);

  useEffect(() => {
    StoreLayout.getPermissions();
    store.loadFeatureFlagDashboardUnitFilter();
  }, []);

  useEffect(() => {
    const filters = store.filters
      .map(
        (el, index) =>
          el.unit_or_attr && {
            id: index,
            name: el.unit_or_attr.name,
            segments: el.segments,
          }
      )
      .filter((i) => i);
    setLocalFilters(filters);
  }, [store.filters]);

  useEffect(() => {
    const mainScrollContent = document.getElementById('main-scroll-content');
    if (mainScrollContent) {
      mainScrollContent.scrollTop = 0;
      setIsSticky(false);
    }
  }, []);

  const handleScroll = (el: HTMLElement) => {
    setIsSticky(Boolean(el.scrollTop > 128));
  };

  useEffect(() => {
    const el = document.getElementById("main-scroll-content");
    if (!el) return;

    el.addEventListener("scroll", () => handleScroll(el));

    return () => {
      window.removeEventListener("scroll", () => handleScroll(el));
    };
  });

  const handlePdfModalOpen = () => {
    responsesStore.dashboardResponsesRaw = null;
    setIsPdfModalOpen(true);
  };

  const handlePdfModalClose = () => {
    setIsPdfModalOpen(false);
  };

  const renderActiveTab = useCallback(() => {
    switch (store.activeTab) {
      case "overview":
        return <OverviewScreen localFilters={localFilters} onPdfDownload={handlePdfModalOpen} />;
      case "responses":
        return <ResponsesScreen localFilters={localFilters} onPdfDownload={handlePdfModalOpen} />;
      case "hotspots":
        return <HotspotsScreen />;
      case "trend":
        return <TrendScreen localFilters={localFilters} onPdfDownload={handlePdfModalOpen} />;
      case "prioritize":
        return <PrioritizeScreen localFilters={localFilters} onPdfDownload={handlePdfModalOpen} />;
      default:
        return <></>;
    }
  }, [store.activeTab, store.projectInfo, localFilters]);

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem("dashboard_filters"));

    if (filters) store.setData(filters, "filters");

    return () => {
      const stringFilters = JSON.stringify(EMPTY_STRING_FILTERS);
      localStorage.setItem("dashboard_filters", stringFilters);
    };
  }, []);

  useEffect(() => {
    const stringFilters = JSON.stringify(store.filters);
    if (store.filters && store.filters.length > 0 && store.filters[0].unit_or_attr)
      localStorage.setItem("dashboard_filters", stringFilters);
    else localStorage.setItem("dashboard_filters", JSON.stringify(EMPTY_STRING_FILTERS));
  }, [store.filters]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const project_id = queryParams.get("project_id");

    store.getSurveyProjects(project_id, setQueryParams);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const survey_id = queryParams.get("survey_id");

    if (store.selectedProject) store.getSurvey(store.selectedProject.id, survey_id, setQueryParams);
  }, [store.selectedProject, store.activeTab]);

  useEffect(() => {
    if (store.selectedSurvey) store.getBenchmarkList(store.selectedSurvey.value);
  }, [store.selectedSurvey]);

  const hasSurveys = store.selectedSurvey || store.selectedProject;
  const blockingRequestPending = store.isSurveyProjectsPending || store.isSurveyPending;

  if (blockingRequestPending) {
    return (
      <NoContentContainer>
        <CircularProgress />
      </NoContentContainer>
    );
  }

  if (!hasSurveys) {
    return (
      <Container>
        <NoSurveysAvailable />
      </Container>
    );
  }

  return (
    <Container>
      <PDFContent ref={pdfContentRef} sections={[...store.overviewPagesName, ...store.responsesPagesName, ...store.prioritizePagesName]} />
      <StickyHeader $isSticky={isSticky}>
        <Header />
        {store.activeTab !== "hotspots" && <Filters surveyId={store.selectedSurvey?.value} />}
        <Menu isSticky={isSticky} />
      </StickyHeader>
      <Content>{renderActiveTab()}</Content>
      <PdfDownloadDialog 
        localFilters={localFilters}
        open={isPdfModalOpen}
        onClose={handlePdfModalClose}
        onDownload={() => {
          pdfContentRef.current?.downloadPDF();
        }}
      />
    </Container>
  );
});

export default DashboardScreen;

const NoContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 142px 0px 142px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StickyHeader = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  padding: 80px 142px 0px 142px;
  position: sticky;
  top: -48px;
  transition: all 0.3s;
  z-index: 10;
  background-color: ${props => 
    props.$isSticky ? 'var(--colorNeutralBackground1)' : 'transparent'};
`;

const Content = styled.div`
  width: 100%;
  padding: 0px 142px 80px 142px;
`;
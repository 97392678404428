import DimensionsList from "./DimensionsList";
import GraphView from "./GraphView";
import PrioritizeContent from "./PrioritizeContent";

interface PrioritizeElementRendererConfig {
    localFilters: { id: number; name: string; segments: string[] }[];
}

export class PrioritizeElementRenderer {
    private props: PrioritizeElementRendererConfig;
    constructor(props: PrioritizeElementRendererConfig) {
        this.props = props;
    }

    public render() : React.ReactNode {
        return <PrioritizeContent localFilters={this.props.localFilters} />  
    }
}
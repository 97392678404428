import { observer } from "mobx-react";
import { HighchartsReact } from "highcharts-react-official";
import { FAVOURABLE, NEUTRAL } from "constants/dashboard-overview";
import { FC, useEffect, useMemo, useState } from "react";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import store from "../../store";
import { CardLoader } from "../../CardLoader"
import styled, { css } from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import Highcharts from "highcharts/highstock";
import useWindowSize from "utils/use-window-size";

type OveralScoreCardProps = {};

const OveralScoreCard: FC<OveralScoreCardProps> = observer(() => {
  const { width } = useWindowSize();
  const { isOveralScoreEmpty } = store;

  const returnFavorableValue = () => {
    if (isOveralScoreEmpty) {
      return "–";
    }
    const favourable = store.projectInfo?.overalScore?.categories.find(
      (el) => el.name === FAVOURABLE
    );
    if (favourable) return `${favourable.percent}%`;
    else return "0%";
  };

  const graphData = useMemo(() => {
    if (!store?.projectInfo?.overalScore?.categories) {
      return []
    }
    return store.projectInfo?.overalScore?.categories
      .map((el) => ({
        name: el.name,
        color:
          el.name === FAVOURABLE
            ? "var(--colorPaletteGreenBackground3)"
            : el.name === NEUTRAL
              ? "var(--colorNeutralForeground10)"
              : "var(--colorPaletteRedForeground1)",
        y: el.percent,        
      }))
      .sort(function (a, b) {
        if (a.name[0] < b.name[0]) return -1;
        if (a.name[0] > b.name[0]) return 1;
        return 0;
      });
  }, [store.projectInfo?.overalScore]);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      alignItems: "left",
      height: width > 1439 ? 200 : 320,
      events: {
        render: function () {
          const chart = this;
          const textX = chart.plotLeft + chart.series[0].center[0];
          const textY = chart.plotTop + chart.series[0].center[1];

          if (chart.centerText) chart.centerText.destroy();

          chart.centerText = chart.renderer
            .html(
              `<div 
                style="display:flex;
                width: 100%; 
                flex-direction:column; 
                align-items: center; 
                justify-content: center;">
                <p 
                  style="font-size: 32px">
                  ${returnFavorableValue()}
                </p>
               ${store.projectInfo?.overalScore?.prevSurveyId > 0
                ? `<p 
                style="font-size: 14px; 
                text-align: center;
                z-index: 1;">
                ${store.projectInfo?.overalScore?.show_diff_trend &&
                `<img style="margin-right: 4px;" src="${store.projectInfo?.overalScore &&
                  store.projectInfo?.overalScore?.percent_trend >= 0
                  ? `/assets/green-arrow.svg`
                  : `/assets/red-arrow.svg`
                }"/>` || ""}
                ${(store.projectInfo?.overalScore && store.projectInfo?.overalScore?.show_diff_trend &&
                  typeof store.projectInfo?.overalScore?.percent_trend === "number")
                  ? Math.abs(store.projectInfo?.overalScore?.percent_trend) + "%"
                  : "-"
                }
              </p>`
                : ""
              }
              </div>`,
              textX,
              store.selectedSurvey?.isFirst ? textY - 4 : textY - 10
            )
            .add();

          chart.centerText.attr({
            x: textX - chart.centerText.getBBox().width / 2,
          });
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: "",
    },

    legend: {
      symbolRadius: 0,
      layout: "vertical",
      align: width > 1439 ? "right" : "center",
      verticalAlign: width > 1439 ? "middle" : "bottom",
      labelFormatter: function () {
        const value = isOveralScoreEmpty ? "-" : `${this.y}%`
        return (
          "<span style={{color:var:(--colorNeutralForeground1)}}>" +
          this.name +
          ": </span>" +
          value
        );
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "",
        color: "var(--colorNeutralForeground2)",
        lineWidth: 1,
        size: "100%",
        borderRadius: 0,
        innerSize: "85%",
        data: graphData,
      },
    ],
  };

  const content = (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
      {/* <BenchmarkContainer>
          <BenchmarkText>Benchmark:</BenchmarkText>
          {(store.projectInfo?.overalScore && store.projectInfo?.overalScore.benchmark_trend) ||
            (store.projectInfo?.overalScore &&
              store.projectInfo?.overalScore.benchmark_trend === 0) ? (
            <>
              <BenchmarkPercent>
                {store.projectInfo?.overalScore &&
                  store.projectInfo?.overalScore?.benchmark_trend > 0 &&
                  "+"}
                {(store.projectInfo?.overalScore &&
                  store.projectInfo?.overalScore.benchmark_trend) + "%" || "0"}
              </BenchmarkPercent>

              <BenchmarkOptionPercent>
                (
                {(store.projectInfo?.overalScore &&
                  store.projectInfo?.overalScore.benchmark_total) ||
                  "0"}
                %)
              </BenchmarkOptionPercent>
            </>
          ) : (
            <EmptyBenchmark>Not available</EmptyBenchmark>
          )}
        </BenchmarkContainer> */}
    </>
  )

  const isPending = store.isOveralScorePending || !store.projectInfo

  return (
    <Card>
      <TitleRow>
        <Title>Overall score</Title>
        <CustomWidthTooltip
          title="Overall Score represents the average of question favorable scores, for questions that contribute to Overall score."
          placement="top"
          arrow
        >
          <StyledInfoIcon />
        </CustomWidthTooltip>
        {isOveralScoreEmpty && <EmptyStateTitle>Overall score is not available for this survey.</EmptyStateTitle>}
      </TitleRow>
      <ContentWrapper isEmpty={isOveralScoreEmpty}>
        {isPending ? <CardLoader contentHeight={229} /> : content}
      </ContentWrapper>
    </Card>
  );
});

export default OveralScoreCard;

const Card = styled.div`
  width: 32%;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);

  @media (max-width: 1439px) {
    width: 50%;
  }
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const EmptyStateTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
  margin-left: auto;
  line-height: 16px;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  color: var(--colorBrandForeground1);
  font-size: 20px !important;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});

const BenchmarkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const BenchmarkText = styled.p`
  color: var(--colorNeutralForeground1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
`;

const BenchmarkPercent = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: var(--colorPaletteVioletBackground3);
  margin: 0px 4px -3px 7px;
`;

const BenchmarkOptionPercent = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
`;

const EmptyBenchmark = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
  margin-left: 4px;
`;

type CustomWrapperProps = {
  isEmpty?: boolean;
}

const ContentWrapper = styled.div<CustomWrapperProps>`
  width: 100%;
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => Boolean(props.isEmpty) && css`
    opacity: 0.4;
    pointer-events: none;
  `}
`;

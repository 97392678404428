import http from "api/https";
import { createUrl } from "../../helpers/url";
import { PermissionRequest } from "types/permission";

export const editEntityEmployeePermission = (
  entityId: number,
  permissions: PermissionRequest[],
): Promise<any> => {
  const data: {
    entity_id: number;
    permissions: PermissionRequest[];
  } = {
    entity_id: entityId,
    permissions: permissions,
  };

  const url = createUrl({
    path: "/permission/entity_permissions/Permissions",
  });
  return http.putGO(url, data);
};

export const AFTER_HEADER_MENU = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },
  {
    name: "Survey projects",
    path: "/projects",
  },
  {
    name: "Templates",
    path: "/templates",
  },
  {
    name: "Employees",
    path: "/employees",
  },
  {
    name: "Manage entities",
    path: "/entities",
  },
  {
    name: "Workspace permissions",
    path: "/workspacePermissions",
  },
  {
    name: "Entity permissions",
    path: "/entityPermissions",
  }
];

export const WORKSPACE_PERMISSIONS_TABS = {
  user: "user",
  role: "role",
};

export const ENTITY_PERMISSIONS_TABS = {
  user: "user",
  role: "role",
};

export const EMPLOYEES_TABS = {
  employeeList: "employeeList",
  attributeList: "attributeList",
  structureList: "structure",
};

export const HEADER_MENU_PATH = {
  dashboard: "/dashboard",
  projects: "/projects",
  templates: "/templates",
  employees: "/employees",
  entityPermissions: "/entityPermissions",
  workspacePermissons: "/workspacePermissions",
  entities: "/entities",
}

import { Fragment, useEffect, useMemo, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import store from "../store";
import { observer } from "mobx-react";
import styled, { css } from "styled-components";
import {
  CheckedEligibility,
  Management,
  PermissionInEntity,
  RoleInEntity,
  SavedEligibility,
} from "types/permission";
import {
  DEFAULT_ENTITY_ADMINS,
  DEFAULT_UNIT_HEADS,
  ENTITY_AND_EMPLOYEE_CODE,
} from "constants/EntityEmployeePermission";
import CustomSquareCheckbox from "components/customSquareCheckbox";

type TablePermissionProps = {
  onHasChanges: (hasChanges: boolean) => void;
};

const TablePermission = observer(({ onHasChanges }: TablePermissionProps) => {
  const [rolesInEntity, setRolesInEntity] = useState<RoleInEntity[]>([]);
  const [permissionsInEntity, setPermissionsInEntity] = useState<PermissionInEntity[]>([]);
  const [displayedEligibility, setDisplayedEligibility] = useState<SavedEligibility[]>([]);
  const [originalEligibility, setOriginalEligibility] = useState<SavedEligibility[]>([]);

  useEffect(() => {
    setRolesInEntity(store.rolesInEntity);
  }, [store.rolesInEntity]);

  useEffect(() => {
    setPermissionsInEntity(store.permissionsInEntity);
  }, [store.permissionsInEntity]);

  useEffect(() => {
    const initialEligibility = permissionsInEntity.flatMap((permission) =>
      permission.managements.flatMap((management) =>
        management.features.flatMap((feature) => {
          // For each feature, we'll reset the index from 0 to 9
          return feature.eligibility.map((eligibility, eligibilityIndex) => {
            // Find the role from rolesInEntity, sequence is the same with eligibility
            const role = rolesInEntity[eligibilityIndex];

            return {
              index: eligibilityIndex,
              managementId: management.id,
              featureCode: feature.code, // Feature ID like 'editEntityPermissions'
              roleId: role.id, // role id from rolesInEntity, need it for updating
              roleName: role.name, // Role Name from rolesInEntity
              checked: eligibility.has_permission, // Checked is true if roleName exists
            };
          });
        }),
      ),
    );

    setDisplayedEligibility(initialEligibility);
    setOriginalEligibility(initialEligibility);
  }, [permissionsInEntity, rolesInEntity]);

  const hasChanges = useMemo(() => {
    let isChanged = false;

    displayedEligibility?.forEach((item, index) => {
      const original = originalEligibility[index];

      if (item.checked !== original?.checked) {
        const itemToBeUpdated: CheckedEligibility = {
          featureCode: item.featureCode,
          roleId: item.roleId,
          checked: item.checked,
        };
        store.appendToPermissionUpdate(itemToBeUpdated);
      } else {
        store.RemoveFromPermissionUpdate(item.roleId, item.featureCode);
      }

      if (isChanged) return;
      isChanged = item.checked !== original?.checked;
    });

    return isChanged;
  }, [displayedEligibility, originalEligibility]);

  useEffect(() => {
    onHasChanges(hasChanges);
  }, [hasChanges, onHasChanges]);

  const handleCheckboxChange = (
    roleIndex: number,
    roleId: number,
    featureCode: string,
    managementId: number,
  ) => {
    setDisplayedEligibility((prev) => {
      let isEditChecked: boolean | undefined = undefined;

      return prev.map((item) => {
        const isSameRole = item.roleId === roleId;
        const isSameManagement = item.managementId === managementId;
        const isEditFeature = item.featureCode.includes("edit");
        const isViewFeature = item.featureCode.includes("view");
        const isClickedItem =
          item.index === roleIndex &&
          item.featureCode === featureCode &&
          isSameRole &&
          isSameManagement;

        // If user clicked an "edit" checkbox -> Toggle it and store the new value
        if (isClickedItem && isEditFeature) {
          isEditChecked = !item.checked;
          return { ...item, checked: isEditChecked };
        }

        // If "edit" was checked, force the related "view" checkbox to be checked
        if (isEditChecked && isSameRole && isSameManagement && isViewFeature) {
          return { ...item, checked: true };
        }

        // If user clicked a "view" checkbox
        if (isClickedItem && isViewFeature) {
          // Find if related "edit" checkbox is checked
          const isRelatedEditChecked = prev.some(
            (el) =>
              el.roleId === roleId &&
              el.managementId === managementId &&
              el.featureCode.includes("edit") &&
              el.checked,
          );

          return { ...item, checked: isRelatedEditChecked ? true : !item.checked };
        }

        return item;
      });
    });
  };

  const renderMovingRows = (management: Management) => {
    const features = management.features;
    if (features.length === 0) return null;

    const maxEligibilityLength = Math.max(...features.map((f) => f.eligibility.length));
    return (
      <>
        {[...Array(maxEligibilityLength)].map((_, roleIndex) => {
          return (
            <TableCellMoveable key={roleIndex}>
              <EligibilityContainer>
                {features.map((feature) => {
                  if (displayedEligibility.length > 0) {
                    const eligibility = displayedEligibility.find(
                      (item) => item.featureCode === feature.code && item.index === roleIndex,
                    );

                    if (eligibility) {
                      return (
                        <div
                          id={`${eligibility.featureCode}-${eligibility.roleId}`}
                          key={`${eligibility.featureCode}-${eligibility.roleId}`}
                        >
                          {eligibility.roleName?.toLowerCase() === DEFAULT_ENTITY_ADMINS ? (
                            <label>Always enabled</label>
                          ) : (
                            <CustomSquareCheckbox
                              id={`Permission_Option_Checkbox_${roleIndex}`}
                              size="small"
                              checked={eligibility?.checked}
                              onChange={() =>
                                handleCheckboxChange(
                                  roleIndex,
                                  eligibility.roleId,
                                  eligibility.featureCode,
                                  management.id,
                                )
                              }
                            />
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return <></>;
                  }
                })}
              </EligibilityContainer>
            </TableCellMoveable>
          );
        })}
      </>
    );
  };

  const renderRows = () => {
    if (permissionsInEntity.length === 0) return <></>;

    const managementList: Management[] =
      permissionsInEntity.find((item) => item.code === ENTITY_AND_EMPLOYEE_CODE)?.managements ?? [];

    return (
      <TableBody id={`EntityPermissions_Roles_Grid_Table_Body`}>
        {managementList.map((management, managementIndex) => (
          <RowCustomWrapper
            id={`EntityPermissions_Roles_Grid_Row_${managementIndex}`}
            key={management.id}
          >
            <TableCellFreeze id={`EntityPermissions_Roles_Grid_Row_Table_0_${managementIndex}`}>
              <FlexAlignCenter>{management.name} management</FlexAlignCenter>
              {management.features.map((feature, featureIndex) => (
                <Fragment key={featureIndex}>
                  <FeaturesContainer>
                    <label>{feature.name}</label>
                  </FeaturesContainer>
                </Fragment>
              ))}
            </TableCellFreeze>

            {renderMovingRows(management)}
          </RowCustomWrapper>
        ))}
      </TableBody>
    );
  };

  return (
    <>
      <TableContainerWrapper>
        <TableWrapper aria-label="simple table">
          <TableHead id={`EntityPermissions_Roles_Grid_Table_Head`}>
            <EmptyTableCellNav>
              <FlexAlignCenter>User roles</FlexAlignCenter>
            </EmptyTableCellNav>

            {rolesInEntity.map((role, indexRole) => {
              return (
                <TableCellRoles
                  id={`EntityPermissions_Roles_Grid_Table_${indexRole}`}
                  align="left"
                  key={role.id}
                >
                  <FlexAlignCenter id={`EntityPermissions_Roles_Grid_Table_${indexRole}`}>
                    <HeaderTitleWrapper>
                      <RoleWrapper>
                        <RoleName>
                          {[DEFAULT_ENTITY_ADMINS, DEFAULT_UNIT_HEADS].includes(
                            role.name.toLowerCase(),
                          ) ? (
                            <img
                              src={"/assets/icons/people-and-users.svg"}
                              width={24}
                              height={24}
                              alt="people and users"
                            />
                          ) : (
                            <img
                              src={"/assets/icons/role-user.svg"}
                              width={24}
                              height={24}
                              alt="role users"
                            />
                          )}

                          <label>{role.name}</label>
                          {[DEFAULT_ENTITY_ADMINS, DEFAULT_UNIT_HEADS].includes(
                            role.name.toLowerCase(),
                          ) && <span>*</span>}
                        </RoleName>
                      </RoleWrapper>
                      <UserCountLabel>
                        ({role.count} {role.count > 1 ? "users" : "user"})
                      </UserCountLabel>
                    </HeaderTitleWrapper>
                  </FlexAlignCenter>
                </TableCellRoles>
              );
            })}
          </TableHead>

          {renderRows()}
        </TableWrapper>
      </TableContainerWrapper>

      <InformationLabel>
        * These are default user roles which cannot be deleted or renamed.
      </InformationLabel>
    </>
  );
});

const afterStyle = css`
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  width: 5px;
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--colorNeutralBackground3) 0,
    var(--colorNeutralBackground1) 6px
  ) !important;
  z-index: 2;
`;

const TableContainerWrapper = styled(TableContainer)`
  margin-bottom: 16px;
  box-shadow: none !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    margin-left: 216px !important;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid transparent;
    background-clip: padding-box;
    background: rgb(177, 177, 177);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(145, 146, 145);
  }

  .MuiTableCell-root {
    border-bottom: 0 !important;
  }
`;

const InformationLabel = styled.label`
  font-size: 14px;
  font-weight: 400px;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const RowCustomWrapper = styled(TableRow)`
  && {
    border-bottom: 1px solid var(--colorNeutralBackground3) !important;
  }
`;

const TableWrapper = styled(Table)`
  min-width: 300px;
  width: auto !important;
`;

const EmptyTableCellNav = styled(TableCell)`
  position: sticky;
  left: 0;
  background: var(--colorPaletteBlueBackground3) !important;
  z-index: 2;
  max-width: 216px;
  width: 216px;
  vertical-align: baseline;
  padding: 12px 12px 32px 12px;

  && {
    border-bottom: 1px solid var(--colorNeutralBackground3) !important;
  }

  &::after {
    ${afterStyle}
  }
`;

const TableCellRoles = styled(TableCell)`
  width: 216px;
  border-right: 1px solid var(--colorNeutralBackground3);
  background-color: var(--colorGrayForeground7) !important;
  padding: 12px 12px 32px 12px;

  && {
    border-bottom: 1px solid var(--colorNeutralBackground3) !important;
  }
`;

const EligibilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 42px;
  align-items: left;

  label {
    color: var(--colorNeutralForeground2);
    font-size: 14px;
    font-weight: 400;
  }
`;

const TableCellFreeze = styled(TableCell)`
  position: sticky;
  left: 0;
  background: var(--colorPaletteBlueBackground3) !important;
  z-index: 1;
  width: 216px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  &::after {
    ${afterStyle}
  }
`;

const TableCellMoveable = styled(TableCell)`
  min-width: 216px;
  max-width: 216px;
  border-right: 1px solid var(--colorNeutralBackground3);
  background-color: var(--colorNeutralBackground1);
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 12px;
    line-height: 16px;
    color: var(--colorNeutralForeground2);
  }
`;

const UserCountLabel = styled.label`
  padding-left: 28px;
`;

const FeaturesContainer = styled.div`
  font-size: 14px;
  padding-left: 24px;
`;

const RoleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const RoleName = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;

  label {
    color: var(--colorNeutralForeground1, #3e4450);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

export default TablePermission;

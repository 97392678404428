import { observer } from "mobx-react";
import { FC, useEffect } from "react";

import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import QuestionsList from "./QuestionsList";
import DimensionsList from "./DimensionsList";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import ErrorPage from "components/errors/ErrorPage";

type OthersTabProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const OthersTab: FC<OthersTabProps> = observer((props) => {
  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "responses" &&
      topStore.responsesActiveLocalTab === "others"
    ) {
      store.getDashboardResponsesOtherDimensions(
        StoreLayout.currentWorkspaceId,
        StoreLayout.currentEntityId,
        topStore.selectedProject.id,
        topStore.selectedSurvey.value,
        props.localFilters
      );
    }
  }, [
    topStore.selectedSurvey,
    topStore.activeTab,
    topStore.role,
    topStore.overviewBenchmark,
    topStore.filterUnitList,
    props.localFilters,
  ]);

  return (
    <>  
      {topStore.isDashboardDataError ? (
        <ErrorPage />
      ) : (
        <Container>
          <DimensionsList />
          <Content>
        <QuestionsList localFilters={props.localFilters} />
      </Content>
    </Container>
      )}
    </>
  );
});

export default OthersTab;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--colorNeutralBackground1);
  border: 1px solid var(--colorNeutralForegroundInverted2);
  padding: 48px 0px;
`;

const Content = styled.div`
  width: calc(100% - 191px);
`;

import { FC, ReactElement } from "react";
import styled from "styled-components";

interface PDFSubHeaderProps {
    title: string;
    className?: string;
}

const PDFSubHeader: FC<PDFSubHeaderProps> = ({ title, className }): ReactElement => {
    return (
        <SubHeaderContainer className={className}>
            <SubHeader>
                <SubHeaderContent>
                    <SubHeaderText>{title}</SubHeaderText>
                </SubHeaderContent>
            </SubHeader>
        </SubHeaderContainer>
    );
};

export default PDFSubHeader;

const SubHeaderContainer = styled.div`
    width: 100%;
`;

const SubHeader = styled.div`
    background-color: #1e004d;
    padding: 12px 24px;
    width: 100%;
`;

const SubHeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const SubHeaderText = styled.span`
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
`; 
import { API_TEMPLATE_URL_GO } from "constants/config";
import { createUrl } from "../../helpers/url";
import { createAuthService, Environment, Platform } from "@engagerocketco/auth-sdk";

const useGetExportEmployees = (entity_id: number) => {
  const query: { entity_id: string } = { entity_id: entity_id.toString() };

  const url = createUrl({
    path: API_TEMPLATE_URL_GO + "/employee/employees/GetExcelEmployees",
    query: query,
  });

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${createAuthService({
        platform: Platform.MAIN,
        environment: process.env.REACT_APP_ENVIRONMENT as Environment,
      }).getStoredTokens().accessToken}`,
    }
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.blob();
  });
};

export default useGetExportEmployees; 

import { WEEKDAY } from "constants/weekDay";
import { UpdateProjects } from "api/useUpdateProjects";
import { GetOneByIdForEdit } from "api/useGetOneByIdForEdit";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { checkUniqueProjectName } from "api/useCheckUniqueProjectName";
import { makeAutoObservable, reaction } from "mobx";
import { getAttributesByEntityWithSegments } from "api/go/useGetAttributesByEntityWithSegments";
import { getFeatureFlagStatus } from "api/featureFlag/useGetFeatureFlag";

import {
  MilestoneDaysSurveyProject,
  TEntityAttribute,
  TProjectForEdit,
} from "types/entityAttribute";
import dayjs, { Dayjs } from "dayjs";

import MainStore from "MainStore";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

class Store {
  project_id: number = 0;
  project: TProjectForEdit = null;
  name: string = "";
  minResponses: number = 0;
  isTestProject: boolean = false;
  projectStatus: string = "";
  automaticTriggers: boolean = false;
  sendOutSurveysType: string = SEND_OUT_SURVEY_TYPES.OneOff;

  frequencyDay: "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "" = "Mon";
  isKeepItUnscheduled: boolean = false;
  endDate: Dayjs | null = null;

  millstoneDayAttribure: string = "";
  millstoneDays: MilestoneDaysSurveyProject[] = [];
  frequency_repeat_every_count: number = 1;
  frequency_repeat_every_period: string = "day";

  entity_attributes: TEntityAttribute[] = [];
  selected_attribute: TEntityAttribute = null;
  selected_attribute_id: number = null;
  selected_attribute_segment_values: string[] = [];
  selected_date_attribute: TEntityAttribute = null;
  selected_date_attribute_id: number = null;
  selected_attribute_segments: { id: number; value: string }[] = [];
  showInfoTestProject: boolean = false;

  errorname: string = "";

  isAutoTriggerDisabled: boolean = false;

  isErrorFrequensyDate: boolean = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.name,
      (newName) => {
        this.delayedCheckNameUniqueness(newName);
      },
      { delay: 500 }
    );
  }

  clearStore = () => {
    this.project = null;
    this.name = "";
    this.errorname = "";
    this.projectStatus = "";
    this.minResponses = 0;
    this.isTestProject = false;
    this.automaticTriggers = false;
    this.sendOutSurveysType = "one_off";
    this.showInfoTestProject = false;
    this.selected_attribute_id = null;
    this.selected_attribute_segment_values = [];
    this.selected_date_attribute_id = null;

    this.frequencyDay = "Mon";
    this.isKeepItUnscheduled = false;
    this.endDate = null;

    this.millstoneDayAttribure = "";
    this.millstoneDays = [];
    this.frequency_repeat_every_count = 1;
    this.frequency_repeat_every_period = "day";

    this.entity_attributes = [];
    this.selected_date_attribute = null;
    this.selected_attribute = null;
    this.selected_attribute_segments = [];

    this.isAutoTriggerDisabled = false;
  };

  setfrequencyDay = (value: "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "") => {
    this.frequencyDay = value;
  };

  setData = (value: any, field: string) => {
    this[field] = value;
    if (field === "frequency_repeat_every_period") {
      this.frequencyDay = "Mon";
      this.frequency_repeat_every_count = 1;
    }
    if (field === "selected_attribute") {
      this.setData([], "selected_attribute_segments");
    }
    if (field === "automaticTriggers" && value) {
      this.setData([], "selected_attribute_segments");
      this.setData(null, "selected_attribute");
    }
    if (field === "isKeepItUnscheduled" && value) {
      this.setData(null, "endDate");
    }
  };

  addMilstoneDay = () => {
    if (this.millstoneDays.length === 0) this.millstoneDays = [{ id: 0, is_new: true, days: 1 }];
    else if (this.millstoneDays.length === 12) return;
    else this.millstoneDays = [...this.millstoneDays, { id: 0, is_new: true, days: 1 }];
  };

  removeMillstoneDay = (changeIndex: number) => {
    this.millstoneDays = this.millstoneDays.map((day, index) => {
      if (index === changeIndex) {
        day.deleted = true;
        day.edited = false;
        return day;
      } else return day;
    });
  };

  setErrorName = (error: string) => {
    this.errorname = error;
  };

  private async delayedCheckNameUniqueness(newName: string) {
    if (this.project === null) return;
    if (!newName) {
      this.setErrorName("Name is a required field");
      return;
    } else {
      try {
        const response = await checkUniqueProjectName(
          StoreLayout.currentEntityId,
          newName,
          this.project_id
        );
        if (response.status === 201 || response.status === 200) {
          if (response.data) {
            this.setErrorName("");
          } else {
            this.setErrorName(
              "Project names have to be unique, another project with the same name already exists"
            );
          }
        } else {
          throw new Error();
        }
      } catch (err) {
        MainStore.setSnackbar("Failed to check name for uniqueness", "error");
      }
    }
  }

  updateMillstoneDay = (changeIndex: number, value: number) => {
    this.millstoneDays = this.millstoneDays.map((day, index) => {
      if (index === changeIndex) {
        day.days = value;
        day.edited = true;
        day.deleted = false;
        return day;
      } else return day;
    });
  };

  loadAttributes = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getAttributesByEntityWithSegments(StoreLayout.currentEntityId);
      if (response.status !== 201 && response.status !== 200) {
        throw new Error();
      }

      this.entity_attributes = response.data;
      this.getAutoTriggerFeatureFlag();
      this.setSelectedAttributes();
    } catch (err) {
      MainStore.setSnackbar("Failed to load attributes", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getAutoTriggerFeatureFlag = async () => {
    const response = await getFeatureFlagStatus("auto_trigger_disabled");
    if (response.status !== 200 && response.status !== 201) {
      throw new Error();
    }

    this.isAutoTriggerDisabled = response.data.enabled;
  };

  doload = (project_id) => {
    this.project_id = project_id;
    this.LoadProject();
  };

  LoadProject = () => {
    GetOneByIdForEdit(this.project_id).then((x: { data: TProjectForEdit }) => {
      if (x?.data !== undefined) {
        this.project = x.data;
        this.name = x.data.name;
        this.isTestProject = x.data.test;
        this.automaticTriggers = x.data.is_triggers_required;
        this.minResponses = x.data.min_responses;
        this.sendOutSurveysType = x.data.project_type;
        this.frequencyDay = WEEKDAY[x.data.week_day];
        this.isKeepItUnscheduled = x.data.projectEndDate === null ? true : false;
        if (x.data.projectEndDate) {
          this.endDate = dayjs(x.data.projectEndDate);
        }
        this.millstoneDays = x.data.milestones;
        this.frequency_repeat_every_count = x.data.repeat_value;
        this.frequency_repeat_every_period = x.data.repeat_type;
        this.selected_date_attribute_id = x.data.dateAttributeMilestone_id;
        this.selected_attribute_id = x.data.trigger_attribute_id;
        this.selected_attribute_segment_values = x.data.trigger_attribute_value;
        this.projectStatus = x.data.status;
        this.setSelectedAttributes();
      }
    });
  };

  setSelectedAttributes = () => {
    if (this.entity_attributes.length === 0) return;
    if (this.selected_date_attribute_id !== null) {
      let attr = this.entity_attributes.filter((x) => x.id == this.selected_date_attribute_id);
      if (attr.length !== 0) {
        this.selected_date_attribute = attr[0];
      }
    }
    if (this.selected_attribute_id !== null) {
      let attr = this.entity_attributes.filter((x) => x.id == this.selected_attribute_id);
      if (attr.length !== 0) {
        this.selected_attribute = attr[0];

        if (this.selected_attribute_segment_values) {
          let segments = attr[0].values.filter((x) =>
            this.selected_attribute_segment_values.includes(x.value)
          );
          if (segments.length !== 0) {
            this.selected_attribute_segments = segments;
          }
        }
      }
    }
  };

  saveProject = async (callback: () => void) => {
    if (this.name === null || this.name === "") {
      return; //Add form validations
    }
    this.project.entity_id = StoreLayout.currentEntityId;
    this.project.name = this.name;
    this.project.test = this.isTestProject;
    this.project.trigger_attribute_value = this.selected_attribute_segments.map((x) => x.value);
    this.project.trigger_attribute_id = this.selected_attribute?.id;
    this.project.min_responses = this.minResponses;
    this.project.is_triggers_required = this.automaticTriggers;
    const dateEndWithTimezone = this.endDate;

    if (this.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.OneOff) {
    } else if (this.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency) {
      this.project.repeat_value = this.frequency_repeat_every_count;
      this.project.repeat_type = this.frequency_repeat_every_period;
      this.project.week_day = WEEKDAY.indexOf(this.frequencyDay);
      this.project.projectEndDate = this.isKeepItUnscheduled
        ? null
        : dateEndWithTimezone?.toISOString();
    } else if (this.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Milestones) {
      this.project.milestones = this.millstoneDays;
      this.project.dateAttributeMilestone_id = this.selected_date_attribute?.id;
    }

    try {
      MainStore.changeLoader(true);
      const response = await UpdateProjects(this.project);
      if (response.status === 201 || response.status === 200) {
        MainStore.changeLoader(false);
        callback();
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Someting went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();

export default store;

import http from "api/https";
import { createUrl } from "../helpers/url";

export const getTodayRecepientMilestoneCount = (surveyID: number): Promise<any> => {
  const url = createUrl({
    path: `/sm/sm_survey/${surveyID}/TodayRecepientMilestoneCount`,
    query: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  return http.getGO(url);
};

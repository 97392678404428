import { FC, Fragment } from "react";
import { observer } from "mobx-react";
import { EmptyLabel } from "./EmptyLabel";
import { EmptyState } from './EmptyState';
import { TDasbboardFilter } from "types/dashboard";
import { DIMENSION, ENPS, OVERAL_SCORE } from "constants/dashboard-overview";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import store from "../../store";
import styled from "styled-components";
import FlagIcon from "@mui/icons-material/Flag";
import PeopleIcon from "@mui/icons-material/People";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CardLoader } from "features/Dashboard/CardLoader";

type SegmentsCardProps = {};

const SegmentsCard: FC<SegmentsCardProps> = observer(() => {
  const isPending = store.isSegmentsPending || !store.projectInfo

  return (
    <Card>
      <TitleRow $isColumn={Boolean(store.overviewSegmentsCardFilter === DIMENSION)}>
        <Row>
          <TitleGroup>
            <Title>Segments</Title>
            {!isPending && !store.isSegmentsEmpty && (
              <StyledSelect
                id="segments_filter_select"
                value={store.overviewSegmentsCardFilter}
                onChange={(e: SelectChangeEvent<number>) => {
                  if (e.target.value === DIMENSION) {
                    store.setData(
                      store.overviewTrendCardDimenssionTypeList[0].label,
                      "selectedOverviewSegmentsCardFilter"
                    );
                  }
                  store.setData(e.target.value, "overviewSegmentsCardFilter");
                }}
                displayEmpty
              >
                <MenuItem value={OVERAL_SCORE}>Overall score</MenuItem>
                <MenuItem value={ENPS}>eNPS</MenuItem>
                <MenuItem value={DIMENSION}>Dimension</MenuItem>
              </StyledSelect>
            )}

            {store.overviewSegmentsCardFilter === DIMENSION && !isPending && (
              <StyledSelect
                id="dimension_segments_type"
                value={store.selectedOverviewSegmentsCardFilter}
                onChange={(e: SelectChangeEvent<string>) =>
                  store.setData(e.target.value, "selectedOverviewSegmentsCardFilter")
                }
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                {store.overviewTrendCardDimenssionTypeList.map((el) => (
                  <MenuItem value={el.label}>{el.label}</MenuItem>
                ))}
              </StyledSelect>
            )}
          </TitleGroup>
        </Row>
        {!isPending && !store.isSegmentsEmpty && (
          <LinkRow $isColumn={Boolean(store.overviewSegmentsCardFilter === DIMENSION)}>
            <ViewLink
              onClick={() => {
                store.setData("hotspots", "activeTab");
              }}
            >
              View more hotspots
            </ViewLink>
          </LinkRow>
        )}
        {store.isSegmentsEmpty && <EmptyStateTitle>Overall score is not available for this survey.</EmptyStateTitle>}

      </TitleRow>
      {/* {!isPending && (
        <DescriptionContainer>
          <StyledFlagIcon />
          <DescriptionText>
            {store.overviewSegmentsCardFilter !== ENPS &&
              store.filters.length > 0 &&
              store.filters[0].unit_or_attr
              ? "Benchmark based on all filtered respondents:"
              : "Benchmark based on all respondents:"}
          </DescriptionText>
          <DescriptionPercent
            $isHaveValue={Boolean(
              store.projectInfo?.segments && store.projectInfo?.segments.benchmark
            )}
          >
            {store.projectInfo?.segments &&
              store.projectInfo?.segments.benchmark &&
              store.overviewSegmentsCardFilter !== ENPS
              ? store.projectInfo?.segments.benchmark + "%"
              : store.projectInfo?.segments &&
                store.projectInfo?.segments.benchmark &&
                store.overviewSegmentsCardFilter === ENPS
                ? store.projectInfo?.segments.benchmark
                : "-"}
          </DescriptionPercent>
        </DescriptionContainer>
      )} */}
      {isPending && (
        <Container $isFilled={false}>
          <CardLoader contentHeight={240} />
        </Container>
      )}
      {!isPending && (
        <Container
          $isFilled={!store.isSegmentsThresholdShown && !store.isSegmentsEmpty}
        >
          {store.isSegmentsThresholdShown && (
            <EmptyLabel />
          )}
          {!store.isSegmentsThresholdShown && store.isSegmentsEmpty && (
            <EmptyState />
          )}
          {!store.isSegmentsThresholdShown && !store.isSegmentsEmpty && (
            <Content>
              <ContentColumn
                $isDisplay={Boolean(
                  store.projectInfo?.segments && store.projectInfo?.segments?.top?.length > 0
                )}
              >
                <ContentColumnTitle>Top segments</ContentColumnTitle>
                {store.projectInfo?.segments && store.projectInfo?.segments?.top[0]?.name && (
                  <ContentRow>
                    <Group>
                      <ContentRowTitle>
                        {(store.projectInfo?.segments && store.projectInfo?.segments?.top[0]?.name) ||
                          ""}
                      </ContentRowTitle>
                      <StyledPeopleIcon />
                      <ContentRowText>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.top[0]?.respondents) ||
                          "0"}
                      </ContentRowText>
                    </Group>
                    <Group>
                      <ContentRowText>
                        <b>
                          {(store.projectInfo?.segments &&
                            store.projectInfo?.segments?.top[0]?.percent) ||
                            "0"}
                          {store.overviewSegmentsCardFilter !== ENPS && "%"}
                        </b>
                      </ContentRowText>
                      {!store.selectedSurvey?.isFirst && (
                        <Fragment>
                          {store.projectInfo?.segments &&
                            store.projectInfo?.segments?.top[0]?.diff >= 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : (
                            <StyledArrowDropDownIcon />
                          )}
                          <ContentRowText>
                            {(store.projectInfo?.segments &&
                              store.projectInfo?.segments?.top[0]?.diff) ||
                              "0"}
                            {store.overviewSegmentsCardFilter !== ENPS && "%"}
                          </ContentRowText>{" "}
                        </Fragment>
                      )}
                    </Group>
                  </ContentRow>
                )}
                {store.projectInfo?.segments && store.projectInfo?.segments?.top[1]?.name && (
                  <ContentRow>
                    <Group>
                      <ContentRowTitle>
                        {(store.projectInfo?.segments && store.projectInfo?.segments?.top[1]?.name) ||
                          ""}
                      </ContentRowTitle>
                      <StyledPeopleIcon />
                      <ContentRowText>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.top[1]?.respondents) ||
                          "0"}
                      </ContentRowText>
                    </Group>
                    <Group>
                      <ContentRowText>
                        <b>
                          {(store.projectInfo?.segments &&
                            store.projectInfo?.segments?.top[1]?.percent) ||
                            "0"}
                          {store.overviewSegmentsCardFilter !== ENPS && "%"}
                        </b>
                      </ContentRowText>
                      {!store.selectedSurvey?.isFirst && (
                        <Fragment>
                          {store.projectInfo?.segments &&
                            store.projectInfo?.segments?.top[1]?.diff >= 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : (
                            <StyledArrowDropDownIcon />
                          )}
                          <ContentRowText>
                            {(store.projectInfo?.segments &&
                              store.projectInfo?.segments?.top[1]?.diff) ||
                              "0"}
                            {store.overviewSegmentsCardFilter !== ENPS && "%"}
                          </ContentRowText>
                        </Fragment>
                      )}
                    </Group>
                  </ContentRow>
                )}
                {store.projectInfo?.segments && store.projectInfo?.segments?.top[2]?.name && (
                  <ContentRow>
                    <Group>
                      <ContentRowTitle>
                        {(store.projectInfo?.segments && store.projectInfo?.segments?.top[2]?.name) ||
                          ""}
                      </ContentRowTitle>
                      <StyledPeopleIcon />
                      <ContentRowText>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.top[2]?.respondents) ||
                          "0"}
                      </ContentRowText>
                    </Group>
                    <Group>
                      <ContentRowText>
                        <b>
                          {(store.projectInfo?.segments &&
                            store.projectInfo?.segments?.top[2]?.percent) ||
                            "0"}
                          {store.overviewSegmentsCardFilter !== ENPS && "%"}
                        </b>
                      </ContentRowText>
                      {!store.selectedSurvey?.isFirst && (
                        <Fragment>
                          {store.projectInfo?.segments &&
                            store.projectInfo?.segments?.top[2]?.diff >= 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : (
                            <StyledArrowDropDownIcon />
                          )}
                          <ContentRowText>
                            {(store.projectInfo?.segments &&
                              store.projectInfo?.segments?.top[1]?.diff) ||
                              "0"}
                            {store.overviewSegmentsCardFilter !== ENPS && "%"}
                          </ContentRowText>
                        </Fragment>
                      )}
                    </Group>
                  </ContentRow>
                )}
              </ContentColumn>
              <ContentColumn
                $isDisplay={Boolean(
                  store.projectInfo?.segments && store.projectInfo?.segments.bottom.length > 0
                )}
              >
                <ContentColumnTitle>Bottom segments</ContentColumnTitle>
                {store.projectInfo?.segments && store.projectInfo?.segments?.bottom[0]?.name && (
                  <ContentRow>
                    <Group>
                      <ContentRowTitle>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.bottom[0]?.name) ||
                          ""}
                      </ContentRowTitle>
                      <StyledPeopleIcon />
                      <ContentRowText>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.bottom[0]?.respondents) ||
                          "0"}
                      </ContentRowText>
                    </Group>
                    <Group>
                      <ContentRowText>
                        <b>
                          {(store.projectInfo?.segments &&
                            store.projectInfo?.segments?.bottom[0]?.percent) ||
                            "0"}
                          {store.overviewSegmentsCardFilter !== ENPS && "%"}
                        </b>
                      </ContentRowText>
                      {!store.selectedSurvey?.isFirst && (
                        <Fragment>
                          {store.projectInfo?.segments &&
                            store.projectInfo?.segments?.bottom[0]?.diff >= 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : (
                            <StyledArrowDropDownIcon />
                          )}
                          <ContentRowText>
                            {(store.projectInfo?.segments &&
                              store.projectInfo?.segments?.bottom[0]?.diff) ||
                              "0"}
                            {store.overviewSegmentsCardFilter !== ENPS && "%"}
                          </ContentRowText>
                        </Fragment>
                      )}
                    </Group>
                  </ContentRow>
                )}
                {store.projectInfo?.segments && store.projectInfo?.segments?.bottom[1]?.name && (
                  <ContentRow>
                    <Group>
                      <ContentRowTitle>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.bottom[1]?.name) ||
                          ""}
                      </ContentRowTitle>
                      <StyledPeopleIcon />
                      <ContentRowText>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.bottom[1]?.respondents) ||
                          "0"}
                      </ContentRowText>
                    </Group>
                    <Group>
                      <ContentRowText>
                        <b>
                          {(store.projectInfo?.segments &&
                            store.projectInfo?.segments.bottom[1]?.percent) ||
                            "0"}
                          {store.overviewSegmentsCardFilter !== ENPS && "%"}
                        </b>
                      </ContentRowText>
                      {!store.selectedSurvey?.isFirst && (
                        <Fragment>
                          {store.projectInfo?.segments &&
                            store.projectInfo?.segments?.bottom[1]?.diff >= 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : (
                            <StyledArrowDropDownIcon />
                          )}
                          <ContentRowText>
                            {(store.projectInfo?.segments &&
                              store.projectInfo?.segments?.bottom[1]?.diff) ||
                              "0"}
                            {store.overviewSegmentsCardFilter !== ENPS && "%"}
                          </ContentRowText>
                        </Fragment>
                      )}
                    </Group>
                  </ContentRow>
                )}
                {store.projectInfo?.segments && store.projectInfo?.segments?.bottom[2]?.name && (
                  <ContentRow>
                    <Group>
                      <ContentRowTitle>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.bottom[2]?.name) ||
                          ""}
                      </ContentRowTitle>
                      <StyledPeopleIcon />
                      <ContentRowText>
                        {(store.projectInfo?.segments &&
                          store.projectInfo?.segments?.bottom[2]?.respondents) ||
                          "0"}
                      </ContentRowText>
                    </Group>
                    <Group>
                      <ContentRowText>
                        <b>
                          {(store.projectInfo?.segments &&
                            store.projectInfo?.segments?.bottom[2]?.percent) ||
                            "0"}
                          {store.overviewSegmentsCardFilter !== ENPS && "%"}
                        </b>
                      </ContentRowText>
                      {!store.selectedSurvey?.isFirst && (
                        <Fragment>
                          {store.projectInfo?.segments &&
                            store.projectInfo?.segments?.bottom[2]?.diff >= 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : (
                            <StyledArrowDropDownIcon />
                          )}
                          <ContentRowText>
                            {(store.projectInfo?.segments &&
                              store.projectInfo?.segments?.bottom[2]?.diff) ||
                              "0"}
                            {store.overviewSegmentsCardFilter !== ENPS && "%"}
                          </ContentRowText>
                        </Fragment>
                      )}
                    </Group>
                  </ContentRow>
                )}
              </ContentColumn>
            </Content>
          )}
        </Container>
      )}
    </Card>
  );
});

export default SegmentsCard;

const Card = styled.div`
  width: 57%;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);
`;

const TitleRow = styled.div<{ $isColumn?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) => props.$isColumn && "flex-direction:column"};
  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
`;

const ViewLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin-left: 8px;
  color: var(--colorBrandForeground1);
  transition: all 0.3s;
  align-self: self-end;
  white-space: nowrap;
  &:hover {
    color: var(--colorBrandForeground2);
    text-decoration: underline;
  }
`;

const EmptyStateTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
  margin-left: auto;
  line-height: 16px;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 140px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 8px !important;

  em {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const TitleGroup = styled.div`
  display: flex;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const DescriptionPercent = styled.p<{ $isHaveValue?: boolean }>`
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  color: ${(props) =>
    props.$isHaveValue ? "var(--colorPaletteVioletBackground3)" : "var(--colorNeutralForeground2)"};
  margin-left: 4px;
`;

const StyledFlagIcon = styled(FlagIcon)`
  color: var(--colorPaletteVioletBackground3);
  width: 18px !important;
  margin-right: 4px;
  margin-top: 1px;
`;

const DescriptionText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-top: 4px;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 42px;
  margin-top: 24px;
`;

const ContentColumn = styled.div<{ $isDisplay: boolean }>`
  width: 100%;
  display: ${(props) => (props.$isDisplay ? "flex" : "none")};
  flex-direction: column;
`;

const ContentColumnTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
`;

const ContentRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 12px 0px;
  margin-top: 16px;
  border-bottom: 1px solid var(--colorNeutralForeground4);
`;

const ContentRowTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContentRowText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--colorNeutralForeground1);

  b {
    margin-left: 16px;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  color: var(--colorPaletteGreenBackground2);
  font-size: 24px !important;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: var(--colorPaletteRedForeground1);
  font-size: 24px !important;
`;

const StyledPeopleIcon = styled(PeopleIcon)`
  color: var(--colorNeutralForeground2);
  font-size: 18px !important;
  margin: 0px 2px 2px 8px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const LinkRow = styled.div<{ $isColumn?: boolean }>`
  ${(props) => props.$isColumn && "width: 100%"};
  ${(props) => props.$isColumn && "justify-content: flex-end"};
  ${(props) => props.$isColumn && "margin-top: 10px"};

  display: flex;
`;

const Container = styled.div<{ $isFilled: boolean }>`
  width: 100%;
  display: flex;
  align-items: ${(props) => (props.$isFilled ? "flex-start" : "center")};
  justify-content: center;
  min-height: 200px;
`;

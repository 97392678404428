import { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, TextField } from "@mui/material";

import { RemoveIcon } from "../../../Project/ProjectList/components/RemoveIcon";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import RemoveUserIcon from "features/Employees/RemoveProfileIcon";
import RemoveFilterIcon from "features/Employees/RemoveIcon";
import EmployeeAddEditPopup from "../AddEditEmployee/popup";
import CustomButton from "components/button/Button";
import EditIcon from "features/Employees/EditIcon";
import CustomModal from "components/modal/Modal";
import FilterAccordion from "./FilterAccordion";
import EmployeeDeletePopup from "./DeletePopup";
import NewCustomTable from "./NewCustomTable";
import NavStore from "./../../store";
import store from "./store";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";
import ExportImportLinkBox from "./ImportExportLinkBox";

type UsersPageProps = {};

const UsersPage: FC<UsersPageProps> = observer(() => {
  const containerRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isHavingEditEmployeePermission, setIsHavingEditEmployeePermission] = useState(false);

  useEffect(() => {
    if (
      !StorePermission.entityPermissions.viewEmployee.hasPermission &&
      !StorePermission.entityPermissions.editEmployee.hasPermission
    ) {
      window.location.href = "/dashboard";
    }

    setIsHavingEditEmployeePermission(StorePermission.entityPermissions.editEmployee.hasPermission);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    let resizeObserver: ResizeObserver;

    const handleScroll = () => {
      if (container.scrollLeft === 0) {
        setIsAtStart(true);
      } else {
        setIsAtStart(false);
      }
      if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        setIsAtEnd(true);
      } else {
        setIsAtEnd(false);
      }
    };

    if (container) {
      container.addEventListener("scroll", handleScroll);

      resizeObserver = new ResizeObserver(handleScroll);
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    store.loadAttributes();
    store.loadUsers();
  }, []);

  useEffect(() => {
    NavStore.loadCheckManagersWithoutRoles();
  }, []);

  const structure =
    NavStore.saved_org_structure_code === ORG_STRUCTURE_CODES.unit_based
      ? "unit heads"
      : "managers";

  return (
    <Box sx={{ mb: 20 }}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"end"} sx={{ mb: 3 }}>
        <Header id="EmployeeList_Title">
          Viewing all employees
          <span>({store.countEmployee.toLocaleString()})</span>
        </Header>
        <Box id="EmployeeList_TitleRight">
          ORGANIZATION STRUCTURE: {NavStore.saved_org_structure_name}
        </Box>
      </Box>
      <Divider />
      <Grid style={{ marginBottom: 20 }} container>
        <Grid item lg={12} xl={6}>
          <FilterAccordion />
        </Grid>
        {isHavingEditEmployeePermission && (
          <Grid item lg={12} xl={6}>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <ExportImportLinkBox />
            </Box>
          </Grid>
        )}
      </Grid>

      {!store.allowAction && (
        <TemplateWrapper data-testid="EmployeeList_ImportEmployee_Information">
          Employee List is being updated at the moment. Certain functionalities will not be
          available until the update is complete.
        </TemplateWrapper>
      )}

      {store.resultSearchValue !== "" && (
        <ResultSearch>
          {store.Employees.length === 0 ? (
            <SearchText id="EmployeeList_SearchText">
              "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" not found on employee
              list.
            </SearchText>
          ) : (
            <SearchText id="EmployeeList_SearchText">
              "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" found on employee list.
            </SearchText>
          )}
          <RemoveSearch>
            <CustomButtonStyled
              id="EmployeeList_SearchRemove"
              onClick={() => {
                store.changeValue("searchedValue", "");
                store.onSearchClicked();
              }}
            >
              <RemoveIcon style={{ marginTop: 0, marginBottom: 1 }} />
              Remove search
            </CustomButtonStyled>
          </RemoveSearch>
        </ResultSearch>
      )}
      <BoxWrapper>
        <BoxWrapperSearchButtons>
          <Box ml={1} minWidth={245}>
            <CustomTextFieldStyled
              id="EmployeeList_Search"
              size="small"
              placeholder="Search for name/email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      id="EmployeeList_Search_Btn"
                      onClick={() => store.onSearchClicked()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={store.searchedValue}
              onKeyDown={store.keyPress}
              onChange={(e) => store.changeValue("searchedValue", e.target.value)}
            />
          </Box>
          <>
            <Box ml={1} minWidth={150}>
              <StyledCustomButtonAdd
                id="EmployeeList_AddEmployee_Btn"
                disabled={!isHavingEditEmployeePermission || !store.allowAction}
                variant="contained"
                onClick={() => {
                  store.setOpenPanel(true);
                  store.currentId = 0;
                }}
              >
                <AddIcon />
                Add employee
              </StyledCustomButtonAdd>
            </Box>
            <BoxWrapperEditRemoveButtons>
              <Box ml={1} marginLeft={2}>
                <CustomButton
                  id="EmployeeList_Edit_Btn"
                  style={{ height: "36px" }}
                  disabled={
                    store.selected.length === 0 ||
                    store.selected.length > 1 ||
                    !isHavingEditEmployeePermission ||
                    !store.allowAction
                  }
                  onClick={() => {
                    store.setOpenPanel(true);
                    store.currentId = store.selected[0];
                  }}
                >
                  <EditIcon
                    color={
                      !store.selected.length || store.selected.length > 1 ? "#00000042" : "#1477F8"
                    }
                  />
                  Edit
                </CustomButton>
              </Box>
              <p />
              <Box ml={1} marginLeft={0}>
                <CustomButton
                  id="EmployeeList_Remove_Btn"
                  style={{ height: "36px" }}
                  disabled={
                    store.selected.length === 0 ||
                    !isHavingEditEmployeePermission ||
                    !store.allowAction
                  }
                  onClick={() => store.handleClickOpenDialog(true)}
                >
                  <RemoveUserIcon color={!store.selected.length ? "#00000042" : "#1477F8"} />
                  Remove
                </CustomButton>
              </Box>
            </BoxWrapperEditRemoveButtons>
          </>
        </BoxWrapperSearchButtons>
        <BoxWrapperResultFilters>
          <p id="EmployeeList_Attribtes_Filter_Text">
            {store.filtersResult.map((el) => el.select_attribute)[0]
              ? `FILTER${store.filtersResult.length > 1 ? "s" : ""}:`
              : "Attributes"}
          </p>
          <WrapperScroll ref={containerRef}>
            {store.filtersResult.map((item, index) => {
              return (
                item.select_attribute && (
                  <ContainerItemFiltersResult key={index}>
                    <p>
                      {item.select_attribute}:
                      <RemoveFilterIcon
                        onClick={() => {
                          store.removeFilterAttribute(index);
                          store.loadUsers();
                        }}
                      />
                    </p>
                    {item.select_segments.map((el, i) => (
                      <span key={i}>
                        {el}
                        <RemoveFilterIcon
                          onClick={() => {
                            store.removeFilterSegment(index, i);
                            store.loadUsers();
                          }}
                        />
                      </span>
                    ))}
                  </ContainerItemFiltersResult>
                )
              );
            })}
          </WrapperScroll>
          <WrapperChevronIcons>
            <StyledCustomButtonStart
              id="EmployeeList_Start_Btn"
              disabledstyle={String(isAtStart)}
              disabled={isAtStart}
              style={{
                border: isAtStart
                  ? "1px solid var(--colorNeutralForeground4)"
                  : "1px solid var(--colorNeutralForeground2)",
              }}
              onClick={() => {
                if (containerRef.current) {
                  containerRef.current.scrollLeft -= 200;
                }
              }}
            >
              <ChevronLeft />
            </StyledCustomButtonStart>
            <StyledCustomButtonEnd
              id="EmployeeList_End_Btn"
              disabledstyle={String(isAtEnd)}
              disabled={isAtEnd}
              style={{
                border: isAtEnd
                  ? "1px solid var(--colorNeutralForeground4)"
                  : "1px solid var(--colorNeutralForeground2)",
              }}
              onClick={() => {
                if (containerRef.current) {
                  containerRef.current.scrollLeft += 200;
                }
              }}
            >
              <ChevronRight />
            </StyledCustomButtonEnd>
          </WrapperChevronIcons>
        </BoxWrapperResultFilters>
      </BoxWrapper>

      <NewCustomTable isHavingEditEmployeePermission={isHavingEditEmployeePermission} />

      <EmployeeAddEditPopup
        open={store.openPanel}
        idEntity={StoreLayout.currentEntityId}
        onSavedClicked={() => {
          store.loadUsers();
          store.changeSelect([]);
        }}
        onBtnCancelClick={() => store.setOpenPanel(false)}
        handleHide={(flag) => store.setOpenPanel(flag)}
        idEmployee={store.currentId}
      />

      <CustomModal
        id="EmployeeList_"
        open={store.openDialog}
        // width='660px'
        maxWidth="md"
        hide={(flag) => store.handleClickOpenDialog(flag)}
        children={<EmployeeDeletePopup />}
      />

      <CustomModal
        open={NavStore.openGrantPermissionModal}
        hide={() => NavStore.setOpenGrantPermissionModal(false)}
        width="522px"
        children={
          <StyledContentGrantModal>
            <h2 data-testid="GrantPermission_title">Grant {structure} permission access</h2>
            <StyledDescriptionGrantModal id="GrandManegers_PopUp_Text_2">
              <p data-testid="GrandManegers_PopUp_Text_1">
                It looks like you have employees that are {structure}.
              </p>
              <p data-testid="GrandManegers_PopUp_Text_2">
                Would you like to grant them access to the platform?
              </p>
            </StyledDescriptionGrantModal>
            <StyledBtnWrpGrantModal>
              <CustomButton
                data-testid="GrantPermissions_Btn"
                variant="contained"
                onClick={() => {
                  NavStore.changeopenPanelGrantPermission(true);
                  NavStore.setOpenGrantPermissionModal(false);
                }}
              >
                Grant permissions
              </CustomButton>
              <CustomButton
                data-testid="GrantLater_Btn"
                variant="outlined"
                onClick={() => {
                  NavStore.useGrantLaterClicked();
                  NavStore.setOpenGrantPermissionModal(false);
                }}
              >
                Grant later
              </CustomButton>
            </StyledBtnWrpGrantModal>
          </StyledContentGrantModal>
        }
      />
    </Box>
  );
});

const StyledContentGrantModal = styled.div`
  padding: 40px 42px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: var(--colorNeutralForeground1);
    margin: 0;
  }
`;

const StyledDescriptionGrantModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;

  > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--colorNeutralForeground1);
  }
`;

const StyledBtnWrpGrantModal = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  > :first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  > :last-child {
    color: var(--colorNeutralForeground2);
    border: 1px solid var(--colorNeutralForeground5);
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;

  display: flex;
  align-items: center;
  gap: 9px;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const BoxWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BoxWrapperSearchButtons = styled(Box)`
  width: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const BoxWrapperEditRemoveButtons = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  > p {
    height: 40px;
    width: 2px;
    background-color: var(--colorPaletteBlueBackground1);
  }
`;

const StyledCustomButtonAdd = styled(CustomButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const BoxWrapperResultFilters = styled(Box)`
  width: 50%;
  min-height: 56px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background-color: var(--grenBackgroundColor2);
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  margin-bottom: 4px;
  > p {
    margin-right: 10px;
    color: var(--colorNeutralForeground2);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
  }
`;

const WrapperScroll = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-behavior: smooth 500ms;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

const WrapperChevronIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-left: 1px solid var(--graySelectBoxBorder);
  padding: 0 10px;
  margin-left: 10px;
  button {
    width: 24px !important;
    height: 24px;
    min-width: 0 !important;
    border-radius: 99%;
  }
`;

const StyledCustomButtonStart = styled(CustomButton)<{ disabledstyle: string }>`
  cursor: pointer !important;
  svg {
    width: 22px;
    height: 21px;
    path {
      fill: ${({ disabledstyle }) =>
        disabledstyle === "true"
          ? "var(--colorNeutralForeground4)"
          : "var(--colorNeutralForeground2)"};
    }
  }
`;

const StyledCustomButtonEnd = styled(CustomButton)<{ disabledstyle: string }>`
  svg {
    width: 22px;
    height: 21px;
    path {
      fill: ${({ disabledstyle }) =>
        disabledstyle === "true"
          ? "var(--colorNeutralForeground4)"
          : "var(--colorNeutralForeground2)"};
    }
  }
`;

const ContainerItemFiltersResult = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: var(--colorPaletteBlueBackground2);
  padding: 5px 8px;
  margin: 0px 10px;
  > p {
    color: var(--colorNeutralBackgroundInverted3);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    display: flex;
    align-items: center;
    gap: 8px;
  }
  > span {
    border-radius: 4px;
    background: var(--colorNeutralForeground4);
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--colorNeutralBackgroundInverted4);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 2px 8px 1px;
  }
`;

const SearchText = styled.div`
  margin-left: 16px;
`;

const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin: 0 0 0 10px;
`;

const ResultSearch = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: var(--colorSearchResultBackground);
  margin-top: 12px;
  height: 36px;
  opacity: 0.7;
`;

const CustomButtonStyled = styled(CustomButton)`
  display: flex !important;
  align-items: center !important;
  font-size: 15px !important;
`;

const CustomTextFieldStyled = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 36px;
  }
`;

const TemplateWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const TemplateWrapperHeader = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 5px;
`;

export default UsersPage;

import { FC } from "react";
import { observer } from "mobx-react";

import store from "./store";
import styled from "styled-components";
import QuestionGroup from "./QuestionGroup";

type QuestionsListProps = {
    localFilters: { id: number; name: string; segments: string[] }[];
};

const QuestionsList: FC<QuestionsListProps> = observer((props) => {
    return (
        <Container>
            {store.scoredQuestionsList && store.scoredQuestionsList.length > 0 && store.scoredQuestionsList.map((dimension) => (
                <QuestionGroup
                    key={dimension.dimension}
                    dimension={dimension}
                    id={`scored-group-anchor-${dimension.dimension}`}
                    localFilters={props.localFilters}
                />
            ))}
        </Container>
    );
});

export default QuestionsList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

import { FC } from "react";
import { ImportHistoricalSurvey } from "features/Survey/ImportHistoricalSurvey";

type ImportHistoricalSurveyProps = {};

const ImportHistoricalSurveyPage: FC<ImportHistoricalSurveyProps> = () => {
  return (
    <div>
      <ImportHistoricalSurvey />
    </div>
  );
};

export default ImportHistoricalSurveyPage;

import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import store from "./store";
import styled from "styled-components";
import Filters from "./Filters";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "components/button/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ImportRecipientsTable from "./ImportRecipientsTable";
import { useNavigate } from "react-router";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { ClearIcon } from "@mui/x-date-pickers";
import { createUrl } from "helpers/url";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type ImportRecipietnsProps = {};

const ImportRecipietns: FC<ImportRecipietnsProps> = observer(() => {
  const navigate = useNavigate();

  const fakeButtonEntityIds = (process.env.REACT_APP_FAKE_BUTTON_ENTITY_IDS || "")
    .split(",")
    .filter((id) => id)
    .map((id) => Number(id));

  const navigateHandler = () => {
    navigate(
      createUrl({
        path: "/recipients-millstone-schedule",
        query: {
          id: store.id_survey.toString(),
        },
      }),
    );
  };

  return (
    <Container>
      {store.Survey?.project_type === SEND_OUT_SURVEY_TYPES.Milestones && (
        <Box>
          <BackButton
            id="Survey_EditCreate_Recipietns_Import_MilestonesType_BackToSchedule_Button"
            onClick={navigateHandler}
          >
            {"< Back to milestone schedule"}
          </BackButton>
        </Box>
      )}
      {fakeButtonEntityIds.includes(StoreLayout.currentEntityId) ? (
        <>
          <PSDHeaderContainer>
            <Box>
              <PSDTableHeaderRow>
                <PSDHeader id="Survey_EditCreate_Recipietns_Import_Title">Employee list</PSDHeader>
                <ManageEntitiesutton
                  id="Survey_EditCreate_Recipietns_Import_ManageEmployees_Link"
                  onClick={() => window.open("/employees", "_blank")}
                >
                  Manage employees <OpenInNewIcon />
                </ManageEntitiesutton>
              </PSDTableHeaderRow>
            </Box>
            <Filters />
            <Box>
              <PSDSearchWrapper>
                <PSDStyledSearchInput
                  id="Survey_EditCreate_Recipietns_Import_SearchInput"
                  size="medium"
                  placeholder="Search for name/email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id="Survey_EditCreate_Recipietns_Import_SearchInput_Button"
                          onClick={store.getSearchImport}
                        >
                          <SearchIcon />
                        </IconButton>
                        {store.importSearchValue && (
                          <IconButton
                            id="Survey_EditCreate_Recipietns_Import_SearchInput_Button"
                            onClick={() => {
                              store.searchImportRecipients("");
                              store.getSearchImport();
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={store.keyPressImport}
                  value={store.importSearchValue}
                  onChange={(e) => store.searchImportRecipients(e.target.value)}
                />
                <TableHeaderRow>
                  <TableCounter id="Survey_EditCreate_Recipietns_Import_TableCounter">
                    {store.countPerPage >= store.countTotal
                      ? "Viewing all " + store.countTotal
                      : `Viewing ${store.countPerPage} of ${store.countTotal}`}
                  </TableCounter>
                </TableHeaderRow>
              </PSDSearchWrapper>
            </Box>
          </PSDHeaderContainer>
        </>
      ) : (
        <>
          <Title id="Survey_EditCreate_Recipietns_Import_SurveyName">{store.Survey?.name}</Title>
          <Filters />
          <TableHeaderWrapper>
            <Box>
              <TableHeaderRow>
                <Header id="Survey_EditCreate_Recipietns_Import_Title">Employee list</Header>
                <ManageEntitiesutton
                  id="Survey_EditCreate_Recipietns_Import_ManageEmployees_Link"
                  onClick={() => window.open("/employees", "_blank")}
                >
                  Manage employees <OpenInNewIcon />
                </ManageEntitiesutton>
              </TableHeaderRow>
              <TableHeaderRow>
                <TableCounter id="Survey_EditCreate_Recipietns_Import_TableCounter">
                  {store.countPerPage >= store.countTotal
                    ? "Viewing all " + store.countTotal
                    : `Viewing ${store.countPerPage} of ${store.countTotal}`
                    }
                </TableCounter>
              </TableHeaderRow>
            </Box>
            <Box>
              <StyledSearchInput
                id="Survey_EditCreate_Recipietns_Import_SearchInput"
                size="small"
                placeholder="Search for name/email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        id="Survey_EditCreate_Recipietns_Import_SearchInput_Button"
                        onClick={store.getSearchImport}
                      >
                        <SearchIcon />
                      </IconButton>
                      {store.importSearchValue && (
                        <IconButton
                          id="Survey_EditCreate_Recipietns_Import_SearchInput_Button"
                          onClick={() => {
                            store.searchImportRecipients("");
                            store.getSearchImport();
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                onKeyDown={store.keyPressImport}
                value={store.importSearchValue}
                onChange={(e) => store.searchImportRecipients(e.target.value)}
              />
            </Box>
          </TableHeaderWrapper>
        </>
      )}

      {!!store.resultimportSearchValue && (
        <ResultSearch id="Survey_EditCreate_Recipietns_Import_ResultSearch">
          {!store.importRecipientsList.length && !store.exportRecipientsList.length ? (
            <SearchText id="Survey_EditCreate_Recipietns_Import_ResultSearch_Text">
              "
              <SearchTextValue id="Survey_EditCreate_Recipietns_Import_ResultSearch_Name">
                {store.resultimportSearchValue}
              </SearchTextValue>
              " No employees match the provided details. Please try using a different search
              keyword.
            </SearchText>
          ) : (
            <SearchText id="Survey_EditCreate_Recipietns_Import_ResultSearch_Text">
              "
              <SearchTextValue id="Survey_EditCreate_Recipietns_Import_ResultSearch_Name">
                {store.resultimportSearchValue}
              </SearchTextValue>
              " found on the employee list.
            </SearchText>
          )}

          <RemoveSearch>
            <CustomButton
              id="Survey_EditCreate_Recipietns_Import_ResultSearch_Remove_Button"
              onClick={() => {
                store.setData("resultimportSearchValue", "");
                store.setData("importSearchValue", "");
                store.getAllEmployees();
              }}
            >
              <ClearIcon /> Remove search
            </CustomButton>
          </RemoveSearch>
        </ResultSearch>
      )}

      <ImportRecipientsTable />
    </Container>
  );
});

export default ImportRecipietns;

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: var(--colorPaletteGrayBackground3);
  padding: 23px 30px 30px 30px;

  @media (max-width: 1450px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const ResultSearch = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 24px;
  height: 50px;
`;

const SearchText = styled.div`
  margin-left: 16px;
`;

const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const BackButton = styled(CustomButton)`
  text-decoration: underline !important;
  color: var(--colorNeutralForeground2) !important;
`;

const PSDSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const PSDStyledSearchInput = styled(TextField)`
  width: 323px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  margin: 0 !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 7px 11px !important;
    border-radius: 2px !important;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 246px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  margin: 0 !important;

  div {
    border-radius: 2px !important;
  }

  input {
    padding: 8px 11px !important;
    border-radius: 2px !important;
    font-family: Roboto;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const TableHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 57px;
`;

const Header = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  text-transform: uppercase;
  white-space: nowrap;
`;

const PSDHeader = styled.h6`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const TableHeaderRow = styled.div`
  width: fit-content;
  display: flex;
  align-items: flex-end;
`;

const PSDHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 72px;
`;

const PSDTableHeaderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid;
  border-color: var(--colorNeutralBackground3);
  padding-bottom: 16px;
`;

const TableCounter = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin-top: 13px;
  white-space: nowrap;
  text-transform: uppercase;
`;

const ManageEntitiesutton = styled(CustomButton)`
  padding: 0 !important;
  margin: 0 0 -2px 12px !important;
  white-space: nowrap;

  svg {
    margin-left: 4px;
    font-size: 18px;
  }

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }
`;

import styled from "styled-components";
import DoneIcon from "@mui/icons-material/Done";

type SelectBoxProps = {
  formName: string;
  isDisabled: boolean;
  isActive: boolean;
  onClick: () => void;
  warningText: string;
  warningIcon: boolean;
  title: string;
  description: string;
}
const SelectBox = ({ formName, isDisabled, isActive, onClick, warningText, warningIcon, title, description }: SelectBoxProps) => {
  return (
    <SelectItem
      id={`${formName}_selectItemReplaceList`}
      $disabled={isDisabled}
      $active={isActive}
    >
      <SelectButton
        id={`${formName}_selectButtonReplaceList`}
        onClick={() => {
          if (isDisabled) {
            return;
          }
          onClick();
        }}
      >
        <DoneIcon id="doneIconReplaceList" />
      </SelectButton>
      <span id={`${formName}_spanReplaceList`}>{title}</span>
      <p id={`${formName}_descriptionReplaceList`}>
        {description}
      </p>
      <WarningContainer>
        {warningIcon && (
          <WarningIconDiv>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99961 0.599609C3.46681 0.599609 0.599609 3.46681 0.599609 6.99961C0.599609 10.5324 3.46681 13.3996 6.99961 13.3996C10.5324 13.3996 13.3996 10.5324 13.3996 6.99961C13.3996 3.46681 10.5324 0.599609 6.99961 0.599609ZM7.63898 10.1991H6.35898V8.91914H7.63898V10.1991ZM6.35898 7.63961H7.63898V3.79961H6.35898V7.63961Z" fill="#FCC658" />
            </svg>
          </WarningIconDiv>
        )}
        <WarningText>
          {warningText}
        </WarningText>
      </WarningContainer>

    </SelectItem>
  )
}

const SelectItem = styled.div<{ $left?: boolean; $active: boolean, $disabled?: boolean }>`
  width: 100%;
  min-width: 250px;
  padding: 20px;
  border-radius: 8px;
  height: inherit;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  opacity: ${(props) => props.$disabled ? 0.5 : 1};
  background-color: var(--colorNeutralBackground1);
  ${(props) => props.$left && " margin-right: 30px;"};
  border: ${(props) =>
    props.$active
      ? "1px solid var(--colorBrandForeground1)"
      : "1px solid var(--colorPaletteBlueBackground1)"};
  position: relative;
  cursor: default;

  button {
    background-color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralBackground3)"};
  }

  span,
  p {
    font-family: Roboto;
    font-weight: 400;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: var(--colorNeutralForeground1);
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
    margin-top: 20px;
  }
`;

const SelectButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 10px;
  border: none;
  cursor: pointer;
  background-color: var(--colorNeutralBackground3);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
    color: var(--colorNeutralBackground1);
  }
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  justify-content: start;
  background-color: var(--colorPaletteGrayBackground1);
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
`;

const WarningIconDiv = styled.div`
display: flex;
flex-direction: column;
align-items: start;
justify-content: start;
margin-right: 4px;
`

const WarningText = styled.div`
font-size: 12px;
font-family: Roboto, sans-serif;
font-weight: 400;
line-height: 16px;
color: var(--colorNeutralForeground1);
`

export default SelectBox;
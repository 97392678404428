import React from 'react';
import { observer } from "mobx-react";
import { FC, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import PDFHeader from "../pdfExport/PDFHeader";
import ReactDOM from "react-dom";
import { PrioritizeElementRenderer } from './PrioritizeElementRenderer';
import prioritizeStore from './store';
import topStore from "../store";

type PDFPrioritizeContentProps = {
    localFilters: { id: number; name: string; segments: string[] }[];
}

interface Page {
    content: ReactElement;
    name: string;
}

const A4_HEIGHT_MM = 370;
const HEADER_HEIGHT_MM = 40;
const CONTENT_PADDING_MM = 0;
const AVAILABLE_HEIGHT_MM = A4_HEIGHT_MM - CONTENT_PADDING_MM;

interface PageSection {
    content: ReactElement;
    height: number;
}

const PDFPrioritizeContent: FC<PDFPrioritizeContentProps> = observer((props): ReactElement => {
    const [pages, setPages] = useState<Page[]>([]);

    const measureComponent = async (component: React.ReactNode): Promise<number> => {
        return new Promise((resolve) => {
            const hiddenContainer = document.createElement('div');
            hiddenContainer.style.position = 'absolute';
            hiddenContainer.style.visibility = 'hidden';
            hiddenContainer.style.width = '300mm';
            document.body.appendChild(hiddenContainer);

            ReactDOM.render(component as ReactElement, hiddenContainer, () => {
                const heightPx = hiddenContainer.getBoundingClientRect().height;
                ReactDOM.unmountComponentAtNode(hiddenContainer);
                hiddenContainer.remove();
                resolve(heightPx / 3.779528);
            });
        });
    };

    const createPageContent = (sections: PageSection[], showHeader: boolean): ReactElement => (
        <A4Container>
            {showHeader && <PDFHeader name="Prioritize" />}
            <ContentContainer>
                {sections.map((section, index) => (
                    <div key={index}>
                        {section.content}
                    </div>
                ))}
            </ContentContainer>
        </A4Container>
    );

    const processContentIntoPages = async (
        contents: React.ReactNode[],
        pageName: string,
        showHeaderCondition: boolean
    ): Promise<Page[]> => {
        const pages: Page[] = [];
        let currentPageSections: PageSection[] = [];
        let currentPageHeight = 0;
        let pageCounter = 1;
        let isFirstPage = true;

        for (const item of contents) {
            const contentHeight = await measureComponent(item);
            const headerHeight = isFirstPage ? (showHeaderCondition ? HEADER_HEIGHT_MM : 0) : 0;
            const availableHeight = AVAILABLE_HEIGHT_MM - headerHeight;

            if (contentHeight > availableHeight) {
                // Handle content that needs to be split
                const tempContainer = document.createElement('div');
                ReactDOM.render(item as ReactElement, tempContainer);
                const children = Array.from(tempContainer.children);
                let currentHeight = 0;
                let currentElements: Element[] = [];

                children.forEach((child) => {
                    const childHeight = child.getBoundingClientRect().height / 3.779528;
                    if (currentHeight + childHeight > availableHeight && currentElements.length > 0) {
                        const pageContent = <div>{currentElements.map(el => React.createElement('div', {
                            dangerouslySetInnerHTML: { __html: el.outerHTML }
                        }))}</div>;
                        pages.push({
                            content: createPageContent([{ content: pageContent as ReactElement, height: currentHeight }], false),
                            name: `${pageName}-${pageCounter}`
                        });
                        pageCounter++;
                        currentElements = [child];
                        currentHeight = childHeight;
                    } else {
                        currentElements.push(child);
                        currentHeight += childHeight;
                    }
                });

                if (currentElements.length > 0) {
                    const pageContent = <div>{currentElements.map(el => React.createElement('div', {
                        dangerouslySetInnerHTML: { __html: el.outerHTML }
                    }))}</div>;
                    currentPageSections = [{
                        content: pageContent as ReactElement,
                        height: currentHeight
                    }];
                }

                ReactDOM.unmountComponentAtNode(tempContainer);
            } else {
                if (currentPageHeight + contentHeight > availableHeight) {
                    pages.push({
                        content: createPageContent(currentPageSections, showHeaderCondition && isFirstPage),
                        name: `${pageName}-${pageCounter}`
                    });
                    currentPageSections = [];
                    currentPageHeight = 0;
                    pageCounter++;
                    isFirstPage = false;
                }

                currentPageSections.push({
                    content: item as ReactElement,
                    height: contentHeight
                });
                currentPageHeight += contentHeight;
            }
        }

        if (currentPageSections.length > 0) {
            pages.push({
                content: createPageContent(currentPageSections, showHeaderCondition && isFirstPage),
                name: `${pageName}-${pageCounter}`
            });
        }

        return pages;
    };

    useEffect(() => {
        const processAllPages = async () => {
            console.log('processAllPages');
            const prioritizeRenderer = new PrioritizeElementRenderer({localFilters: props.localFilters});
            const renderedContent = prioritizeRenderer.render();
            
            const prioritizePages = await processContentIntoPages(
                [
                    <Content>
                        {renderedContent}
                    </Content>
                ],
                "prioritize",
                true
            );

            setPages(prioritizePages);
            topStore.setPrioritizePagesName(prioritizePages.map((page) => page.name));
        };

        processAllPages();
    }, [props.localFilters, prioritizeStore.questionsList, prioritizeStore.selectedQuestion, prioritizeStore.prioritizeList]);

    return (
        <>
            {pages.map((page, index) => (
                <div key={index} data-page-name={page.name} className={page.name}>
                    {page.content}
                </div>
            ))}
        </>
    );
});

export default PDFPrioritizeContent;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const A4Container = styled(Container)`
    width: 100%;
    min-width: 300mm;
    height: 390mm;
    margin: 10mm auto;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    page-break-after: always;
    
    @media print {
        margin: 0;
        box-shadow: none;
    }
`;

const ContentContainer = styled.div`
`;


const Content = styled.div`
  width: 100%;
  display: flex;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  min-height: 312px;
`;
import http from "api/https";
import { createUrl } from "../../helpers/url";

export const createUserRole = (name: string, entityId: number): Promise<any> => {
  const data: {
    name: string;
    entity_id: number;
  } = {
    name: name,
    entity_id: entityId,
  };

  const url = createUrl({
    path: "/permission/entity_permissions/Role",
  });
  return http.postGO(url, data);
};

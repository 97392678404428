import * as React from "react";
import { observer } from "mobx-react";
import store from "./store";
import DoneIcon from "@mui/icons-material/Done";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "components/pagination/Pagination";
import styled from "styled-components";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { entityAttributesRecipients } from "types/entityAttributesRecip";
import { Employee } from "api/go/useGetEmployeesWithAttrByEntity ";
import "./ScrollContainer.css";
import shortStringLength from "components/shortStringLength/shortStringLength";

const SORT_STATUS = {
  asc: "asc",
  desc: "desc",
};

type NewCustomTableProps = {
  isHavingEditEmployeePermission: boolean;
};

const NewCustomTable: React.FC<NewCustomTableProps> = observer((props) => {
  const isSelected = (id: number) => store.selected.indexOf(id) !== -1;
  const changeCurrentCount = (num: number) => {
    store.paginationItemsPerPage = num;
    store.paginationActivePage = 1;
    store.onSearchClicked();
  };
  const { isHavingEditEmployeePermission } = props;
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = store.selected.indexOf(id);
    let newSelected: readonly number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(store.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(store.selected.slice(1));
    } else if (selectedIndex === store.selected.length - 1) {
      newSelected = newSelected.concat(store.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        store.selected.slice(0, selectedIndex),
        store.selected.slice(selectedIndex + 1),
      );
    }
    store.changeSelect(newSelected);
  };

  const handlerSort = (attribute: entityAttributesRecipients, sort: string) => {
    if (attribute.is_unit_based) store.changeSort(null, sort, null, attribute.id);
    else store.changeSort(null, sort, attribute.id);
  };

  const getAttributeValue = (attribute: entityAttributesRecipients, employee: Employee) => {
    const attributeId = attribute.level > 0 ? attribute.level : attribute.id;
    return employee.attributes.find(
      (attr) => attr.entity_attribute_id === attributeId || attr.level === attributeId,
    )?.value;
  };

  const handlerArrowIconStyle = (attribute: entityAttributesRecipients, sortStatus: string) => {
    if (
      attribute.is_unit_based
        ? store.sortOrganizationId === attribute.id && store.sortType === sortStatus
        : store.sortAttributeId === attribute.id && store.sortType === sortStatus
    ) {
      return { color: "var(--colorBrandForeground1)" };
    }
    return null;
  };

  return (
    <>
      <TablesWrapper>
        <MainTableWrapper>
          <Table className="child-surveys" aria-label="simple table">
            <TableHead>
              <SurveyTableRowHeader>
                <SurveyValueTableCellHeader $minWidth={280} width={280} $isLeftTable>
                  <FullNameHeader id="EmployeesList_Grid_Column_FullName">
                    <TableHeaderCellWithSort>
                      FULL NAME <PreferredNameStyle>(PREFERRED NAME)</PreferredNameStyle>
                      <BlockSortStyle>
                        <KeyboardArrowUpIconStyle
                          id={`EmployeesList_Grid_Column_FullName_Sort_Asc`}
                          style={
                            store.sortField === "fullName" && store.sortType === SORT_STATUS.asc
                              ? { color: "var(--colorBrandForeground1)" }
                              : null
                          }
                          onClick={() => store.changeSort("fullName", "asc")}
                        />
                        <PlugStyle />
                        <KeyboardArrowDownIconStyle
                          id={`EmployeesList_Grid_Column_FullName_Sort_Desc`}
                          style={
                            store.sortField === "fullName" && store.sortType === SORT_STATUS.desc
                              ? { color: "var(--colorBrandForeground1)" }
                              : null
                          }
                          onClick={() => store.changeSort("fullName", "desc")}
                        />
                      </BlockSortStyle>
                    </TableHeaderCellWithSort>
                  </FullNameHeader>
                </SurveyValueTableCellHeader>
                <SurveyValueTableCellHeader
                  id="EmployeesList_Grid_Column_EmailAddress"
                  $minWidth={200}
                  width={200}
                  $isLeftTable
                >
                  <TableHeaderCellWithSort>
                    EMAIL ADDRESS
                    <BlockSortStyle>
                      <KeyboardArrowUpIconStyle
                        id={`EmployeesList_Grid_Column_EmailAddress_Sort_Asc`}
                        style={
                          store.sortField === "email" && store.sortType === SORT_STATUS.asc
                            ? { color: "var(--colorBrandForeground1)" }
                            : null
                        }
                        onClick={() => store.changeSort("email", "asc")}
                      />
                      <PlugStyle />
                      <KeyboardArrowDownIconStyle
                        id={`EmployeesList_Grid_Column_EmailAddress_Sort_Desc`}
                        style={
                          store.sortField === "email" && store.sortType === SORT_STATUS.desc
                            ? { color: "var(--colorBrandForeground1)" }
                            : null
                        }
                        onClick={() => store.changeSort("email", "desc")}
                      />
                    </BlockSortStyle>
                  </TableHeaderCellWithSort>
                </SurveyValueTableCellHeader>
                <SurveyValueTableCellHeader
                  id="EmployeesList_Grid_Column_UniqueId"
                  $minWidth={97}
                  width={97}
                  $isLeftTable
                >
                  <TableHeaderCellWithSort>
                    UNIQUE ID
                    <BlockSortStyle>
                      <KeyboardArrowUpIconStyle
                        id={`EmployeesList_Grid_Column_UniqueId_Sort_Asc`}
                        style={
                          store.sortField === "uniqueId" && store.sortType === SORT_STATUS.asc
                            ? { color: "var(--colorBrandForeground1)" }
                            : null
                        }
                        onClick={() => store.changeSort("uniqueId", "asc")}
                      />
                      <PlugStyle />
                      <KeyboardArrowDownIconStyle
                        id={`EmployeesList_Grid_Column_UniqueId_Sort_Desc`}
                        style={
                          store.sortField === "uniqueId" && store.sortType === SORT_STATUS.desc
                            ? { color: "var(--colorBrandForeground1)" }
                            : null
                        }
                        onClick={() => store.changeSort("uniqueId", "desc")}
                      />
                    </BlockSortStyle>
                  </TableHeaderCellWithSort>
                </SurveyValueTableCellHeader>
              </SurveyTableRowHeader>
            </TableHead>

            <TableBody id={`EmployeesList_Grid_Body_1`}>
              {store.Employees.map((employee, indexRow) => {
                const isItemSelected = isSelected(employee.id);
                return (
                  <SurveyTableRow
                    id={`EmployeesList_Grid_Row_${indexRow}_${employee.id}`}
                    key={employee.id + employee.fullName}
                  >
                    <SurveyValueTableCell $minWidth={280} width={280} scope="row" $isLeftTable>
                      <CellBlock id={`EmployeesList_Grid_Table_0_${indexRow}_${employee.id}`}>
                        {StoreLayout.lockEdit || !isHavingEditEmployeePermission ? (
                          <div style={{ minWidth: 28, margin: "0 10px" }}></div>
                        ) : (
                          <Checked
                            id={`EmployeesList_Grid_Table_Checbox_0_${indexRow}_${employee.id}`}
                            $active={isItemSelected}
                            onClick={() => handleClick(null, employee.id)}
                          >
                            {isItemSelected && <DoneIcon />}
                          </Checked>
                        )}
                        {shortStringLength(employee.fullName, 15, "bottom-end")}{" "}
                        <PreferredNameStyle>
                          ({shortStringLength(employee.preferredName, 20, "bottom-end")})
                        </PreferredNameStyle>
                      </CellBlock>
                    </SurveyValueTableCell>
                    <SurveyValueTableCell
                      id={`EmployeesList_Grid_Table_1_${indexRow}_${employee.id}`}
                      $minWidth={200}
                      scope="row"
                      $isLeftTable
                    >
                      {shortStringLength(employee.email, 35, "bottom-end")}
                    </SurveyValueTableCell>
                    <SurveyValueTableCell
                      id={`EmployeesList_Grid_Table_2_${indexRow}_${employee.id}`}
                      $minWidth={97}
                      scope="row"
                      $isLeftTable
                    >
                      {shortStringLength(employee.unique_id, 20, "bottom-end")}
                    </SurveyValueTableCell>
                  </SurveyTableRow>
                );
              })}
            </TableBody>
          </Table>
        </MainTableWrapper>

        <SurveyTableWrapper className="parent-surveys">
          <Table className="child-surveys" aria-label="simple table">
            <TableHead>
              <SurveyTableRowHeader id={`EmployeesList_Grid_2_Container`}>
                {store.AttributesUnits.map((attribute, index) => {
                  return (
                    <SurveyValueTableCellHeader
                      id={`EmployeesList_Grid2_Column_${index}`}
                      $minWidth={120}
                      align="left"
                      key={attribute.id}
                    >
                      <TableHeaderCellWithSort>
                        {attribute.name?.slice(0, 35)}
                        <BlockSortStyle>
                          <KeyboardArrowUpIconStyle
                            id={`EmployeesList_Grid2_Column_Sort_Asc_${index}`}
                            style={handlerArrowIconStyle(attribute, SORT_STATUS.asc)}
                            onClick={() => handlerSort(attribute, "asc")}
                          />
                          <PlugStyle />
                          <KeyboardArrowDownIconStyle
                            id={`EmployeesList_Grid2_Column_Sort_Desc_${index}`}
                            style={handlerArrowIconStyle(attribute, SORT_STATUS.desc)}
                            onClick={() => handlerSort(attribute, "desc")}
                          />
                        </BlockSortStyle>
                      </TableHeaderCellWithSort>
                    </SurveyValueTableCellHeader>
                  );
                })}
              </SurveyTableRowHeader>
            </TableHead>

            <TableBody id={`EmployeesList_Grid_Body_2`}>
              {store.Employees.map((employee, indexRow) => {
                return (
                  <SurveyTableRow
                    id={`EmployeesList_Grid2_Row_${indexRow}`}
                    key={employee.id + employee.fullName}
                  >
                    {store.AttributesUnits.map((attribute, i) => {
                      const attributeValue = getAttributeValue(attribute, employee);

                      return (
                        <SurveyValueTableCell $minWidth={120} key={i} align="left">
                          <TableCellValue id={`EmployeesList_Grid2_Table_${i}_${indexRow}`}>
                            {attributeValue}
                          </TableCellValue>
                        </SurveyValueTableCell>
                      );
                    })}
                  </SurveyTableRow>
                );
              })}
            </TableBody>
          </Table>
        </SurveyTableWrapper>
      </TablesWrapper>

      <PaginationWrapper>
        <Pagination
          activePage={store.paginationActivePage}
          itemsCountPerPage={store.paginationItemsPerPage}
          onChange={(e) => {
            store.paginationSetActivePage(e);
            store.loadUsers();
          }}
          pageRangeDisplayed={store.paginationItemsPerPage}
          totalItemsCount={store.countEmployee}
          totalPages={store.countEmployee / store.paginationItemsPerPage}
          setCount={changeCurrentCount}
        />
      </PaginationWrapper>
    </>
  );
});

const TablesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const MainTableWrapper = styled(TableContainer)`
  position: sticky;
  background-color: var(--colorNeutralBackground1);
  box-shadow: 5px 1px 4px -2px var(--colorNeutralForeground7);
  padding-bottom: 7px;
  width: 50% !important;
`;

const SurveyTableWrapper = styled(TableContainer)`
  background-color: var(--colorNeutralBackground1);
  width: 50% !important;
`;

const FullNameHeader = styled.div`
  margin-left: 40px;
`;

const SurveyTableRow = styled(TableRow)`
  height: 63px;
  min-width: 120px;
  max-width: 300px;
  max-height: 63px;
`;

const SurveyTableRowHeader = styled(TableRow)`
  height: 70px;
  max-height: 70px;
`;

const TableCellValue = styled.span`
  height: 63px;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
`;

const SurveyValueTableCellHeader = styled(TableCell)<{
  $minWidth?: number;
  $isLeftTable?: boolean;
}>`
  min-width: ${(props) => (props.$minWidth ? props.$minWidth : 120)}px;
  padding: 10px !important;
  margin: 0;
  background-color: var(--colorPaletteBlueBackground3);

  &:first-child {
    min-width: ${(props) => (props.$isLeftTable ? 280 : 200)}px;
    width: ${(props) => (props.$isLeftTable ? 280 : 200)}px;
  }
`;

const SurveyValueTableCell = styled(TableCell)<{ $minWidth?: number; $isLeftTable?: boolean }>`
  min-width: ${(props) => (props.$minWidth ? props.$minWidth : 200)}px;
  margin: 0 !important;
  padding: 0 10px !important;
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  height: 63px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &:first-child {
    min-width: ${(props) => (props.$isLeftTable ? 280 : 200)}px;
    width: ${(props) => (props.$isLeftTable ? 280 : 200)}px;
    padding: 0 2px !important;

    > span {
      padding-left: 8px !important;
    }
  }
`;

const CellBlock = styled.div`
  display: flex;
  align-items: center;
  height: 63px;
  margin: 0 !important;
  padding: 0 !important;

  > span:last-child {
    color: var(--colorNeutralForeground5);
    margin-left: 5px;
  }
`;

const PreferredNameStyle = styled.span`
  color: var(--colorNeutralForeground5);
  margin-left: 5px;
`;

const Checked = styled.span<{ $active?: boolean }>`
  width: 28px;
  min-width: 28px;
  margin: 0 10px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralBackground3)"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
    color: var(--colorNeutralBackground1);
  }
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const PlugStyle = styled.div`
  width: 20px;
  height: 8px;
  background-color: transparent;
  position: absolute;
  top: 6px;
  left: -5px;
  z-index: 999;
`;

const TableHeaderCellWithSort = styled.div`
  display: flex;
  align-items: center;
  color: var(--colorNeutralForeground2);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

export default NewCustomTable;

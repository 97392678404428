import http from "api/https";
import { createUrl } from "../../helpers/url";

export const deleteUserRole = (currentRoleId: number, entityId: number): Promise<any> => {
  const data: {
    role_id: number;
    entity_id: number;
  } = {
    role_id: currentRoleId,
    entity_id: entityId,
  };

  const url = createUrl({
    path: "/permission/entity_permissions/RemoveRole",
  });
  return http.postGO(url, data);
};
